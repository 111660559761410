import React from "react";
import {
  formatDateToLongFormat,
  formatDateToShortFormat,
} from "../../../utils/IGdateTimeFunctions";

const IGHeader = ({
  title,
  date,
  league,
  className,
  leagueClassName,
  leagueStyle,
  small = false,
}) => {
  return (
    <div className="mb-2">
      <section
        className={`mb-6 flex w-full min-w-[450px] items-center justify-between px-2 font-bold italic ${className}`}
      >
        <h1 className={small ? `text-3xl` : "text-4xl"}>{title}</h1>
        <p className={`w-fit text-nowrap ${small ? "text-xl" : "text-2xl"}`}>
          {small ? formatDateToShortFormat(date) : formatDateToLongFormat(date)}
        </p>
      </section>
      {league && (
        <h2
          className={`-mt-2 px-2 font-league text-3xl italic ${leagueClassName ?? ''}`}
          style={leagueStyle ?? {}}
        >
          {league}
        </h2>
      )}
    </div>
  );
};

export default IGHeader;
