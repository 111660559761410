import React from 'react';
import { ConfigProvider } from 'antd';
import Router from './Router';
import useLoginStore from 'store/loginStore';
import useGlobalStore from 'store/globalStore';
import { useEffect } from 'react';

const theme = {
};

export default function App() {
  const user = useLoginStore(s => s.user);
  const ORGANIZATION_ID = useGlobalStore(s => s.ORGANIZATION_ID);
  const setOrganizationId = useGlobalStore(s => s.setOrganizationId);

  useEffect(() => {
      if (user) {
        const selectedOrg = user.Organizations.find(o => o.organizationId == ORGANIZATION_ID);
        if (selectedOrg == null) {
          console.log('Invalid organization selected:', ORGANIZATION_ID);
          const defaultOrg = user.Organizations[0]?.organizationId;
          if (defaultOrg != null) {
            setOrganizationId(user.Organizations[0]?.organizationId);
          } else {
            setOrganizationId('NO_ORGANIZATIONS')
          }
        }
      }
  }, [user])

  return (
    <>
      <ConfigProvider theme={theme}>
        <Router />
      </ConfigProvider>
    </>
  );
}