import { Button, Modal, Tooltip } from 'antd';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import SchedulerSimulationLegend from 'components/SchedulerSimulation/SchedulerSimulationLegend';

const GamesTimelineModal = ({ open, onClose, games: gamesParam }) => {

  const games = useMemo(() => {
    return (open && gamesParam?.filter(g => g.startDate)) ?? [];
  }, [gamesParam, open]);

  console.log('GamesTimelineModal - games', games);

  const teams = useMemo(() => {
    const teams = {};
    for (const game of games) {
      if (game.HomeTeam) {
        teams[game.HomeTeam?.name] = game.HomeTeam;
      }
      if (game.AwayTeam) {
        teams[game.AwayTeam?.name] = game.AwayTeam;
      }
    }
    return Object.values(teams)
      .map(team => (
        {
          name: team.name,
          games: games.filter(g => g.HomeTeam?.name === team.name || g.AwayTeam?.name === team.name),
        }))
      .sort((a, b) => a.name?.localeCompare(b.name));
  }, [games]);

  const minGameDate = useMemo(() => {
    return games.reduce((min, p) => p.startDate < min ? p.startDate : min, games[0]?.startDate);
  }, [games]);

  const maxGameDate = useMemo(() => {
    return games.reduce((max, p) => p.startDate > max ? p.startDate : max, games[0]?.startDate);
  }, [games]);

  const dates = useMemo(() => {
    const dates = [];
    if (minGameDate && maxGameDate) {
      let currentDate = moment(minGameDate);
      while (currentDate.isSameOrBefore(maxGameDate)) {
        dates.push(currentDate.format('YYYY-MM-DD'));
        currentDate = currentDate.add(1, 'days');
      }
    }
    return dates;
  }, [minGameDate, maxGameDate]);

  function getSlotClassName(team, date) {
    const games = team.games.filter(g => g.startDate === date);
    let className = '';
    if (games.length == 0) {
      className += 'bg-gray-300 hover:bg-gray-400';
    }
    if (games.length == 1) {
      className += 'bg-green-400 hover:bg-gray-500';
    }
    if (games.length > 1) {
      className += 'bg-red-400 hover:bg-gray-500';
    }
    if (moment(date).format('ddd') == 'Sun') {
      className += ' mr-1';
    }
    return className;
  }

  function getSlotTooltip(team, date) {
    const games = team.games.filter(g => g.startDate === date);
    return (
      <div className="text-center">
        <div className="mb-6">
          <div>{moment(date).format('DD.MM.YYYY')}</div>
          <div>{moment(date).format('dddd')}</div>
        </div>
        {games.length > 0
          ?
          <div className="flex flex-col gap-3">
            {games.map((game, idx) => (
              <div key={idx}>
                <div>
                  {game.startTime}
                </div>
                <div>
                  {game.Venue?.name}
                </div>
                <div>
                  {game.HomeTeam?.name} vs {game.AwayTeam?.name}
                </div>
                <div>
                  {game.Phase?.Group?.Season?.Competition?.name} - {game.Phase?.Group?.Season?.name}
                </div>
                <div>
                  {game.Phase?.Group?.name} - {game.Phase?.name}
                </div>
              </div>
            ))}
          </div>
          :
          <div>
            No games
          </div>
        }
      </div>
    )
  }

  return (
    <Modal
      title={null}
      open={open}
      onCancel={onClose}
      footer={null}
      className="min-w-[95vw] max-w-[95vw]"
    >
      <div>
        <h3 className="text-center mt-0 mb-4 font-xl">Game Timeline</h3>

        <SchedulerSimulationLegend
          className="mx-auto"
          items={
            [
              { color: 'bg-gray-300', text: 'No games' },
              { color: 'bg-green-400', text: 'One game' },
              { color: 'bg-red-400', text: 'Multiple games' },
            ]
          } />

        <div className="overflow-scroll w-full flex justify-center mt-0 mb-3">
          <div className="mt-4 grid grid-cols-[repeat(3,max-content)] gap-y-2 gap-x-4 items-center mx-auto">
            {teams.map((team, idx) => (
              <React.Fragment key={idx}>
                <div className="text-right">
                  {team.name}
                </div>
                <div className="text-gray-400">
                  ({team.games.length})
                </div>
                <div className="flex gap-[1px]">
                  {dates.map((date, idx) => (
                    <Tooltip title={getSlotTooltip(team, date)} key={idx}>
                      <span
                        className={`inline-block w-1 h-6 cursor-pointer ${getSlotClassName(team, date)}`}
                        key={idx}
                      ></span>
                    </Tooltip>
                  ))}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default GamesTimelineModal