import React, { useMemo, useState } from 'react'
import { Checkbox, Form, Input, Row, Col, DatePicker, Select, ColorPicker } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import FormSelect from 'components/Form/FormSelect';

const ColorListSelect = ({ value, colors, ...props }) => {
    // use a state to remember the custom color - let's the user return to it if he accidentally changed it
    const allColors = colors
        .concat(
            value && !colors.find((c) => c.hex == value)
                ? [{
                    hex: value,
                    name: '(' + value + ')'
                }]
                : []
        );

    let options = 
        allColors.map(c => {
            if (c.hex?.toLowerCase() == '#ffffff' || c.hex?.toLowerCase() == '#fff' || c.hex?.toLowerCase() == 'white') {
                return {
                    key: c.name,
                    value: c.hex,
                    label: (
                        <span className="flex items-center" style={{ color: '#444' }}>
                            {c.hex == '#ffffff' && <span className="w-2 h-2 mr-2 rounded-full border-2 border-[#444]" style={{ backgroundColor: c.hex }}></span>}
                            {c.name}
                        </span>
                    )
                }
            } else {
                return {
                    key: c.name,
                    value: c.hex,
                    label: (
                        <span className="flex items-center" style={{ color: c.hex }}>
                            {c.hex != '#ffffff' && <span className="w-2 h-2 mr-2 rounded-full border-2" style={{ backgroundColor: c.hex, borderColor: c.hex }}></span>}
                            {c.name}
                        </span>
                    )
                }
            }
        });
    return (
        <>
        <Select 
            {...props}
            value={value}
            options={options}
        />
        </>
    );
}

const FormColorList = ({ label, name, required, autoFocus, colors, formItemProps, fieldItemProps = {}, currentColor, ...props }) => {
    return (
        <HmsFormItem
            label={label}
            name={name}
            required={required}
            {...formItemProps}
      >
            <ColorListSelect
                colors={colors}
                {...fieldItemProps}
                allowClear
        />
      </HmsFormItem>
    );
}

export default FormColorList