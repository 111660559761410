import React from "react";
import GameUpcomingCard from "./GameUpcomingCard";
import LogoOrTitle from "./LogoOrTitle";

// component to display each venue's details and its games
const VenueTodayGameList = ({ venue }) => {
  const heightClass = 'h-[70px]';
  const maxHeightClass = 'max-h-[70px]';

  return (<div className="w-[400px]">
    <div className={`w-full border-b-4 border-white flex items-center justify-center ${maxHeightClass}`}>
      <LogoOrTitle
        logo={venue.VenueLogo}
        title={venue.VenueName}
        className={`mx-auto max-w-full ${heightClass}`}
        logoClassName={`aspect-video object-contain ${heightClass}`}
        titleClassName={`text-3xl flex items-center justify-center ${heightClass}`}
      />
    </div>
    {venue.Games.map((game, index) => (
      <GameUpcomingCard key={index} game={game} />
    ))}
  </div>
  );
};

export default VenueTodayGameList;
