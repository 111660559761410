import { create } from 'zustand';

const useLocationStore = create(
  (set, get) => ({
    history: [],
    replaceNext: false,

    addLocationToHistory: (location) => {
      if (get().replaceNext) {
        set({
          history: [...get().history.slice(0, -1), location],
          replaceNext: false,
        });
      } else {
        set({
          history: [...get().history, location],
        });
      }
    },

    getPreviousLocationFromHistory: () => {
      const history = get().history;
      if (history.length > 1) {
        return history[history.length - 2];
      }
    },

    replaceNextLocation: (replaceNext) => {
      set({
        replaceNext: replaceNext,
      });
    }
  })
);

export default useLocationStore;