import React, { useRef } from 'react'
import { Checkbox, Form, Input, Row, Col, DatePicker, Select, Space } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import useFetchEntities from 'hooks/useFetchEntities';
import FormSelect from 'components/Form/FormSelect';
const { Option } = Select;

const VenueSelect = ({ defaultValue, onChange, name }) => {
    const { data, isLoading } = useFetchEntities('Venue');

    return (
        <FormSelect
            allowClear
            isLoading={isLoading}
            name={name}
            label='Venue'
            options={
                data?.map(v => ({
                    value: v.venueId,
                    searchText: v.name,
                    label: v.name,
                }))
            }
      />
    )
}

export default VenueSelect