import { Button, Checkbox, Modal, Space, Spin, Tooltip } from 'antd'
import useFetchEntities from 'hooks/useFetchEntities'
import React, { useState } from 'react'
import { unique } from 'utils/oneLiners'

const TeamRegistrationDialog_SelectGroups = ({ registration, setRegistration }) => {
  const { data: seasons } = useFetchEntities('Season', {
    filters: {
      status: 'OPEN_FOR_REGISTRATION',
    },
    relations: [
      'Competition',
      'Groups',
    ].join(','),
  });

  const groupOptions = registration?.seasonId && seasons &&
    seasons.find(s => s.seasonId == registration?.seasonId)?.Groups;

  return (
    <>
      <h4 className="mt-0">4. Select preferred group(s)</h4>

        {groupOptions == null && 
          <div className="flex items-center justify-center h-[100px] w-full">
            <Spin />
          </div>
        }

        <div className="flex flex-col gap-2">
          {groupOptions && groupOptions.map(g => (
            <Checkbox
              key={g.groupId} 
              checked={(registration.groupIds ?? []).indexOf(g.groupId) != -1}
              onChange={(e) => {
                  if (e.target.checked) {
                    setRegistration({
                      ...registration,
                      groupIds: unique([
                        ...registration.groupIds ?? [],
                        g.groupId,
                      ]),
                    });
                  } else {
                    setRegistration({
                      ...registration,
                      groupIds: (registration.groupIds ?? []).filter(id => id != g.groupId),
                    });
                  }
                }
              }
            >{g.name}</Checkbox>
          ))
          }
        </div>
    </>
  )
}

export default TeamRegistrationDialog_SelectGroups
