import React from 'react'

const PoweredByWootera = ({ className }) => {
  return (
    <div className={"mt-8 mb-8 flex justify-center " + className}>
      <a
        href="https://www.wootera.com/"
        target="_blank"
      >
        <img
          src="/PoweredByWootera.svg"
          className="w-[100px] hover:brightness-75 transition duration-300 ease-in-out"
          alt="Powered By Wootera"
        />
      </a>
    </div>
  )
}

export default PoweredByWootera