import HmsFormItem from 'components/Form/HmsFormItem'
import TipTapEditor from 'components/TipTapEditor'
import React from 'react'

const HtmlEditorItem = ({ options, value, onChange, ...props }) => {
  return (
    <TipTapEditor
      value={value}
      onChange={(value) => onChange(value)}
    />
  )
}

const FormHtmlEditor = ({ label, name, required, autoFocus, options, formItemProps, fieldItemProps }) => {
  return (
      <HmsFormItem
          label={label}
          name={name}
          required={required}
          {...formItemProps}
      >
        <HtmlEditorItem />
      </HmsFormItem>
  )
}

export default FormHtmlEditor