import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Spin, Table, Card, Button, Layout, Dropdown, Space, Modal, Tooltip } from 'antd';
import { LoadingOutlined, RollbackOutlined, LeftOutlined, SettingOutlined } from '@ant-design/icons';
import { DownOutlined } from '@ant-design/icons';
import icons from 'utils/icons';
import BeforeUnload from 'components/BeforeUnload';
import ConfirmDelete from 'components/ConfirmDelete';
import HmsFormItem from 'components/Form/HmsFormItem';
import { Link, useNavigate } from 'react-router-dom';
import Confirm from 'components/Confirm';
import { simulateCtrlClick } from 'utils/simulateCtrlClick';

const HmsFormInner = ({
    title = '[Form Title]',
    saveButtonClick,
    saveAndCloseButtonClick,
    deleteButtonClick,
    closeButtonClick,
    cloneButtonClick,
    isDirty,
    isSaving,
    canDelete,
    canClone,
    buttons,
    systemButtons,
    children
}) => {
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
    const saveDisabled = !isDirty || isSaving;
    const [closeConfirmOpen, setCloseConfirmOpen] = useState(false);

    const handleConfirmDelete = () => {
        setConfirmDeleteVisible(false);
        deleteButtonClick();
    };

    const handleCancelDelete = () => {
        setConfirmDeleteVisible(false);
    };

    const handleKeyPress = (event) => {
        // console.log(event);

        if ((event.metaKey || event.ctrlKey) && event.keyCode == 'S'.charCodeAt(0)) {
            event.preventDefault();
            if (!saveDisabled) {
                // console.log('Ctrl-S pressed')
                saveButtonClick();
            }
        }

        if ((event.metaKey || event.ctrlKey) && event.key == 'Enter') {
            event.preventDefault();
            if (!saveDisabled) {
                // console.log('Ctrl-Enter pressed')
                saveAndCloseButtonClick();
            }
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    const navbar = true;

    return (
        <>
            {/* <BeforeUnload isDirty={isDirty} /> */}

            {/* <Confirm
                message={
                    <div className="mt-4 mb-6">
                        <div>Are you sure to close and discard usaved changes?</div>
                    </div>
                }
                confirmButtonText="Close"
                visible={closeConfirmOpen}
                handleConfirm={() => closeButtonClick()}
                handleCancel={() => setCloseConfirmOpen(false)}
            /> */}

            <ConfirmDelete visible={confirmDeleteVisible} handleConfirm={handleConfirmDelete} handleCancel={handleCancelDelete} />

            <Card
                size="small"
                className={`w-[calc(100vw-25px)] ${navbar ? 'desktop:w-[calc(100vw-238px)]' : 'desktop:w-[calc(100vw-33px)]'} overflow-auto`}
                title={
                    <div className="py-2 flex flex-wrap gap-x-3 gap-y-2">
                        <span className="font-bold">{title}</span>

                        {(saveButtonClick || deleteButtonClick) &&
                            <span className="h-5 border-l-1 hidden desktop:flex" style={{ borderLeft: '1px solid gray' }} />
                        }

                        {saveButtonClick &&
                            <Dropdown.Button
                                className="w-fit"
                                size="small"
                                type="primary"
                                disabled={saveDisabled}
                                placement="bottomRight"
                                menu={{
                                    items: [
                                        {
                                            key: 'save',
                                            label: 'Save and close',
                                            icon: icons.Save,
                                            onClick: () => saveAndCloseButtonClick(),
                                        },
                                    ]
                                }}
                                onClick={() => saveButtonClick()}
                                trigger={["click"]}
                                icon={<DownOutlined />}
                            >
                                {isSaving ? <LoadingOutlined spin /> : icons.Save} Save
                            </Dropdown.Button>
                        }

                        {/* {closeButtonClick &&
                            <Button
                                size="small"
                                onClick={() => isDirty ? setCloseConfirmOpen(true) : closeButtonClick()}
                            >
                                Close
                            </Button>
                        } */}

                        {canDelete && deleteButtonClick &&
                            <Button
                                size="small"
                                className="custom-danger"
                                onClick={() => setConfirmDeleteVisible(true)}
                                icon={icons.Trash}
                            >
                                Delete
                            </Button>
                        }

                        {cloneButtonClick &&
                            <Button
                                size="small"
                                disabled={!canClone}
                                onClick={() => cloneButtonClick()}
                            >
                                <div className="flex items-center gap-1">
                                    {icons.Clone}
                                    <span>Clone</span>
                                </div>
                            </Button>
                            }

                        {buttons && buttons.map((b, idx) => (
                            <Tooltip key={idx} title={b.disabled && b.disabledTooltip}>
                                <Button
                                    size="small"
                                    type={b.type}
                                    icon={b.icon}
                                    onClick={(e) => {
                                        b.onClick?.(e);
                                    }}
                                    disabled={b.disabled}
                                >
                                    {b.label}
                                </Button>
                            </Tooltip>
                        ))}

                        <span className="desktop:ml-auto font-normal">
                            {(systemButtons && systemButtons.length > 0) &&
                                <Dropdown
                                    // trigger={["click"]}
                                    placement="bottomRight"
                                    menu={{
                                        items: systemButtons.map((i, idx) => ({ ...i, key: idx }))
                                    }}>
                                    <Button size="small">
                                        <Space>
                                            <SettingOutlined />
                                            System
                                            <DownOutlined />
                                        </Space>
                                    </Button>
                                </Dropdown>
                            }
                        </span>
                    </div>
                }
            >
                <div>
                    {children}
                </div>
            </Card>
        </>
    )
}

export default HmsFormInner