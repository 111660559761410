import useIsMobile from 'hooks/useIsMobile';
import React from 'react'

const StandingsTextCell = ({ text, align, sorted, className }) => {
  const isMobile = useIsMobile();

  return (
    <div className={
      `table-cell align-middle py-2 pl-2 first:pl-2 pr-2 last:pr-2 ` +
      `font-thin text-[#696969] border-gray-300 border-b-[1px] ` +
      `${sorted ? 'bg-gray-100 !font-normal' : ''} ` +
      `${!isMobile ? 'text-[1.25rem]' : 'text-[0.9rem]'} ` +
      `${align == 'left' ? 'text-left' : align == 'center' ? 'text-center' : 'text-right'} ` +
      `${className}`
    }>{text}</div>
  )
}

export default StandingsTextCell