import useEntities from 'hooks/useEntities';
import { entityColumnsToAntColumns } from 'utils/entityColumnsToAntColumns';
import HmsTableWithFilters from 'components/HmsTableWithFilters';

const SeasonGroups = ({ sourceEntityId: id, data: childrenData }) => {
    const entities = useEntities();
    const childEntity = entities.Group;

    return (
        <HmsTableWithFilters
            exportEnabled={true}
            layoutType='plain'
            createButtonLink={`/groups/_new_?parentId=${id}`}
            onRowClickedLink={(record) => childEntity.entityUrl(record)}
            columns={entityColumnsToAntColumns(childEntity, [
                'name',
                'nick',
                'short',
                'Color',
                'teamsLimit',
                'status',
            ])}
            data={childrenData}
        />
    )
}

export default SeasonGroups
