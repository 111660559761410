import React from "react";

const GraphWinLossDraw = ({ wins, losses, draws }) => {
  const totalGames = wins + losses + draws;
  const winsPercentage = (wins / totalGames) * 100;
  const lossesPercentage = (losses / totalGames) * 100;
  const drawsPercentage = (draws / totalGames) * 100;

  if (totalGames == 0) {
    return (
      <div className="z-[2] h-5 w-full border-2 bg-hockey-card-bg border-hockey-card-bg">
        {/* <div className="w-full bg-gray-400 h-full text-xs" title={`No games yet`}>
          No games yet
        </div> */}
      </div>
    )
  }
  return  (
    <div className="z-[2] flex h-5 w-full border-2 border-hockey-card-bg">
      <div
        className="bg-green-500"
        style={{ width: `${winsPercentage}%` }}
        title={`Wins: ${wins}`}
      />
      <div
        className="bg-red-500"
        style={{ width: `${lossesPercentage}%` }}
        title={`Losses: ${losses}`}
      />
      <div
        className="bg-yellow-500"
        style={{ width: `${drawsPercentage}%` }}
        title={`Draws: ${draws}`}
      />
    </div>
  );
};

export default GraphWinLossDraw;
