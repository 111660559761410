import React from "react";
import IGImg from "./IGImg";
import { Tooltip } from "antd";

const WinLossDrawIcon = ({ status, className }) => {
  return (
    <p
      className={`${
        status == 'W' ? "bg-green-600" :
        status == 'L' ? "bg-red-500" :
        status == 'D' ? "bg-orange-500" :
        ""
        } ${className}`}
    >
      {status ?? <span>&nbsp;</span>}
    </p>
  );
};

const StreakSmallOpponent = ({ game }) => {
  const status = game.status == 'FINISHED' ? (game.MainTeamGoals > game.OpponentTeamGoals ? "W" : game.OpponentTeamGoals > game.MainTeamGoals ? "L" : "D") : null;
  const score = game.status == 'FINISHED' ? `${game.MainTeamGoals} : ${game.OpponentTeamGoals}` : '-:-';

  return (
    <div className="w-6 text-center text-sm">
      <WinLossDrawIcon status={status}  />
      <p className={`flex items-center justify-center ${!status ? 'text-gray-400 font-sans' : ''}`}>
        {score}
      </p>
      {game.OpponentTeam?.logoUrl && (
        <Tooltip title={
          <div className="flex flex-col items-center">
            <div>{game.MainTeam?.name}</div>
            <div>vs</div>
            <div>{game.OpponentTeam?.name}</div>
            <div className="mt-2">{game.status == 'FINISHED' ? score : game.status}</div>
          </div>
        } placement="bottom">
          <IGImg
            src={game.OpponentTeam.logoUrl}
            alt={game.OpponentTeam.name}
            className="mx-auto aspect-square w-5 rounded-full cursor-pointer"
          />
        </Tooltip>
      )}
    </div>
  );
};

export default StreakSmallOpponent;
