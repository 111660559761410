import { useContext, useEffect, useMemo } from 'react';
import { ListContext } from 'components/ListsProvider';
const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const useLists = () => {
  const { schema, logoUrlPrefix, colors, countries, lists } = useContext(ListContext);

  const events = [
    { entity: 'GameEventSave', name: 'Save', color: '#7c5f0d' },
    { entity: 'GameEventGoal', name: 'Goal', color: '#0a0' },
    { entity: 'GameEventPenalty', name: 'Penalty', color: '#a00' },
    { entity: 'GameEventInjury', name: 'Injury', color: '#a00' },
    { entity: 'GameEventFaceOff', name: 'Face off', color: '#968f66' },
    { entity: 'GameEventPenaltyshot', name: 'Penalty shot', color: '#99371f' },
  ];

  const userRoles = [
    { value: 'ADMIN', label: 'Admin' },
    { value: 'TEAM_MANAGER', label: 'Team Manager' },
  ];

  const FILE_SIZE_LIMIT = 25 * 1024 * 1024;

  return { schema, logoUrlPrefix, colors, countries, ...lists, events, userRoles, FILE_SIZE_LIMIT };
}

export default useLists;
