import FormInput from 'components/Form/FormInput';
import FormDatePicker from 'components/Form/FormDatePicker';
import FormSelect from 'components/Form/FormSelect';
import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useEntities from 'hooks/useEntities';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import useLoadEntity from 'hooks/useLoadEntity';
import HmsField from 'components/Form/HmsField';
import EntityLogoEditor from 'components/EntityLogoEditor';
import HmsTabbedTables from 'components/HmsTabbedTables';
import EntityAttachments from 'components/EntityAttachments';
import TabsWithUrlMemory from 'components/TabsWithUrlMemory';
import HmsEntityFormGridFullWidth from 'components/Form/HmsEntityFormGridFullWidth';

const Organization = () => {
  const entities = useEntities();
  const entity = entities.Organization;
  const { id, isLoading, isError, isNotFound, data, reload } = useLoadEntity({ entity });
  const { relationsData: attachmentsData, reloadRelations: reloadAttachments } = useLoadEntity({
    entity,
    relations: [
      'Attachments',
    ].join(',')
  });  

  return (
    <>
      <HmsEntityFormController
        {... { entity, id, isLoading, isError, isNotFound, data, reload }}
        form={(
          <TabsWithUrlMemory
            className="no-tab-padding w-[calc(100vw-75px)] desktop:w-[calc(100vw-280px)]"
            items={[
              {
                label: `Basic`,
                children: (
                  <div className="flex flex-col desktop:flex-row w-full">
                    <div className="flex items-center justify-center ml-4 mr-6 mb-5 desktop:mb-0 ">
                      <EntityLogoEditor entity={entities.Organization} id={id} data={data} size={112} />
                    </div>
                    <div className="flex-auto">
                      <HmsEntityFormGridBasic>
                        <HmsField name="name" autoFocus />
                        <HmsField name="nick" />
                        <HmsField name="short" />
                        <HmsField name="status" />
                        <HmsField name="foundDate" />
                        <HmsField name="legalName" />
                        <HmsField name="urlSlug" />
                        <HmsField name="urlGameLinks" />
                        <HmsField name="bankAccountNumber" />
                        <HmsField name="bankAccountCurrency" />
                        <HmsField name="bankCode" />
                      </HmsEntityFormGridBasic>
                    </div>
                  </div>
                )
              },
              {
                label: `Preferences`,
                children: (
                  <>
                    <HmsEntityFormGridBasic>
                      <HmsField 
                        name="newPlayerReqFields" 
                        type="CHECKBOX_GROUP"
                        options={[
                          { value: 'firstName', label: 'First Name', disabled: true },
                          { value: 'lastName', label: 'Last Name', disabled: true },
                          { value: 'teamId', label: 'Team' },
                          { value: 'listPlayerLevelId', label: 'Player Level' },
                          { value: 'jerseyNumber', label: 'Jersey' },
                          { value: 'listPositionId', label: 'Position' },
                          { value: 'publicProfileUrl', label: 'Public Profile URL' },
                          { value: 'stick', label: 'Stick' },
                          { value: 'gender', label: 'Gender' },
                          { value: 'birthday', label: 'Birthday' },
                          { value: 'height', label: 'Height' },
                          { value: 'weight', label: 'Weight' },
                          { value: 'cityBirth', label: 'City of Birth' },
                          { value: 'countryBirth', label: 'Country of Birth' },
                          { value: 'email', label: 'E-mail' },
                          { value: 'phone', label: 'Phone' },
                        ]}
                      />
                    </HmsEntityFormGridBasic>
                    <HmsEntityFormGridFullWidth>
                      <HmsField name="mailFooterText" />
                    </HmsEntityFormGridFullWidth>
                  </>
                )
              },
            ].map(tab => ({ ...tab, key: tab.label }))}
          />
        )}
        footer={id != '_new_' && (
          <div className="mt-4">
            <div className="mt-4">
              <HmsTabbedTables
                tables={[
                  {
                    title: "Attachments" + (attachmentsData?.Attachments ? (' (' + attachmentsData?.Attachments?.length + ')') : ''),
                    children: <EntityAttachments entity={entity.name} id={data?.[entity.primaryKey]} data={attachmentsData} reload={reloadAttachments} />,
                  },
               ]}
              />
            </div>
          </div>
        )}
      />
    </>
  );
}

export default Organization
