import React, { useMemo, useState } from 'react';
import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useEntities from 'hooks/useEntities';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import useLoadEntity from 'hooks/useLoadEntity';
import HmsField from 'components/Form/HmsField';
import TeamPlayers from 'components/Team/TeamPlayers';
import HmsTabbedTables from 'components/HmsTabbedTables';
import TeamSponsors from 'components/Team/TeamSponsors';
import TeamAwards from 'components/Team/TeamAwards';
import TeamPersons from 'components/Team/TeamPersons';
import TeamGroups from 'components/Team/TeamGroups';
import TeamPhases from 'components/Team/TeamPhases';
import EntityLogoEditor from 'components/EntityLogoEditor';
import EntityAttachments from 'components/EntityAttachments';
import useLoginStore from 'store/loginStore';
import TeamRegistrationTeamManager from 'components/Team/TeamRegistrationTeamManager';
import EntityChangeRequests from 'components/EntityChangeRequests';
import ApprovalBar from 'components/ApprovalBar';

const Team = () => {
  const userRoles = useLoginStore(s => s.roles);
  const isAdmin = userRoles?.indexOf('ADMIN') != -1;
  const entities = useEntities();
  const entity = entities.Team;
  const { id, isLoading, isReloading, isError, isNotFound, data, reload, relationsData, reloadRelations } = useLoadEntity({
    entity,
    relations: [
      'People',
      isAdmin && 'Sponsors',
      isAdmin && 'Awards',
    ].filter(t => t).join(','),
  });
  const { relationsData: groupsData, reload: reloadGroups } = useLoadEntity({
    entity: isAdmin ? entity : null,
    relations: [
      'Groups,Groups>Season,Groups>Season>Competition',
    ].join(','),
  });
  const { relationsData: phasesData, reload: reloadPhases } = useLoadEntity({
    entity: isAdmin ? entity : null,
    relations: [
      'Phases,Phases>Group,Phases>Group>Season,Phases>Group>Season>Competition',
    ].join(','),
  });
  const { relationsData: playersData, reload: reloadPlayers } = useLoadEntity({
    entity: entity,
    relations: [
      'Players,Players>Teams,Players>ListPositions,Players>Jerseys,Players>ListPlayerLevel',
    ].join(','),
  });
  const { relationsData: attachmentsData, reloadRelations: reloadAttachments } = useLoadEntity({
    entity,
    relations: [
      'Attachments',
    ].join(',')
  });

    // Change requests
    const { relationsData: changeRequestsData, reloadRelations: reloadChangeRequests } = useLoadEntity({
      entity,
      relations: [
        'ChangeRequests',
      ].join(',')
    });
    const unprocessedChangeRequestCount = useMemo(() => changeRequestsData?.ChangeRequests?.filter(r => r.status == 'NEW')?.length ?? 0, [changeRequestsData]);
    const showChangeRequestDot = isAdmin && unprocessedChangeRequestCount > 0;
    const isLocked = data && data?.status != 'DRAFT' && !isAdmin;
    const canCreateChangeRequest = isLocked && !isAdmin;
    const changeRequestFields = [
      { value: 'name',          },
      { value: "nick",          },
      { value: "short",         },
      { value: "foundDate",     },
      { value: "status",        },
      { value: "teamColor1",    },
      { value: "teamColor2",    },
      { value: "teamColor3",    },
      { value: "jerseyColor1",  },
      { value: "jerseyColor2",  },
      { value: "jerseyColor3",  },
      { value: "AddPlayer",              label: 'Add Existing Player'         },
      { value: "RemovePlayer",           label: 'Remove Existing Player'      },
      { value: "Other", label: 'Other / Comment' },
    ].map(f => ({
      ...f,
      label: f.label ?? entity.fields[f.value].displayName,
      type: f.type ?? 'text',
    }));

  return (
    <>
      <HmsEntityFormController
        {... { entity, id, isLoading, isReloading, isError, isNotFound, data, reload, canCreateChangeRequest, isLocked, canCreateChangeRequest, changeRequestFields, reloadChangeRequests }}
        header={({ isDirty }) =>
          <ApprovalBar
            status={data?.status}
            entity={entity}
            isDirty={isDirty}
            id={id}
            reload={reload}
          />
        }
        form={(
          <>
            <div className="flex flex-col desktop:flex-row w-full">
              <div className="flex items-center justify-center ml-4 mr-6 mb-5 desktop:mb-0 ">
                <EntityLogoEditor entity={entities.Team} id={id} data={data} size={112} disabled={false /* isLocked */} />
              </div>
              <div className="flex-auto">
                <HmsEntityFormGridBasic>
                  <HmsField name="name" fieldItemProps={{ disabled: isLocked }} />
                  <HmsField name="nick" fieldItemProps={{ disabled: isLocked }} />
                  <HmsField name="short" fieldItemProps={{ disabled: isLocked }} required={isAdmin} />
                  {isAdmin &&
                    <HmsField name="externalId" fieldItemProps={{ disabled: isLocked }} />
                  }
                  <HmsField name="foundDate" fieldItemProps={{ disabled: isLocked }} />
                  <HmsField name="status" fieldItemProps={{ disabled: !isAdmin || isLocked }} />
                </HmsEntityFormGridBasic>
              </div>
            </div>

            <h4>Colors:</h4>
            <HmsEntityFormGridBasic>
              <HmsField name="teamColor1" fieldItemProps={{ disabled: isLocked }} />
              <HmsField name="teamColor2" fieldItemProps={{ disabled: isLocked }} />
              <HmsField name="teamColor3" fieldItemProps={{ disabled: isLocked }} />
            </HmsEntityFormGridBasic>

            <HmsEntityFormGridBasic>
              <HmsField name="jerseyColor1" fieldItemProps={{ disabled: isLocked }} />
              <HmsField name="jerseyColor2" fieldItemProps={{ disabled: isLocked }} />
              <HmsField name="jerseyColor3" fieldItemProps={{ disabled: isLocked }} />
            </HmsEntityFormGridBasic>
          </>
        )}
        footer={id != '_new_' && (
          <div className="mt-4">
            <div className="mt-4">
              <HmsTabbedTables
                tables={[
                  !isAdmin && {
                    key: 'Registration',
                    title: "Registration",
                    children: <TeamRegistrationTeamManager
                      sourceEntityId={data?.teamId}
                      sourceEntityData={data}
                    />
                  },
                  {
                    key: 'Players',
                    title: "Players" + (playersData?.Players ? (' (' + playersData?.Players.length + ')') : ''),
                    children: <TeamPlayers
                      // canEdit={isAdmin || data?.status == 'DRAFT'}
                      sourceEntityId={data?.teamId}
                      data={playersData?.Players}
                      onChange={reloadPlayers}
                    />
                  },
                  {
                    key: 'Persons',
                    title: "Persons" + (relationsData?.People ? (' (' + relationsData?.People.length + ')') : ''),
                    children: <TeamPersons
                      // canEdit={isAdmin || data?.status == 'DRAFT'}
                      sourceEntityId={data?.teamId}
                      data={relationsData?.People}
                      onChange={reloadRelations}
                    />
                  },
                  isAdmin && {
                    key: 'Sponsors',
                    title: "Sponsors" + (relationsData?.Sponsors ? (' (' + relationsData?.Sponsors.length + ')') : ''),
                    children: <TeamSponsors
                      sourceEntityId={data?.teamId}
                      data={relationsData?.Sponsors}
                      onChange={reloadRelations}
                    />
                  },
                  isAdmin && {
                    key: 'Awards',
                    title: "Awards" + (relationsData?.Awards ? (' (' + relationsData?.Awards.length + ')') : ''),
                    children: <TeamAwards
                      sourceEntityId={data?.teamId}
                      data={relationsData?.Awards}
                      onChange={reloadRelations}
                    />
                  },
                  isAdmin && {
                    key: 'Groups',
                    title: "Groups" + (groupsData?.Groups ? (' (' + groupsData?.Groups.length + ')') : ''),
                    children: <TeamGroups
                      sourceEntityId={data?.teamId}
                      data={groupsData?.Groups}
                      onChange={reloadGroups}
                    />
                  },
                  isAdmin && {
                    key: 'Phases',
                    title: "Phases" + (phasesData?.Phases ? (' (' + phasesData?.Phases.length + ')') : ''),
                    children: <TeamPhases
                      sourceEntityId={data?.teamId}
                      data={phasesData?.Phases}
                      onChange={reloadPhases}
                    />
                  },
                  {
                    key: 'Attachments',
                    title: "Attachments" + (attachmentsData?.Attachments ? (' (' + attachmentsData?.Attachments?.length + ')') : ''),
                    children: <EntityAttachments entity={entity.name} id={data?.[entity.primaryKey]} data={attachmentsData} reload={reloadAttachments} />,
                  },
                  {
                    key: 'ChangeRequests',
                    title: "Change Requests" + (changeRequestsData?.ChangeRequests ? (' (' + (unprocessedChangeRequestCount > 0 ? '' + unprocessedChangeRequestCount + '/' : '') + changeRequestsData?.ChangeRequests?.length + ')') : ''),
                    children: <EntityChangeRequests sourceEntityName={entity.name} sourceEntityId={data?.[entity.primaryKey]} data={changeRequestsData?.ChangeRequests} onChange={reloadChangeRequests} />,
                    showDot: showChangeRequestDot,
                  },
                ].filter(t => t)}
              />
            </div>
          </div>
        )}
      />
    </>
  );
}

export default Team
