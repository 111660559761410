import useEntities from 'hooks/useEntities';
import HmsEntityTableController from 'components/HmsEntityTableController';

const SchedulerConfigs = () => {
  const entities = useEntities();
  const filterGroups = null;
  const showColumns = [
    { column: 'createdAt', defaultSortOrder: 'descend' },
    'name',
    'Competition',
    'Season',
    'GroupCount',
    'TeamCount',
    'VenueCount',
    'SchedulerSimulationCount',
    'GeneratedGameCount',
    'createdBy',
  ];

  return (
    <HmsEntityTableController
      entity={entities.SchedulerConfig}
      filterGroups={filterGroups}
      showColumns={showColumns}
    />
  )
}

export default SchedulerConfigs