import useEntities from 'hooks/useEntities';
import RelationsEditable from 'components/RelationsEditable';

const SchedulerConfigVenues = ({ sourceEntityId, data, onChange, isLocked }) => {
    const entities = useEntities();
    const sourceEntity = entities.SchedulerConfig;
    const targetEntity = entities.Venue;

    return (
        <RelationsEditable 
            data={data}
            isLocked={isLocked}
            sourceEntity={sourceEntity} 
            targetEntity={targetEntity}
            sourceEntityId={sourceEntityId}
            emptyMessage={`This ${sourceEntity.displayName} does not have any ${targetEntity.displayNamePlural}`}
            onChange={onChange}
            canOpen={true}
        />
    )    

}

export default SchedulerConfigVenues
