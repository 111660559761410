// export const defaultPhaseIdIG = '3BC7DFE0-37BF-11EE-A852-DDFA9E2BD87C'
// export const defaultGroupIdIG = '1D81EF80-37BF-11EE-A852-DDFA9E2BD87C'
// export const defaultDateIG = '2024-04-11'


export const defaultPhaseIdIG = null
export const defaultGroupIdIG = null
export const defaultDateIG = null

export const dateFormatIG = 'YYYY-MM-DD'

