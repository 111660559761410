import React, { useRef } from 'react'
import { Checkbox, Form, Input, Row, Col, DatePicker, Select, Space } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import useFetchEntities from 'hooks/useFetchEntities';
import FormSelect from 'components/Form/FormSelect';
import EntityLogo from 'components/EntityLogo';

const TeamSelect = ({ name, label, phaseId, rules, dependencies }) => {
    const { data, isLoading } = useFetchEntities('Team', {
      filters: {
        'Phase>phaseId': phaseId != null ? phaseId : undefined,
      }
    });

    return (
        <FormSelect
          isLoading={isLoading}
          allowClear
          name={name}
          label={label}
          formItemProps={{rules, dependencies}}
          options={
            data?.map(t => ({
              value: t.teamId,
              searchText: t.name,
              label: <span className="flex items-center gap-2 ml-[2px]">
                <EntityLogo entity={t} size={20} />
                {t.name}
              </span>
            }))
          }
        />
    )
}

export default TeamSelect