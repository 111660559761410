import React from 'react'

const DisplayColor = ({ name, hex }) => {
    return (
        <span className="flex items-center" style={{ color: hex }}>
            <span className="w-3 h-3 mr-2 rounded-full" style={{ backgroundColor: hex }}></span>
            {name}
        </span>
    )
}

export default DisplayColor