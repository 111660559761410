import React from 'react'

const SchedulerSimulationLegend = ({ className, items }) => (
    <div className={"flex items-center gap-2 " + className} style={{width: 'min-content'}}>
        {items.map((i, idx) => {
            const bgColor = i.color;
            return (
                <span className="flex items-center gap-2" key={idx}>
                    <span className={`inline-block w-6 h-3 ${bgColor} border-1 border-solid border-gray-400`}></span>
                    <span className="whitespace-nowrap">{i.text}</span>
                </span>
            )
        })}
    </div>
  );

export default SchedulerSimulationLegend