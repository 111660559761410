import React from "react";
import BaseTableRows from "./BaseTableRows";
import { fullName } from "../../../utils/oneLiners";
import IGImg from "./IGImg";

const TablePlayers = ({ data, highlightColID }) => {
  /* Check if show level column */
  const hasLevel = data.some((obj) => obj.PlayerLevelCode !== undefined);

  const getClassName = (colID) => {
    return colID === highlightColID ? "border-x-2 !text-lg" : "";
  };

  return (
    <table className="w-full text-center font-bold italic">
      <thead>
        <tr className="border-b">
          <th colSpan="2" />
          <th className="px-4 text-left">Player</th>
          <th className="px-4">T</th>
          <th className="px-4">GP</th>
          <th className="bg-green-600 px-4">G</th>
          <th className="bg-lime-600 px-4">A</th>
          <th className="bg-lime-300/70 px-4">P</th>
          <th className="bg-red-400/80 px-4">PIM</th>
          <th className="bg-orange-400 px-4 text-xs not-italic">★</th>
          {hasLevel && <th className="px-4 text-gray-400">Level</th>}
        </tr>
      </thead>
      <tbody>
        {data.map((player, index) => (
          <tr key={index}>
            <BaseTableRows
              index={index}
              icon={player.PlayerLogo}
              name={fullName(player)}
              nameClassName={index < 1 && "!font-bold"}
            />
            <td className="h-6 w-8 overflow-hidden border-0 border-red-400">
              <IGImg
                src={player.TeamLogo}
                alt=""
                className="h-6 w-full scale-[1.35] object-cover"
              />
            </td>
            <td>{player.Z}</td>
            <td className={`text-green-600 ${getClassName("g")}`}>
              {player.G}
            </td>
            <td className={`text-lime-600 ${getClassName("a")}`}>{player.A}</td>
            <td className={`text-lime-300/70 ${getClassName("p")}`}>
              {player.P}
            </td>
            <td className={`text-sm text-red-400/80 ${getClassName("tm")}`}>
              {player.TM}
            </td>
            <td className={`text-orange-400 ${getClassName("stars")}`}>
              {player.Stars}
            </td>
            {hasLevel && (
              <td className="text-sm text-gray-400">{player.PlayerLevelCode?.substr(0, 1) ?? '-'}</td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TablePlayers;
