import useEntities from 'hooks/useEntities';
import HmsEntityTableController from 'components/HmsEntityTableController';
import useIsMobile from 'hooks/useIsMobile';
import useFetchEntities from 'hooks/useFetchEntities';
import Dot from 'components/Dot';

const Players = () => {
    const entities = useEntities();
    const isMobile = useIsMobile();

    const { data: players } = useFetchEntities('Player', { onlyAttributes: 'playerId', extraAttributes: 'NewChangeRequestCount', filters: { 'HasNewChangeRequest': 1 } });
    const hasWithChangeRequests = players?.length > 0;

    return (
      <HmsEntityTableController
        entity={entities.Player}
        columnSets={[
          {
            name: 'Active',
            filters: { status: ['ACTIVE'] },
            columns: [
              { column: 'NameWithLogo', width: 200, fixed: !isMobile ? 'left' : undefined, },
              { column: 'LevelTag', },
              { column: 'Teams', width: 250, },
              { column: 'Positions', width: 125, },
              { column: 'Jerseys', width: 125, },
              'status',
              'birthday',
              'phone',
              'email',
              'gender',
              'stick',
              'weight',
              'height',
              'externalId',
              'countryBirth',
              'cityBirth',
              'country',
              'city',
            ],
          },
          {
            name: 'Not active',
            filters: { status: ['INACTIVE', 'RETIRED', 'UNKNOWN'] },
            columns: [
              { column: 'NameWithLogo', width: 200, fixed: !isMobile ? 'left' : undefined, },
              { column: 'LevelTag', },
              { column: 'Teams', width: 250, },
              { column: 'Positions', width: 125, },
              { column: 'Jerseys', width: 125, },
              'status',
              'birthday',
              'phone',
              'email',
              'gender',
              'stick',
              'weight',
              'height',
              'externalId',
              'countryBirth',
              'cityBirth',
              'country',
              'city',
            ],
          },
          {
            name: 'Newly Registered',
            filters: { status: ['DRAFT', 'PREAPPROVED'] },
            columns: [
              'createdAt',
              { column: 'NameWithLogo', width: 200 },
              { column: 'Teams', width: 250, },
              { column: 'RegistrationExistingTeam', width: 250, },
              { column: 'Level', width: 175, },
              { column: 'Positions', width: 125, },
              { column: 'Jerseys', width: 125, },
              'status',
              'birthday',
              { column: 'phone', width: 175, },
              { column: 'email', width: 250, },
              'gender',
              'stick',
              'weight',
              'height',
              'countryBirth',
              'cityBirth',
            ],
          },
          {
            name: <Dot show={hasWithChangeRequests} dotClassName="top-[-6px] right-[-10px]">Change Requests</Dot>,
            key: 'ChangeRequests',
            shitFilter: (r) => r.NewChangeRequestCount > 0,
            columns: [
              'createdAt',
              { column: 'NameWithLogo', width: 200 },
              { column: 'Teams', width: 250, },
              { column: 'RegistrationExistingTeam', width: 250, },
              { column: 'Level', width: 175, },
              { column: 'Positions', width: 125, },
              { column: 'Jerseys', width: 125, },
              'status',
              'birthday',
              { column: 'phone', width: 175, },
              { column: 'email', width: 250, },
              'gender',
              'stick',
              'weight',
              'height',
              'countryBirth',
              'cityBirth',
              'NewChangeRequestCount',
            ],
          },
        ]}
      />
    )
}

export default Players