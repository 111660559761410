import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useEntities from 'hooks/useEntities';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import useLoadEntity from 'hooks/useLoadEntity';
import HmsField from 'components/Form/HmsField';
import PersonTeams from 'components/Person/PersonTeams';
import HmsTabbedTables from 'components/HmsTabbedTables';
import PersonAwards from 'components/Person/PersonAwards';
import EntityAttachments from 'components/EntityAttachments';
import useLoginStore from 'store/loginStore';
import { useMemo, useState } from 'react';
import EntityChangeRequests from 'components/EntityChangeRequests';
import useFetchEntities from 'hooks/useFetchEntities';
import Confirm from 'components/Confirm';
import EntityLogo from 'components/EntityLogo';
import ListSelect from 'components/Form/ListSelect';
import ApprovalBar from 'components/ApprovalBar';

const Person = () => {
  const userRoles = useLoginStore(s => s.roles);
  const isAdmin = userRoles?.indexOf('ADMIN') != -1;
  const entities = useEntities();
  const entity = entities.Person;
  const { id, isLoading, isReloading, isError, isNotFound, data, reload, relationsData, reloadRelations } = useLoadEntity({
    entity, 
    relations: [
      'Teams',
      'Awards',
    ].join(','),
  });
  const { relationsData: attachmentsData, reloadRelations: reloadAttachments } = useLoadEntity({
    entity,
    relations: [
      'Attachments',
    ].join(',')
  });
  const needsToSelectTeam = !isAdmin && !data?.status;
  const { data: teamsData } = useFetchEntities(needsToSelectTeam && 'Team', { onlyAttributes: ['teamId', 'name', 'logoUrl'] });

  // Change requests
  const { relationsData: changeRequestsData, reloadRelations: reloadChangeRequests } = useLoadEntity({
    entity,
    relations: [
      'ChangeRequests',
    ].join(',')
  });
  const unprocessedChangeRequestCount = useMemo(() => changeRequestsData?.ChangeRequests?.filter(r => r.status == 'NEW')?.length ?? 0, [changeRequestsData]);
  const showChangeRequestDot = isAdmin && unprocessedChangeRequestCount > 0;
  const isLocked = data && data?.status != 'DRAFT' && !isAdmin;
  const canCreateChangeRequest = isLocked && !isAdmin;
  const changeRequestFields = [
    { value: 'firstName',         },
    { value: "lastName",          },
    { value: "birthday",          },
    { value: "status",            },
    { value: "height",            },
    { value: "weight",            },
    { value: "phone",             },
    { value: "email",             },
    { value: "countryBirth",      },
    { value: "cityBirth",         },
    { value: "country",           },
    { value: "city",              },
    { value: "Other", label: 'Other / Comment' },
  ].map(f => ({
    ...f,
    label: f.label ?? entity.fields[f.value].displayName,
    type: f.type ?? 'text',
  }));

  return (
    <>
      <HmsEntityFormController
        {... { entity, id, isLoading, isReloading, isError, isNotFound, data, reload, canCreateChangeRequest, isLocked, canCreateChangeRequest, changeRequestFields, reloadChangeRequests }}
        header={({ isDirty }) =>
          <ApprovalBar
            status={data?.status}
            entity={entity}
            isDirty={isDirty}
            id={id}
            reload={reload}
          />
        }
        form={(
          <>
            <HmsEntityFormGridBasic>
              <HmsField name="firstName" fieldItemProps={{ disabled: isLocked }} />
              <HmsField name="lastName" fieldItemProps={{ disabled: isLocked }} />
              <HmsField name="birthday" fieldItemProps={{ disabled: isLocked }} />
              <HmsField name="status" fieldItemProps={{ disabled: !isAdmin || isLocked }} />
              <ListSelect name="listPersonRoleId" entity="ListPersonRole" label="Role" fieldItemProps={{ disabled: isLocked }} />
            </HmsEntityFormGridBasic>

            <h4>Personal</h4>
            <HmsEntityFormGridBasic>
              <HmsField name="height" addonAfter="cm" fieldItemProps={{ disabled: isLocked }} />
              <HmsField name="weight" addonAfter="kg" fieldItemProps={{ disabled: isLocked }} />
            </HmsEntityFormGridBasic>
            <HmsEntityFormGridBasic>
              <HmsField name="phone" fieldItemProps={{ disabled: isLocked }} />
              <HmsField name="email" fieldItemProps={{ disabled: isLocked }} />
            </HmsEntityFormGridBasic>
            <HmsEntityFormGridBasic>
              <HmsField name="countryBirth" type="COUNTRY_LIST" label="Country of Birth" fieldItemProps={{ disabled: isLocked }} />
              <HmsField name="cityBirth" label="City of Birth" fieldItemProps={{ disabled: isLocked }} />
            </HmsEntityFormGridBasic>
            <HmsEntityFormGridBasic>
              <HmsField name="country" type="COUNTRY_LIST" fieldItemProps={{ disabled: isLocked }} />
              <HmsField name="city" fieldItemProps={{ disabled: isLocked }} />
            </HmsEntityFormGridBasic>
            {isAdmin &&
              <h4>External relations</h4>
            }
            {isAdmin &&
              <HmsEntityFormGridBasic>
                <HmsField name="externalId" fieldItemProps={{ disabled: isLocked }} />
              </HmsEntityFormGridBasic>
            }
            {needsToSelectTeam &&
              <>             
                <h4>Teams</h4>
                <HmsEntityFormGridBasic>
                  <HmsField 
                    name="Teams"
                    label="Team Membership" 
                    type="CHECKBOX_GROUP"
                    required={true}
                    fieldItemProps={{ dataType: 'ARRAY' }}
                    options={teamsData?.map((team, idx) => ({
                      value: team.teamId,
                      label: <div className="flex items-center gap-2 my-1 w-full">
                        <EntityLogo entity={team} size={24} useExperimentalBgStyle={true} />
                        <span>{team.name}</span>
                      </div>,
                    }))}
                  />
                </HmsEntityFormGridBasic>
              </>
            }
            </>
        )}
        footer={id != '_new_' && (
          <div className="mt-4">
            <div className="mt-4">
              <HmsTabbedTables
                tables={[
                  {
                    key: 'Teams',
                    title: "Teams" + (relationsData?.Teams ? (' (' + relationsData?.Teams?.length + ')') : ''),
                    children: <PersonTeams 
                      sourceEntityId={data?.personId} 
                      sourceEntityData={data}
                      data={relationsData?.Teams} 
                      onChange={reloadRelations} 
                    />
                  },
                  isAdmin && {
                    key: 'Awards',
                    title: "Awards" + (relationsData?.Awards ? (' (' + relationsData?.Awards?.length + ')') : ''),
                    children: <PersonAwards 
                      sourceEntityId={data?.personId} 
                      data={relationsData?.Awards} 
                      onChange={reloadRelations} 
                    />
                  },
                  {
                    key: 'Attachments',
                    title: "Attachments" + (attachmentsData?.Attachments ? (' (' + attachmentsData?.Attachments?.length + ')') : ''),
                    children: <EntityAttachments entity={entity.name} id={data?.[entity.primaryKey]} data={attachmentsData} reload={reloadAttachments} />,
                  },
                  {
                    key: 'ChangeRequests',
                    title: "Change Requests" + (changeRequestsData?.ChangeRequests ? (' (' + (unprocessedChangeRequestCount > 0 ? '' + unprocessedChangeRequestCount + '/' : '') + changeRequestsData?.ChangeRequests?.length + ')') : ''),
                    children: <EntityChangeRequests sourceEntityName={entity.name} sourceEntityId={data?.[entity.primaryKey]} data={changeRequestsData?.ChangeRequests} onChange={reloadChangeRequests} />,
                    showDot: showChangeRequestDot,
                  },
                ].filter(t => t)}
              />
            </div>
          </div>
        )}      />
    </>
  );
}

export default Person
