import { Button, Input, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Select, Space } from 'antd';
import useLists from 'hooks/useLists';
import { formatTimeString, parseTimeString } from 'utils/timeString';

const GameEventDuration = ({ name, data, onValueChange }) => {

    const onGameTimeChange = (e) => {
        const gameTime = e.target.value;
        onValueChange(name, gameTime);
    }

    const onGameTimeBlur = () => {
        const gameTime = data?.event?.[name];
        const gameTimeParsed = parseTimeString(gameTime);
        if (gameTimeParsed) {
            onValueChange(name, formatTimeString(gameTimeParsed));
        }
    }

    return (
        <div className="grid gap-x-4 grid-cols-10 items-start">
            <div className="col-span-2 h-8 flex items-center">
                Duration:
            </div>
            <div className="col-span-8 h-8 flex items-center">
                <Input 
                    className="w-20" 
                    placeholder="00:00" 
                    tabIndex={1} 
                    name={name} 
                    value={data?.event?.[name]} 
                    disabled={!onValueChange}
                    onChange={onValueChange ? onGameTimeChange : () => {}} 
                    onBlur={onValueChange ? onGameTimeBlur : () => {}}
                />
            </div>
        </div>
    )
}

export default GameEventDuration;
