import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Spin } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import useLoginStore from 'store/loginStore';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { set } from 'lodash';
import usePageTitle from 'hooks/usePageTitle';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import LoginLayout from 'components/Layouts/LoginLayout';

const ForgotPassword = () => {
  usePageTitle('HMS - Forgot Password');

  const forgotPassword = useLoginStore(s => s.forgotPassword);

  const [status, setStatus] = useState('ENTER_EMAIL');

  const [errorMessage, setErrorMessage] = useState(null);

  const [submitInProgress, setSubmitInProgress] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setSubmitInProgress(true);
    setErrorMessage(null);

    const res = await forgotPassword({
      email: values.email,
    });

    if (res.status == 200) {
      setStatus('SUCCESS');
    } else {
      if (res.status == 400 && res.body.status == 'SLOW_DOWN') {
        setErrorMessage('Too many requests - please slow down.');
      } else {
        setErrorMessage('Sorry, there was an error processing your request.');
      }
    }

    setSubmitInProgress(false);
  }

  return (
    <LoginLayout>
      <div className="min-h-[250px]">

      {status == 'ENTER_EMAIL' &&
        <>
          <div className="text-white mt-2 mb-8 text-center">
            Please enter your email to reset password:
          </div>

          <Form
            form={form}
            onFinish={onFinish}
          >
            <div className="w-[300px] grid grid-cols-1 mx-auto">
              <div>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: 'Please enter your email!' }]}
                >
                  <Input
                    className="w-full"
                    placeholder="E-mail address"
                    prefix={<UserOutlined className="text-gray-400 mr-1" />}
                  />
                </Form.Item>
              </div>

              <div>
                {!submitInProgress && <Button className="w-full" type="primary" htmlType="submit" >Send Request</Button>}
                {submitInProgress && <Button className="w-full" type="primary" htmlType="submit" loading={true}> </Button>}
              </div>
            </div>
          </Form>

          <div className="h-10 mt-7">
            {errorMessage &&
              <div className="h-10 mt-7 text-center text-red-500">
                <CloseCircleFilled className="mr-2" />
                <span className="mb-4 text-center">
                  {errorMessage}
                </span>
              </div>
            }
          </div>
        </>
      }

      {status == 'SUCCESS' &&
        <div className="max-w-[80vw] flex items-center justify-center">
          <div className="text-center text-white">
            <div className="mt-4 mb-4 text-green-400 text-lg flex gap-2 items-center justify-center">
              <CheckCircleFilled />
              All set!
            </div>
            <div className="mt-8">
              <p>
                If we have a user with this e-mail address in our system, we have just sent a password reset link to it.
              </p>
              <p>
                Please give it a few moments to arrive and check you Junk/Spam folder in case you don't see it.
              </p>
            </div>
          </div>
        </div>
      }

    </div>
    </LoginLayout>
  )
}

export default ForgotPassword
