import useEntities from 'hooks/useEntities';
import RelationsTable from 'components/RelationsTable';
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select, Table, TimePicker, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import moment from 'moment';
import SchedulerConfigIcePatternModal from 'components/SchedulerConfig/SchedulerConfigIcePatternModal';
import SchedulerConfigIcePatternsCalendar from 'components/SchedulerConfig/SchedulerConfigIcePatternsCalendar';

const SchedulerConfigIcePatterns = ({ sourceEntityId, data, onChange, phases, isLocked }) => {
    const entities = useEntities();
    const sourceEntity = entities.SchedulerConfig;
    const targetEntity = entities.SchedulerConfigIcePattern;
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [addModalValues, setAddModalValues] = useState(null);
    const [mapOpen, setMapOpen] = useState(false);

    const addClicked = () => {
        setAddModalValues(null);
        setAddModalOpen(moment().format('YYYY-MM-DD-HH-mm-ss'));
    }

    const editClicked = (record) => {
        setAddModalValues(record);
        setAddModalOpen(moment().format('YYYY-MM-DD-HH-mm-ss'));
    }

    const onAddModalClose = () => {
        setAddModalOpen(false);
        onChange();
    }

    return (
        <>
            <SchedulerConfigIcePatternModal 
                key={addModalOpen}
                sourceEntityId={sourceEntityId} 
                addModalOpen={addModalOpen} 
                onClose={onAddModalClose}
                phases={phases}
                values={addModalValues}
            />

            <Modal
                open={mapOpen}
                onCancel={() => setMapOpen(false)}
                footer={null}
                className="max-w-[90vw] min-w-[90vw]"
                title="Ice Patterns Calendar"
            >
                <div className="py-5">
                    <SchedulerConfigIcePatternsCalendar
                        phases={phases}
                        icePatterns={data}
                    />
                </div>
            </Modal>            

            <RelationsTable
                data={data}
                sourceEntity={sourceEntity}
                targetEntity={targetEntity}
                sourceEntityId={sourceEntityId}
                emptyMessage={`This ${sourceEntity.displayName} does not have any ${targetEntity.displayNamePlural}`}
                removeClicked={!isLocked && (async (id) => {
                    await entities.SchedulerConfigIcePattern.delete(id);
                    onChange();
                })}
                onRowClicked={!isLocked && ((record) => editClicked(record))}
                buttons={[
                    ...(!isLocked ? [{
                        label: 'Add',
                        icon: <PlusOutlined />,
                        onClick: addClicked,
                    }] : []),
                ]}
                extraButtonBarItems={
                    <div>
                        <Button size="small" onClick={() => setMapOpen(true)}>Show Calendar</Button>
                    </div>
                }
            />
        </>
    )

}

export default SchedulerConfigIcePatterns
