import useEntities from 'hooks/useEntities';
import RelationsEditable from 'components/RelationsEditable';

const PlayerAwards = ({ data, sourceEntityId, onChange }) => {
    const entities = useEntities();

    return (
        <RelationsEditable
            data={data}
            sourceEntity={entities.Player} 
            targetEntity={entities.Award}
            emptyMessage="This player does not have any awards"
            sourceEntityId={sourceEntityId}
            addButtonText="Add Award"
            onChange={onChange}
        />
    )
}

export default PlayerAwards
