import React, { useEffect, useState } from 'react'
import { compareString } from 'utils/comparators';
import FilterWrapper from 'components/Embeds/FilterWrapper';
import useLang from 'hooks/useLang';
import moment from 'moment';

const EmbedCompetitionSeasonPhaseTeamSelector = ({
  competitionsData,
  showTeamsFilter,
  teamsData,
  lang,
  competitionId: paramCompetitionId,
  seasonId: paramSeasonId,
  groupId: paramGroupId,
  phaseId: paramPhaseId,
  setSeasonId: setParentSeasonId,
  setGroupId: setParentGroupId,
  setPhaseId: setParentPhaseId,
  setTeamId: setParentTeamId,
}) => {

  const LANG = useLang(lang);

  const [competitions, setCompetitions] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState(null);

  const [seasons, setSeasons] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(null);

  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const [phases, setPhases] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState(null);

  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);

  function toOptions(data, valueKey) {
    return data?.map(c => ({
      value: c[valueKey],
      label: c.name,
    }));
  }

  // const competitionOptions = toOptions(competitions, 'competitionId');
  // const seasonOptions = toOptions(seasons, 'seasonId');
  // const groupOptions = toOptions(groups, 'groupId');
  // const phaseOptions = toOptions(phases, 'phaseId');
  // const teamOptions = toOptions(teams, 'teamId');

  useEffect(() => {
    if (competitionsData) {
      const competitions = competitionsData.Competitions ?? [];

      // debugger;

      competitions.sort((a, b) => compareString(a.name, b.name));

      setCompetitions(competitions);

      const selectedCompetition =
        competitions.find(c => c.competitionId == paramCompetitionId)
        ?? competitions?.[0];

      setSelectedCompetition(selectedCompetition);

      //

      const seasons = selectedCompetition?.Seasons ?? [];

      seasons.sort((a, b) => compareString(a.startDate, b.startDate));

      setSeasons(seasons);

      const mostRecentSeason = seasons
        .filter(s => moment().format('YYYY-MM-DD') >= s.startDate)
        .pop();

      const selectedSeason =
        seasons.find(s => s.seasonId == paramSeasonId)
        ?? mostRecentSeason
        ?? seasons[0];

      setSelectedSeason(selectedSeason);
      setParentSeasonId?.(selectedSeason?.seasonId);

      //

      const groups = selectedSeason?.Groups ?? [];

      groups.sort((a, b) => compareString(a.name, b.name));

      setGroups(groups);

      const selectedGroup =
        groups.find(g => g.groupId == paramGroupId)
        // ?? groups[0];

      setSelectedGroup(selectedGroup);
      setParentGroupId?.(selectedGroup?.groupId);

      //

      const phases = selectedGroup?.Phases ?? [];

      phases.sort((a, b) => compareString(a.startDate, b.startDate));

      setPhases(phases);

      const mostRecentPhase = phases
        .filter(p => moment().format('YYYY-MM-DD') >= p.startDate)
        .pop();

      const selectedPhase =
        phases.find(p => p.phaseId == paramPhaseId)
        // ?? mostRecentPhase
        // ?? phases[0];
      
      setSelectedPhase(selectedPhase);
      setParentPhaseId(selectedPhase?.phaseId);
    }

  }, [competitionsData, paramCompetitionId, paramSeasonId, paramGroupId, paramPhaseId]);

  function setCompetitionId(competitionId) {
    const selectedCompetition = competitions.find(c => c.competitionId == competitionId);
    setSelectedCompetition(selectedCompetition);

    //

    const seasons = selectedCompetition?.Seasons ?? [];

    seasons.sort((a, b) => compareString(a.startDate, b.startDate));

    setSeasons(seasons);

    const mostRecentSeason = seasons
      .filter(s => moment().format('YYYY-MM-DD') >= s.startDate)
      .pop();

    const nextSelectedSeason =
      seasons.find(s => s.name == selectedSeason?.name)
      ?? seasons.find(s => s.seasonId == paramSeasonId)
      ?? mostRecentSeason
      ?? seasons[0];

    setSelectedSeason(nextSelectedSeason);
    setParentSeasonId?.(selectedSeason?.seasonId);

    //

    const groups = nextSelectedSeason?.Groups ?? [];

    groups.sort((a, b) => compareString(a.name, b.name));

    setGroups(groups);

    const selectedGroup =
      groups.find(g => g.groupId == paramGroupId)
      ?? groups[0];

    setSelectedGroup(selectedGroup);
    console.log('setParentGroupId:', selectedGroup?.groupId);
    setParentGroupId?.(selectedGroup?.groupId);

    //

    const phases = selectedGroup?.Phases ?? [];

    phases.sort((a, b) => compareString(a.startDate, b.startDate));

    setPhases(phases);

    const mostRecentPhase = phases
      .filter(p => moment().format('YYYY-MM-DD') >= p.startDate)
      .pop();

    const selectedPhase =
      phases.find(p => p.phaseId == paramPhaseId)
      ?? mostRecentPhase
      ?? phases[0];
    
    setSelectedPhase(selectedPhase);
    setParentPhaseId(selectedPhase?.phaseId);
  }

  function setSeasonId(seasonId) {
    const selectedSeason = seasons.find(s => s.seasonId == seasonId);
    setSelectedSeason(selectedSeason);
    setParentSeasonId?.(selectedSeason?.seasonId);

    //

    const groups = selectedSeason?.Groups ?? [];
    setGroups(groups);

    const nextSelectedGroup =
      groups.find(g => g.name == selectedGroup?.name)
      // ?? groups[0];
    
    setSelectedGroup(nextSelectedGroup);
    setParentGroupId?.(selectedGroup?.groupId);

    //

    const phases = nextSelectedGroup?.Phases ?? [];
    setPhases(phases);

    const mostRecentPhase = phases
      .filter(p => moment().format('YYYY-MM-DD') >= p.startDate)
      .pop();

    const nextSelectedPhase =
      phases.find(p => p.name == selectedPhase?.name)
      // ?? mostRecentPhase
      // ?? phases[0];
    
    setSelectedPhase(nextSelectedPhase);
    setParentPhaseId(nextSelectedPhase?.phaseId);

  }

  function setGroupId(groupId) {
    const selectedGroup = groups.find(g => g.groupId == groupId);
    setSelectedGroup(selectedGroup);
    console.log('setParentGroupId:', selectedGroup?.groupId);
    setParentGroupId?.(selectedGroup?.groupId);

    //

    const phases = selectedGroup?.Phases ?? [];
    setPhases(phases);

    const mostRecentPhase = phases
      .filter(p => moment().format('YYYY-MM-DD') >= p.startDate)
      .pop();

    const nextSelectedPhase =
      phases.find(p => p.name == selectedPhase?.name)
      // ?? mostRecentPhase
      // ?? phases[0];

    setSelectedPhase(nextSelectedPhase);
    setParentPhaseId(nextSelectedPhase?.phaseId);

  }

  function setPhaseId(phaseId) {
    const selectedPhase = phases.find(p => p.phaseId == phaseId);
    setSelectedPhase(selectedPhase);
    setParentPhaseId(selectedPhase?.phaseId);
  }

  ///////

  useEffect(() => {
    console.log('teamsData changed!:', teamsData);

    const teams = teamsData ?? [];

    teams.sort((a, b) => compareString(a.name, b.name));
    setTeams(teams);

    const team =
      teams.find(t => t.name == selectedTeam?.name);

    setSelectedTeam(team);
    setParentTeamId?.(team?.teamId);

  }, [teamsData]);


  function setTeamId(teamId) {
    const selectedTeam = teams.find(t => t.teamId == teamId);

    setSelectedTeam(selectedTeam);
    setParentTeamId?.(selectedTeam?.teamId);
  }

  return (
    <>
      {competitions && seasons && groups && phases &&
        <div className="flex mb-10 gap-x-8 gap-y-2 desktop:gap-y-4 flex-wrap justify-center">
          {competitions?.length > 1 &&
            <FilterWrapper
              lang={lang}
              label={LANG.COMPETITION}
              type="select"
              value={selectedCompetition?.competitionId}
              options={toOptions(competitions, 'competitionId') ?? []}
              onChange={(value) => setCompetitionId(value)}
            />
          }
          <FilterWrapper
            lang={lang}
            label={LANG.SEASON}
            type="select"
            value={selectedSeason?.seasonId}
            options={toOptions(seasons, 'seasonId') ?? []}
            onChange={(value) => setSeasonId(value)}
          />
          <FilterWrapper
            lang={lang}
            label={LANG.GROUP}
            type="select"
            allowAll={true}
            value={selectedGroup?.groupId}
            options={toOptions(groups, 'groupId') ?? []}
            onChange={(value) => setGroupId(value)}
          />
          <FilterWrapper
            lang={lang}
            label={LANG.PHASE}
            type="select"
            allowAll={true}
            value={selectedPhase?.phaseId}
            options={toOptions(phases, 'phaseId') ?? []}
            onChange={(value) => setPhaseId(value)}
          />
          {showTeamsFilter &&
            <FilterWrapper
              lang={lang}
              label={LANG.TEAM}
              type="select"
              allowAll={true}
              value={selectedTeam?.teamId}
              options={toOptions(teams, 'teamId') ?? []}
              onChange={(value) => setTeamId(value)}
            />
          }
        </div>
      }
    </>
  )
}

export default EmbedCompetitionSeasonPhaseTeamSelector