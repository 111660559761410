import useFetchData from 'hooks/useFetchData'
import React, { useEffect, useState, useMemo } from 'react'
import useLang from 'hooks/useLang';
import { ClockLoader } from 'react-spinners';
import useIsMobile from 'hooks/useIsMobile';
import EmbedCompetitionSeasonPhaseTeamSelector from 'components/Embeds/EmbedCompetitionSeasonPhaseTeamSelector';
import StandingsPlayerCell from 'components/Embeds/StandingsPlayerCell';
import StandingsTeamCell from 'components/Embeds/StandingsTeamCell';
import StandingsTextCell from 'components/Embeds/StandingsTextCell';
import StandingsPlayerLevelCell from 'components/Embeds/StandingsPlayerLevelCell';
import StandingsHeader from 'components/Embeds/StandingsHeader';
import { uniqueByProperty } from 'utils/oneLiners';
import StandingsTable from 'components/Embeds/StandingsTable';
import StandingsRow from 'components/Embeds/StandingsRow';
import EmbedTitle from 'components/Embeds/EmbedTitle';
import EmbedStatsWrapper from 'components/Embeds/EmbedStatsWrapper';

const EmbedGoaliesStatsInner = ({ competitionsData, lang, competitionId, seasonId: paramSeasonId, groupId: paramGroupId, phaseId: paramPhaseId }) => {
  const isMobile = useIsMobile();
  const LANG = useLang(lang);
  const [seasonId, setSeasonId] = useState(paramSeasonId);
  const [groupId, setGroupId] = useState(paramGroupId);
  const [phaseId, setPhaseId] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const { data, isLoading } = useFetchData(
    phaseId ? `/public/goaliesStats?phaseId=${phaseId}`
    : groupId ? `/public/goaliesStats?groupId=${groupId}`
    : seasonId ? `/public/goaliesStats?seasonId=${seasonId}`
    : null
  );

  const uniqueTeamsData = useMemo(() => {
    const allTeamsData = data?.map(p => ({ teamId: p.TeamId, name: p.Team }));
    return uniqueByProperty(allTeamsData, 'teamId');
  }, [data]);

  const filteredData = useMemo(() => {
    return teamId ? data?.filter(p => p.TeamId === teamId) : data
  }, [data, teamId]);

  return (
    <>
      <EmbedStatsWrapper>
        <EmbedTitle>{LANG.GOALIESSTATS}</EmbedTitle>

        <EmbedCompetitionSeasonPhaseTeamSelector
          competitionsData={competitionsData}
          lang={lang}
          competitionId={competitionId}
          seasonId={paramSeasonId}
          groupId={paramGroupId}
          phaseId={paramPhaseId}
          setSeasonId={setSeasonId}
          setGroupId={setGroupId}
          setPhaseId={setPhaseId}
          showTeamsFilter={true}
          teamsData={uniqueTeamsData}
          setTeamId={setTeamId}
        />

        {!filteredData && isLoading &&
          <div className="w-full h-[450px] flex justify-center items-center">
            <ClockLoader color="#aaa" size={40} />
          </div>
        }

        {filteredData && !isLoading &&
          <>
            <StandingsTable>
              <StandingsRow>
                <StandingsHeader className="w-[5%] text-left">{LANG.GOALIESSTATS_COLUMN_SHORTS['Rank']}</StandingsHeader>
                <StandingsHeader className="w-[5%] text-left !pl-8">{LANG.NAME}</StandingsHeader>
                <StandingsHeader className="w-[5%] text-left !pl-8">{LANG.TEAM}</StandingsHeader>
                <StandingsHeader isHeader={true} className="w-[3%] text-center" tooltip={LANG.GOALIESSTATS_COLUMN_SHORTS['Level']}>{LANG.GOALIESSTATS_COLUMN_SHORTS['Level']}</StandingsHeader>
                <StandingsHeader className="w-[6%] text-right" tooltip={LANG.GOALIESSTATS_COLUMN_TOOLTIPS['GP']}>{LANG.GOALIESSTATS_COLUMN_SHORTS['GP']}</StandingsHeader>
                <StandingsHeader className="w-[6%] text-right" tooltip={LANG.GOALIESSTATS_COLUMN_TOOLTIPS['Svs']}>{LANG.GOALIESSTATS_COLUMN_SHORTS['Svs']}</StandingsHeader>
                <StandingsHeader className="w-[6%] text-right" tooltip={LANG.GOALIESSTATS_COLUMN_TOOLTIPS['GA']}>{LANG.GOALIESSTATS_COLUMN_SHORTS['GA']}</StandingsHeader>
                <StandingsHeader className="w-[7%] text-right" tooltip={LANG.GOALIESSTATS_COLUMN_TOOLTIPS['GAA']}>{LANG.GOALIESSTATS_COLUMN_SHORTS['GAA']}</StandingsHeader>
                <StandingsHeader className="w-[8%] text-right" tooltip={LANG.GOALIESSTATS_COLUMN_TOOLTIPS['SvP']} sorted={true}>{LANG.GOALIESSTATS_COLUMN_SHORTS['SvP']}</StandingsHeader>
                <StandingsHeader className="w-[4%] text-right" tooltip={LANG.GOALIESSTATS_COLUMN_TOOLTIPS['SO']}>{LANG.GOALIESSTATS_COLUMN_SHORTS['SO']}</StandingsHeader>
                <StandingsHeader className="w-[4%] text-right" tooltip={LANG.GOALIESSTATS_COLUMN_TOOLTIPS['PIM']}>{LANG.GOALIESSTATS_COLUMN_SHORTS['PIM']}</StandingsHeader>
                <StandingsHeader className="w-[4%] text-right" tooltip={LANG.GOALIESSTATS_COLUMN_TOOLTIPS['W']}>{LANG.GOALIESSTATS_COLUMN_SHORTS['W']}</StandingsHeader>
                <StandingsHeader className="w-[4%] text-right" tooltip={LANG.GOALIESSTATS_COLUMN_TOOLTIPS['D']}>{LANG.GOALIESSTATS_COLUMN_SHORTS['D']}</StandingsHeader>
                <StandingsHeader className="w-[4%] text-right" tooltip={LANG.GOALIESSTATS_COLUMN_TOOLTIPS['L']}>{LANG.GOALIESSTATS_COLUMN_SHORTS['L']}</StandingsHeader>
              </StandingsRow>
              {filteredData?.map((goalie, idx) => (
                <StandingsRow key={idx}>
                  <StandingsTextCell   className={`${idx == 0 ? 'pt-4' : ''}`} text={(idx+1) + '.'} align="left" />
                  <StandingsPlayerCell className={`${idx == 0 ? 'pt-4' : ''}`} player={{ firstName: goalie.FirstName, lastName: goalie.LastName, jerseyNumber: goalie.JerseyNumber, logoUrl: goalie.PlayerLogo }} />
                  <StandingsTeamCell   className={`${idx == 0 ? 'pt-4' : ''}`} team={{ name: goalie.Team, short: goalie.TeamShort, logoUrl: goalie.TeamLogo }} shortOnMobile={true} />
                  <StandingsPlayerLevelCell className={`${idx == 0 ? 'pt-4' : ''}`} listPlayerLevel={{ code: goalie.PlayerLevelCode, name: goalie.PlayerLevelName }} />
                  <StandingsTextCell   className={`${idx == 0 ? 'pt-4' : ''}`} text={goalie['GP']} />
                  <StandingsTextCell   className={`${idx == 0 ? 'pt-4' : ''}`} text={goalie['Svs']} />
                  <StandingsTextCell   className={`${idx == 0 ? 'pt-4' : ''}`} text={goalie['GA']} />
                  <StandingsTextCell   className={`${idx == 0 ? 'pt-4' : ''}`} text={goalie['GAA']?.toFixed(2)} />
                  <StandingsTextCell   className={`${idx == 0 ? 'pt-4' : ''}`} text={goalie['SvP']?.toFixed(2)} sorted={true} />
                  <StandingsTextCell   className={`${idx == 0 ? 'pt-4' : ''}`} text={goalie['SO']} />
                  <StandingsTextCell   className={`${idx == 0 ? 'pt-4' : ''}`} text={goalie['PIM']} />
                  <StandingsTextCell   className={`${idx == 0 ? 'pt-4' : ''}`} text={goalie['W']} />
                  <StandingsTextCell   className={`${idx == 0 ? 'pt-4' : ''}`} text={goalie['D']} />
                  <StandingsTextCell   className={`${idx == 0 ? 'pt-4' : ''}`} text={goalie['L']} />
                </StandingsRow>
              ))}
            </StandingsTable>
          </>
        }
      </EmbedStatsWrapper>
    </>
  );
}

export default EmbedGoaliesStatsInner