import useEntities from 'hooks/useEntities';
import RelationsTable from 'components/RelationsTable';
import { Button, Checkbox, DatePicker, Form, Input, Modal, Radio, Select, Switch, Table, TimePicker, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { add } from 'lodash';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import moment from 'moment';
import useFetchEntity from 'hooks/useFetchEntity';
import useConfirmStore from 'store/confirmStore';
import SchedulerConfigIceSpecialRequestModal from 'components/SchedulerConfig/SchedulerConfigIceSpecialRequestModal';
import EntityLogo from 'components/EntityLogo';
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { MdCheckBox } from "react-icons/md";
import { IoCheckbox } from "react-icons/io5";
import { formatTimeShort } from 'utils/generateEntityColumns';
import { compareString } from 'utils/comparators';

const SchedulerConfigSpecialRequestsMap = ({ specialRequests, phases, icePatterns }) => {
    const teams = useMemo(() => phases?.map(p => p.Teams)?.flat().sort((a, b) => compareString(a.name, b.name)), [phases]);
    const [showExceptionsOnly, setShowExceptionsOnly] = useState(true);

    const compareWeekdayDDD = (a, b) => {
        const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        return weekdays.indexOf(a) - weekdays.indexOf(b);
    }

    const icePatternsSorted = useMemo(() => {
        return icePatterns.sort((a, b) => {
            return compareWeekdayDDD(a.weekday, b.weekday);
        });
    });

    const canTeamPlay = (teamId, icePatternId, location) => {
        const teamSpecialRequests = specialRequests.filter(sr => sr.teamId == teamId && sr.location == location);

        if (teamSpecialRequests.length == 0) {
            return true;
        }

        const mode = teamSpecialRequests.find(sr => sr.type == 'ONLY') ? 'ONLY' : 'EXCEPT';
        const rules = teamSpecialRequests.filter(sr => sr.type == mode);

        switch (mode) {
            case 'ONLY':
                if (rules.find(sr => sr.schedulerConfigIcePatternId == icePatternId)) {
                    return true;
                }
                return false;

            case 'EXCEPT':
                if (rules.find(sr => sr.schedulerConfigIcePatternId == icePatternId)) {
                    return false;
                }
                return true;
        }
    };

    const fullWeekday = (weekday) => {
        switch (weekday) {
            case 'Mon':
                return 'Monday';
            case 'Tue':
                return 'Tuesday';
            case 'Wed':
                return 'Wednesday';
            case 'Thu':
                return 'Thursday';
            case 'Fri':
                return 'Friday';
            case 'Sat':
                return 'Saturday';
            case 'Sun':
                return 'Sunday';
        }
    }
    
    return (
        <>
            <div className="overflow-scroll">
                <div className="mb-5 flex items-center gap-2">
                    <Radio.Group value={showExceptionsOnly} onChange={(e) => setShowExceptionsOnly(e.target.value)}>
                        <Radio value={true}>Show exceptions only</Radio>
                        <Radio value={false}>Show all</Radio>
                    </Radio.Group>
                </div>
                
                <table className="border-collapse">
                    <thead>
                        <tr>
                            <th className="sticky left-0 bg-white border border-gray-300 w-[1%] min-w-[250px]">
                            </th>
                            {icePatternsSorted.map((ip, idx) => (
                                <th key={idx} className="border border-gray-300 text-center font-normal w-[1%] min-w-[80px] max-w-[80px]">
                                    {fullWeekday(ip.weekday)}
                                </th>
                            ))}
                        </tr>
                        <tr>
                            <th className="sticky left-0 bg-white border border-gray-300 w-[1%] min-w-[250px]">
                            </th>
                            {icePatternsSorted.map((ip, idx) => (
                                <th key={idx} className="border border-gray-300 text-center font-normal w-[1%] min-w-[80px] max-w-[80px]">
                                    {ip.Venue?.name}
                                </th>
                            ))}
                        </tr>
                        <tr>
                            <th className="sticky left-0 bg-white border border-gray-300 w-[1%] min-w-[250px]">
                            </th>
                            {icePatternsSorted.map((ip, idx) => (
                                <th key={idx} className="border border-gray-300 text-center font-normal w-[1%] min-w-[80px] max-w-[80px]">
                                    {formatTimeShort(ip.startTime)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {teams
                            .filter(t => !showExceptionsOnly || specialRequests.find(sr => sr.teamId == t.teamId))
                            .map((t, tidx) => (
                            <React.Fragment key={tidx}>
                                {['HOME', 'AWAY'].map((location, lidx) => (
                                    <tr>
                                        <td className={`sticky left-0 p-1 border border-gray-300 ${tidx % 2 == 0 ? 'bg-gray-100' : 'bg-white'}`}>
                                            <div className="flex gap-3 items-center">
                                                <EntityLogo entity={t} size={20} />
                                                <span>{t.name} - {location}</span>
                                            </div>
                                        </td>
                                        {icePatternsSorted.map((ip, idx) => (
                                            <td key={idx} className={`p-1 border border-gray-300 text-center ${tidx % 2 == 0 ? 'bg-gray-100' : ''}`}>
                                                {
                                                    canTeamPlay(t.teamId, ip.schedulerConfigIcePatternId, location)
                                                        ?
                                                        <IoCheckbox className="text-green-500" />
                                                        :
                                                        <MdOutlineCheckBoxOutlineBlank className="text-red-500" />
                                                }
                                                {/* <Checkbox checked={canTeamPlay(t.teamId, ip.schedulerConfigIcePatternId, location)} /> */}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )

}

export default SchedulerConfigSpecialRequestsMap
