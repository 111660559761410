import useFetchData from 'hooks/useFetchData';

export default function useFetchEntity (entity, id, relations = '', options = {}) {
    const params = new URLSearchParams();
    params.append('entity', entity);
    params.append('id', id);
    params.append('relations', relations);
    if (options.separateIncludes) {
        params.append('separateIncludes', 'true');
    }
    if (options.extraAttributes) {
        params.append('extraAttributes', options.extraAttributes.join(','));
    }
    if (options.onlyAttributes) {
        params.append('onlyAttributes', options.onlyAttributes.join(','));
    }
  
    const result = useFetchData((entity && id)
        ? `/admin/entity?${params.toString()}`
        : null
    );

    return result;
}
