import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga4";

const GoogleAnalyticsTracker = ({ id }) => {
  const location = useLocation();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (id) {
      if (!isInitialized) {
        ReactGA.initialize(id);
        setIsInitialized(true);
        console.log('Google analytics started');
      }
      const path = location.pathname + location.search
      console.log('GA4', { hitType: "pageview", page: path });
      ReactGA.send({ hitType: "pageview", page: path });
    }
  }, [location.pathname, location.search])
}

export default GoogleAnalyticsTracker