import useEntities from 'hooks/useEntities';
import RelationsEditable from 'components/RelationsEditable';

const TeamPersons = ({ sourceEntityId, data, canEdit, onChange }) => {
    const entities = useEntities();
    const sourceEntity = entities.Team;
    const targetEntity = entities.Person;
    
    return (
        <RelationsEditable 
            data={data}
            sourceEntity={sourceEntity} 
            targetEntity={targetEntity}
            sourceEntityId={sourceEntityId}
            emptyMessage={`This ${sourceEntity.displayName} does not have any ${targetEntity.displayNamePlural}`}
            onChange={onChange}
            canRemove={canEdit}
            canAdd={canEdit}
        />
    )
}

export default TeamPersons
