import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import useLocationStore from 'store/locationStore';

const LocationHistory = ({ children }) => {
  const location = useLocation();
  const addLocationToHistory = useLocationStore(s => s.addLocationToHistory);

  useEffect(() => {
    addLocationToHistory(location);
  }, [location]);


  return children;
}

export default LocationHistory