
import * as React from "react";

function StarIcon({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 26 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13 0L16.7442 7.84656L25.3637 8.98278L19.0582 14.9684L20.6412 23.5172L13 19.37L5.35879 23.5172L6.94177 14.9684L0.636266 8.98278L9.25581 7.84656L13 0Z" fill="currentColor" />
    </svg>
  );
}

export default StarIcon;
