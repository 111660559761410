import React, { useEffect } from 'react'

const useRecaptcha = () => {
  const appendCapcha = () => {
    const scriptId = 'captcha-script';
    if (!document.querySelector(`#${scriptId}`)) {
      const js = document.createElement('script');
      js.id = scriptId;
      js.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITEKEY}`;
      document.body.appendChild(js);
    }
  }

  useEffect(() => {
    appendCapcha();
  });

  const prepareToken = async () => {
    return new Promise((resolve) => {
      try {
        window.grecaptcha.ready(function () {
          try {
            window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITEKEY, { action: 'submit' }).then(async function (token) {
              resolve(token);
            });
          } catch (error) {
            console.error(error);
            alert('ReCaptcha error');
          }
        });
      } catch (error) {
        console.error(error);
        alert('ReCaptcha error');
      }
    });
  }

  return { prepareToken };
}

export default useRecaptcha