import React from 'react'
import { Checkbox, Form, Input, Row, Col, DatePicker, Select } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';

const FormTextArea = ({ label, name, autoFocus, placeholder, required, formItemProps, fieldItemProps }) => {
    return (
        <HmsFormItem
            label={label}
            name={name}
            required={required}
            {...formItemProps}
        >
            <Input.TextArea
                autoSize={{ minRows: 1, maxRows: 100 }}
                autoFocus={autoFocus}
                className="w-full"
                placeholder={placeholder}
                autoComplete="no-autocomplete"
                {...fieldItemProps}
            />
        </HmsFormItem>
    )
}

export default FormTextArea