import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useEntities from 'hooks/useEntities';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import useLoadEntity from 'hooks/useLoadEntity';
import HmsField from 'components/Form/HmsField';
import useFetchEntity from 'hooks/useFetchEntity';
import HmsTabbedTables from 'components/HmsTabbedTables';
import VenueCompetitions from 'components/Venue/VenueCompetitions';
import VenueAwards from 'components/Venue/VenueAwards';
import EntityLogoEditor from 'components/EntityLogoEditor';
import EntityAttachments from 'components/EntityAttachments';
import EntityLogo from 'components/EntityLogo';
import HmsFormItem from 'components/Form/HmsFormItem';
import HmsEntityFormGridFullWidth from 'components/Form/HmsEntityFormGridFullWidth';
import { DollarOutlined } from '@ant-design/icons';
import React from 'react';
import SeasonRegistrationTeamGroups from 'components/SeasonRegistration/SeasonRegistrationTeamGroups';
import icons from 'utils/icons';
import { Link } from 'react-router-dom';

const SeasonRegistration = () => {
  const entities = useEntities();
  const entity = entities.SeasonRegistration;
  const { id, isLoading, isError, isNotFound, data, reload, relationsData, reloadRelations } = useLoadEntity({
    entity,
    relations: [
      'Groups',
      'Team',
      'Season',
      'Season>Competition',
      'SeasonRegistrationSpecials',
    ].join(','),
  });
  const { data: teamData, reload: reloadTeamGroups } = useFetchEntity(
    'Team',
    data?.teamId,
    [
      'Groups',
      'Groups>Season',
      'Groups>Season>Competition',
    ].join(','),
  );
  const { relationsData: attachmentsData, reloadRelations: reloadAttachments } = useLoadEntity({ entity,
    relations: [
      'Attachments',
    ].join(',')
  });

  const teamGroups = teamData?.Groups
    ?.filter(g => g.seasonId == data?.seasonId);

  return (
    <>
      <HmsEntityFormController
        {... { entity, id, isLoading, isError, isNotFound, data, reload }}
        form={(
          <div className="w-full">

            <HmsEntityFormGridFullWidth>

              <span className="max-w-[200px]">
                <HmsField name="status" />
              </span>

              <HmsFormItem label="Team">
                <span className="flex items-center gap-2">
                  <span>
                    <EntityLogo entity={relationsData?.Team} size={24} />
                  </span>
                  {relationsData?.Team?.name}
                  <a href={entities.Team.entityUrl(relationsData?.Team)} target="_blank">
                    {icons.ExternalLink}
                  </a>
                </span>
              </HmsFormItem>

              <HmsFormItem label="Competition / Season / Preferred Group(s)">
                <span className="flex gap-2">
                  <span>{relationsData?.Season?.Competition?.name}</span>
                  <span>&gt;</span>
                  <span>{relationsData?.Season?.name}</span>
                  <span>&gt;</span>
                  <span>{relationsData?.Groups?.map(g => g.name)?.join(', ')}</span>
                </span>
              </HmsFormItem>

              <HmsFormItem label="Special Requests">
                {relationsData?.SeasonRegistrationSpecials && relationsData.SeasonRegistrationSpecials.length > 0
                  ?
                    <>
                      <div>
                        {relationsData?.SeasonRegistrationSpecials
                          ?.map((s, idx) => (
                            <React.Fragment key={idx}>
                              <div>
                                <DollarOutlined className="mr-1" /> {s.name}
                              </div>
                              <div className="mt-1 italic">
                                Comment: {(s.text && s.text != '') ? s.text : '-'}
                              </div>
                            </React.Fragment>
                          ))
                        }
                      </div>
                    </>
                  :
                    <span className="text-gray-400">-</span>
                }

              </HmsFormItem>

              <span className="max-w-[500px]">
                <HmsField name="externalComment" />
              </span>

              <span className="max-w-[500px]">
                <HmsField name="internalComment" />
              </span>

            </HmsEntityFormGridFullWidth>

          </div>
        )}
        footer={id != '_new_' && (
          <div className="mt-4">
            <div className="mt-4">
              <HmsTabbedTables
                tables={[
                  {
                    title: "Team Groups" + (teamGroups ? (' (' + teamGroups.length + ')') : ''),
                    children: <SeasonRegistrationTeamGroups data={teamGroups} sourceEntityId={data?.teamId} seasonId={data?.seasonId} onChange={reloadTeamGroups} />
                    //children: <VenueCompetitions data={relationsData?.Competitions} sourceEntityId={data?.venueId} onChange={reloadRelations} />
                  },
                  {
                    title: "Attachments" + (attachmentsData?.Attachments ? (' (' + attachmentsData?.Attachments?.length + ')') : ''),
                    children: <EntityAttachments entity={entity.name} id={data?.[entity.primaryKey]} data={attachmentsData} reload={reloadAttachments} />,
                  },
                ]}
              />
            </div>
          </div>
        )}
      />
    </>
  );
}

export default SeasonRegistration
