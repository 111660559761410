import { create } from 'zustand';

const useConfirmStore = create((set, get) => ({
  confirmOpen: null,

  setConfirmOpen: (confirmOpen) => {
    set({ confirmOpen });
  },

  showConfirm: (confirmOpen) => {
    set({ confirmOpen });
  },

}));

export default useConfirmStore;
