import React from 'react'
import Breadcrumbs from 'components/Breadcrumbs';
import { Spin, Table, Card, Button, Layout } from 'antd';
const { Header, Content, Sider } = Layout;


const HmsTableLayout = ({ isLoading, breadcrumbs, children }) => {
    return (
        <>
            {isLoading &&
                <Content className="flex items-center justify-center">
                    <Spin />
                </Content>
            }

            {!isLoading &&
                <Content className="m-3 desktop:m-4 desktop:mr-0">  { /* desktop:mr-0 is to prevent unwanted horizontal scrollbar */ }
                    <Breadcrumbs items={breadcrumbs} />
                    {children}
                </Content>
            }
        </>
    )
}

export default HmsTableLayout