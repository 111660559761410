import useEntities from 'hooks/useEntities';
import useNotificationStore from 'store/notificationStore';
import useLoginStore from 'store/loginStore';
import useFetchEntity from 'hooks/useFetchEntity';
import HmsFormLayout from 'components/HmsFormLayout';
import HmsFormInner from 'components/Form/HmsFormInner';
import icons from 'utils/icons';
import EntityLogo from 'components/EntityLogo';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { validatePassword } from 'utils/passwordUtils';
import { CheckCircleFilled } from '@ant-design/icons';

const MyProfile = () => {
  const entities = useEntities();
  const entity = entities.User;
  const user = useLoginStore(s => s.user);
  const id = user.userId;
  const { data, isLoading, isError, isNotFound, reload } = useFetchEntity('User', id, 'Organizations,ManagedTeams');
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const autoFocusRef = useRef();
  const addMessage = useNotificationStore(s => s.addMessage);

  const validationStatus = useMemo(() => {
    if (newPassword == '' && confirmPassword == '') {
      return {
        isValid: false,
        className: 'text-gray-400',
        text: 'Please start typing...'
      };
    }

    const validityByPolicy = validatePassword(newPassword);
    if (!validityByPolicy.isValid) {
      return {
        isValid: false,
        className: 'text-red-500',
        text: validityByPolicy.message
      };
    }

    if (confirmPassword == '') {
      return {
        isValid: false,
        className: 'text-gray-400',
        text: 'Please confirm password...'
      };
    }

    if (newPassword != confirmPassword) {
      return {
        isValid: false,
        className: 'text-red-500',
        text: 'Passwords do not match'
      };
    }
    return {
      isValid: true,
      className: 'text-green-500',
      text: <span className="flex gap-2 items-center"><span>OK</span><CheckCircleFilled /></span>
    };
  }, [newPassword, confirmPassword]);

  useEffect(() => {
    if (changePasswordOpen) {
      setTimeout(() => {
        autoFocusRef.current.focus();
      });
    }
  }, [changePasswordOpen]);

  const showChangePasswordModal = () => {
    setChangePasswordOpen(true);
    setNewPassword('');
    setConfirmPassword('');
  }

  const changePasswordOk = async () => {
    const res = await entities.User.patch(id, {
      password: newPassword
    });
    if (res.status == 200) {
      addMessage({ text: 'Password changed successfully', type: 'success', expiration: 5 });
      setChangePasswordOpen(false);
      reload();
    } else {
      // no need to show a message because User.patch does
    }
  }

  const changePasswordCancel = async () => {
    setChangePasswordOpen(false);
  }

  return (
    <>
      <Modal
        open={changePasswordOpen}
        title="Change Password"
        closeIcon={false}
        footer={
          <div>
            <Button 
              size="small"
              onClick={changePasswordCancel}
            >
              Cancel
            </Button>
            <Button 
              size="small"
              type="primary" 
              disabled={!validationStatus.isValid}
              onClick={changePasswordOk}
            >
              Change
            </Button>
          </div>
        }
      >
        <div className="mt-4 mb-4">
          <div className="grid grid-cols-[auto_1fr] grid-rows-[1fr_1fr_1fr] gap-x-3 gap-y-2">
            <div className="flex items-center">New password:</div>
            <Input.Password
              ref={autoFocusRef}
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
            />

            <div className="flex items-center">Confirm:</div>
            <Input.Password
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />

            <div className="flex items-center">Status:</div>
            <div className={`flex items-center ${validationStatus.className}`}>
              {validationStatus.text}
            </div>
          </div>
        </div>
      </Modal>

      <HmsFormLayout
        isLoading={isLoading || data == null}
        isNotFound={isNotFound}
        isError={isError}
        breadcrumbs={[
          { title: 'Home', icon: icons.Home, link: '/' },
          { title: 'Profile', icon: icons.User },
        ]}
      >
        <HmsFormInner
          title="My Profile"
          buttons={[
            {
              label: 'Change Password',
              onClick: showChangePasswordModal,
            }
          ]}
        >
          <div className="grid grid-cols-[auto_1fr] gap-x-3 gap-y-2">
            <div className="font-bold">First Name:</div>
            <div className="font-normal">{data?.firstName}</div>

            <div className="font-bold">Last Name:</div>
            <div className="font-normal">{data?.lastName}</div>

            <div className="font-bold">Email:</div>
            <div className="font-normal">{data?.email}</div>

            <div className="col-span-full mt-4"></div>

            <div className="font-bold flex items-center">My organizations:</div>
            <div className="font-normal">
              {data?.Organizations?.map((record, idx) => (
                <span key={idx} className="flex items-center gap-3">
                  <span>
                    <EntityLogo entity={record} size={32} />
                  </span>
                  {record?.name}
                </span>
              ))}
            </div>

            {data?.roles?.indexOf('TEAM_MANAGER') != -1 &&
              <React.Fragment>
                <div className="font-bold flex items-center">My teams:</div>
                <div className="font-normal">
                  {data?.ManagedTeams?.map((record, idx) => (
                    <span key={idx} className="flex items-center gap-3">
                      <span>
                        <EntityLogo entity={record} size={32} />
                      </span>
                      {record?.name}
                    </span>
                  ))}
                </div>
              </React.Fragment>
            }

          </div>

        </HmsFormInner>
      </HmsFormLayout>
    </>
  )
}

export default MyProfile
