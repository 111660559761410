import React from 'react'
import Confirm from 'components/Confirm';

const ConfirmDelete = ({ visible, handleConfirm, handleCancel }) => {
    return (
        <Confirm
        message={"Are you sure to delete this item?"}
        confirmButtonText="Delete"
        confirmButtonType="danger"
        visible={visible}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
    />
    )
}

export default ConfirmDelete