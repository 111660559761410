import * as React from "react";

function RefereeIcon() {
    return (
        <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4.69253C0.103111 4.39256 0.204143 4.09519 0.408701 3.84349C0.936313 3.1953 1.83604 3.00782 2.55199 3.41855C2.67756 3.49052 2.71082 3.45733 2.7553 3.33321C3.28832 1.83383 4.28991 0.818865 5.73263 0.271087C6.27022 0.0668022 6.83151 0.00172406 7.40278 0.00215504C10.5452 0.00474093 13.6871 0.0047408 16.8295 0C16.9717 0 17.0008 0.0430983 17 0.182305C16.9946 1.34639 16.9954 2.51047 16.9992 3.67455C16.9992 3.78704 16.9784 3.83875 16.8607 3.87582C15.3257 4.36067 13.794 4.85544 12.2598 5.34245C12.138 5.38124 12.0976 5.45149 12.0843 5.57001C11.9251 6.99914 11.2728 8.13909 10.1789 9.00924C9.47123 9.5721 8.66006 9.9087 7.77655 9.98067C6.05194 10.1212 4.61962 9.49582 3.51783 8.09901C3.17981 7.67018 2.92702 7.18834 2.75364 6.66384C2.71456 6.54532 2.66966 6.52205 2.55449 6.58626C1.5583 7.14223 0.326795 6.57075 0.05405 5.43382C0.0415769 5.38167 0.0503081 5.31745 0.000831538 5.27694V4.6921L0 4.69253ZM4.67907 5.00542C4.68364 6.49403 5.85944 7.7331 7.2901 7.71716C8.74945 7.70035 9.91028 6.5423 9.90862 4.99853C9.90696 3.45863 8.74571 2.30101 7.28428 2.28981C5.85196 2.2786 4.6745 3.52457 4.67907 5.00585V5.00542ZM1.73251 5.76093C2.14329 5.76266 2.47009 5.42563 2.46884 5.00197C2.46718 4.58263 2.1487 4.24775 1.7479 4.24388C1.33878 4.23957 1.00658 4.57142 0.996184 4.99422C0.986205 5.40149 1.32963 5.75921 1.73251 5.76136V5.76093Z" fill="#A1A1A1" />
        </svg>
    );
}

export default RefereeIcon;
