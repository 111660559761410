import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Spin, Table, Card, Button, Tabs, Dropdown } from 'antd';
import { Radio } from 'antd';
import { AppstoreAddOutlined } from '@ant-design/icons';
import { Link, useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import TabsWithUrlMemory from 'components/TabsWithUrlMemory';
import Dot from 'components/Dot';

const HmsTabbedTables = forwardRef(({ tables, navbar = true }, ref) => {
    const [searchParams, setSearchParams] = useSearchParams();

    useImperativeHandle(ref, () => ({
        setActiveKey: (key) => {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set('relationsTab', key);
            setSearchParams(newSearchParams);
        }
    }));

    // the purpose of the min-h-[500px] is that some tables might be very large
    // and switching to other tables might inadvertedly jump up
    // because there's not enought screen real estate left
    return (
        <div className="min-h-[500px]">
            <Card
                size="small"
                className={`w-[calc(100vw-25px)] ${navbar ? 'desktop:w-[calc(100vw-238px)]' : 'desktop:w-[calc(100vw-33px)]'} overflow-auto`}
            >
                <div>
                    <TabsWithUrlMemory
                        param="relationsTab"
                        className={`no-tab-padding w-[calc(100vw-50px)] ${navbar ? 'desktop:w-[calc(100vw-265px)]' : 'desktop:w-[calc(100vw-60px)]'}`}
                        items={
                            tables.map((t, idx) => ({
                                label: 
                                    t.showDot
                                        ?
                                            <Dot show={true}>
                                                {t.title}
                                            </Dot>
                                        :
                                        t.title,
                                key: t.key ?? ('' + idx),      // need to be string because of the TabsWithUrlMemory
                                disabled: t.disabled,
                                children:
                                    <div>
                                        {t.children}
                                    </div>
                            }))
                        }
                    />
                </div>
            </Card>
        </div>
    )
})

export default HmsTabbedTables
