import { Dropdown } from 'antd'
import React from 'react'
import { DownOutlined, UserOutlined, MenuOutlined } from '@ant-design/icons';
import useLoginStore from 'store/loginStore';
import { Link } from 'react-router-dom';
import icons from 'utils/icons';

const UserMenu = () => {
  const { user, logout } = useLoginStore();
  const roles = useLoginStore(s => s.roles);
  const isAdmin = roles.indexOf('ADMIN') != -1;

  const getUserMenuItems = () => {
    return [
      isAdmin &&
        {
          key: 'settings',
          label: (
            <Link to="/settings">Settings &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</Link>
          ),
          icon: icons.Settings,
        },
      {
        key: 'profile',
        label: (
          <Link to="/profile">Profile &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</Link>
        ),
        icon: <UserOutlined />,
      },
      {
        type: 'divider',
      },
      {
        key: 'logout',
        label: 'Logout',
        icon: icons.User,
        onClick: () => { logout() }
      },
    ].filter(i => i);
  }

  return (
    <Dropdown menu={{ items: getUserMenuItems() }} placement="bottomRight" trigger={['click']}>
      <a className="inline-block select-none" onClick={(e) => e.preventDefault()}>
        <UserOutlined className="text-white mr-1" />
        <span className="cursor-pointer text-white">
          {user.firstName + ' ' + user.lastName}
        </span>
        <DownOutlined className="text-white ml-1" />
      </a>
    </Dropdown>
  )
}

export default UserMenu