import { getImage } from "utils/getImage";
import { unique } from "utils/oneLiners";

export function getAllColumnDefinitions(entity, showColumns) {
  const allColumns = entity.columns;
  const showColumnsList = showColumns ?? entity.defaultColumns;
  return showColumnsList
    .map((colDef) => {
      switch (typeof colDef) {
        case 'string':
          return allColumns[colDef];
        case 'object':
          if (colDef.column) {
            return allColumns[colDef.column];
          } else {
            return colDef;
          }
      }
    })
    .filter(c => c != null);
}

export function autoRelationsFromColumns(entity, showColumns) {
  let requiredRelations = getAllColumnDefinitions(entity, showColumns)
    .map(c => {
      return c.requiredRelations
    })
    .filter(c => c != null);

  const requiredRelationsByEntity = {};
  for (const requiredRelationArray of requiredRelations) {
    for (const requiredRelation of requiredRelationArray) {

      // Parse string in format such as 'Venues(id,name)'
      const [, beforeParentheses, contentInsideParentheses] = (requiredRelation.match(/(.*)\(([^)]+)\)/) || []);
      const entity = beforeParentheses ?? requiredRelation;
      const fields = contentInsideParentheses ? contentInsideParentheses.split(',') : '*';

      if (requiredRelationsByEntity[entity]) {
        if (requiredRelationsByEntity[entity] == '*') {
          // relations for entity already exists and contains all fields => do nothing
        } else {
          // relations for entity alreeady exists => let's merge fields
          requiredRelationsByEntity[entity] = unique([...requiredRelationsByEntity[entity], ...fields]);
        }
      } else {
        // relations for entity does not exist => let's create it
        requiredRelationsByEntity[entity] = fields;
      }
    }
  }
  return Object.entries(requiredRelationsByEntity)
    .map(([entity, fields]) => (entity + (fields != '*' ? '(' + fields.join(',') + ')' : '')));
}

export function autoExtraAttributesFromColumns(entity, showColumns) {
  let requiredExtraAttributes = getAllColumnDefinitions(entity, showColumns)
    .map(c => {
      return c.requiredExtraAttributes
    })
    .filter(c => c != null);

  return unique(requiredExtraAttributes.flat());
}

export function autoAttributesFromColumns(entity, showColumns) {
  let requiredAttributes = getAllColumnDefinitions(entity, showColumns)
    .map(c => {
      return c.requiredAttributes ?? c.dataIndex;
    })
    .filter(c => c != null);

  return unique([entity.primaryKey, ...requiredAttributes.flat()]);
}

export function autoAddIdColumnForExport(entity, tableColumns) {
  tableColumns.push({
    title: 'ID',
    render: (record) => record[entity.primaryKey],
    visible: false,
    exported: true,
    filterDisabled: true,
  });
}

export function autoAddLinkColumnForExport(entity, tableColumns) {
  tableColumns.push({
    title: 'Link',
    render: (record) => window.location.protocol + '//' + window.location.host + entity.entityUrl(record),
    visible: false,
    exported: true,
    filterDisabled: true,
  });
}

export function autoAddLogoColumnForExport(entity, tableColumns) {
  if (entity.hasLogo) {
    tableColumns.push({
      title: 'Logo (Medium)',
      render: (record) => getImage(record, 'md'),
      visible: false,
      exported: true,
      filterDisabled: true,
    });
  }
}
