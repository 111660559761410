import { Radio, Segmented } from "antd";
import { useState } from "react";

const SegmentedTabs = ({ items, block }) => {
    const [selected, setSelected ] = useState(0);
    const options = items.map((i, idx) => ({
        label: i.label,
        value: idx
    }));

    if (items.length === 0) {
        return (
            <></>
        )
    }

    if (block) {
        return (
            <>
                <Segmented block options={options} value={selected} onChange={setSelected} />
                <div className="mt-4">
                    {items[selected].children}
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="mt-2 mb-2 flex justify-center">
                    <Segmented className=" bg-gray-200" options={options} value={selected} onChange={setSelected} />
                </div>
                <div className="mt-4">
                    {items[selected].children}
                </div>
            </>
        )
    }

}

export default SegmentedTabs