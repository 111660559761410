import { StarFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import RelationsTable from 'components/RelationsTable';
import useEntities from 'hooks/useEntities';
import { entityColumnsToAntColumns } from 'utils/entityColumnsToAntColumns';

const PlayerGames = ({ gamesData }) => {
    const entities = useEntities();
    const sourceEntity = entities.Player;
    const targetEntity = entities.Game;

    return (
        <RelationsTable
            data={gamesData?.map(l => ({
                ...l?.Game,
                IsStar: l.IsStar,
                StatGoals: l.StatGoals,
                StatPoints: l.StatPoints,
                StatAssists: l.StatAssists,
                StatPenaltyMinutes: l.StatPenaltyMinutes,
                ListPosition_name: l.ListPosition?.name
            }))}
            sourceEntity={sourceEntity}
            targetEntity={targetEntity}
            columns={entityColumnsToAntColumns(targetEntity, [
                { column: 'StartDateTime-nowrap', defaultSortOrder: 'descend' },
                'Venue',
                'HomeTeam-nick',
                'Score',
                'AwayTeam-nick',
                'status',
                'timekeeper',
                'referee',
                {
                    title: 'Position',
                    render: (record) => record.ListPosition_name,
                },
                {
                    title: 'Star',
                    align: 'center',
                    render: (record) =>
                        record.IsStar ? 
                            <Tooltip title="Player was star of the game">
                                <StarFilled  className="text-yellow-500 text-lg"/>
                            </Tooltip>
                        : null,
                },
                {
                    title: 'G',
                    titleTooltip: 'Goals',
                    align: 'right',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => a.StatGoals - b.StatGoals,
                    render: (record) => record.StatGoals,
                },
                {
                    title: 'A',
                    titleTooltip: 'Assists',
                    align: 'right',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => a.StatAssists - b.StatAssists,
                    render: (record) => record.StatAssists,
                },
                {
                    title: 'PTS',
                    titleTooltip: 'Points',
                    align: 'right',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => a.StatPoints - b.StatPoints,
                    render: (record) => record.StatPoints,
                },
                {
                    title: 'PIM',
                    titleTooltip: 'Penalty Minutes',
                    align: 'right',
                    sortDirections: ['ascend', 'descend'],
                    sorter: (a, b) => a.StatPenaltyMinutes - b.StatPenaltyMinutes,
                    render: (record) => record.StatPenaltyMinutes,
                },
                {
                    title: 'Special',
                    width: 150,
                    align: 'center',
                    render: (r) => {
                        if (r.StatGoals >= 3 && r.StatPoints < 4) {
                            return <span className="bg-green-500 text-white rounded px-2 py-1 whitespace-nowrap">HATTRICK!</span>
                        }
                        if (r.StatPoints >= 3) {
                            return <span className="bg-blue-500 text-white rounded px-2 py-1 whitespace-nowrap">{r.StatPoints}+ POINTS!</span>
                        }
                    }
                },
            ])}
        />
    );
}

export default PlayerGames
