import React, { useEffect, useState } from 'react'
import EmbedCard from 'components/Embeds/EmbedCard';
import EmbedCardVideo from 'components/Embeds/EmbedCardVideo';
import EmbedCardTitleDivier from 'components/Embeds/EmbedCardTitleDivier';
import { formatDate, formatTime } from 'utils/generateEntityColumns';
import { Link } from 'react-router-dom';
import useLang from 'hooks/useLang';

const EmbedSeasonTermsInner = ({ data, lang, title }) => {
  const LANG = useLang(lang);

  return (
    <div className="min-h-screen bg-[#eeeeee]">
      <div className="flex flex-col gap-5 items-center dekstop:mx-10 p-5 dekstop:p-10">

        <EmbedCard>
          <div className="text-xl">
            {title}
          </div>

          <EmbedCardTitleDivier />

          <div className="embed_html" dangerouslySetInnerHTML={{__html: data?.terms}}></div>

        </EmbedCard>

      </div>
    </div>
  );
}

export default EmbedSeasonTermsInner