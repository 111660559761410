import React from 'react';
import { Layout } from 'antd';
import icons from 'utils/icons';

const { Content } = Layout;

const DataNotFound = () => {
    return (
        <Content className="flex items-center justify-center">
            <div className="text-gray-500">
                <div className="text-6xl text-center mb-8">
                    {icons.Error}
                </div>
                <div className="text-4xl text-center">
                    Data not found
                </div>
            </div>
        </Content>

    );
};

export default DataNotFound