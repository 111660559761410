
import * as React from "react";

function CaptainIcon({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M28.1122 8.64038L22.031 12.3873L16.1997 4.60913C16.1762 4.57783 16.1458 4.55242 16.1108 4.53492C16.0758 4.51741 16.0373 4.5083 15.9981 4.5083C15.959 4.5083 15.9204 4.51741 15.8855 4.53492C15.8505 4.55242 15.82 4.57783 15.7966 4.60913L9.96845 12.3873L3.88408 8.64038C3.70595 8.53101 3.4747 8.67788 3.50283 8.88726L5.89033 27.031C5.9247 27.2779 6.1372 27.4685 6.39033 27.4685H25.6122C25.8622 27.4685 26.0778 27.281 26.1091 27.031L28.4966 8.88726C28.5216 8.67788 28.2935 8.53101 28.1122 8.64038ZM24.1747 25.331H7.82158L6.14033 12.5373L10.5091 15.2279L15.9997 7.90288L21.4903 15.2279L25.8591 12.5373L24.1747 25.331ZM15.9997 15.906C14.0591 15.906 12.481 17.4841 12.481 19.4248C12.481 21.3654 14.0591 22.9435 15.9997 22.9435C17.9403 22.9435 19.5185 21.3654 19.5185 19.4248C19.5185 17.4841 17.9403 15.906 15.9997 15.906ZM15.9997 20.9341C15.1685 20.9341 14.4935 20.2591 14.4935 19.4248C14.4935 18.5935 15.1685 17.9154 15.9997 17.9154C16.831 17.9154 17.506 18.5904 17.506 19.4248C17.506 20.256 16.831 20.9341 15.9997 20.9341Z" fill="currentColor" />
    </svg>
  );
}

export default CaptainIcon;
