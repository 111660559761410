const MIN_LENGTH = 8;

export const validatePassword = (value) => {
  if (value.length < MIN_LENGTH) {
    return {
      isValid: false,
      message: `Password must be at least ${MIN_LENGTH} characters long!`,
    };
  }
  if (/^(.)\1+$/.test(value)) {
    return {
      isValid: false,
      message: `Password must not consist of all the same characters!`,
    };
  }
  return {
    isValid: true,
  };
};
