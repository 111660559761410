import React from "react";
import StreakSmallOpponent from "./StreakSmallOpponent";
import LogoOrTitle from "./LogoOrTitle";

const TeamStreakRow = ({ team, games }, key) => {
  return (
    <div key={key} className="mt-2 flex border-y border-white">
      <LogoOrTitle
        logo={team.logoUrl}
        title={team.name}
        titleClassName="text-center"
        className="mr-2 h-16 w-16 rounded-full"
      />

      {games
        .map((game, index) => (
          <StreakSmallOpponent key={index} game={game} team={team } />
      ))}
    </div>
  );
};

export default TeamStreakRow;
