import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Spin, Table, Card, Button, Input, Space, Dropdown } from 'antd';
import { Radio } from 'antd';
import { AppstoreAddOutlined, FileExcelOutlined, DownOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { simulateCtrlClick } from 'utils/simulateCtrlClick';
import { cleanFileName, removeAccents } from 'utils/oneLiners';
import useSearchParamsMulti from 'hooks/useSearchParamsMulti';
import useIsMobile from 'hooks/useIsMobile';
import ErrorIcon from 'components/ErrorIcon';
import extractTextFromJSX from 'utils/extractTextFromJsx';
import dayjs from 'dayjs';
import copy from 'copy-to-clipboard';
import icons from 'utils/icons';
import useNotificationStore from 'store/notificationStore';

export default function exportTableToCsv(finalColumns, sortedData, target = 'csv', exportPrefix = 'table-data') {
  const exportableColumns = finalColumns
      .filter(c => c.exportable !== false);

  let separator = '\t';
  switch (target) {
      case 'clipboard':
          separator = '\t';
          break;
      case 'csv':
          separator = ';';
          break;
      default:
          alert('Invalid target: ' + target);
          return;
  }

  const quantifier = '"';
  const header = exportableColumns.map(col => extractTextFromJSX(col.title)).join(separator);
  const body = sortedData.map(row =>
      exportableColumns.map(col => {
          const getColValue = (row, col) => {
              if (col.exportValue) {
                  return col.exportValue(row);
              } else if (col.render) {
                  if (col.dataIndex) {
                      return extractTextFromJSX(col.render(row[col.dataIndex], row));
                  } else {
                      return extractTextFromJSX(col.render(row));
                  }
              } else {
                  return row[col.dataIndex];
              }
          }
          const getColValueOrEmpty = (row, col) => {
              const value = getColValue(row, col);
              return (value !== null && value !== undefined) ? ('' + value) : '';
          }
          const value = getColValueOrEmpty(row, col);
          const eq = col.exportExactCsv ? '=' : '';

          // Note: This is a possible auto-detect instead of exportExactCsv -- currently not in use
          //   const eq = (col.exportExactCsv || value?.substr(0, 1) == '+') ? '=' : '';

          return `${eq}${quantifier}${value}${quantifier}`
      }
      )
      .join(separator)
  ).join('\n');

  const csv = `${header}\n${body}`;

  switch (target) {
      case 'clipboard':
          copy(csv, { format: 'text/plain' });
          break;
      case 'csv':
          const UTF8_BOM = '\uFEFF';
          const blob = new Blob([UTF8_BOM + csv], { type: 'text/csv;charset=utf-8;' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `${cleanFileName(exportPrefix)}-${dayjs().format('YYYYMMDD-HHMMss')}.csv`;
          link.click();
          URL.revokeObjectURL(link.href);
          break;
  }
};
