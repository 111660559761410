import useFetchData from 'hooks/useFetchData';
import useGlobalStore from 'store/globalStore';

export default function useFetchDuplicities(entity, filters, onlyAttributes) {
  const ORGANIZATION_ID = useGlobalStore(s => s.ORGANIZATION_ID);
  const entitiesDontNeedOrganizationId = useGlobalStore(s => s.entitiesDontNeedOrganizationId);

  filters = filters ?? {};
  if (entitiesDontNeedOrganizationId.indexOf(entity) == -1) {
    filters = { ...filters, 'organizationId': ORGANIZATION_ID };
  }

  const params = new URLSearchParams();
  params.append('entity', entity);
  params.append('onlyAttributes', onlyAttributes ?? '');

  for (const [key, value] of Object.entries(filters)) {
    if (value !== undefined) {
      params.append(key, JSON.stringify(value));
    }
  }

  const { data, status, isLoading, isNotFound, isError, reload } = useFetchData(
    entity
      ? `/admin/duplicities?${params.toString()}`
      : null
  );

  return { data, status, isLoading, isNotFound, isError, reload };
}