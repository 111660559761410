import React from 'react'
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import icons from 'utils/icons';
import { useLocation } from 'react-router-dom';
import useLists from 'hooks/useLists';
import useVersion from 'hooks/useVersion';
import useLoginStore from 'store/loginStore';
import useGlobalStore from 'store/globalStore';

const Navbar = ({ mode, setMenuIsOpen }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { schema } = useLists();
  const { version } = useVersion();
  const roles = useLoginStore(s => s.roles);
  const ORGANIZATION_ID = useGlobalStore(s => s.ORGANIZATION_ID);
  const showInfographics = ORGANIZATION_ID == 'BB669217-41EB-4162-8040-A73F49A43570';
  const showScheduler = ORGANIZATION_ID == 'BB669217-41EB-4162-8040-A73F49A43570';

  const selectedKeys = [
    currentPath
      .replace('settings/', 'settings__')
      .replace('rulepacks/', 'rulepacks__')
      .split('/')[1]
      .replace('settings__', 'settings/')
      .replace('rulepacks__', 'rulepacks/')
  ];

  const getNavbarItems = () => {
    if (roles.indexOf('ADMIN') != -1) {
      switch (mode) {
        case 'general':
          return [
              { key: "dashboard", icon: icons.Dashboard, label: <Link to="/dashboard">Dashboard</Link> },
              { key: "competitions", icon: icons.Competition, label: <Link to="/competitions">Competitions</Link> },
              { key: "sponsors", icon: icons.Sponsor, label: <Link to="/sponsors">Sponsors</Link> },
              { key: "venues", icon: icons.Venue, label: <Link to="/venues">Venues</Link> },
              { key: "awards", icon: icons.Award, label: <Link to="/awards">Awards</Link> },
              { key: "teams", icon: icons.Team, label: <Link to="/teams">Teams</Link> },
              { key: "players", icon: icons.Player, label: <Link to="/players">Players</Link> },
              { key: "persons", icon: icons.Person, label: <Link to="/persons">Persons</Link>, link: "/persons" },
              { key: "games", icon: icons.Game, label: <Link to="/games">Games</Link> },
              { key: "disciplinaries", icon: icons.Disciplinary, label: <Link to="/disciplinaries">Disciplinaries</Link> },
              { key: "seasonregistrations", icon: icons.SeasonRegistration, label: <Link to="/seasonregistrations">Registrations</Link> },
              showScheduler && { key: "schedulerconfigs", icon: icons.Scheduler, label: <Link to="/schedulerconfigs">Scheduler</Link> },
              showInfographics && { key: "infographics", icon: icons.Infographics, label: <Link to="/infographics">Infographics</Link> },
              { key: "statistics", icon: icons.Statistics, label: <Link to="/statistics">Statistics</Link> },
              { key: "rulepacks", icon: icons.Settings, label: <Link to="/rulepacks">Rule Packs</Link>, placement: 'bottom' },
            ].filter(i => i);
        case 'settings':
          return [
            {
              key: "settings",
              icon: icons.Settings,
              label: "Settings",
              children: [
                { key: "settings/organizations", icon: icons.Settings, label: <Link to="/settings/organizations">Organizations</Link> },
                { key: "settings/users", icon: icons.Settings, label: <Link to="/settings/users">Users</Link> },
              ],
            },
            { key: "general", icon: icons.Back, label: <Link to="/competitions">Back to General</Link> },
          ];
        case 'rulepacks':
          return [
            {
              key: "rulepacks",
              icon: icons.Settings,
              label: "Rule Packs",
              children:
                [
                  { key: 'rulepacks/ListEventLocations', icon: null, label: <Link to={'/rulepacks/ListEventLocations'}>Event Locations</Link> },
                  { key: 'rulepacks/ListGoalLocations', icon: null, label: <Link to={'/rulepacks/ListGoalLocations'}>Goal Locations</Link> },
                  { key: 'rulepacks/ListGoalTypes', icon: null, label: <Link to={'/rulepacks/ListGoalTypes'}>Goal Types</Link> },
                  { key: 'rulepacks/ListGoalSubtypes', icon: null, label: <Link to={'/rulepacks/ListGoalSubtypes'}>Goal Subtypes</Link> },
                  { key: 'rulepacks/ListInjuryTypes', icon: null, label: <Link to={'/rulepacks/ListInjuryTypes'}>Injury Types</Link> },
                  { key: 'rulepacks/ListPenaltyTypes', icon: null, label: <Link to={'/rulepacks/ListPenaltyTypes'}>Penalty Types</Link> },
                  { key: 'rulepacks/ListPenaltySubtypes', icon: null, label: <Link to={'/rulepacks/ListPenaltySubtypes'}>Penalty Subtypes</Link> },
                  { key: 'rulepacks/ListPeriods', icon: null, label: <Link to={'/rulepacks/ListPeriods'}>Periods</Link> },
                  { key: 'rulepacks/ListPlayerLevels', icon: null, label: <Link to={'/rulepacks/ListPlayerLevels'}>Player Levels</Link> },
                  { key: 'rulepacks/ListPersonRoles', icon: null, label: <Link to={'/rulepacks/ListPersonRoles'}>Person Roles</Link> },
                  { key: 'rulepacks/ListPositions', icon: null, label: <Link to={'/rulepacks/ListPositions'}>Positions</Link> },
                  { key: 'rulepacks/ListResultPoints', icon: null, label: <Link to={'/rulepacks/ListResultPoints'}>Result Points</Link> },
                  { key: 'rulepacks/ListResultTypes', icon: null, label: <Link to={'/rulepacks/ListResultTypes'}>Result Types</Link> },
                  { key: 'rulepacks/ListShotTypes', icon: null, label: <Link to={'/rulepacks/ListShotTypes'}>Shot Types</Link> },
                  { key: 'rulepacks/ListColors', icon: null, label: <Link to={'/rulepacks/ListColors'}>Colors</Link> },
                ]
            },
            { key: "general", icon: icons.Back, label: <Link to="/competitions">Back to General</Link> },
          ];
        default:
          return [];
      }
    }
    if (roles.indexOf('TEAM_MANAGER') != -1) {
      return [
        { key: "dashboard_team_manager", icon: icons.Dashboard, label: <Link to="/dashboard_team_manager">Dashboard</Link> },
        { key: "games_team_manager", icon: icons.Game, label: <Link to="/games_team_manager">My Games</Link> },
        { key: "teams_team_manager", icon: icons.Team, label: <Link to="/teams_team_manager">My Teams</Link> },
        { key: "players_team_manager", icon: icons.Player, label: <Link to="/players_team_manager">My Players</Link> },
        { key: "persons_team_manager", icon: icons.Person, label: <Link to="/persons_team_manager">My Persons</Link> },
      ];
    }
  };

  const NavbarTop = () => (
    <Menu
      className="select-none !h-fit"
      onClick={() => setMenuIsOpen(false)}
      mode="inline"
      selectedKeys={selectedKeys}
      defaultOpenKeys={['general', 'settings', 'rulepacks']}
      style={{ height: '100%', borderRight: 0 }}
      items={getNavbarItems()?.filter(item => item.placement != 'bottom')}
    />
  );

  const NavbarBottom = () => (
    <Menu
      className="select-none"
      onClick={() => setMenuIsOpen(false)}
      mode="inline"
      selectedKeys={selectedKeys}
      defaultOpenKeys={['general', 'settings']}
      style={{ borderRight: 0 }}
      items={getNavbarItems()?.filter(item => item.placement == 'bottom')}
    />
  );

  const Schema = () => (
    <div className="pl-7 text-xs text-gray-400">Version: {version} {schema.replace('dbo_', '')}</div>
  );

  return (
    <>
      <NavbarTop />
        <div className="mt-auto mb-1">
          <NavbarBottom />
          <Schema />
      </div>
    </>
  )
}

export default Navbar