import useFetchData from 'hooks/useFetchData'
import useFetchEntity from 'hooks/useFetchEntity';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Tooltip } from 'antd';
import useLang from 'hooks/useLang';
import { ClockLoader } from 'react-spinners';
import EmbedCompetitionSeasonPhaseTeamSelector from 'components/Embeds/EmbedCompetitionSeasonPhaseTeamSelector';
import StandingsPlayerCell from 'components/Embeds/StandingsPlayerCell';
import StandingsTeamCell from 'components/Embeds/StandingsTeamCell';
import StandingsTextCell from 'components/Embeds/StandingsTextCell';
import StandingsPlayerLevelCell from 'components/Embeds/StandingsPlayerLevelCell';
import StandingsHeader from 'components/Embeds/StandingsHeader';
import useIsMobile from 'hooks/useIsMobile';
import { uniqueByProperty } from 'utils/oneLiners';
import StandingsTable from 'components/Embeds/StandingsTable';
import StandingsRow from 'components/Embeds/StandingsRow';
import EmbedTitle from 'components/Embeds/EmbedTitle';
import EmbedStatsWrapper from 'components/Embeds/EmbedStatsWrapper';

const EmbedPlayersStatsInner = ({ competitionsData, lang, competitionId, seasonId: paramSeasonId, groupId: paramGroupId, phaseId: paramPhaseId }) => {
  const isMobile = useIsMobile();
  const LANG = useLang(lang);
  const [seasonId, setSeasonId] = useState(paramSeasonId);
  const [groupId, setGroupId] = useState(paramGroupId);
  const [phaseId, setPhaseId] = useState(paramPhaseId);
  const [teamId, setTeamId] = useState(null);
  const { data, isLoading } = useFetchData(
    phaseId ? `/public/playersStats?phaseId=${phaseId}`
    : groupId ? `/public/playersStats?groupId=${groupId}`
    : seasonId ? `/public/playersStats?seasonId=${seasonId}`
    : null
  );

  const uniqueTeamsData = useMemo(() => {
    const allTeamsData = data?.map(p => ({ teamId: p.TeamId, name: p.Team }));
    return uniqueByProperty(allTeamsData, 'teamId');
  }, [data]);

  const filteredData = useMemo(() => {
    return teamId ? data?.filter(p => p.TeamId === teamId) : data
  }, [data, teamId]);

  return (
    <>
      <EmbedStatsWrapper>
        <div className="realtive top-0 right-0">
          <EmbedTitle>{LANG.PLAYERSSTATS}</EmbedTitle>

            <EmbedCompetitionSeasonPhaseTeamSelector
              competitionsData={competitionsData}
              lang={lang}
              competitionId={competitionId}
              seasonId={paramSeasonId}
              groupId={paramGroupId}
              phaseId={paramPhaseId}
              setSeasonId={(i) => setTimeout(() => setSeasonId(i), 50)}
              setGroupId={(i) => setTimeout(() => setGroupId(i), 50)}
              setPhaseId={(i) => setTimeout(() => setPhaseId(i), 50)}
              showTeamsFilter={true}
              teamsData={uniqueTeamsData}
              setTeamId={setTeamId}
            />

          </div>

        {!data && isLoading &&
          <div className="w-full h-[450px] flex justify-center items-center">
            <ClockLoader color="#aaa" size={40} />
          </div>
        }

        {filteredData && !isLoading &&
          <>
            <StandingsTable>
              <StandingsRow>
                  <StandingsHeader isHeader={true} className="w-[5%] text-left">{LANG.PLAYERSSTATS_COLUMN_SHORTS['Rank']}</StandingsHeader>
                  <StandingsHeader isHeader={true} className="w-[23%] text-left !pl-8">{LANG.NAME}</StandingsHeader>
                  <StandingsHeader isHeader={true} className="w-[20%] text-left !pl-8">{LANG.TEAM}</StandingsHeader>
                  <StandingsHeader isHeader={true} className="w-[3%] text-center" tooltip={LANG.PLAYERSSTATS_COLUMN_TOOLTIPS['Level']}>{LANG.PLAYERSSTATS_COLUMN_SHORTS['Level']}</StandingsHeader>
                  <StandingsHeader isHeader={true} className="w-[6%] text-right" tooltip={LANG.PLAYERSSTATS_COLUMN_TOOLTIPS['GP']}>{LANG.PLAYERSSTATS_COLUMN_SHORTS['GP']}</StandingsHeader>
                  <StandingsHeader isHeader={true} className="w-[6%] text-right" tooltip={LANG.PLAYERSSTATS_COLUMN_TOOLTIPS['G']}>{LANG.PLAYERSSTATS_COLUMN_SHORTS['G']}</StandingsHeader>
                  <StandingsHeader isHeader={true} className="w-[6%] text-right" tooltip={LANG.PLAYERSSTATS_COLUMN_TOOLTIPS['A']}>{LANG.PLAYERSSTATS_COLUMN_SHORTS['A']}</StandingsHeader>
                  <StandingsHeader isHeader={true} className="w-[6%] text-right" tooltip={LANG.PLAYERSSTATS_COLUMN_TOOLTIPS['P']} sorted={true}>{LANG.PLAYERSSTATS_COLUMN_SHORTS['P']}</StandingsHeader>
                  <StandingsHeader isHeader={true} className="w-[7%] text-right" tooltip={LANG.PLAYERSSTATS_COLUMN_TOOLTIPS['PIM']}>{LANG.PLAYERSSTATS_COLUMN_SHORTS['PIM']}</StandingsHeader>
                  <StandingsHeader isHeader={true} className="w-[6%] text-right" tooltip={LANG.PLAYERSSTATS_COLUMN_TOOLTIPS['PPG']}>{LANG.PLAYERSSTATS_COLUMN_SHORTS['PPG']}</StandingsHeader>
                  <StandingsHeader isHeader={true} className="w-[6%] text-right" tooltip={LANG.PLAYERSSTATS_COLUMN_TOOLTIPS['SHG']}>{LANG.PLAYERSSTATS_COLUMN_SHORTS['SHG']}</StandingsHeader>
                  <StandingsHeader isHeader={true} className="w-[6%] text-right" tooltip={LANG.PLAYERSSTATS_COLUMN_TOOLTIPS['Stars']}>{
                    isMobile
                      ? <img src="/Star.svg" className="w-4 h-4" />
                      : LANG.PLAYERSSTATS_COLUMN_SHORTS['Stars']
                  }</StandingsHeader>
                </StandingsRow>
                {filteredData?.map((player, idx) => (
                  <StandingsRow key={idx}>
                    <StandingsTextCell        className={`${idx == 0 ? 'pt-4' : ''}`} text={(idx+1) + '.'} align="left" />
                    <StandingsPlayerCell      className={`${idx == 0 ? 'pt-4' : ''}`} player={{ firstName: player.FirstName, lastName: player.LastName, jerseyNumber: player.JerseyNumber, logoUrl: player.PlayerLogo }} />
                    <StandingsTeamCell        className={`${idx == 0 ? 'pt-4' : ''}`} team={{ name: player.Team, short: player.TeamShort, logoUrl: player.TeamLogo }} shortOnMobile={true} />
                    <StandingsPlayerLevelCell className={`${idx == 0 ? 'pt-4' : ''}`} listPlayerLevel={{ code: player.PlayerLevelCode, name: player.PlayerLevelName }} />
                    <StandingsTextCell        className={`${idx == 0 ? 'pt-4' : ''}`} text={player['GP']} />
                    <StandingsTextCell        className={`${idx == 0 ? 'pt-4' : ''}`} text={player['G']} />
                    <StandingsTextCell        className={`${idx == 0 ? 'pt-4' : ''}`} text={player['A']} />
                    <StandingsTextCell        className={`${idx == 0 ? 'pt-4' : ''}`} text={player['P']} sorted={true} />
                    <StandingsTextCell        className={`${idx == 0 ? 'pt-4' : ''}`} text={player['PIM']} />
                    <StandingsTextCell        className={`${idx == 0 ? 'pt-4' : ''}`} text={player['PPG']} />
                    <StandingsTextCell        className={`${idx == 0 ? 'pt-4' : ''}`} text={player['SHG']} />
                    <StandingsTextCell        className={`${idx == 0 ? 'pt-4' : ''}`} text={player['Stars']} />
                  </StandingsRow>
                ))}
            </StandingsTable>
          </>
        }
      </EmbedStatsWrapper>
    </>
  );
}

export default EmbedPlayersStatsInner