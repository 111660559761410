import useEntities from 'hooks/useEntities';
import RelationsEditable from 'components/RelationsEditable';
import useLoginStore from 'store/loginStore';

const PersonTeams = ({ sourceEntityId, sourceEntityData, data, onChange }) => {
    const hasRole = useLoginStore(s => s.hasRole);
    const isAdmin = hasRole('ADMIN');
    const entities = useEntities();
    const sourceEntity = entities.Person;
    const targetEntity = entities.Team;

    return (
        <RelationsEditable 
            data={data}
            sourceEntity={sourceEntity} 
            targetEntity={targetEntity}
            sourceEntityId={sourceEntityId}
            emptyMessage={`This ${sourceEntity.displayName} does not belong to any ${targetEntity.displayNamePlural}`}
            onChange={onChange}
            canRemove={isAdmin || (sourceEntityData?.status == 'DRAFT')}
            cannotRemoveTooltip={"Please use the 'Request Change' button to request removal of this Player from this Team."}
            canAdd={isAdmin || sourceEntityData?.status == 'DRAFT'}
        />
    )
}

export default PersonTeams
