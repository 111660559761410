import { Button, Checkbox, Input, Modal, Spin, Switch, Upload } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons';
import useNotificationStore from 'store/notificationStore';

const TeamRegistrationDialog_Specials = ({ registration, setRegistration }) => {
  const [showAttachments, setShowAttachments] = useState(false);
  const addMessage = useNotificationStore((state) => state.addMessage);

  const fileList = registration?.Attachments ?? [];
  const setFileList = (newFileList) => {
    setRegistration({
      ...registration,
      Attachments: newFileList
    });
  }
  
  const handleChange = ({ fileList }) => {
    const LIMIT_MB = 10;
    const filteredFileList = fileList.filter(file => {
      const fileTooLarge = file.size > 1024*1024*LIMIT_MB;
      if (fileTooLarge) {
        addMessage({ text: `File too large (limit: ${LIMIT_MB} MB)`, type: 'error' });
      }
      return !fileTooLarge;
    });
    setFileList(filteredFileList);
  };

  const handleBeforeUpload = () => {
    return false;   // prevent immediate upload
  };  

  return (
    <div>
      <div className="mt-4 mb-2">
        <div className="flex items-center gap-3">
          <Switch
            checked={showAttachments}
            onChange={(checked) => setShowAttachments(checked) }
          />
          Add attachments
        </div>

        <div className={`${showAttachments ? 'block' : 'hidden'} mt-4`}
        >
          <Upload
            
            listType="picture-card"
            beforeUpload={handleBeforeUpload}
            onChange={handleChange}
            fileList={fileList}
          >
            <div className="flex flex-col items-center gap-2 text-blue-500">
              <UploadOutlined />
              <span className="text-xs">Click or drag & drop here</span>
            </div>
          </Upload>
        </div>
      </div>

      <div className="mb-8"></div>
    </div>
  );
}

export default TeamRegistrationDialog_Specials
