
export function getStatusColor(status, entity) {
    switch (status) {
        case 'RUNNING': return entity == 'Game' ? 'orange' : 'success';
        case 'ACTIVE': return 'success';
        case 'FINISHED': return 'success';
        case 'NOTSTARTED': return 'orange';
        case 'OPEN_FOR_REGISTRATION': return 'pink';
        case 'FINISHED': return null;
        case 'CANCELLED': return null;
        case 'POSTPONED': return 'red';
        case 'NOT REGISTERED': return 'orange';
        case 'NOT PLAYED': return null;
        case 'DRAFT': return 'orange';
        case 'PREAPPROVED': return 'processing';
        // registration:
        case 'NEW': return 'orange';
        case 'PROCESSED': return 'green';
        case 'REJECTED': return 'red';
    }
}

export function getStatusText(status, entity) {
    return {
        'NOTSTARTED': 'NOT STARTED',
        'OPEN_FOR_REGISTRATION': 'OPEN FOR REGISTRATION',
    }[status] ?? status;
}
