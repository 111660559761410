import React from 'react'
import { Tooltip } from 'antd';

const PlayerLevelTag = ({ listPlayerLevel, className }) => {
  return listPlayerLevel && 
      <Tooltip title={listPlayerLevel.name}>
          <span className={`font-bold text-gray-700 bg-gray-200 w-[1.4em] h-[1.4em] rounded-[0.7em] flex items-center justify-center ${className ?? ''}`}>{listPlayerLevel?.code?.substr(0, 1)?.toUpperCase()}</span>
      </Tooltip>
}

export default PlayerLevelTag