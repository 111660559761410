import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useEntities from 'hooks/useEntities';
import useLoadEntity from 'hooks/useLoadEntity';
import HmsField from 'components/Form/HmsField';
import HmsTabbedTables from 'components/HmsTabbedTables';
import EntityAttachments from 'components/EntityAttachments';
import HmsEntityFormGridFullWidth from 'components/Form/HmsEntityFormGridFullWidth';
import DisciplinaryPlayers from 'components/Disciplinary/DisciplinaryPlayers';
import GameSelect from 'components/Form/GameSelect';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import openInNewTab from 'utils/openInNewTab';
import { useSearchParams } from 'react-router-dom';
import CompetitionSelect from 'components/Form/CompetitionSelect';
import { HmsFormContext } from 'components/Form/HmsFormContext';
import { useEffect } from 'react';
import { Card } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const Disciplinary = () => {
  const entities = useEntities();
  const entity = entities.Disciplinary;
  const [searchParams] = useSearchParams();
  const playerId = searchParams.get('playerId');
  const gameId = searchParams.get('gameId');
  const competitionId = searchParams.get('competitionId');

  const { id, isLoading, isError, isNotFound, data, reload, relationsData, reloadRelations } = useLoadEntity({ entity,
    relations: [
      'Players,Players>Teams,Players>ListPlayerLevel,Players>ListPositions',
    ].join(',')
  });
  const { relationsData: attachmentsData, reloadRelations: reloadAttachments } = useLoadEntity({ entity,
    relations: [
      'Attachments',
    ].join(',')
  });

  const initialValues = {};
  if (playerId) {
    initialValues.Players = [ playerId ];
  }
  if (gameId) {
    initialValues.gameId = gameId;
  }
  if (competitionId) {
    initialValues.competitionId = competitionId;
  }

  return (
    <>    
      <HmsEntityFormController
        {... { entity, id, isLoading, isError, isNotFound, data, reload, initialValues }}
        form={(
          <div>
            <HmsEntityFormGridFullWidth>
              <CompetitionSelect name="competitionId" label="Competition" required={true} />
            </HmsEntityFormGridFullWidth>            
            <HmsEntityFormGridFullWidth>
            <HmsFormContext.Consumer>
              {({ data: formData }) => (
                <GameSelect name="gameId" label="Game" competitionId={formData?.competitionId} />
              )}
            </HmsFormContext.Consumer>
            </HmsEntityFormGridFullWidth>
            <HmsEntityFormGridBasic>
              <HmsField name="subject" />
              <HmsField name="date" />
              <HmsField name="status" />
              <HmsField name="youtubeUrl" />
            </HmsEntityFormGridBasic>     
            <HmsEntityFormGridFullWidth>
              <HmsField name="description" fieldItemProps={{autoSize: { minRows: 5 }}} />
              <HmsField name="result" fieldItemProps={{autoSize: { minRows: 5 }}} />
            </HmsEntityFormGridFullWidth>
          </div>
        )}
        footer={id != '_new_' ? (
          <div className="mt-4">
            <div className="mt-4">
              <HmsTabbedTables
                tables={[
                  {
                    title: 'Players' + (relationsData?.Players ? ` (${relationsData['Players'].length})` : ''),
                    children: <DisciplinaryPlayers sourceEntity={entity} sourceEntityId={id} data={relationsData && relationsData['Players']} onChange={reloadRelations} />,
                  },
                  {
                    title: "Attachments" + (attachmentsData?.Attachments ? (' (' + attachmentsData?.Attachments?.length + ')') : ''),
                    children: <EntityAttachments entity={entity.name} id={data?.[entity.primaryKey]} data={attachmentsData} reload={reloadAttachments} />,
                  },
                ]}
              />
            </div>
          </div>
        ) : playerId != null ? (
          <Card className="mt-4">
            <div className="flex gap-3 text-gray-500">
              <InfoCircleOutlined />
              <span>This Disciplinary record will be linked with Player after save.</span>
            </div>
          </Card>
        ) : null
        }
        systemButtons={[
          ...(id ? [
            {
              label: 'Open embed - disciplinary',
              onClick: () => { openInNewTab(`/embed/disciplinary?disciplinaryId=${id}&lang=cs`) },
              disabled: !id,
              disabledTooltip: 'Need to save first'
            }
          ] : []),
          ...(data?.competitionId ? [
            {
              label: 'Open embed - disciplinaries',
              onClick: () => { openInNewTab(`/embed/disciplinaries?competitionId=${data?.competitionId}&lang=cs`) },
              disabled: !id,
              disabledTooltip: 'Need to save first'
            }
          ] : []),
        ]}
      />
    </>
  );
}

export default Disciplinary
