import React from 'react'
import { Switch } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';

const FormSwitch = ({ label, name, required, autoFocus, placeholder, formItemProps, fieldItemProps }) => {
    return (
        <HmsFormItem
            label={label}
            name={name}
            required={required}
            valuePropName='checked'
            {...formItemProps}
        >
            <Switch
            autoFocus={autoFocus}
                {...fieldItemProps} />
        </HmsFormItem>
    )
}

export default FormSwitch