import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useEntities from 'hooks/useEntities';
import useLoadEntity from 'hooks/useLoadEntity';
import useFetchEntities from 'hooks/useFetchEntities';
import useFetchEntity from 'hooks/useFetchEntity';
import { Button, Card, Divider, Modal, Spin, Tabs } from 'antd';
import GameInfo from 'components/Game/GameInfo';
import GameLineup from 'components/Game/GameLineup';
import GamePayments from 'components/Game/GamePayments';
import GameNews from 'components/Game/GameNews';
import GameResults from 'components/Game/GameResults';
import { BeatLoader } from 'react-spinners';
import icons from 'utils/icons';
import GameAwards from 'components/Game/GameAwards';
import HmsTabbedTables from 'components/HmsTabbedTables';
import GameEvents from 'components/Game/GameEvents';
import openInNewTab from 'utils/openInNewTab';
import { useEffect, useRef, useState } from 'react';
import { formatDate, formatTime } from 'utils/generateEntityColumns';
import moment from 'moment';
import { BsFillPersonFill } from 'react-icons/bs';
import TextArea from 'antd/es/input/TextArea';
import copy from 'copy-to-clipboard';
import useLang from 'hooks/useLang';
import CopyToClipboardButton from 'components/CopyToClipboardButton';
import html2canvas from 'html2canvas';
import { getImage } from 'utils/getImage';
const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const GameInviteModal = ({ open, data, relationsData, onClose }) => {
  const LANG = useLang('cs');
  const allowCopyToClipboard = false;

  const getDateInCzech = (d) => {
    if (!d) return '';
    const weekdays = LANG.DAYS_LONG;
    const formattedDate = moment(d).format(`[${weekdays[moment(d).day()]}], DD.MM.YYYY`);
    return formattedDate;
  }

  const getDayOfWeekInCzech = (d) => {
    if (!d) return '';
    const weekdays = LANG.DAYS_LONG;
    return weekdays[moment(d).day()];
  }

  const getInviteText = () => {
    return `Ahoj, 

Tímto vás srdečně zveme na váš zápas v rámci PHM Cupu, který se koná 

${formatDate(data?.startDate)} v ${getDayOfWeekInCzech(data?.startDate)}

od ${formatTime(data?.startTime)}

Na stadionu: ${relationsData?.Venue?.name}

Prosíme vyplňte si svou zápasovou soupisku včetně čísel dresů v aplikaci HMS: https://prod.hms.wootera.net/
Upozorňujeme, že soupiska musí být vyplěná před zahájením utkání.

Dresy si prosím vezměte dle grafiky

Děkujeme a těšíme se na vás! Užijte si zápas,

Vaše PHM ;)`;
  }

  const copyInviteText = () => {
    copy(getInviteText())
  }

  const captureRef = useRef(null);

  const handleCapture = async () => {
    try {

      const canvas = await html2canvas(captureRef.current, { scale: 2 });

      const imgData = canvas.toDataURL('image/png');

      // Convert the data URL to a Blob
      const response = await fetch(imgData);
      const blob = await response.blob();

      // Create a temporary anchor element and set its attributes to initiate a download
      const a = document.createElement('a');
      document.body.appendChild(a);  // This is necessary as Firefox requires the anchor to be part of the body
      a.style.display = 'none';
      a.href = URL.createObjectURL(blob);
      a.download = 'screenshot.png';

      // Trigger a click event to start the download
      a.click();

      // Clean up by removing the anchor and revoking the blob URL
      document.body.removeChild(a);
      URL.revokeObjectURL(a.href);

      //       // // Use the Clipboard API to copy the blob to the clipboard as an image
      //       // const data = [new ClipboardItem({ 'image/png': blob })];
      //       // await navigator.clipboard.write(data);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  const InviteTeam = ({ team, color }) => {
    const imgSrc = getImage(team, 'md');
    const imgRef = useRef(null);

    useEffect(() => {
      if (allowCopyToClipboard) {
        // fetch(BASE_URL + '/admin/imageProxy', { credentials: 'include' })
        // .then(res => res.blob())
        // .then(blob => new Promise((resolve, reject) => {
        //   const reader = new FileReader;
        //   reader.onerror = reject;
        //   reader.onload = () => {
        //     resolve(reader.result);
        //   };
        //   reader.readAsDataURL(blob);
        // })
        // )
        // .then(dataURL => {
        //   imgRef.current.src = dataURL
        // })
      }
    }, []);

    return (
      <div className="m-5 w-full flex flex-col items-center gap-6">
        <span className="font-bold text-[1.25rem]">{team?.name}</span>
        <img ref={imgRef} className="w-32 h-32" src={allowCopyToClipboard ? null : imgSrc} />
        <BsFillPersonFill size={64} color={color} />
      </div>
    )
  }

  return (
    <Modal
      title="Invite"
      open={open}
      width={750 + (allowCopyToClipboard ? 70 : 0)}
      onCancel={onClose}
      footer={null}
    >
      <Tabs
        items={[
          {
            label: 'Image',
            key: 'image',
            children:
              <div className="mt-3 flex gap-2 items-center">

                <div ref={captureRef} className="w-full border-[1px] border-solid border-gray-150 rounded-lg p-5">
                  <div className="text-center text-[1.5rem] font-bold flex gap-3 items-center justify-center">
                    <span>{getDateInCzech(data?.startDate)}</span>
                    <span>–</span>
                    <span>{formatTime(data?.startTime)}</span>
                    <span>–</span>
                    <span>{relationsData?.Venue?.name}</span>
                  </div>
                  <Divider />
                  <div className="flex">
                    <div className="flex-1 flex items-center justify-center">
                      <InviteTeam team={data?.HomeTeam} color={data?.homeJerseyColor} />
                    </div>
                    <div className="w-32 flex items-center justify-center">
                      <span className="text-[3rem] text-gray-600">
                        vs
                      </span>
                    </div>
                    <div className="flex-1 flex items-center justify-center">
                      <InviteTeam team={data?.AwayTeam} color={data?.awayJerseyColor} />
                    </div>
                  </div>
                  <Divider />
                  <div className="flex justify-between text-[1.25rem]">
                    <div className="text-gray-600">Referee: <span className="font-bold text-black">{data?.referee}</span></div>
                    <div className="text-gray-600">Timekeeper: <span className="font-bold text-black">{data?.timekeeper}</span></div>
                  </div>
                </div>
                {allowCopyToClipboard &&
                  <div className="">
                    <CopyToClipboardButton onClick={() => handleCapture()} />
                  </div>
                }
              </div>
          },
          {
            label: 'Text',
            key: 'text',
            children:
              <div className="mt-3 flex gap-2 items-center">
                <div className="flex-1">
                  <TextArea value={getInviteText()} autoSize />
                </div>
                <div className="">
                  <CopyToClipboardButton onClick={() => copyInviteText()} />
                </div>
              </div>
          },
        ]}
      />

    </Modal>
  )
}

export default GameInviteModal