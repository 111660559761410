import { DatePicker, Divider, Radio } from 'antd';
import { Button, Checkbox, Form, Input, Select } from 'antd';
import EmbedNewPlayerSTART from 'components/Embeds/EmbedNewPlayerSTART';
import EmbedNewPlayerSUCCESS from 'components/Embeds/EmbedNewPlayerSUCCESS';
import OpenReplayTracker from 'components/OpenReplayTracker';
import useFetchData from 'hooks/useFetchData'
import usePageTitle from 'hooks/usePageTitle';
import useRecaptcha from 'hooks/useRecaptcha';
import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';
import useGlobalStore from 'store/globalStore';

const EmbedNewPlayer = () => {
  const [state, setState] = React.useState('START');
  usePageTitle('New User - HMS');

  const generateBody = () => {
    switch (state) {
      case 'START':
        return <EmbedNewPlayerSTART setState={setState} />;
      case 'SUCCESS':
        return <EmbedNewPlayerSUCCESS setState={setState} />;
    }
  }

  return (
    <>
        <OpenReplayTracker 
          projectKey={process.env.REACT_APP_OPENREPLAY_KEY}
          userId={'Anonymous'} 
          disableSecureMode={process.env.REACT_APP_OPENREPLAY_DISABLE_SECURE_MODE == 'true'} 
          metadata={{ ENVIRONMENT: process.env.REACT_APP_OPENREPLAY_ENVIRONMENT }}
          silent={true} />

      {generateBody()}
    </>
  )
}

export default EmbedNewPlayer