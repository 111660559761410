import React from 'react';

const SchedulerSimulationErrorsWarnings = ({ data }) => {
    if (!data) {
        return;
    }

    if (Array.isArray(data)) {
        return (
            <pre className="whitespace-pre-wrap bg-gray-100 p-2 text-xs">
                <div className="grid grid-cols-[auto_1fr] gap-2">
                    {data.map((e, idx) => (
                        <React.Fragment key={idx}>
                            <div className="text-right">{idx+1}.</div>
                            <div>
                            {
                                typeof e == 'object' && e.message
                                ?
                                    <div>{e.message}</div>
                                :
                                    <div>{JSON.stringify(e, null, 2)}</div>
                            }    
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </pre>
        )
    } else {
        return (
            <pre className="whitespace-pre-wrap bg-gray-100 p-2 text-xs">
                {JSON.stringify(data?.errors, null, 2)}
            </pre>
        )
    }
}

export default SchedulerSimulationErrorsWarnings


