import React from 'react'
import { Tooltip } from 'antd';
import { useStandingsTableContext } from 'components/Embeds/StandingsTable';

const StandingsHeader = ({ className, tooltip, sorted, children }) => {
  const { isHeaderSticked } = useStandingsTableContext();

  return (
    <div className={
      `table-cell sticky top-0 bg-white align-middle font-normal text-[0.9rem] desktop:text-[1.0rem] py-2 pl-2 pr-2 ` +
      `${sorted ? '!bg-gray-100' : ''} ` +
      `${isHeaderSticked ? 'border-b-[0px] border-gray-300 !bg-gray-100' : ''} ` +
      `${className}`
    }>
      <Tooltip title={tooltip}>{children}</Tooltip>
    </div>
  );
}

export default StandingsHeader