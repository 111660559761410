import React, { useState, useEffect, createContext } from 'react';
import useLoginStore from 'store/loginStore';

const LoginContext = createContext({});

const LoginProvider = ({ children }) => {
    const isLoggedIn = useLoginStore(s => s.isLoggedIn);
    const updateIsLoggedIn = useLoginStore(s => s.updateIsLoggedIn);

    useEffect(() => {
        let isMounted = true;

        const checkLoginStatus = async () => {
            const result = await updateIsLoggedIn();
            if (!result) {
                setTimeout(() => {
                    if (isMounted) {
                        checkLoginStatus();
                    }
                }, 1000);
            }
        };

        checkLoginStatus();

        return () => {
            isMounted = false; // Cleanup on unmount to stop retries
        };
    }, [updateIsLoggedIn]);

    return (
        <LoginContext.Provider value={{ isLoggedIn }}>
            {children}
        </LoginContext.Provider>
    );
};

export { LoginContext, LoginProvider };
