import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useEntities from 'hooks/useEntities';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import useLoadEntity from 'hooks/useLoadEntity';
import HmsField from 'components/Form/HmsField';
import UserOrganizations from 'components/User/UserOrganizations';
import HmsTabbedTables from 'components/HmsTabbedTables';
import UserManagedTeams from 'components/User/UserManagedTeams';
import FormSelect from 'components/Form/FormSelect';
import useLists from 'hooks/useLists';
import EntityAttachments from 'components/EntityAttachments';
import UserInvites from 'components/User/UserInvites';

const User = () => {
  const entities = useEntities();
  const entity = entities.User;
  const { id, isLoading, isError, isNotFound, data, reload, relationsData, reloadRelations } = useLoadEntity({
    entity,
    relations: [
      'Organizations',
      'ManagedTeams',
    ].join(','),
  });
  const { relationsData: attachmentsData, reloadRelations: reloadAttachments } = useLoadEntity({
    entity,
    relations: [
      'Attachments',
    ].join(',')
  });  
  const { relationsData: invitesData, reloadRelations: reloadInvites } = useLoadEntity({
    entity,
    relations: [
      'Invites',
    ].join(','),
  });
  const { userRoles } = useLists();

  return (
    <>
      <HmsEntityFormController
        {... { entity, id, isLoading, isError, isNotFound, data, reload }}
        afterSave={reload}
        form={(
          <>
            <HmsEntityFormGridBasic>
              <HmsField name="firstName" autoFocus />
              <HmsField name="lastName" />
              <FormSelect name={'roles'} label={'Roles'} formItemProps={{ required: true }} options={userRoles}
              />
            </HmsEntityFormGridBasic>
            <HmsEntityFormGridBasic>
              <HmsField name="email" />
              <HmsField name="password" password={true} fieldItemProps={{ editable: id == '_new_' }} />
              <HmsField name="phone" />
            </HmsEntityFormGridBasic>
          </>
        )}
        footer={id != '_new_' && (
          <div className="mt-4">
            <div className="mt-4">
              <HmsTabbedTables
                tables={[
                  {
                    title: "Organizations" + (relationsData?.Organizations ? (' (' + relationsData?.Organizations?.length + ')') : ''),
                    children: <UserOrganizations
                      sourceEntityId={data?.userId}
                      data={relationsData?.Organizations}
                      onChange={reloadRelations}
                    />
                  },
                  {
                    title: "Managed Teams" + (relationsData?.ManagedTeams ? (' (' + relationsData?.ManagedTeams?.length + ')') : ''),
                    children: <UserManagedTeams
                      sourceEntityId={data?.userId}
                      data={relationsData?.ManagedTeams}
                      onChange={reloadRelations}
                    />
                  },
                  {
                    title: "Invites" + (invitesData?.Invites ? (' (' + invitesData?.Invites?.length + ')') : ''),
                    children: <UserInvites 
                      sourceEntityId={data?.userId}
                      data={invitesData?.Invites}
                      onChange={reloadInvites}
                      sendInviteDisabledReason={
                        relationsData?.Organizations?.length == 0 ? 'User must be a member of at least one organization to send invites' :
                        null
                      }
                    />,
                  },
                  {
                    title: "Attachments" + (attachmentsData?.Attachments ? (' (' + attachmentsData?.Attachments?.length + ')') : ''),
                    children: <EntityAttachments entity={entity.name} id={data?.[entity.primaryKey]} data={attachmentsData} reload={reloadAttachments} />,
                  },
               ]}
              />
            </div>
          </div>
        )}
      />
    </>
  );
}

export default User
