import useEntities from 'hooks/useEntities';
import RelationsTable from 'components/RelationsTable';
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select, Table, TimePicker, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { add } from 'lodash';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import moment from 'moment';
import useFetchEntity from 'hooks/useFetchEntity';
import useConfirmStore from 'store/confirmStore';
import SchedulerConfigIceSpecialRequestModal from 'components/SchedulerConfig/SchedulerConfigIceSpecialRequestModal';
import SchedulerConfigSpecialRequestsMap from 'components/SchedulerConfig/SchedulerConfigSpecialRequestsMap';

const SchedulerConfigSpecialRequests = ({ sourceEntityId, data, onChange, phases, icePatterns, isLocked }) => {
    const entities = useEntities();
    const sourceEntity = entities.SchedulerConfig;
    const targetEntity = entities.SchedulerConfigSpecialRequest;
    const showConfirm = useConfirmStore(state => state.showConfirm);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [addModalValues, setAddModalValues] = useState(null);
    const [mapOpen, setMapOpen] = useState(false);

    const addClicked = () => {
        setAddModalValues(null);
        setAddModalOpen(moment().format('YYYY-MM-DD-HH-mm-ss'));
    }

    const editClicked = (record) => {
        setAddModalValues(record);
        setAddModalOpen(moment().format('YYYY-MM-DD-HH-mm-ss'));
    }

    const onAddModalClose = () => {
        setAddModalOpen(false);
        onChange();
    }

    return (
        <>
            <SchedulerConfigIceSpecialRequestModal 
                key={addModalOpen}
                sourceEntityId={sourceEntityId} 
                addModalOpen={addModalOpen} 
                onClose={onAddModalClose}
                phases={phases}
                icePatterns={icePatterns}
                values={addModalValues}
            />

            <Modal
                open={mapOpen}
                onCancel={() => setMapOpen(false)}
                footer={null}
                className="max-w-[90vw] min-w-[90vw]"
                title="Special Requests Map"
            >
                <div className="py-2">
                    <SchedulerConfigSpecialRequestsMap
                        specialRequests={data}
                        phases={phases}
                        icePatterns={icePatterns}
                    />
                </div>
            </Modal>

            <RelationsTable
                data={data}
                sourceEntity={sourceEntity}
                targetEntity={targetEntity}
                sourceEntityId={sourceEntityId}
                emptyMessage={`This ${sourceEntity.displayName} does not have any ${targetEntity.displayNamePlural}`}
                removeClicked={!isLocked && (async (id) => {
                    await entities.SchedulerConfigSpecialRequest.delete(id);
                    onChange();
                })}
                onRowClicked={(record) => editClicked(record)}
                buttons={[
                    ...(!isLocked ? [
                    {
                        label: 'Add',
                        icon: <PlusOutlined />,
                        onClick: addClicked,
                    }] : []),
                ]}
                extraButtonBarItems={
                    <div>
                        <Button size="small" onClick={() => setMapOpen(true)}>Show Map</Button>
                    </div>
                }
            />
        </>
    )

}

export default SchedulerConfigSpecialRequests
