import React from "react";
import IGImg from "./IGImg";

const LogoOrTitle = ({
  logo,
  title,
  className,
  logoClassName,
  titleClassName,
}) => {
  return logo ? (
    <IGImg 
      className={`${className} ${logoClassName}`} 
      src={logo} 
      alt={title}
    />
  ) : (
    <h2 className={`${className} ${titleClassName} text-center`}>{title}</h2>
  );
};

export default LogoOrTitle;
