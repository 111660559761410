import React from 'react'

function DebugPrinter({ data }) {
  return (
    <pre className="mt-2 bg-gray-100 p-2 whitespace-pre-wrap">
      {data.map(d => JSON.stringify(d, null, 2))}
    </pre>
  )
}

export default DebugPrinter