import RelationsEditable from 'components/RelationsEditable'
import useEntities from 'hooks/useEntities';
import React from 'react'

const SeasonRegistrationTeamGroups = ({ sourceEntityId, seasonId, data, onChange }) => {
  const entities = useEntities();
  const sourceEntity = entities.Team;
  const targetEntity = entities.Group;

  return (
      <RelationsEditable 
          data={data}
          sourceEntity={sourceEntity} 
          targetEntity={targetEntity}
          sourceEntityId={sourceEntityId}
          emptyMessage={`This ${sourceEntity.displayName} does not have any ${targetEntity.displayNamePlural} for the given Season.`}
          targetEntityFilters={{seasonId: seasonId}}
          onChange={onChange}
      />
  )
}

export default SeasonRegistrationTeamGroups