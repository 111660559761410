import React, { useEffect, useState } from 'react';
import { Layout, Menu, Dropdown, Row, Col, Spin, Space, Divider, Drawer, Tabs, Collapse, Tooltip, Tag, Table, Input, Slider, Modal, Button, Radio, Checkbox } from 'antd';
import moment from 'moment';
import SchedulerSimulationSummaryTable from 'components/SchedulerSimulation/SchedulerSimulationSummaryTable';
import _, { sortBy } from 'lodash';
import SchedulerSimulationLegend from 'components/SchedulerSimulation/SchedulerSimulationLegend';
import { getSchedulerUserFriendlyName } from 'utils/schedulerHelper';
const sorted = (arr, comparer = (a, b) => ('' + a).localeCompare(('' + b))) => arr.slice().sort(comparer);

const SchedulerSimulationTeams = ({ data, specialRequestsData, type }) => {
  const [gamesByWeek, setGamesByWeek] = useState(null);
  const [timeSlotsByWeeks, setTimeSlotsByWeek] = useState(null);
  const [showSpecialTeams, setShowSpecialTeams] = useState(false);
  const SPECIAL_TEAMS = ['PLATIDLO', 'PROJEKŤÁK', 'FANKLUB LEV'];

  const isTeamSpecial = (team) => {
    const teamName = team?.split('__')?.[1];
    return SPECIAL_TEAMS.includes(teamName);
  }

  const categoryColors = {
    'A':       { bg: 'bg-green-300',  text: 'text-green-500' },
    'B':       { bg: 'bg-orange-300', text: 'text-orange-500' },
    'C':       { bg: 'bg-red-400',    text: 'text-red-500' },
    'Unknown': { bg: 'bg-purple-400', text: 'text-purple-500' },
  }

  const isTeamVisible = (team) => {
    // special teams
    if (showSpecialTeams && !isTeamSpecial(team)) {
      return false;
    }

    // special requests
    if (type == 'BySpecialRequests') {
      const teamId = team.split('__')[0];
      const specialRequest = specialRequestsData.find(sr => sr.teamId == teamId);
      if (specialRequest == null) {
        return false;
      }
    }

    return true;
  }

  useEffect(() => {
    if (data) {
      const allTimeSlots = calcAllTimeSlots(data);

      const timeSlotsByWeeks = calcTimeSlotsByWeeks(allTimeSlots);
      setTimeSlotsByWeek(timeSlotsByWeeks);

      const gamesByWeek = calcGamesByWeek(allTimeSlots);
      setGamesByWeek(gamesByWeek);
    }
  }, [data]);

  if (type != 'ByCategory' && type != 'BySpecialRequests') {
    return (<>Invalid type: {type ?? 'null'}</>);
  }

  function calcTimeSlotsByWeeks(allTimeSlots) {
    const timeSlotsByWeeks = [];
    let prevWeek = null;
    let prevDay = null;
    allTimeSlots.forEach(t => {
        const curWeek = moment(t.date).startOf('week').isoWeekday(1).format('MM/DD');
        const curDay = moment(t.date).format('DD');
        if (prevWeek != curWeek) {
            timeSlotsByWeeks.push({
                type: 'week_separator',
                value: curWeek,
            });
            prevWeek = curWeek;
        } else {
            if (prevDay != curDay) {
                timeSlotsByWeeks.push({
                    type: 'day_separator',
                    value: curDay,
                });
                prevDay = curDay;
            }
        }
        timeSlotsByWeeks.push(t);
    });
    // console.log('timeSlotsByWeeks', timeSlotsByWeeks)
    return timeSlotsByWeeks;
}

  function calcGamesByWeek(allTimeSlots) {
    if (allTimeSlots.length == 0) {
      return [];
    }
    const generateDaysBetween = (startDate, endDate) => Array.from({ length: moment(endDate).diff(startDate, 'days') + 1 }, (_, i) => moment(startDate).add(i, 'days').format('YYYY-MM-DD'));
    let prevWeek = null;
    const gamesByWeek = [];
    generateDaysBetween(
        allTimeSlots[0].date,
        allTimeSlots[allTimeSlots.length - 1].date)
        .forEach(day => {
            const curWeek = moment(day).startOf('week').isoWeekday(1).format('MM/DD');
            if (prevWeek != curWeek) {
                gamesByWeek.push({
                    week: curWeek,
                    days: [],
                    games: [],
                });
                prevWeek = curWeek;
            }
            const games = data.games.filter(g => g.time && g.time.substr(0, 10) == day);    // g.time is null if the game has not been scheduled
            gamesByWeek[gamesByWeek.length - 1].days.push({
                date: day,
                games: games,
            });
            gamesByWeek[gamesByWeek.length - 1].games.push(...games);
        });
    // console.log('gamesByWeek', gamesByWeek);
    return gamesByWeek;
}

  function calcAllTimeSlots(data) {
    const allTimeSlots = [];
    data?.venues?.forEach(v => {
        v.availableTimes.forEach(t => {
            allTimeSlots.push({
                name: getSchedulerUserFriendlyName(v.name),
                ...t,
                game: data.games.find(g => g.time == (t.date + 'T' + t.startTime + ':00') && g.venue == v.name),
            });
        });
    });
    allTimeSlots.sort((a, b) => {
        return ('' + a.date + 'T' + a.startTime + '-' + a.venue).localeCompare('' + b.date + 'T' + b.startTime + '-' + b.venue);
    })
    // console.log('allTimeSlots', allTimeSlots)
    return allTimeSlots;
}

  const SlotsByWeek = ({ team, getBgColor, gamesByWeek }) => (
    <div className={`flex items-center`}>
      {gamesByWeek.map((w, idx) => {
        const games = (w.games ?? []).filter(g => g.homeTeam == team || g.awayTeam == team);
        return (
          <span 
            key={idx} 
            className={[
              `flex items-center gap-[1px] border-[1px] mr-[1px] p-[2px]`,
              type == 'ByCategory'
                ? games.length > 2 ? 'border-red-500 border-dashed bg-red-100' : games.length > 1 ? 'border-yellow-500 border-solid' : 'border-transparent'
                : 'border-transparent'
            ].join(' ')}
          >
            {w.days?.map((t, idx) => {
              const bgColor = getBgColor ? getBgColor(t) : '';
              const games = (t.games ?? []).filter(g => g.homeTeam == team || g.awayTeam == team);
              return (
                <div key={idx}>
                  {games.length > 0 &&
                    <Tooltip placement="bottom" title={
                      <>
                        {games.map((g, idx) => (
                          <div key={idx}>
                            <div className="flex flex-col items-center">
                              <div className="whitespace-nowrap mb-1">{getSchedulerUserFriendlyName(g.venue)}</div>
                              <div className="whitespace-nowrap mb-1">{moment(g.time).format('dddd')}</div>
                              <div className="whitespace-nowrap mb-3">{moment(g.time).format('YYYY-MM-DD HH:mm')}</div>
                              <div className="whitespace-nowrap">{getSchedulerUserFriendlyName(g.homeTeam)}</div>
                              <div className="whitespace-nowrap">vs.</div>
                              <div className="whitespace-nowrap mb-3">{getSchedulerUserFriendlyName(g.awayTeam)}</div>
                              <div className="whitespace-nowrap mb-3">Category: {g.category}</div>
                              <div className="whitespace-nowrap">Idx: {g.idx}</div>
                              <div className="whitespace-nowrap">Scheduler score Home: {g.scoreHt && g.scoreHt.toFixed(2)}</div>
                              <div className="whitespace-nowrap">Scheduler score Away: {g.scoreAt && g.scoreAt.toFixed(2)}</div>
                            </div>
                          </div>))}
                      </>
                    }>
                      <div className="flex">
                        <span
                          className={`inline-block w-1 h-6 ${bgColor} hover:bg-gray-500 cursor-pointer`}
                        ></span>
                      </div>
                    </Tooltip>
                  }
                  {games.length == 0 &&
                    <span className="flex">
                      <span className={`inline-block w-1 h-6 ${bgColor} hover:bg-gray-500 cursor-pointer`}></span>
                    </span>
                  }
                </div>
              );
            })}
          </span>);
      })
      }
    </div>
  );

  const SeparatedViews = ({ items }) => (
    <>
      {items.map((i, idx) => (
        <div key={idx}>
          <Divider>{i.label}</Divider>
          {i.children}
        </div>
      ))}
    </>
  );

  if (!gamesByWeek) {
    return (<></>);
  }

  const getPreferenceRate = (t) => {
    if (!data?.games) {
      return '-';
    }

    const percent = getPreferenceRateNum(t);

    let className = '';

    if (percent == 100) {
      className = 'text-green-500';
    } else if (percent > 75) {
      className = 'text-orange-500';
    } else {
      className = 'text-red-500';
    }
    
    return (
      <span className="text-gray-400 text-xs cursor-pointer">(
        <Tooltip title="Special Reqeust Preference Rate">
          <span className={className}>{percent}%</span>
        </Tooltip>
        )</span>
    );
  }
  
  const getCategoryRate = (t) => {
    const games = data?.games?.filter(g => g.time && (g.homeTeam == t || g.awayTeam == t));

    const groupGamesCount = data?.games.filter(g => g.group == games[0]?.group).length;
    const numberOfUniqueTeamsInGroup = data?.games.filter(g => g.group == games[0]?.group).map(g => g.homeTeam).concat(data?.games.filter(g => g.group == games[0]?.group).map(g => g.awayTeam)).filter((value, index, self) => self.indexOf(value) === index).length;
    const correctNumberOfGamesPerTeam = groupGamesCount / (numberOfUniqueTeamsInGroup || 1) * 2;

    const categories = [ 'A', 'B', 'C', 'Unknown' ];

    const countPerCategory = categories
      .map(c => ({
        category: c,
        percentage: Math.round(games.filter(g => (g.category == '' || g.category == null ? 'Unknown' : g.category) == c).length / (games.length || 1) * 100),
      }));

    return (
      <span className="text-gray-400 text-xs">
        <span className="flex">
          (
            <span className="flex gap-1">
              <span className={`cursor-pointer ${games.length != correctNumberOfGamesPerTeam ? 'bg-red-500 text-white rounded-sm' : ''}`}>
                <Tooltip title={games.length != correctNumberOfGamesPerTeam ? 'ERROR: Team is playing fewer than required number of games' : 'Number of games'}>
                  {games.length}
                </Tooltip>
              </span>
              →
              {countPerCategory
                .filter(cpc => cpc.percentage > 0)
                .map((cpc, idx) => (
                  <span key={idx} className={`${categoryColors[cpc.category]?.text} cursor-pointer`}>
                    <Tooltip title={`Percentage of games played in Category ${cpc.category} slot`}>
                      {cpc.percentage}%
                    </Tooltip>
                  </span>
                ))
              }
            </span>
          )
        </span>
      </span>
    );
  }

  const sortByCategory = (teams) => {
    
    const compareTeamsByCategory = (a, b) => {
      const aGames = data.games.filter(g => g.time && (g.homeTeam == a || g.awayTeam == a));
      const bGames = data.games.filter(g => g.time && (g.homeTeam == b || g.awayTeam == b));

      const aGamesByCategory = aGames.reduce((acc, g) => {
        const category = g.category == '' || g.category == null ? 'Unknown' : g.category;
        acc[category] = (acc[category] || 0) + 1;
        return acc;
      }
      , {});

      const bGamesByCategory = bGames.reduce((acc, g) => {
        const category = g.category == '' || g.category == null ? 'Unknown' : g.category;
        acc[category] = (acc[category] || 0) + 1;
        return acc;
      }
      , {});

      if ((aGamesByCategory['A'] ?? 0) > (bGamesByCategory['A'] ?? 0)) return -1;
      if ((aGamesByCategory['A'] ?? 0) < (bGamesByCategory['A'] ?? 0)) return 1;
      if ((aGamesByCategory['B'] ?? 0) > (bGamesByCategory['B'] ?? 0)) return -1;
      if ((aGamesByCategory['B'] ?? 0) < (bGamesByCategory['B'] ?? 0)) return 1;
      if ((aGamesByCategory['C'] ?? 0) > (bGamesByCategory['C'] ?? 0)) return -1;
      if ((aGamesByCategory['C'] ?? 0) < (bGamesByCategory['C'] ?? 0)) return 1;
      return 0;
    }

    teams.sort((a, b) => {
      return compareTeamsByCategory(a, b);
    });

    return teams;
  }

  const getPreferenceRateNum = (t) => {
    let preferred = 0;
    let unpreferred = 0;
    for (const game of data.games) {
      if (game.homeTeam == t) {
        if (game.prefersHt) {
          preferred++;
        } else {
          unpreferred++;
        }
      }
      if (game.awayTeam == t) {
        if (game.prefersAt) {
          preferred++;
        } else {
          unpreferred++;
        }
      }
    }

    const percent = Math.round(preferred / ((preferred + unpreferred) || 1) * 100);

    return percent;
}
  
  const sortBySpecialRequests = (teams) => {

    const compareTeamsByPreferenceRate = (a, b) => {
      const prefRateA = getPreferenceRateNum(a);
      const prefRateB = getPreferenceRateNum(b);

      return prefRateB - prefRateA;
    };

    teams.sort((a, b) => {
      return compareTeamsByPreferenceRate(a, b);
    });

    return teams;
  }

  return (
    <div className="max-h-[calc(100vh-80px)] overflow-scroll">
      <div className="flex justify-between">
        <div>
          <Checkbox checked={showSpecialTeams} onChange={(e) => setShowSpecialTeams(e.target.checked)}>
            Show special teams
          </Checkbox>
        </div>
        <SchedulerSimulationLegend
          className="mx-auto mb-4"
          items={
            type == 'ByCategory'
              ?
              [
                { color: categoryColors['A'].bg, text: 'Category A' },
                { color: categoryColors['B'].bg, text: 'Category B' },
                { color: categoryColors['C'].bg, text: 'Category C' },
                { color: categoryColors['Unknown'].bg, text: 'Category Unknown' },
                { color: 'bg-gray-300', text: 'Not playing' },
                { color: 'border-[1px] border-yellow-500 bg-white', text: '2 games per week' },
                { color: 'border-[1px] border-dashed border-red-500 bg-red-100', text: '3+ games per week' },
              ]
              :
              [
                { color: 'bg-green-300', text: 'Preferred slot' },
                { color: 'bg-red-400', text: 'Unpreferred slot' },
                { color: 'bg-orange-400', text: 'Unknown' },
              ]
          } />
        <div>x</div>
      </div>

      <SeparatedViews
        items={
          sorted((data.groups.map((g) => ({
            label: getSchedulerUserFriendlyName(g.name),
            key: g.name,
            children: <>
              <div>
                <SchedulerSimulationSummaryTable
                  className="mt-3 mb-3"
                  data={[
                    { label: 'Teams', value: g.teams.length },
                    { label: 'Games', value: data.games.filter(game => game.group.toUpperCase() == g.name.toUpperCase()).length },
                  ]} />
                <div className="max-w-screen overflow-scroll pt-3 pb-2">
                  {
                  (type == 'ByCategory' ? sortByCategory(g.teams) : sortBySpecialRequests(g.teams))
                    .filter(t => isTeamVisible(t))
                    .map(t => (
                    <div key={t} className="flex flex-nowrap items-center mb-[1px]">
                      <div className="flex-shrink-0 mb-1 pr-3 w-[150px] whitespace-nowrap overflow-hidden overflow-ellipsis flex items-center justify-end">
                        {getSchedulerUserFriendlyName(t)}
                      </div>
                      <div className={`flex-shrink-0 mb-1 pr-3 ${type == 'ByCategory' ? 'w-[150px]' : 'w-[50px]'} whitespace-nowrap overflow-hidden overflow-ellipsis flex items-center justify-start`}>
                      <span>
                          {type == 'ByCategory'
                            ?
                              getCategoryRate(t)
                            :
                              getPreferenceRate(t)
                          }
                        </span>
                      </div>
                      <div className="">
                        <SlotsByWeek team={t} gamesByWeek={gamesByWeek} getBgColor={(d) => {

                          const gamesHome = (d.games ?? []).filter(g => g.homeTeam == t);
                          const gamesAway = (d.games ?? []).filter(g => g.awayTeam == t);

                          const games = [...gamesHome, ...gamesAway];

                          if (games.length == 0) {
                            return 'bg-gray-300';
                          }
                          
                          if (games.length > 1) {
                            console.log(`%cMore games per day ${d.date} for team ${t}`, 'color: rgb(228,105,98); background-color: rgb(41,0,0);');
                            return 'border-[1px] border-red-500 bg-white';
                          }

                          const game = games[0];

                          switch (type) {
                            case 'ByCategory':
                              const gameCategory = game.category;
                              switch (gameCategory) {
                                case 'A': return 'bg-green-300';
                                case 'B': return 'bg-orange-300';
                                case 'C': return 'bg-red-400';
                                default:  return 'bg-purple-400';
                              }

                            case 'BySpecialRequests':
                              const teamPrefers = game.homeTeam == t ? game.prefersHt : game.awayTeam == t ? game.prefersAt : false;
                              if (teamPrefers === false) {
                                return 'bg-red-400';
                              }
                              if (teamPrefers === true) {
                                return 'bg-green-300';
                              }
                              return 'bg-orange-300';
                          }
                          
                        }} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>,
          }))), (a, b) => a.label.localeCompare(b.label))
        }
        tabBarExtraContent={
          <></>
        }
      />
    </div>

  )
}

export default SchedulerSimulationTeams