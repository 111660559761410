import useEntities from 'hooks/useEntities';
import RelationsTable from 'components/RelationsTable';
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select, Table, TimePicker, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { add } from 'lodash';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import moment from 'moment';
import useFetchEntity from 'hooks/useFetchEntity';
import useConfirmStore from 'store/confirmStore';
import SchedulerConfigIceSpecialRequestModal from 'components/SchedulerConfig/SchedulerConfigIceSpecialRequestModal';
import EntityLogo from 'components/EntityLogo';
import { formatTimeShort } from 'utils/generateEntityColumns';
import { uniqueByProperty } from 'utils/oneLiners';

const SchedulerConfigIcePatternsCalendar = ({ phases, icePatterns }) => {

    const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const weekdaysFull = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const hours = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];

    const getPatterns = (hour, weekday) => {
        return icePatterns.filter(p => {
            const startTimeMoment = p.startTime;
            const hourMoment = moment(hour, 'HH:mm').format('HH:mm');
            const nextHourMoment = moment(hour, 'HH:mm').add(1, 'hour').format('HH:mm');
            return p.weekday == weekday && startTimeMoment >= hourMoment && startTimeMoment < nextHourMoment;
        });
    }

    const overlappingPattersByPatternId = useMemo(() => {
        const getOverlappingPatternsRecursive = (p) => {
            let result = [];
            result.push(p);
    
            for (let i=0; i<10; i++) {
    
                const allOverlapping = result.map(r => getOverlappingPatternsInner(r)).flat();
    
                result.push(...allOverlapping);
                result = uniqueByProperty(result, 'schedulerConfigIcePatternId');
            }
    
            result.sort((a, b) => {
                return ((a.startTime ?? '') + a.schedulerConfigIcePatternId).localeCompare((b.startTime ?? '') + b.schedulerConfigIcePatternId);
            });
    
            return result;
        }
    
        const getOverlappingPatternsInner = (p) => {
            const result = icePatterns.filter(p2 => {
                return (
                    (p.weekday == p2.weekday) &&
                    (
                        (p2.startTime >= p.startTime && p2.startTime < p.endTime) ||
                        (p2.endTime > p.startTime && p2.endTime <= p.endTime)
                    )
                );
            });
            return result;
        }

        const result = {};
        icePatterns.forEach(p => {
            result[p.schedulerConfigIcePatternId] = getOverlappingPatternsRecursive(p);
        });
        return result;
    }, [icePatterns]);

    const getOverlappingPatterns = (p) => {
        return overlappingPattersByPatternId[p.schedulerConfigIcePatternId];
    }

    const getPatternPosition = (p) => {
        const overlappingPatterns = overlappingPattersByPatternId[p.schedulerConfigIcePatternId];

        let position = -1;
        overlappingPatterns.some((p2, idx) => {
            if (p2.schedulerConfigIcePatternId == p.schedulerConfigIcePatternId) {
                position = idx;
                return true;
            }
        });
        
        return position;
    }

    const weekdayToFull = (weekday) => {
        return weekdaysFull[weekdays.indexOf(weekday)];
    }

    const getBgClass = (idx) => {
        return 'bg-green-100'
        // if (idx == 0) return 'bg-green-100';
        // if (idx == 1) return 'bg-green-200';
        // if (idx == 2) return 'bg-green-300';
        // if (idx == 3) return 'bg-green-400';
        // if (idx > 3) return 'bg-green-500';
    }

    const getDuration = (start, end) => {
        const startMoment = moment(start, 'HH:mm');
        const endMoment = moment(end, 'HH:mm');
        return endMoment.diff(startMoment, 'minutes')
    }

    return (
        <>
            <div className="grid grid-cols-[75px_1fr_1fr_1fr_1fr_1fr_1fr_1fr] gap-px border-t border-l border-gray-200">
                <div className="border-r border-b bg-gray-50"></div>
                {weekdaysFull.map((weekday, idx) => (
                    <div key={idx} className="border-r border-b text-center h-[35px] bg-gray-100">
                        <span className="w-full h-full flex items-center justify-center font-bold">
                            {weekday}
                        </span>
                    </div>
                ))}
            </div>

            <div className="w-full max-h-[70vh] overflow-scroll">

                <div className="grid grid-cols-[75px_1fr_1fr_1fr_1fr_1fr_1fr_1fr] gap-px border-t border-l border-gray-200">

                    {hours.map((hour, hidx) => (
                        <React.Fragment key={hidx}>
                            <div class="border-r border-b text-center h-[40px] bg-gray-100">
                                <span className="w-full h-full flex items-center justify-center font-medium">
                                    {hour}
                                </span>
                            </div>
                            {weekdays.map((weekday, idx) => (
                                <div key={idx} class="border-r border-b relative">
                                    {getPatterns(hour, weekday).map((p, idx) => (
                                        <div
                                            key={idx}
                                            class={`absolute px-1 border-[1px] border-green-500 box-border ${getBgClass(idx)} text-[0.8rem] overflow-hidden z-10`}
                                            style={{
                                                top: '' + Math.round((40 / 60) * getDuration(hour, p.startTime)) + 'px',
                                                left: 'calc(' + Math.round((100 / getOverlappingPatterns(p).length)) * getPatternPosition(p) + '%)',
                                                width: 'calc(' + Math.round((100 / getOverlappingPatterns(p).length)) + '% - '+3+'px)',
                                                height: 'calc(' + ((40 / 60) * getDuration(p.startTime, p.endTime) + Math.floor(getDuration(p.startTime, p.endTime)/60*2)) + 'px - 1px)',
                                            }}
                                        >
                                            <Tooltip title={
                                                <div className="flex flex-col">
                                                    <span className="overflow-ellipsis truncate w-full">{p.Venue?.name}</span>
                                                    <span>{weekdayToFull(p.weekday)}</span>
                                                    <span>{formatTimeShort(p.startTime)} - {formatTimeShort(p.endTime)}</span>
                                                </div>
                                            }>
                                                <div className={`w-full h-full flex flex-col justify-start cursor-pointer`}>
                                                    <span className={`overflow-ellipsis w-full ${getOverlappingPatterns(p).length > 5 ? 'text-[0.6rem]' : getOverlappingPatterns(p).length > 2 ? 'text-[0.75rem]' : ''}`}>{p.Venue?.short ?? p.Venue?.name}</span>
                                                    <span className={`${getOverlappingPatterns(p).length > 2 ? 'text-[0.55rem]' : ''}`}>{formatTimeShort(p.startTime)} - {formatTimeShort(p.endTime)}</span>
                                                </div>
                                            </Tooltip>
                                            </div>
                                    ))}
                                </div>
                            ))}
                        </React.Fragment>
                    ))}

                </div>
            </div>

        </>
    )

}

export default SchedulerConfigIcePatternsCalendar
