import { Button, Modal, Spin, Tooltip } from 'antd'
import TeamRegistrationDialog from 'components/TeamRegistrationDialog/TeamRegistrationDialog'
import useFetchEntities from 'hooks/useFetchEntities'
import React, { useState } from 'react'
import { CheckCircleOutlined, DeleteOutlined, ShoppingCartOutlined, DollarOutlined, InfoCircleOutlined, CloseCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import useFetchEntity from 'hooks/useFetchEntity';
import HmsStatusTag from 'components/HmsStatusTag';

const TeamRegistrationTeamManager = ({ sourceEntityId, sourceEntityData }) => {
  const [registerDialogOpenKey, setRegisterDialogOpenKey] = useState(null);
  const { data: teamData, reload } = useFetchEntity('Team', sourceEntityId,
    [
      'Groups',
      'SeasonRegistrations',
      'SeasonRegistrations>Season(name)',
      'SeasonRegistrations>Season>Competition(name)',
      'SeasonRegistrations>Groups(name)',
      'SeasonRegistrations>SeasonRegistrationSpecials(name,text)',
      'SeasonRegistrations>Attachments(fileName,publicUrl)',
    ].join(','),
  );

  const seasonRegistrationsData = teamData?.SeasonRegistrations;

  const getFinalGroups = (seasonId) => {
    return teamData?.Groups?.filter(g => g.seasonId == seasonId);
  }

  const registerClicked = () => {
    setRegisterDialogOpenKey(new Date().toISOString());
  }

  const onDialogClose = () => {
    reload();
  }

  const InvalidState = () => {
    return (
      <div className="flex flex-col items-center justify-evenly h-[8em]">
        <div className="text-gray-500">
          This team needs to be sent for approval before you can register in a competition.
        </div>
      </div>
    )
  }

  const NotRegistered = () => {
    return (
      <div className="flex flex-col items-center justify-evenly h-[8em]">
        <div className="text-gray-500">
          This team is not registered to any future competition.
        </div>
        <div>
          <Button size="small" type="primary" onClick={() => registerClicked()}>Register</Button>
        </div>
      </div>
    )
  }

  const RegistrationTable = () => {
    return (
      <div className="flex flex-col">
        <div className="mt-1 mb-3">This team is registered for the following competitions:</div>
        <div className="flex flex-col gap-2">
          {seasonRegistrationsData.map((registration, idx) => (
            <div key={idx} className="grid grid-cols-[auto_1fr] gap-x-2 gap-y-1 bg-gray-100 rounded-md p-2">

              <span>
                {registration.status == 'PROCESSED' && <CheckCircleOutlined className="text-green-500" />}
                {registration.status == 'REJECTED'  && <CloseCircleOutlined className="text-red-500" />}
                {registration.status == 'NEW'       && <ClockCircleOutlined className="text-yellow-500" />}
              </span>

              <div className="flex flex-col gap-1">             
                <div className="flex gap-2 font-semibold">
                  <span>{registration.Season?.Competition?.name}</span>
                  <span>&gt;</span>
                  <span>{registration.Season?.name}</span>
                </div>

                <span className="font-normal">
                  <span>
                    Preferred Groups: {registration.Groups?.map(g => g.name)?.join(', ')}
                  </span>
                </span>

                <span className="font-normal">
                  <span>
                    Final Groups: {getFinalGroups(registration.seasonId)?.map(g => g.name)?.join(', ') ?? '-'}
                  </span>
                </span>

                {registration.Attachments.length > 0 &&
                  <span className="font-normal">
                    <span>
                      Attachments: {registration.Attachments?.map((a, idx) => (
                        <span key={idx} className="inline-block">
                          {idx != 0 && <span>, </span>}
                          <a href={a.publicUrl} target="_blank">
                            {a.fileName}
                          </a>
                        </span>
                      ))}
                    </span>
                  </span>
                }

                {registration.SeasonRegistrationSpecials?.map((special, idx) => (
                  <>
                    <span className="font-normal">
                      <span>
                          <DollarOutlined className="mr-2" />
                      </span>
                      {special.name}
                      <span className="ml-2 underline hover:no-underline">
                        <Tooltip className="cursor-pointer" title={special.text}>
                          (more info)
                        </Tooltip>
                      </span>
                    </span>
                  </>
                ))}

                {registration.externalComment && registration.externalComment.trim().length > 0 && (
                  <>
                    <span className="font-normal">
                      <span>
                        Comment from organizer: <i>{registration.externalComment}</i>
                      </span>
                    </span>
                  </>
                )}

                <span className="font-normal">
                  <span>
                    Status: <HmsStatusTag status={registration.status} entity={'SeasonRegistration'} />
                  </span>
                </span>

                <span className="font-normal">
                  <span>
                    <a href={`/embed/season_terms?seasonId=${registration.seasonId}&lang=en`} target="_blank">View propositions</a>
                  </span>
                </span>
              </div>

            </div>
          ))}
        </div>
        <div className="mt-4 mb-4">
          If you want to register for an another competition, please click below:
        </div>
        <div>
          <Button size="small" type="primary" onClick={() => registerClicked()}>New registration</Button>
        </div>
        <div className="mb-5"></div>
      </div>
    )
  }

  return (
    <>
      <TeamRegistrationDialog
        teamId={sourceEntityId}
        alreadyRegisteredSeasonIds={seasonRegistrationsData?.map(r => r.seasonId)}
        key={registerDialogOpenKey}
        open={registerDialogOpenKey != null}
        onClose={() => { setRegisterDialogOpenKey(null); onDialogClose(); } }
      />

      {sourceEntityData?.status == 'DRAFT' && <InvalidState />}
      {sourceEntityData?.status != 'DRAFT' && seasonRegistrationsData?.length == 0 && <NotRegistered />}
      {sourceEntityData?.status != 'DRAFT' && seasonRegistrationsData?.length > 0 && <RegistrationTable />}
    </>
  )
}

export default TeamRegistrationTeamManager
