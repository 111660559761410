import React, { useEffect, useState } from 'react'
import Confirm from 'components/Confirm';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import useGlobalStore from 'store/globalStore';
import { Button, Spin, Modal } from 'antd';

const SchedulerSimulationGenerateButton = ({ data, generatedGames, onFinished }) => {
    const [generateConfirmOpen, setGenerateConfirmOpen] = useState(false);
    const [generatorWorking, setGeneratorWorking] = useState(false);
    const [generatorFeedback, setGeneratorFeedback] = useState(false);
    const postSchedulerGenerateGames = useGlobalStore(s => s.postSchedulerGenerateGames);

    const generate = () => {
        setGenerateConfirmOpen(true);
    }

    const generateConfirmed = async () => {
        setGenerateConfirmOpen(false);
        setGeneratorWorking(true);

        const result = await postSchedulerGenerateGames(data.schedulerSimulationId);
        console.log('Generator result', result);
        
        setGeneratorWorking(false);
        if (result.status == 200) {
            setGeneratorFeedback({ type: 'success' });
        } else {
            setGeneratorFeedback({ type: 'error', message: result.body?.message ? <div className="flex flex-col items-center gap-1"><div>ERROR!</div><div>{result.body?.message}</div></div> : null });
        }

        if (onFinished) {
            onFinished();
        }
    }

    const generateCancelled = () => {
        setGenerateConfirmOpen(false);
    }

    return (
        <>
            <Confirm
                message={
                    <div className="mt-4">
                        {(generatedGames?.length ?? 0) > 0
                            ?
                                <div className="mb-3 border-[1px] p-1 border-red-500 bg-red-100 text-red-500">
                                    WARNING! There are already {generatedGames?.length} games generated.
                                    <br/>
                                    This action would duplicate them?
                                </div>
                            :
                                (data?.errors?.length ?? 0) > 0
                                    ?
                                        <div className="mb-3 border-[1px] p-1 border-red-500 bg-red-100 text-red-500">
                                            WARNING! This simulation contains errors and should not be used to generate games!
                                        </div>
                                    :
                                        <div className="mb-3">{`This will generate ${data.games.length} new games in the system.`}</div>
                        }
                        <div>Are you sure do you want to continue?</div>
                    </div>
                }
                confirmButtonText="Generate"
                visible={generateConfirmOpen}
                handleConfirm={generateConfirmed}
                handleCancel={generateCancelled}
            />

            <Modal
                open={generatorWorking}
                closeIcon={null}
                footer={[]}
            >
                <div className="mt-6 mb-6 flex flex-col items-center gap-8">
                    <div><Spin /></div>
                    <div className="text-gray-600 text-[1.0rem]">Generating games, please wait...</div>
                </div>
            </Modal>

            <Modal
                open={generatorFeedback}
                closeIcon={false}
                footer={[]}
                maskClosable={true}
                onCancel={() => setGeneratorFeedback(null)}
            >
                {generatorFeedback && <div className="mt-4 mb-4 flex flex-col items-center gap-3">
                    {generatorFeedback.type == 'success' && <span className="text-green-600 text-[2.5rem]">{generatorFeedback.icon ?? <CheckCircleFilled />}</span>}
                    {generatorFeedback.type == 'success' && <span className="text-green-600 text-[1.2rem]">{generatorFeedback.message ?? 'Success!'}</span>}
                    {generatorFeedback.type == 'error' && <span className="text-red-600 text-[2.5rem]">{generatorFeedback.icon ?? <CloseCircleFilled />}</span>}
                    {generatorFeedback.type == 'error' && <span className="text-red-600 text-[1.0rem]">{generatorFeedback.message ?? 'Error!'}</span>}
                </div>}
            </Modal>

            <Button
                size="small" 
                onClick={generate}
                disabled={(data?.errors?.length ?? 0) > 0}
            >Generate games</Button>
        </>
    )
}

export default SchedulerSimulationGenerateButton