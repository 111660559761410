import EmbedGamesInner from 'components/Embeds/EmbedGamesInner';
import useFetchData from 'hooks/useFetchData'
import useLang from 'hooks/useLang';
import usePageTitle from 'hooks/usePageTitle';
import React from 'react'
import { useSearchParams } from 'react-router-dom';
import EmbedWrapper from 'components/Embeds/EmbedWrapper';
import EmbedDisciplinaryInner from 'components/Embeds/EmbedDisciplinaryInner';

const EmbedDisciplinary = () => {
  const [searchParams] = useSearchParams();
  const disciplinaryId = searchParams.get('disciplinaryId');
  const lang = 'cs';

  const { data, isLoading, isError } = useFetchData(`/public/disciplinary` +
    `?disciplinaryId=${disciplinaryId}`
  );

  usePageTitle('Disciplinární řízení' + (data ? ` - ${data.subject}` : ''));

  return (
    data && <EmbedDisciplinaryInner data={data} lang={lang} />
  )
}

export default EmbedDisciplinary