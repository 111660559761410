const downloadFile = async (url, fileName) => {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      // Create a Blob from the file
      let blobUrl = window.URL.createObjectURL(blob);

      // Create an anchor element and set attributes
      let anchor = document.createElement('a');
      anchor.href = blobUrl;
      anchor.download = fileName;

      // Append anchor to body, trigger click, and remove it
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      // Clean up
      window.URL.revokeObjectURL(blobUrl);
    })
}

export default downloadFile;
