export function simulateCtrlClick (link) {
    const linkElement = document.createElement('a');
    linkElement.href = link;
    linkElement.target = '_blank';

    // Simulate the click on the <a> element
    const clickEvent = new MouseEvent('click', {
      metaKey: true, // Simulate the meta key being pressed (Ctrl / Command)
      ctrlKey: true, // Simulate the ctrl key being pressed
      bubbles: true,
      cancelable: true,
      view: window,
    });

    linkElement.dispatchEvent(clickEvent);
};