
export function sortDate(a, b) {
  return (a ?? '').localeCompare(b ?? '');
}

export function sortTime(a, b) {
  return (a ?? '').localeCompare(b ?? '');
}

export function sortDateAndTime(ad, at, bd, bt) {
  return ('' + ad + 'T' + at).localeCompare('' + bd + 'T' + bt);
}

export function sortString(a, b) {
  return (a ?? '').localeCompare(b ?? '');
}
