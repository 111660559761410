import React, { useEffect, useRef, useState } from 'react';
import { Layout, Menu, Dropdown, Row, Col, Spin, Space, Divider, Drawer, Tabs, Collapse, Tooltip, Tag, Table, Input, Slider, Modal, Button } from 'antd';
import { DownOutlined, UserOutlined, MenuOutlined } from '@ant-design/icons';
import { Link, json } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import icons from 'utils/icons';
import { useMediaQuery } from 'react-responsive'
import RouterScrollToTop from 'components/RouterScrollToTop';
import Notifications from 'components/Notifications';
import useLoginStore from 'store/loginStore';
import useFetch from 'hooks/useFetch';
import DrawerPanel from 'antd/es/drawer/DrawerPanel';
import moment from 'moment';
import dayjs from 'dayjs';
import SegmentedTabs from 'components/SegmentedTabs';
import SchedulerSimulationSummaryTable from 'components/SchedulerSimulation/SchedulerSimulationSummaryTable';
import FixedHeaderTable from 'components/SchedulerSimulation/FixedHeaderTable';
import { getWeekDay, sorted, unique } from 'utils/oneLiners';
import { getSchedulerUserFriendlyName } from 'utils/schedulerHelper';
const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;
const { Panel } = Collapse;

const SchedulerSimulationStatistics = ({ data }) => {
    const [teamStatistics, setTeamStatistics] = useState(null);
    const [groups, setGroups] = useState(null);
    const [allTimes, setAllTimes] = useState(null);

    const defaultSorter = (field) => (a, b) => {
        const valA = (typeof field === 'function') ? field(a) : a[field];
        const valB = (typeof field === 'function') ? field(b) : b[field];
        if (typeof valA === 'string' && typeof valB === 'string') {
            return valA.localeCompare(valB);
        }
        if (typeof valA === 'number' && typeof valB === 'number') {
            return valA - valB;
        }
        console.log('Cannot sort:', valA, '(' + typeof valA + ')', valB, '(' + typeof valB + ')');
    }

    function calcTeamStatistics(data) {
        const groupBy = (array, property) => array.reduce((acc, obj) => ({ ...acc, [obj[property]]: [...(acc[obj[property]] || []), obj] }), {});
        const getWeekStart = (date) => moment(date).startOf('week').isoWeekday(1).format('YYYY/MM/DD');
        const generateDaysBetween = (startDate, endDate) => Array.from({ length: moment(endDate).diff(startDate, 'days') + 1 }, (_, i) => moment(startDate).add(i, 'days').format('YYYY-MM-DD'));
        const unique = (arr) => [...new Set(arr)];
        const getMinPropertyValue = (array, property) => Math.min(...array.map(obj => obj[property]));
        const getMaxPropertyValue = (array, property) => Math.max(...array.map(obj => obj[property]));
        const propertyComparer = (property, direction = 'ASC') => (a, b) => (direction == 'DESC' ? -1 : 1) * (a[property] - b[property]);

        if (data.games.length == 0) debugger;

        const times = sorted(data.games.filter(g => g.time != null).map(g => g.time));
        const minTime = times[0];
        const maxTime = times[times.length - 1];

        const allDays = generateDaysBetween(minTime, maxTime);
        const allWeeks = unique(allDays.map(d => getWeekStart(d)));
        // console.log(allWeeks)

        const allTimes = sorted(unique(data.games.filter(g => g.time != null).map(g => g.time.split('T')[1].substr(0, 5))));
        setAllTimes(allTimes);

        const teamStatistics = data.teams.map(t => {
            const games = data.games
                .filter(g => (g.homeTeam ?? '').toUpperCase() == t.toUpperCase() || (g.awayTeam ?? '').toUpperCase() == t.toUpperCase())
                .map(g => ({ ...g, week: getWeekStart(g.time) }));
            const gamesPerWeek = allWeeks.map(w => ({
                week: w,
                games: games.filter(g => g.week == w),
                gamesCount: games.filter(g => g.week == w).length,
            }));

            return {
                name: t,
                group: (data.groups.find(g => g.teams.map(t => t.toUpperCase()).indexOf(t.toUpperCase()) != -1) ?? {}).name,
                games: games,
                gamesPerWeek: gamesPerWeek,
                gamesPerWeekStats: groupBy(gamesPerWeek, 'gamesCount'),
                maxGamesPerWeek: getMaxPropertyValue(gamesPerWeek, 'gamesCount'),
                gamesPerWeekDay: [0, 1, 2, 3, 4, 5, 6].map(wd => games.filter(g => getWeekDay(g.time) == wd)),
                gamesPerWeekDayCount: [0, 1, 2, 3, 4, 5, 6].map(wd => games.filter(g => getWeekDay(g.time) == wd).length),
                gamesPerTime: Object.fromEntries(allTimes.map(t => ([t, games.filter(g => (g.time?.indexOf('T' + t) ?? -1) != -1)]))),
                gamesPerTimeCount: Object.fromEntries(allTimes.map(t => ([t, games.filter(g => (g.time?.indexOf('T' + t) ?? -1) != -1).length]))),
            }
        })
            .sort((a, b) => {
                if (a.maxGamesPerWeek > b.maxGamesPerWeek) return -1;
                if (a.maxGamesPerWeek < b.maxGamesPerWeek) return 1;
                if (a.gamesPerWeekStats[a.maxGamesPerWeek] > b.gamesPerWeekStats[a.maxGamesPerWeek]) return -1;
                if (a.gamesPerWeekStats[a.maxGamesPerWeek].length < b.gamesPerWeekStats[a.maxGamesPerWeek].length) return 1;
                return 0;
            });
        // console.log(teamStatistics);
        return teamStatistics;
    }
    
    useEffect(() => {
        if (data) {
            console.log('data:', data);

            const teamStatistics = calcTeamStatistics(data);
            setTeamStatistics(teamStatistics);

            const groups = unique(teamStatistics.map(t => t.group))
            setGroups(groups);
        }
    }, [data]);

    const SuppressZero = ({ value }) => {
        return (<span className={value == 0 ? 'text-gray-300' : ''}>{value == 0 ? '0̷' : value}</span>);
    }

    if (!teamStatistics || !groups) {
        return <></>;
    }

    return (
        <SegmentedTabs
            items={
                sorted(['all__All', ...groups].map((g) => ({
                    label: getSchedulerUserFriendlyName(g),
                    key: g,
                    children:
                        <div>
                            <SchedulerSimulationSummaryTable
                                className="mb-6"
                                data={[
                                    { label: 'Games', value: data.games.filter(game => g == 'all__All' || (game.group ?? '').toUpperCase() == g.toUpperCase()).length },
                                    { label: 'Teams', value: teamStatistics.filter(t => g == 'all__All' || t.group == g).length },
                            ]} />
                            <FixedHeaderTable
                                size="small"
                                columns={[
                                    { title: "Name",
                                        width: '25%', 
                                        dataIndex: "name", 
                                        sortDirections: ['ascend', 'descend'], 
                                        sorter: defaultSorter('name'), },
                                    {
                                        title: "# of games",
                                        width: '10%',
                                        sortDirections: ['ascend', 'descend'],
                                        sorter: defaultSorter('name'),
                                        render: (r) => (r?.games ?? []).length,
                                        sorter: defaultSorter((r) => (r?.games ?? []).length),
                                    },
                                    {
                                        title: "Cat A",
                                        width: '5%',
                                        render: (r) => r?.games ? '' + Math.round(r.games.filter(g => g.category == 'A').length / (r.games.length || 1) * 100) + '%' : 'N/A',
                                        sortDirections: ['ascend', 'descend'],
                                        sorter: defaultSorter((r) => (r?.games ? (r.games.filter(g => g.category == 'A').length / (r.games.length || 1) * 100) : 0)),
                                    },
                                    {
                                        title: <Tooltip title={`Number of games the team plays as "Home Team"`}><div className="w-full">H</div></Tooltip>,
                                        width: '5%',
                                        sortDirections: ['ascend', 'descend'],
                                        render: (r) => r.games?.filter(g => g.homeTeam == r.fullName)?.length,
                                        sorter: defaultSorter((r) => (r.games?.filter(g => g.homeTeam == r.fullName)?.length)),
                                    },
                                    {
                                        title: <Tooltip title={`Number of games the team plays as "Away Team"`}><div className="w-full">A</div></Tooltip>,
                                        width: '5%',
                                        sortDirections: ['ascend', 'descend'],
                                        render: (r) => r.games?.filter(g => g.awayTeam == r.fullName)?.length,
                                        sorter: defaultSorter((r) => (r.games?.filter(g => g.awayTeam == r.fullName)?.length)),
                                    },
                                    ...([2, 1, 0].map(w => (
                                        {
                                            title: <Tooltip title={`Number of weeks the team is playing ${w} game${w != 1 ? 's' : ''}`}><div className="w-full">{`W${w}`}</div></Tooltip>,
                                            width: '7%',
                                            sortDirections: ['descend'],
                                            defaultSortOrder: (w == 2) ? 'descend' : null,
                                            render: (r) => (
                                                <span className={w > 1 && ((r?.gamesPerWeekStats ?? {})[w] ?? []).length != 0 ? 'text-orange-500' : ''}>
                                                    {(((r?.gamesPerWeekStats ?? {})[w] ?? []).length)}
                                                </span>
                                            ),
                                            sorter: defaultSorter((r) => (((r?.gamesPerWeekStats ?? {})[w] ?? []).length)),
                                        }))),
                                    ...([0, 1, 2, 3, 4, 5, 6].map(wd => (
                                        {
                                            title: <Tooltip
                                                title={`Number of games played on ${["Monday", "Tueday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"][wd]}`}>
                                                <div className="w-full">{["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"][wd]}</div>
                                            </Tooltip>,
                                            width: '7%',
                                            render: (r) => <SuppressZero value={r.gamesPerWeekDayCount[wd]} />,
                                            sorter: defaultSorter((r) => r.gamesPerWeekDayCount[wd]),
                                            sortDirections: ['descend'],
                                        }))),
                                    ...(allTimes.map((time) => (
                                        {
                                            title: <Tooltip
                                                title={`Number of games played at ${time}`}>
                                                <div className="w-full">{time}</div>
                                            </Tooltip>,
                                            width: '7%',
                                            render: (r) => <SuppressZero value={r.gamesPerTimeCount[time]} />,
                                            sorter: defaultSorter((r) => r.gamesPerTimeCount[time]),
                                            sortDirections: ['descend'],
                                        }))),
                                ]}
                                rowKey={(r) => r.name}
                                dataSource={teamStatistics
                                    .filter(t => g == 'all__All' || t.group == g)
                                    .map(ts => ({
                                        ...ts,
                                        name: getSchedulerUserFriendlyName(ts.name),
                                        fullName: ts.name,
                                    }))
                                }
                                pagination={false}
                                showSorterTooltip={false}
                            />
                        </div>
                })), (a, b) => a.label.localeCompare(b.label))
            }
        />
    )
}

export default SchedulerSimulationStatistics


