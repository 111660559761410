import EmbedGameInner from 'components/Embeds/EmbedGameInner';
import useFetchData from 'hooks/useFetchData'
import useLang from 'hooks/useLang';
import usePageTitle from 'hooks/usePageTitle';
import React from 'react'
import { useSearchParams } from 'react-router-dom';

const EmbedGame = () => {
  const [ searchParams ] = useSearchParams();
  const gameId = searchParams.get('gameId');
  const lang = searchParams.get('lang');
  const LANG = useLang(lang);
  usePageTitle(LANG.EMBED_GAME_TITLE)

  const { data } = useFetchData(`/public/game` +
    (gameId ? `?gameId=${gameId}` : '') +
    ``
  );

  const { data: imagesData } = useFetchData(`/public/gameMultimedia` +
    (gameId ? `?gameId=${gameId}` : '') +
    ``
  );

  if (!data) {
    return (<></>);
  }

  // console.log('data:', data);
  // console.log('phase:', data?.Phase);
  // console.log('group:', data?.Phase?.Group);
  // console.log('season:', data?.Phase?.Group?.Season);
  // console.log('competition:', data?.Phase?.Group?.Season?.Competition);
  // console.log('game events:', data.GameEvents);
  // console.log('lineup:', data.Lineups);
  // console.log('imagesData:', imagesData);
  
  return (
    <>
      <EmbedGameInner data={data} imagesData={imagesData} lang={lang} />
    </>
  )
}

export default EmbedGame