import React, { useEffect, useMemo, useState } from 'react';
import { Layout, Menu, Dropdown, Row, Col, Spin, Space, Divider, Drawer, Tabs, Collapse, Tooltip, Tag, Table, Input, Slider, Modal, Button } from 'antd';
import dayjs from 'dayjs';
import SchedulerStatistics from 'components/SchedulerSimulation/SchedulerSimulationStatistics';
import SchedulerTeams from 'components/SchedulerSimulation/SchedulerSimulationTeams';
import SchedulerVenues from 'components/SchedulerSimulation/SchedulerSimulationVenues';
import SchedulerGames from 'components/SchedulerSimulation/SchedulerSimulationGames';
import SchedulerGenerateButton from 'components/SchedulerSimulation/SchedulerSimulationGenerateButton';
import SchedulerGeneratedGames from 'components/SchedulerSimulation/SchedulerSimulationGeneratedGames';
import SchedulerSimulationErrorsWarnings from 'components/SchedulerSimulation/SchedulerSimulationErrorsWarnings';
import SchedulerLog from 'components/SchedulerSimulation/SchedulerSimulationLog';
import usePageTitle from 'hooks/usePageTitle';
import icons from 'utils/icons';
import useEntities from 'hooks/useEntities';
import useLoadEntity from 'hooks/useLoadEntity';
import { getAbsoluteUrl } from 'utils/urlFunctions';
import useFetchEntity from 'hooks/useFetchEntity';

export default function SchedulerSimulation() {
    usePageTitle('Scheduler Run')

    const entities = useEntities();
    const entity = entities.SchedulerSimulation;

    const { id, isLoading, isReloading, isError, isNotFound, data: loadedData, reload, relationsData: gamesData, reloadRelations } = useLoadEntity({
      entity,
      relations: [
        'Games,Games>HomeTeam(teamId,logoUrl),Games>AwayTeam(teamId,logoUrl),Games>Venue(name)',
      ].join(','),
    });

    const { data: relationsData } = useFetchEntity('SchedulerConfig', loadedData?.schedulerConfigId, [
          'SchedulerConfigSpecialRequests',
        ].join(','),
    );

    const data = useMemo(() => {
        if (loadedData) {
            const data = {
                ...loadedData,
                ...(JSON.parse(loadedData.data)),
            };
            delete data.data;

            // Console details for debugging >>

            console.log('data', data);

            const gamesSorted = data.games.sort((a, b) => a.idx - b.idx);
            console.log('gamesSorted', gamesSorted);

            const gamesSortedSummary = gamesSorted.map(g => ({ 
                idx: g.idx, 
                time: g.time,
                home: [ g.homeTeam.split('__')[1], g.HomeTeamPreferredTimesCount, g.prefersHt ? '✅ YES' : '❌ NO' ].join(' - '),
                away: [ g.awayTeam.split('__')[1], g.AwayTeamPreferredTimesCount, g.prefersAt ? '✅ YES' : '❌ NO' ].join(' - '),
            }))
            console.log('gamesSortedSummary', gamesSortedSummary);

            // << Console details for debugging

            return data;
        }
        return null;
    }, [loadedData]);


    const onGenerateFinished = () => {
        reloadRelations();
    }

    if (isLoading) {
        return <div>Loading...</div>;
    };

    return (
        <>
            {data?.errors?.length > 0 &&
                <div className="m-2 border-[1px] border-red-500 p-3 bg-red-100 flex items-center justify-center gap-1">
                    <span className="font-bold text-red-600">WARNING!</span>
                    <span className="text-red-600">This simulation contains errors and should not be used to generate games!</span>
                </div>
            }
            {data?.errors?.length == 0 && data?.warnings?.length > 0 &&
                <div className="m-2 border-[1px] border-orange-500 p-3 bg-orange-100 flex items-center justify-center gap-1">
                    <span className="font-bold text-orange-600">WARNING!</span>
                    <span className="text-orange-600">This simulation contains warnings - make sure to check them before you generate games!</span>
                </div>
            }
            <Tabs
                className="mx-2"
                items={
                    [
                        {
                            label: 'Team Categories',
                            key: 'Team Categories',
                            children: <>
                                <SchedulerTeams data={data} type="ByCategory" />
                            </>
                        },
                        {
                            label: 'Team Special Requests',
                            key: 'Team Special Requests',
                            children: <>
                                <SchedulerTeams data={data} specialRequestsData={relationsData?.SchedulerConfigSpecialRequests} type="BySpecialRequests" />
                            </>
                        },
                        {
                            label: 'Venue Usage',
                            key: 'Venue Usage',
                            children: <>
                                <SchedulerVenues data={data} />
                            </>
                        },
                        {
                            label: 'Games',
                            key: 'Games',
                            children: <>
                                <SchedulerGames data={data} />
                            </>
                        },
                        {
                            label: 'Statistics',
                            key: 'Statistics',
                            children: <>
                                <SchedulerStatistics data={data} />
                            </>
                        },
                        {
                            label: (data?.warnings?.length > 0) ? (<span className="text-orange-500">Warnings ({data?.warnings?.length})</span>) : <span>Warnings ({data?.warnings?.length})</span>,
                            key: 'Warnings',
                            children: <SchedulerSimulationErrorsWarnings data={data?.warnings} />,
                            hidden: (data?.warnings?.length ?? 0) == 0,
                        },
                        {
                            label: (data?.errors?.length > 0) ? (<span className="text-red-500">Errors ({data?.errors?.length})</span>) : <span>Errors ({data?.errors?.length})</span>,
                            key: 'Errors',
                            children: <SchedulerSimulationErrorsWarnings data={data?.errors} />,
                            hidden: (data?.errors?.length ?? 0) == 0,
                        },
                        {
                            label: 'Log',
                            key: 'Log',
                            children: <>
                                <SchedulerLog data={data} />
                            </>,
                        },
                        {
                            label: 'Generated Games',
                            key: 'GeneratedGames',
                            children: <>
                                <SchedulerGeneratedGames data={gamesData?.Games} />
                            </>,
                            hidden: (gamesData?.Games?.length ?? 0) == 0,
                        },
                    ].filter(t => !t.hidden)}
                tabBarExtraContent={
                    <span className="flex items-center gap-4">
                        <SchedulerGenerateButton data={data} generatedGames={gamesData?.Games} onFinished={onGenerateFinished} />
                        <a 
                            className="flex items-center gap-1" 
                            href={getAbsoluteUrl(`/schedulerconfigs/${data.schedulerConfigId}`)}
                            target="_blank"
                        >
                            {icons.ExternalLink}
                            <span>Open Configuration</span>
                        </a>
                        <span
                            className="text-gray-500"
                        >
                            Created at: {dayjs(data.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                    </span>
                }
            />
        </>
    )
}
