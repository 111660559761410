import { Button, Input, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Select, Space } from 'antd';
import useLists from 'hooks/useLists';
import { formatTimeString, parseTimeString } from 'utils/timeString';

const GameEventPlayerSelect = ({ label, name, options, data, toggleValueChange, noOptionsText = "No options" }) => (
    <div className={`grid gap-x-4 grid-cols-10 items-start`}>
        <div className="col-span-2 h-8 flex items-center">
            {label}:
        </div>
        <div className="col-span-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            {options.length == 0 && <span className="text-gray-400">{noOptionsText}</span>}
            {options.length > 0 && options.map((b, idx) => (
                <Button
                    key={idx}
                    type={data.event[name] == b.value ? 'primary' : 'default'}
                    disabled={b.disabled}
                    className="flex items-center gap-2"
                    onClick={() => toggleValueChange(name, b.value)}
                >
                    <span className="text-xl">{b.number}</span>
                    <span>{b.name}</span>
                </Button>
            ))}
        </div>
    </div>
)

export default GameEventPlayerSelect;