import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import { EditorView } from 'prosemirror-view'
import { Button, Input, Modal } from 'antd'
import InputPrompt from 'components/Modals/InputPrompt'
import { useEffect, useRef, useState } from 'react'
import './TipTapEditor.css';

// https://github.com/ueberdosis/tiptap/issues/1451#issuecomment-953348865
EditorView.prototype.updateState = function updateState(state) {
  if (!this.docView) return // This prevents the matchesNode error on hot reloads
  this.updateStateInner(state, this.state.plugins != state.plugins)
}

const ToolbarButton = ({ onClick, isActive, children }) => {
  return (
    <button
      className={[
        isActive ? 'bg-[#eff8ff] border-[#3f96ff] border' : 'bg-white hover:bg-gray-200 text-gray-700',
        'w-10 h-8 rounded-sm cursor-pointer'
      ].join(' ')}
      onClick={() => onClick()}
    >
      {children}
    </button>
  )
}

const Toolbar = ({ editor }) => {
  const [urlEditOpen, setUrlEditOpen] = useState(false);
  const [urlEditValue, setUrlEditValue] = useState('');
  const [urlEditCanRemove, setUrlEditCanRemove] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (urlEditOpen && inputRef.current) {
      setTimeout(() => inputRef.current.focus(), 0);
    }
  }, [urlEditOpen]);

  if (!editor) {
    return;
  }

  function getCurrentHeadingLevel() {
    const { state } = editor;
    const { selection } = state;
    const { from, to } = selection;
    let headingLevel = null;
    state.doc.nodesBetween(from, to, node => {
      if (node.type.name === 'heading') {
        headingLevel = node.attrs.level;
        return false;
      }
    });
    return headingLevel;
  }

  return (
    <div className="mb-1 flex gap-[2px]">
      <Modal
        open={urlEditOpen}
        title={`${urlEditCanRemove ? 'Update' : 'Insert'} hyperlink`}
        onCancel={() => setUrlEditOpen(false)}
        closeIcon={false}
        footer={
          <div className="flex justify-end">
            {urlEditCanRemove &&
              <Button 
                size="small" 
                className="custom-danger-2"
                danger={true}
                onClick={() => {
                  editor.chain().focus().unsetLink().run();
                  setUrlEditOpen(false);
                }}
              >
                Remove
              </Button>
            }
            <Button 
              size="small" 
              onClick={() => setUrlEditOpen(false)}
            >
              Cancel
            </Button>
            <Button 
              size="small" 
              disabled={urlEditValue == ''}
              type="primary" onClick={() => {
                const href = urlEditValue.startsWith('http://') || urlEditValue.startsWith('https://') ? urlEditValue : `http://${urlEditValue}`;
                editor.chain().focus().setLink({ href }).run();
                setUrlEditOpen(false);
              }}
            >
              {`${urlEditCanRemove ? 'Update' : 'Insert'}`}
            </Button>
          </div>
        }
      >
        <div className="py-4">
          <Input 
            ref={inputRef}
            value={urlEditValue} 
            onChange={(e) => setUrlEditValue(e.target.value)}
            placeholder="https://"
          />
        </div>
      </Modal>

      <ToolbarButton
        isActive={editor.isFocused && editor.isActive('heading') && getCurrentHeadingLevel() == 1}
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
      >
        H1
      </ToolbarButton>

      <ToolbarButton
        isActive={editor.isFocused && editor.isActive('heading') && getCurrentHeadingLevel() == 2}
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
      >
        H2
      </ToolbarButton>

      <ToolbarButton
        isActive={editor.isFocused && editor.isActive('bold')}
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        <span className="font-bold">B</span>
      </ToolbarButton>

      <ToolbarButton
        isActive={editor.isFocused && editor.isActive('italic')}
        onClick={() => editor.chain().focus().toggleItalic().run()}
      >
        <span className="italic">I</span>
      </ToolbarButton>

      <ToolbarButton
        isActive={editor.isFocused && editor.isActive('underline')}
        onClick={() => editor.chain().focus().toggleUnderline().run()}
      >
        <span className="underline">U</span>
      </ToolbarButton>

      <ToolbarButton
        isActive={editor.isFocused && editor.isActive('link')}
        onClick={() => {
          setUrlEditValue(editor.getAttributes('link').href ?? '');
          setUrlEditCanRemove(editor.getAttributes('link').href != undefined);
          setUrlEditOpen(true);
        }}
      >
        url
      </ToolbarButton>

    </div>
  )
}

const TipTapEditor = ({ value, onChange }) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure(),
      Underline.configure(),
      Link.configure({
        protocols: ['ftp', 'mailto'],
        autolink: true,
        openOnClick: false,
        linkOnPaste: true,
        HTMLAttributes: {
          class: 'text-blue-500 underline cursor-text',
          target: '_blank',
        },
      }),
    ],
    editorProps: {
      attributes: {
        class: 'tiptap rounded-md border focus:border-[#3f96ff] focus:bg-[#eff8ff] transition duration-300 min-h-[150px] bg-back p-2 outline-none',
      },
    },
    content: value ?? '',
    onUpdate({ editor }) {
      onChange?.(editor.getHTML())
    }
  })


  return (
    <div className="">
      <Toolbar editor={editor} />
      <EditorContent editor={editor} />
    </div>
  )
}

export default TipTapEditor