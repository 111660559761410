import useEntities from 'hooks/useEntities';
import HmsEntityTableController from 'components/HmsEntityTableController';

const SeasonRegistrations = () => {
  const entities = useEntities();
  const filterGroups = null;
  const showColumns = [
    'CreatedAt',
    'Team.NameWithLogo',
    'Competition.NameWithLogo',
    'Season.Name',
    'PreferredGroups',
    'Status',
    'RegisteredStatus',
    'Specials',
    'ExternalComment',
    'InternalComment',
  ];

  return (
    <HmsEntityTableController
      entity={entities.SeasonRegistration}
      filterGroups={filterGroups}
      columnSets={[
        {
          name: 'All',
          columns: showColumns,
        },
        {
          name: 'New',
          filters: { status: ['NEW'] },
          columns: showColumns,
        },
        {
          name: 'Processed',
          filters: { status: ['PROCESSED'] },
          columns: showColumns,
        },
        {
          name: 'Rejected',
          filters: { status: ['REJECTED'] },
          columns: showColumns,
        },
      ]}
      canCreate={false}
    />
  )
}

export default SeasonRegistrations