import React, { useRef } from 'react'
import { Checkbox, Form, Input, Row, Col, DatePicker, Select } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import NullableSelect from 'components/Form/NullableSelect';
import useLists from 'hooks/useLists';
import useEntities from 'hooks/useEntities';
import FormSelect from 'components/Form/FormSelect';
const { Option } = Select;

const ListSelect = ({ entity: entityName, ...props }) => {
  const entities = useEntities();
  const entity = entities[entityName];
  const lists = useLists();
  const list = lists[entityName];
  const options = list.map((l) => ({
    value: l[entity.primaryKey],
    label: <span className="flex gap-2 items-center">{l.name}</span>
  }));

  return (
    <FormSelect
      label={entity.displayName}
      options={options}
      {...props}
    />
  )
}

export default ListSelect