import useEntities from 'hooks/useEntities';
import RelationsTable from 'components/RelationsTable';
import { Button, Checkbox, DatePicker, Form, Input, Modal, Select, Table, TimePicker, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import moment from 'moment';
import SchedulerConfigIcePatternModal from 'components/SchedulerConfig/SchedulerConfigIcePatternModal';
import SchedulerConfigIcePatternsCalendar from 'components/SchedulerConfig/SchedulerConfigIcePatternsCalendar';

const SchedulerConfigSimulations = ({ sourceEntityId, data, onChange, phases }) => {
    const entities = useEntities();
    const sourceEntity = entities.SchedulerConfig;
    const targetEntity = entities.SchedulerSimulation;

    return (
        <>
            <RelationsTable
                data={data}
                sourceEntity={sourceEntity}
                targetEntity={targetEntity}
                sourceEntityId={sourceEntityId}
                emptyMessage={`This ${sourceEntity.displayName} does not have any ${targetEntity.displayNamePlural}`}
                canOpen={true}
            />
        </>
    )

}

export default SchedulerConfigSimulations
