import React from 'react'
import PoweredByWootera from 'components/Embeds/PoweredByWootera';

const EmbedStatsWrapper = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <div>
        {children}
      </div>
      <div className="mt-auto">
        <PoweredByWootera className="mt-10" />
      </div>
    </div>
  )
}

export default EmbedStatsWrapper