import useEntities from 'hooks/useEntities';
import RelationsEditable from 'components/RelationsEditable';

const SchedulerConfigPhases = ({ sourceEntityId, data, onChange, isLocked }) => {
    const entities = useEntities();
    const sourceEntity = entities.SchedulerConfig;
    const targetEntity = entities.Phase;

    return (
        <RelationsEditable 
            data={data}
            isLocked={isLocked}
            sourceEntity={sourceEntity} 
            targetEntity={targetEntity}
            sourceEntityId={sourceEntityId}
            emptyMessage={`This ${sourceEntity.displayName} does not have any ${targetEntity.displayNamePlural}`}
            onChange={onChange}
            canOpen={true}
            columns={[
                'Group.Season.Competition.Name',
                'Group.Season.Name',
                'Group.Name',
                'name',
                'TeamCount',
                'tournamentType',
                'startDate',
                'endDate',
                'status',
            ]}
        />
    )    

}

export default SchedulerConfigPhases
