import { Tag } from 'antd';
import React from 'react'

const PaymentTag = ({ value }) => {
    switch (value) {
        case 'PAID':
            return <Tag color="success">PAID</Tag>;
        case 'NOT_PAID':
            return <Tag color="error">NOT PAID</Tag>;
        default:
            return <Tag>{JSON.stringify(value)}</Tag>;
    }
}

export default PaymentTag