import React from 'react'
import { CloseCircleFilled } from '@ant-design/icons';
import { ClockLoader } from 'react-spinners';
import useLang from 'hooks/useLang';

const EmbedLoadingIndicator = ({ isLoading, isError, lang }) => {
  const LANG = useLang(lang);

  return (
    <div className="w-full h-[100svh] flex justify-center items-center font-roboto-flex">
      {isLoading && !isError && <ClockLoader color="#aaa" size={40} />}
      {isError &&
        <div className="flex flex-col items-center gap-5 text-[#888]">
          <CloseCircleFilled className="text-3xl" />
          <span className="flex items-center gap-3 mt-2">
            <span className="text-2xl">😢</span>
            <span>{LANG.ERROR_LOADING_DATA}</span>
          </span>
          <span className="font-roboto-condensed font-light text-sm">{LANG.PLEASE_REFRESH_PAGE}</span>
        </div>
      }
    </div>
  );
}

export default EmbedLoadingIndicator