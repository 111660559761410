import useEntities from 'hooks/useEntities';
import HmsEntityTableController from 'components/HmsEntityTableController';

const Disciplinaries = () => {
  const entities = useEntities();
  const filterGroups = null;
  const showColumns = [
    'Date', 
    'Competition', 
    'Game', 
    { column: 'subject', width: 250 },
    'Players', 
    'Status', 
  ];

  return (
    <HmsEntityTableController
      entity={entities.Disciplinary}
      filterGroups={filterGroups}
      showColumns={showColumns}
    />
  )
}

export default Disciplinaries