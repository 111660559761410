export function parseTimeString(timeString) {
  let cleanedTimeString = (timeString ?? '').replace(/[^0-9:]/g, ''); // Remove non-numeric and non-colon characters

  // If the cleaned string is empty, return null
  if (cleanedTimeString === "") {
    return null;
  }

  if (cleanedTimeString.includes(':')) {
    // If the string already contains a colon, treat it as "minutes:seconds"
    const parts = cleanedTimeString.split(':');
    if (parts.length !== 2) {
      return null;
    }

    const minutes = parseInt(parts[0]);
    const seconds = parseInt(parts[1]);

    if (isNaN(minutes) || isNaN(seconds) || minutes < 0 || seconds < 0 || seconds > 59) {
      return null;
    }

    return (minutes * 60) + seconds;
  } else {
    // If no colon is present, treat the input as a combined minutes and seconds value
    if (cleanedTimeString.length == 0 || cleanedTimeString.length > 4) {
      return null;
    }
    if (Number.isNaN(parseInt(cleanedTimeString))) {
      return null;
    }

    switch (cleanedTimeString.length) {
      case 1:
      case 2:
          return parseInt(cleanedTimeString);
      case 3:
          return parseInt(cleanedTimeString.substr(0, 1)) * 60 + parseInt(cleanedTimeString.substr(1, 2));
      case 4:
          return parseInt(cleanedTimeString.substr(0, 2)) * 60 + parseInt(cleanedTimeString.substr(2, 2));
      }
  }
}

export function formatTimeString(totalSeconds) {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
}
