import React from 'react'
import { Checkbox, Form, Input, Row, Col, DatePicker, Select } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';

const CheckboxGroup = Checkbox.Group;

const CheckboxGroupItem = ({ options, checked, onChange, dataType = 'CSV', ...props }) => {
    return (
        <CheckboxGroup
            {...props}
            value={dataType == 'CSV' ? (checked ?? '').split(',') : (checked ?? [])}
            onChange={(checked) => { onChange(dataType == 'CSV' ? checked.join(',') : checked) }}
            className="w-full"
        >
            <div className="flex flex-col border-[1px] border-[#d9d9d9] rounded-md p-2 px-3 w-full">
                {options.map((option) => (
                    <Checkbox key={option.value} value={option.value} disabled={option.disabled} className="select-none">
                        {option.label}
                    </Checkbox>
                ))}
            </div>
        </CheckboxGroup>
    )
}

const FormCheckboxGroup = ({ label, name, required, autoFocus, placeholder, formItemProps, fieldItemProps, options = [] }) => {
    return (
        <HmsFormItem
            label={label}
            name={name}
            required={required}
            valuePropName='checked'
            {...formItemProps}
        >
            <CheckboxGroupItem
                options={options}
                {...fieldItemProps}
            />
        </HmsFormItem>
    )
}

export default FormCheckboxGroup