import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Select, Space, Spin } from 'antd';
import { useRef, useState } from 'react';

function SelectWithAdd({ className, onChange, defaultValue, options, disabled, optionLabelProp, optionFilterProp, newItemPlaceholder = 'New entry' }) {
    const [name, setName] = useState('');
    const [added, setAdded] = useState(false);
    const nameInputRef = useRef(null);
    const selectRef = useRef(null);

    const onNameChange = (event) => {
        setName(event.target.value);
    };

    const addItemInner = (e) => {
        e.preventDefault();
        onChange(name); // + == onchange, the same as selected
        setAdded(true);
    };

    return (
        <Select
            className={`w-full ${className ?? ''}`}
            ref={selectRef}
            onClick={(e) => e.stopPropagation()}
            onChange={onChange}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider className="mt-2 mb-4"  />
                    <div className="flex gap-2 m-2">
                        <Input
                            placeholder={newItemPlaceholder}
                            ref={nameInputRef}
                            disabled={added}
                            value={name}
                            onChange={onNameChange}
                            onKeyDown={(e) => e.stopPropagation()}
                        />
                        <Button 
                            type="text" 
                            disabled={name == null || name.length == 0 || added} 
                            icon={added
                                ? <div className="flex items-center justify-center"><Spin size="small" /></div>
                                : <PlusOutlined />
                            }
                            onClick={addItemInner}
                        />
                    </div>
                </>
            )}
            showSearch
            optionLabelProp={optionLabelProp}
            optionFilterProp={optionFilterProp}
            disabled={disabled}
            options={options}
            defaultValue={defaultValue}
            popupMatchSelectWidth={230}
        ></Select >
    )
}

export default SelectWithAdd