import EmbedPlayersStatsInner from 'components/Embeds/EmbedPlayersStatsInner';
import useFetchData from 'hooks/useFetchData'
import usePageTitle from 'hooks/usePageTitle';
import React from 'react'
import { useSearchParams } from 'react-router-dom';
import useLang from 'hooks/useLang';
import EmbedWrapper from 'components/Embeds/EmbedWrapper';

const EmbedPlayersStats = () => {
  const [searchParams] = useSearchParams();
  const competitionIds = searchParams.get('competitionIds');
  const competitionId = searchParams.get('competitionId');
  const seasonId = searchParams.get('seasonId');
  const groupId = searchParams.get('groupId');
  const phaseId = searchParams.get('phaseId');
  const lang = searchParams.get('lang');
  const LANG = useLang(lang);
  usePageTitle(LANG.PLAYERSSTATS + ' - HMS');

  const { data: competitionsData, isLoading, isError } = useFetchData(`/public/competitionInfo` +
    `?competitionIds=${competitionIds ?? competitionId}` +   // backward compatibility with old embed links
    ``);

  return (
    <EmbedWrapper isLoading={isLoading} isError={isError} lang={lang}>
      <EmbedPlayersStatsInner
        competitionsData={competitionsData}
        competitionId={competitionId}
        seasonId={seasonId}
        groupId={groupId}
        phaseId={phaseId}
        lang={lang}
      />
    </EmbedWrapper >
  )
}

export default EmbedPlayersStats