const validateFlickrAlbumUrl = (value) => {
  if (!value) return true;
  const patterns = [
      /^$/, // This pattern matches an empty string
      /^\d+$/,
      /^https:\/\/www\.flickr\.com\/photos\/[a-zA-Z0-9@]+\/albums\/\d+$/,
      /^https:\/\/www\.flickr\.com\/gp\/[a-zA-Z0-9@]+\/[a-zA-Z0-9]+$/,
      /^https:\/\/flic\.kr\/s\/[a-zA-Z0-9]+$/
  ];
  if (patterns.some(pattern => pattern.test(value))) {
      return true;
  }
  return false;
};

export default validateFlickrAlbumUrl;