import { Button, Input, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Select, Space } from 'antd';
import useLists from 'hooks/useLists';
import { formatTimeString, parseTimeString } from 'utils/timeString';
import GameEventGridSelect from 'components/Modals/GameEvent/GameEventGridSelect';

const GameEventEventLocationSelect = ({ data, toggleValueChange }) => {
    const { ListEventLocation } = useLists();

    return (
        <GameEventGridSelect
            name="listEventLocationId"
            width={273}
            height={288}
            data={data}
            toggleValueChange={toggleValueChange}
            image="/ice.png"
            options={
                [
                    'Left corner',
                    'Behind net',
                    'Right corner',
                    'Left circle',
                    'Between the circles',
                    'Right circle',
                    'Left blue line',
                    'Center blue line',
                    'Right blue line',
                    'Left central zone',
                    'Center central zone',
                    'Right central zone',
                    'Left defensive zone',
                    'Center defensive zone',
                    'Right defensive zone',
                ].map(o => {
                    const id = ListEventLocation.find(l => l.name.toLowerCase() == o.toLowerCase())?.listEventLocationId;
                    return {
                        value: id,
                        label: o,
                    }
                })

            } />
    )
}

export default GameEventEventLocationSelect;