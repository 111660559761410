import useEntities from 'hooks/useEntities';
import RelationsEditable from 'components/RelationsEditable';
import { Button } from 'antd';
import icons from 'utils/icons';
import { useState } from 'react';
import LinkRelationModal from 'components/Modals/LinkRelationModal';
import Confirm from 'components/Confirm';
// import useLoginStore from 'store/loginStore';
import RelationsTable from 'components/RelationsTable';

const PlayerTeams = ({ sourceEntityId, sourceEntityData, data, onChange }) => {
    const entities = useEntities();
    // const hasRole = useLoginStore(s => s.hasRole);
    // const isAdmin = hasRole('ADMIN');

    return (
        <RelationsEditable
            data={data}
            sourceEntity={entities.Player}
            targetEntity={entities.Team}
            emptyMessage="This player does not belong to any teams"
            sourceEntityId={sourceEntityId}
            onChange={onChange}
            addButtonText="Add Team"
            // canRemove={isAdmin || (sourceEntityData?.status == 'DRAFT' && data?.length > 1)}
            cannotRemoveTooltip={
                sourceEntityData?.status != 'DRAFT'
                    ?
                        "Please use the 'Request Change' button to request removal of this Player from this Team."
                    :
                        data?.length <= 1
                            ?
                                "A Player belong to at least one Team."
                            :
                                null
            }
            // canAdd={isAdmin || sourceEntityData?.status == 'DRAFT'}
        />
    )
}

export default PlayerTeams
