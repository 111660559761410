import React from "react";
import IGImg from "./IGImg";

/**
 * Shows the index, name and icon of an entity.
 * @param {number} index - Index: expanded by one, shown in first row.
 * @param {string} icon - Icon: zoomed, as a rectangle, in second row.
 * @param {string} name - Name: italic, with default width (192px) and white border bottom; in third row.
 * @param {string} nameClassName - Classname for name part: Being used after the default styles.
 * @returns {object} Three \<td\> tags wrapped in <> to make it fit well in any table.
 */
const BaseTableRows = ({ index, name, icon, nameClassName }) => (
  <>
    {/* we count from 1 */}
    <td className="pr-1 text-sm italic">{index + 1}</td>
    <td className="h-6 w-11 overflow-hidden border-0">
      <IGImg src={icon} alt="" className="h-6 w-full scale-[1.35] object-cover" />
    </td>
    <td
      className={`w-48 border-b border-white pl-2 text-left text-lg font-normal italic ${nameClassName}`}
    >
      {name}
    </td>
  </>
);

export default BaseTableRows;
