import useEntities from 'hooks/useEntities';
import RelationsEditable from 'components/RelationsEditable';
import { entityColumnsToAntColumns } from 'utils/entityColumnsToAntColumns';

const AwardGames = ({ sourceEntityId, data, onChange }) => {
    const entities = useEntities();
    const sourceEntity = entities.Award;
    const targetEntity = entities.Game;

    return (
        <RelationsEditable
            data={data}
            sourceEntity={sourceEntity}
            targetEntity={targetEntity}
            sourceEntityId={sourceEntityId}
            columns={[
                { column: 'StartDateTime-nowrap', defaultSortOrder: 'descend' },
                'Venue', 
                'TeamsVs-nick',
                'status', 
                'timekeeper', 
                'referee', 
                'foreignId', 
                'name',
            ]}
            emptyMessage={`This ${sourceEntity.displayName} does not have any ${targetEntity.displayNamePlural}`}
            onChange={onChange}
            targetEntityModalWidth={800}
        />
    )
}

export default AwardGames
