import useEntities from 'hooks/useEntities';
import RelationsEditable from 'components/RelationsEditable';

const CompetitionVenues = ({ sourceEntityId, data, onChange }) => {
    const entities = useEntities();
    const sourceEntity = entities.Competition;
    const targetEntity = entities.Venue;

    return (
        <RelationsEditable 
            data={data}
            sourceEntity={sourceEntity} 
            targetEntity={targetEntity}
            sourceEntityId={sourceEntityId}
            emptyMessage={`This ${sourceEntity.displayName} does not have any ${targetEntity.displayNamePlural}`}
            onChange={onChange}
        />
    )
}

export default CompetitionVenues
