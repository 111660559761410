import useEntities from 'hooks/useEntities';
import HmsEntityTableController from 'components/HmsEntityTableController';

const Organizations = () => {
  const entities = useEntities();
  const filterGroups = null;
  const showColumns = ['NameWithLogo', 'nick', 'short', 'status'];

  return (
    <HmsEntityTableController
      entity={entities.Organization}
      filterGroups={filterGroups}
      showColumns={showColumns}
    />
  )
}

export default Organizations