import React from 'react'
import { Checkbox, Form, Input, Row, Col, DatePicker, Select, ColorPicker, Tooltip } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import getColorName from 'utils/getColorName';

const ColorPickerInner = ({ value, onChange, ...props }) => {
    return (
        <span className="flex items-center gap-2">
            <ColorPicker
                {...props}
                value={value}
                onChange={onChange}
            />
            <span className="text-[#555]">
                <Tooltip title={value?.toUpperCase()}>
                    {getColorName(value)}
                </Tooltip>
            </span>
        </span>
    );
}

const FormColorPicker = ({ label, name, required, autoFocus, formItemProps, fieldItemProps }) => {
    return (
        <HmsFormItem
            label={label}
            name={name}
            required={required}
            getValueFromEvent={(e) => { return e.toHexString(); }}
            getValueProps={(value) => { return { value: value ?? null }; }}     // need to use 'null' instead of 'undefined'
            {...formItemProps}
        >
            <ColorPickerInner
                autoFocus={autoFocus}
                allowClear={true}
                {...fieldItemProps}
            />
        </HmsFormItem>
    )
}

export default FormColorPicker