import React from 'react'
import { Tabs } from 'antd';
import useSearchParamsMulti from 'hooks/useSearchParamsMulti';

const TabsWithUrlMemory = ({ param = 'selectedTab', ...props }) => {
  const { useSearchParamSingle } = useSearchParamsMulti();
  const [selectedTab, setSelectedTab] = useSearchParamSingle(param, props.items?.[0]?.key);

  return (
    <Tabs
      {...props}
      activeKey={selectedTab}
      onChange={setSelectedTab}
    />
  )
}

export default TabsWithUrlMemory