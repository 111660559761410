import React, { useEffect } from 'react'
import { Spin, Table, Card, Button, Layout, Dropdown, Space, Modal } from 'antd';
import { HotKeys } from 'react-hotkeys';
import { LoadingOutlined } from '@ant-design/icons';

const Confirm = ({ visible, title = 'Warning', handleConfirm, handleCancel, confirmButtonText = 'Confirm', confirmButtonType = "primary", cancelButtonText = 'Cancel', cancelButtonProps = {}, confirmButtonProps = {}, message }) => {
    const [confirmButtonActive, setConfirmButtonActive] = React.useState(false);

    useEffect(() => {
        setConfirmButtonActive(false);
    }, [visible]);

    const keyMap = {
        PRESS_ENTER: 'enter',
    };

    const handlers = {
        PRESS_ENTER: () => {
            console.log('ENTER:', confirmButtonText)
            setConfirmButtonActive(true);
            handleConfirm();
        },
    };

    return (
        <HotKeys keyMap={keyMap} handlers={handlers}>
            <Modal
                closeIcon={null}
                open={visible}
                title={title}
                onCancel={handleCancel}
                footer={[
                    <Button
                        {...confirmButtonProps}
                        key="confirm"
                        onClick={() => { if (confirmButtonActive) return; setConfirmButtonActive(true); handleConfirm(); }}
                        type={confirmButtonType != 'danger' ? confirmButtonType : undefined}
                        disabled={confirmButtonActive}
                        className={
                            // (confirmButtonActive ? (confirmButtonType == 'danger' ? '!bg-[#f58585]' : '!bg-[#67a6ff]') + ' ' : '') +
                            (confirmButtonType == 'danger' ? 'custom-danger-2' : '') +
                            ''
                        }
                    >
                        {confirmButtonActive && <LoadingOutlined spin />}
                        {confirmButtonText}
                    </Button>,
                    (
                        cancelButtonText &&
                            <Button
                                {...cancelButtonProps}
                                key="cancel"
                                onClick={handleCancel}
                            >
                                {cancelButtonText}
                            </Button>
                    ),
                    ].filter(b => b)}
            >
                {message}
            </Modal>
        </HotKeys>
    )
}

export default Confirm