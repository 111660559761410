import React from 'react'
import { Checkbox, Form, InputNumber, Row, Col, DatePicker, Select } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';

const FormInputNumber = ({ label, name, required, autoFocus, formItemProps, fieldItemProps, addonAfter }) => {
    return (
        <HmsFormItem
            label={label}
            name={name}
            required={required}
            {...formItemProps}
        >
            <InputNumber
                autoFocus={autoFocus}
                className="w-full"
                addonAfter={addonAfter}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0')}
                parser={(value) => `${value}`.replace(/[\u00A0 ]/g, '')}
                {...fieldItemProps}
            />            
        </HmsFormItem>
    )
}

export default FormInputNumber