import React from 'react'
import { Checkbox, Form, Input, Row, Col, DatePicker, Select } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
const { RangePicker } = DatePicker;

const StringDatePicker = ({ value, onChange, ...props }) => {
    const dateStringToDayjs = (dateStr) => {
        return dateStr ? dayjs(dateStr) : null;
    }

    const dayjsToDateStr = (date) => {
        return date ? date.format('YYYY-MM-DD') : '';
    }

    return (
        <DatePicker 
            {...props}
            value={dateStringToDayjs(value)}
            onChange={onChange ? (value) => onChange(dayjsToDateStr(value)) : null}
        />
    );
}

const FormRangePicker = ({ label, name, required, autoFocus, formItemProps, fieldItemProps }) => {
    return (
        <HmsFormItem
            label={label}
            name={name}
            required={required}
            {...formItemProps}
        >
            <RangePicker
                autoFocus={autoFocus}
                className="w-full"
                allowClear={true}
                showTime
                {...fieldItemProps}
            />
        </HmsFormItem>
    )
}

export default FormRangePicker
