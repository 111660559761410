import { compareString } from "utils/comparators";
import { formatTimeString, parseTimeString } from "utils/timeString";

export default function sortGameEvents(initialData, homeTeamId, awayTeamId, periodsParam) {

  // PREPARE PERIODS
  //
  // Notes (TODO):
  //    Periods should be linked with game events via periodId...
  //    but until we have that, we need to sort the game events by period name

  const periods = [...periodsParam];
  periods.sort((a, b) => a.index - b.index);

  // SORT EVENTS

  const dataSorted = [...initialData];

  // sort by real time first
  dataSorted.sort((a, b) => compareString(a.createdAt, b.createdAt));

  // iterate through game events and mark which one are succeeding each other
  let previousPeriod = null;
  let previousGameTime = '00:00';
  for (const gameEvent of dataSorted) {
    const periodIndex = periods.findIndex(p => p.name == gameEvent.period);
    if (periodIndex != previousPeriod) {
      previousGameTime = '00:00';
      previousPeriod = periodIndex;
    }

    if (gameEvent.gameTime) {
      previousGameTime = ('0' + gameEvent.gameTime).slice(-5);
    } else {
      const increasedGameTime = formatTimeString(parseTimeString(previousGameTime) + 1);
      previousGameTime = ('0' + increasedGameTime).slice(-5);
    }
    gameEvent.inferredGameTime = 'P' + periodIndex + ' ' + previousGameTime;
  }

  // console.log('Game Event Sort Logic (sorted by ServerTime):', dataSorted
  //   .map(d => `GameTime: [${d.gameTime}] InferredTime: [${d.inferredGameTime}] ServerTime: [${d.createdAt}]`));

  dataSorted.sort((a, b) => compareString(a.inferredGameTime, b.inferredGameTime));

  // console.log('Game Event Sort Logic (sorted by InferredTime):', dataSorted
  //   .map(d => `GameTime: [${d.gameTime}] InferredTime: [${d.inferredGameTime}] ServerTime: [${d.createdAt}]`));

  // INFER SCORE

  let scoreHome = 0;
  let scoreAway = 0;

  for (const event of dataSorted) {
    if (event.entity == 'GameEventGoal') {
      if (event.scoredByTeamId == homeTeamId) {
        scoreHome += 1;
      } else if (event.scoredByTeamId == awayTeamId) {
        scoreAway += 1;
      } else {
        console.log('!! Unknown team in GameEvent:', event.scoredByTeamId, `HomeTeam: ${homeTeamId}, AwayTeam: ${awayTeamId}`);
      }
    }
    event.scoreHome = scoreHome;
    event.scoreAway = scoreAway;
  }

  for (const idx in dataSorted) {
    dataSorted[idx].sortIndex = parseInt(idx);
  }

  return dataSorted;

}