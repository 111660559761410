import useEntities from 'hooks/useEntities';
import HmsEntityTableController from 'components/HmsEntityTableController';
import useFetchEntities from 'hooks/useFetchEntities';
import Dot from 'components/Dot';

const Persons = () => {
  const { data: players } = useFetchEntities('Person', { onlyAttributes: 'personId', extraAttributes: 'NewChangeRequestCount', filters: { 'HasNewChangeRequest': 1 } });
  const hasWithChangeRequests = players?.length > 0;

  const entities = useEntities();
  const filterGroups = null;
  const showColumns = (showStatus) => [
    { column: 'Name', width: 200, },
    ...(showStatus ? ['Status'] : []),
    'birthday',
    'height',
    'weight',
    'phone',
    'email',
    'Role',
    'countryBirth',
    'cityBirth',
    'country',
    'city',
    'status',
    'externalId',
    'NewChangeRequestCount',
  ];

  return (
    <HmsEntityTableController
      entity={entities.Person}
      filterGroups={filterGroups}
      columnSets={[
        {
          name: 'All',
          columns: showColumns(false),
        },
        {
          name: 'Newly Registered',
          filters: { status: ['DRAFT', 'PREAPPROVED'] },
          columns: showColumns(true),
        },
        {
          name: <Dot show={hasWithChangeRequests} dotClassName="top-[-6px] right-[-10px]">Change Requests</Dot>,
          key: 'ChangeRequests',
          shitFilter: (r) => r.NewChangeRequestCount > 0,        
          columns: showColumns(false),
        },
      ]}
    />
  )
}

export default Persons