export default function extractTextFromJSX(jsx) {
  if (jsx === null || jsx === undefined) {
    return '';
  }
  if (typeof jsx === 'string') {
    return jsx;
  }
  if (typeof jsx === 'number') {
    return '' + jsx;
  }
  if (Array.isArray(jsx)) {
    return jsx.map(child => extractTextFromJSX(child)).join('');
  }
  if (jsx.props && jsx.props.children) {
    return extractTextFromJSX(jsx.props.children);
  }
  return '';
}
