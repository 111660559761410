import React from 'react'
import { Cascader, Form, Input, Row, Col, DatePicker, Select } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import LastValueCascader from 'components/Form/LastValueCascader';

const FormCascader = ({ label, name, required, autoFocus, options, formItemProps, fieldItemProps }) => {
    return (
        <HmsFormItem
            label={label}
            name={name}
            required={required}
            {...formItemProps}
        >
            <LastValueCascader
                options={options}
                autoFocus={autoFocus}
                {...fieldItemProps} />
        </HmsFormItem>
    )
}

export default FormCascader