import React, { useRef } from 'react'
import { Checkbox, Form, Input, Row, Col, DatePicker, Select } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
const { Option } = Select;

const NullableSelect = ({ isLoading, value, onChange, ...restProps }) => {
  const handleSelectChange = (selectedValue) => {
    const newValue = selectedValue || null; // Convert falsy values to null
    onChange(newValue);
  };

  return (
    <Select
      value={isLoading ? 'Loading...' : value}
      disabled={isLoading || restProps.disabled}
      loading={isLoading}
      onChange={handleSelectChange}
      onClear={() => onChange(null)}
      {...restProps}
    />
  );
};

export default NullableSelect