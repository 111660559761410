import { Tooltip } from 'antd';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react'
import { getImage } from 'utils/getImage';

const StandingsTeamCell = ({ team, shortOnMobile }) => {
  const isMobile = useIsMobile();

  return (
    <div className={`table-cell align-middle py-1 pl-1 desktop:pl-0 font-roboto-condensed text-[0.9rem] desktop:text-[1.2rem] border-gray-300 border-b-[1px]`}>
      <span className="flex items-center gap-2">
        <span
          className="inline-block w-6 h-3 rounded-[5px] bg-gray-300 bg-cover bg-center"
          style={{ backgroundImage: `url('${getImage(team)}')`, backgroundSize: '150%' }}
        ></span>
        <span className="max-w-[calc(34vw-30px)] truncate text-ellipsis overflow-hidden">
          {shortOnMobile && isMobile
            ? <Tooltip title={team.name}>{team.short}</Tooltip>
            : team.name
          }</span>
      </span>
    </div>
  )
}

export default StandingsTeamCell