import MessagesBar from 'components/MessagesBar';
import WarnMessage from 'components/WarnMessage';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useFetchDuplicities from 'hooks/useFetchDuplicities';
import useLoginStore from 'store/loginStore';
import icons from 'utils/icons';
dayjs.extend(utc);

const PlayerDuplicityCheck = ({ data, id }) => {
  const userRoles = useLoginStore(s => s.roles);
  const isAdmin = userRoles?.indexOf('ADMIN') != -1;

  const firstName = data?.firstName?.trim();
  const lastName = data?.lastName?.trim();
  const birthDay = data?.birthday;
  const callFetchDuplicities = firstName && lastName && firstName.length > 0 && lastName.length > 0;

  const { data: possibleDuplPlayersTemp } = useFetchDuplicities(callFetchDuplicities ? 'Player' : null,
    {
      firstName: firstName,
      lastName: lastName
    },
    'playerId,firstName,lastName,birthday,cityBirth'
  );

  const possibleDuplPlayers = possibleDuplPlayersTemp
    ?.filter(p => p.playerId != id)
    ?.filter(p => 
      birthDay == null || birthDay?.trim() == '' || 
      p.birthday == null || p.birthday?.trim() == '' || 
      p.birthday == birthDay
  )

  const duplicityMessage = possibleDuplPlayers?.length > 0 && (
    <WarnMessage
      icon={icons.Information}
      msg={
        <>
          <div>Possible {(possibleDuplPlayers?.length > 1 ? 'duplicates' : 'duplicate')} found:</div>
          <ul className="mt-2 mb-0 pl-7 flex flex-col gap-1">
            {possibleDuplPlayers?.map((player, idx) =>
              <li key={idx}>
                <span className="flex">
                  <span className="mr-2">
                    {(possibleDuplPlayers?.length > 1 ? <span className="">#{idx + 1} - </span> : null)}
                    Player <b>{player.firstName} {player.lastName}</b> with the same name (birthdate: {player.birthday ? <b>{player.birthday}</b> : <i>unknown</i>}, city of birth: {(player.cityBirth && player.cityBirth.trim().length > 0) ? <b>{player.cityBirth}</b> : <i>unknown</i>})
                  </span>
                  {isAdmin &&
                    <a className="underline" target='_blank' rel='noreferrer' href={`/players/${player.playerId}`}>
                      Open
                    </a>
                  }
                </span>
              </li>
            ) ?? []}
          </ul>
          <div className="mt-2">
          {isAdmin
            ? <div>Hint: Open the existing player detail to check and merge if needed.</div>
            : <div>Hint: Go to <a target='_blank' className='underline' href='/teams_team_manager'>My Teams</a> and use Add Player button to add this existing player.</div>
          }
          </div>
        </>
      }
    />
  )

  if (duplicityMessage) {
    return (
      <MessagesBar messages={[duplicityMessage]} />
    )
  }
}

export default PlayerDuplicityCheck;
