const Dot = ({ className, dotClassName, children, show }) => {
  return (
    <span className={`relative ${className}`}>
      {children}
      {show && <span className={`absolute right-[-8px] top-[-3px] bg-red-500 rounded-full w-2 h-2 ${dotClassName}`}></span>}
    </span>
  )
}

export default Dot;
