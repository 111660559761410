import React from 'react'
import { Checkbox, Form, Input, Row, Col, TimePicker, Select } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const backendTimeFormat = 'HH:mm:ss';

const StringTimePicker = ({ value, onChange, ...props }) => {
    const timeStringToDayjs = (timeStr) => {
        const converted = timeStr ? dayjs(timeStr, backendTimeFormat) : null;
        return converted;
    }

    const dayjsToTimeStr = (time) => {
        const converted = time ? time.format(backendTimeFormat) : '';
        return converted;
    }

    return (
        <TimePicker 
            {...props}
            value={timeStringToDayjs(value)}
            onChange={onChange ? (value) => onChange(dayjsToTimeStr(value)) : null}
        />
    );
}

const FormTimePicker = ({ label, name, required, autoFocus, formItemProps, fieldItemProps }) => {
    return (
        <HmsFormItem
            label={label}
            name={name}
            required={required}
            {...formItemProps}
        >
            <StringTimePicker
                autoFocus={autoFocus}
                className="w-full"
                allowClear={true}
                {...fieldItemProps}
            />
        </HmsFormItem>
    )
}

export default FormTimePicker
