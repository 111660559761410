import React, { useState, createContext, useContext, useRef, useEffect } from 'react';

const StandingsTableContext = createContext();

export const useStandingsTableContext = () => useContext(StandingsTableContext);

const StandingsTable = ({ children }) => {
  const tableRef = useRef(null);
  const [isTableOutOfScreen, setIsTableOutOfScreen] = useState(false);
  const [isTableTallerThanScreen, setIsTableTallerThanScreen] = useState(false);

  // Check if the header of the table is out of screen (it means it sticks => we want to change the background color)
  useEffect(() => {
    const handleScroll = () => {
      const tableNode = tableRef.current;
      if (tableNode) {
        const tableRect = tableNode.getBoundingClientRect();
        const isTableOutOfScreen = tableRect.top < 0;
        setIsTableOutOfScreen(isTableOutOfScreen);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Check if the table is taller than the screen (if it does, we don't need sticky headers, which means we can scroll the table horizontally)
  useEffect(() => {
    const checkElementHeight = (entries) => {
      const elementHeight = entries[0].contentRect.height;
      const screenHeight = window.innerHeight;
      setIsTableTallerThanScreen(elementHeight > screenHeight);
    };
    const resizeObserver = new ResizeObserver(checkElementHeight);
    if (tableRef.current) {
      resizeObserver.observe(tableRef.current);
    }
    checkElementHeight([{ contentRect: tableRef.current.getBoundingClientRect() }]);
    return () => {
      if (tableRef.current) {
        resizeObserver.unobserve(tableRef.current);
      }
    };
  }, []);

  return (
    <StandingsTableContext.Provider value={{ isHeaderSticked: isTableOutOfScreen }}>
      <div
        className={`font-roboto ${!isTableTallerThanScreen ? 'max-w-[100vw] overflow-x-auto' : ''}`}>
        <div ref={tableRef} className={`table w-[calc(100vw-60px)] max-w-[950px] mx-auto`}>
          {children}
        </div>
      </div>
    </StandingsTableContext.Provider>
  );
};

export default StandingsTable;