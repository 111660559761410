import React, { useState } from 'react'
import { CopyOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import useNotificationStore from 'store/notificationStore';
import { Button, Tooltip } from 'antd';

const CopyToClipboardButton = ({ onClick = async () => {}, disabled = false }) => {
  const [copiedSuccessfully, setCopiedSuccessfully] = useState(false);
  const [copiedError, setCopiedError] = useState(false);
  const addMessage = useNotificationStore((state) => state.addMessage);

  const clicked = async () => {
    
    const result = await onClick();

    if (result === false) {
      setCopiedError(true);
      setTimeout(() => setCopiedError(false), 2000);

      addMessage({ text: 'Error copying to clipboard', type: 'error' });
    } else {
      setCopiedSuccessfully(true);
      setTimeout(() => setCopiedSuccessfully(false), 2000);
  
      addMessage({ text: 'Copied to clipboard', type: 'success' });
    }

  }

  return (
    <Tooltip title="Copy to clipboard">
      <Button onClick={() => clicked()} disabled={disabled} className={`flex items-center ${copiedSuccessfully ? 'border-green-500 hover:!border-green-500' : copiedError ? 'border-red-500 hover:!border-red-500' : ''}`}>
        {copiedSuccessfully
          ? <span className="flex gap-2 items-center text-green-500"><CheckCircleOutlined /> Copy</span>
          : copiedError
            ? <span className="flex gap-2 items-center text-red-500"><CloseCircleOutlined /> Copy</span>
            : <span className="flex gap-2 items-center"><CopyOutlined /> Copy</span>
        }
      </Button>
    </Tooltip>
  )
}

export default CopyToClipboardButton