// import useEntities from 'hooks/useEntities';
import HmsEntityTableController from 'components/HmsEntityTableController';
import useEntities from 'hooks/useEntities';
import usePageTitle from 'hooks/usePageTitle';

const Competitions = () => {
  const entities = useEntities();

  return (
    <HmsEntityTableController
      entity={entities.Competition}
      showColumns={[
        'NameWithLogo',
        'nick',
        'short',
        'foundDate',
        'status',
      ]}
    />
  )
}

export default Competitions