import React, { useRef } from 'react'
import { Checkbox, Form, Input, Row, Col, DatePicker, Select } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import NullableSelect from 'components/Form/NullableSelect';
const { Option } = Select;

const FormSelect = ({ isLoading, label, name, required, autoFocus, showSearch, formItemProps, fieldItemProps, options = [], allowClear }) => {
  return (
    <HmsFormItem
      label={label}
      name={name}
      required={required}
      {...formItemProps}
    >
      <NullableSelect
        isLoading={isLoading}
        autoFocus={autoFocus}
        showAction='focus'
        allowClear={allowClear}
        showSearch={showSearch ?? true}
        optionFilterProp="children"
        filterOption={(inputValue, option) => {
          const searchText =
            (typeof option.searchText == 'string') ? option.searchText :
              (typeof option.label == 'string') ? option.label :
                (typeof option.value == 'string') ? option.value :
                  '';
          return (
              searchText
                .toLowerCase()
                .includes((inputValue ?? '').toLowerCase())
            );
          }
        }
        options={options}
        {...fieldItemProps}
      />
    </HmsFormItem>
  )
}

export default FormSelect