import { Link, useNavigate } from 'react-router-dom';
import HmsTable from 'components/HmsTable';
import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import useLoadEntity from 'hooks/useLoadEntity';
import useEntities from 'hooks/useEntities';
import HmsField from 'components/Form/HmsField';
import HmsTabbedTables from 'components/HmsTabbedTables';
import icons from 'utils/icons';
import CompetitionVenues from 'components/Competition/CompetitionsVenues';
import CompetitionAwards from 'components/Competition/CompetitionsAwards';
import { Button } from 'antd';
import LinkRelationModal from 'components/Modals/LinkRelationModal';
import { useRef, useState } from 'react';
import EntityLogoEditor from 'components/EntityLogoEditor';
import CompetitionsSeasons from 'components/Competition/CompetitionsSeasons';
import EntityAttachments from 'components/EntityAttachments';

const Competition = () => {
  const entities = useEntities();
  const entity = entities.Competition;
  const parentEntity = null;
  const childEntity = entities.Season;
  const { id, parentId, isLoading, isError, isNotFound, data, reload, parentData, childrenData, relationsData, reloadRelations } = useLoadEntity({
    entity,
    parentEntity,
    childEntity,
    relations: [
      'Venues',
      'Awards',
    ].join(','),
  });
  const { relationsData: attachmentsData, reloadRelations: reloadAttachments } = useLoadEntity({ entity,
    relations: [
      'Attachments',
    ].join(',')
  });

  return (
    <>
      <HmsEntityFormController
        {... { entity, parentEntity, id, parentId, isLoading, isError, isNotFound, data, reload, parentData, childrenData }}
        form={(
          <div className="flex flex-col desktop:flex-row w-full">
            <div className="flex items-center justify-center ml-4 mr-6 mb-5 desktop:mb-0 ">
              <EntityLogoEditor entity={entities.Competition} id={id} data={data} size={112} />
            </div>
            <div className="flex-auto">
              <HmsEntityFormGridBasic>
                <HmsField name="name" />
                <HmsField name="nick" />
                <HmsField name="short" />
                <HmsField name="foundDate" />
                <HmsField name="status" />
              </HmsEntityFormGridBasic>
            </div>
          </div>
        )}
        footer={id != '_new_' && (
          <div className="mt-4">
            <HmsTabbedTables
              tables={[
                {
                  title: "Seasons" + (childrenData ? (' (' + childrenData?.length + ')') : ''),
                  children: <CompetitionsSeasons data={childrenData} sourceEntityId={data?.competitionId} />
                },
                {
                  title: "Venues" + (relationsData?.Venues ? (' (' + relationsData?.Venues?.length + ')') : ''),
                  children: <CompetitionVenues data={relationsData?.Venues} sourceEntityId={data?.competitionId} onChange={reloadRelations} />,
                },
                {
                  title: "Awards" + (relationsData?.Awards ? (' (' + relationsData?.Awards?.length + ')') : ''),
                  children: <CompetitionAwards data={relationsData?.Awards} sourceEntityId={data?.competitionId} onChange={reloadRelations} />,
                },
                {
                  title: "Attachments" + (attachmentsData?.Attachments ? (' (' + attachmentsData?.Attachments?.length + ')') : ''),
                  children: <EntityAttachments entity={entity.name} id={data?.[entity.primaryKey]} data={attachmentsData} reload={reloadAttachments} />,
                },
              ]}
            />
          </div>
        )}
      />
    </>
  );
}

export default Competition
