import React from 'react'
import useNotificationStore from 'store/notificationStore'
import useInterval from 'use-interval';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const Notifications = () => {
  const messages = useNotificationStore((state) => state.messages);
  const removeExpiredMessages = useNotificationStore((state) => state.removeExpiredMessages);

  useInterval(() => {
    removeExpiredMessages();
  }, 1000)

  // Z-index considerations:
  //   1000 = ant.d modal -- we want it to be over
  //   1050 = ant.d user menu -- we want it to be under

  return (
    <div className="fixed top-[60px] right-0 m-3 z-[1025] flex flex-col items-end">
      {messages.map((m, idx) => (
        <span key={idx} className={`${m.type == 'error' ? 'bg-red-600' : m.type == 'warning' ? 'bg-orange-600' : m.type == 'success' ? 'bg-green-600' : 'bg-gray-600'} text-white rounded-lg shadow-lg p-4 mb-4`}>
          <span className="flex gap-3 items-center">
            {m.icon !== null &&
              <>
                {
                  m.type == 'error' ? <CloseCircleOutlined /> :
                  m.type == 'success' ? <CheckCircleOutlined /> :
                  m.icon
                }
              </>
            }
            <span className="leading-5">{m.text}</span>
          </span>
        </span>
      ))}
    </div>
  )
}

export default Notifications