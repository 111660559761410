import React from 'react'
import { getImage } from 'utils/getImage';

const EntityLogo = ({ entity, size = 32, circle = true, className, useExperimentalBgStyle}) => {
  const serverSize = size <= 64 ? 'sm' : 'md';
  const image = getImage(entity, serverSize);

  if (image) {
    return (
      <div style={{ width: size + 'px', height: size + 'px' }} className={`${className ?? ''} flex items-center justify-center`}>
        {!useExperimentalBgStyle && <img src={image} loading="lazy" className={''} style={{ width: size + 'px', height: size + 'px' }} />}
        {useExperimentalBgStyle && <div style={{ backgroundImage: `url('${image}')`, backgroundSize:"contain", width: size + 'px', height: size + 'px' }} className={`font-xs text-transparent`}>&nbsp;</div>}
      </div>
    )
  }

  return (
    <div style={{ width: size + 'px', height: size + 'px' }} className={`${className ?? ''} bg-gray-300 ${circle ? 'rounded-full' : ''} flex items-center justify-center`}>
      <span style={{ fontSize: size/2 + 'px' }} className={`text-white uppercase`}>{entity?.name?.[0] ?? ((entity?.firstName?.[0] ?? '') + (entity?.lastName?.[0] ?? ''))}</span>
    </div>
  )
}

export default EntityLogo