import useEntities from 'hooks/useEntities';
import { Button, Checkbox, Radio, DatePicker, Form, Input, Modal, Select, Table, TimePicker } from 'antd';
import { useMemo, useState } from 'react';
import EntityLogo from 'components/EntityLogo';
import FormItemWithoutLabel from 'components/FormItemWithoutLabel';
import { formatTimeShort } from 'utils/generateEntityColumns';
import { removeAccents } from 'utils/oneLiners';

const SchedulerConfigIceSpecialRequestModal = ({ sourceEntityId, addModalOpen, onClose, phases, icePatterns, values }) => {
    const entities = useEntities();

    const teams = useMemo(() => phases?.map(p => p.Teams)?.flat(), [phases]);

    const initialValues =
        values
            ?   {
                    ...values,
                }
            :
                {
                };

    // console.log('initialValues', initialValues);

    const mode = values ? 'EDIT' : 'CREATE';

    const [busy, setBusy] = useState(false);

    const [form] = Form.useForm();

    const okClicked = async () => {

        const formValues = form.getFieldsValue();

        const record = {
            schedulerConfigId: sourceEntityId,
            teamId: formValues.teamId,
            location: formValues.location,
            type: formValues.type,
            schedulerConfigIcePatternId: formValues.schedulerConfigIcePatternId,
        }

        if (mode == 'EDIT') {
            record.schedulerConfigSpecialRequestId = values.schedulerConfigSpecialRequestId;
        }

        setBusy(true);

        const result = 
            mode == 'CREATE'
                ?
                    await entities.SchedulerConfigSpecialRequest.create(record)
                :
                    await entities.SchedulerConfigSpecialRequest.put(record)

        setBusy(false);

        if (result.status == 200) {
            onClose();
        }
    }

    const addCancelled = () => {
        onClose();
    }

    return (
        <Modal
            title="Add Special Request"
            open={addModalOpen}
            onCancel={addCancelled}
            width={800}
            footer={null}
        >
            <Form
                form={form}
                initialValues={initialValues}
                className="mt-8"
                labelAlign='left'
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
            >
                <div className="grid grid-cols-[1fr_2fr_1fr_2fr] gap-y-4 items-center">

                    <span>Team:</span>
                        <span className="col-span-3">
                        <FormItemWithoutLabel name="teamId">
                            <Select
                                className="!w-[350px]"
                                options={
                                    teams?.map(t => ({
                                      value: t.teamId,
                                      searchText: t.name,
                                      label: <span className="flex items-center gap-2 ml-[2px]">
                                        <EntityLogo entity={t} size={20} />
                                        {t.name}
                                      </span>
                                    }))
                                }
                                showSearch
                                filterOption={(inputValue, option) => {
                                    const opt = removeAccents(option.searchText).toLowerCase().split(' ').filter(v => v);
                                    const inp = removeAccents(inputValue).toLowerCase().split(' ').filter(v => v);
                                    return inp.every(v => opt.some(o => o.includes(v)));
                                  }
                                }
                            />
                        </FormItemWithoutLabel>
                    </span>

                    <span>Type:</span>
                    <span className="col-span-3">
                        <FormItemWithoutLabel name="type">
                            <Radio.Group className="flex flex-nowrap">
                                <Radio value="ONLY">Only</Radio>
                                <Radio value="EXCEPT">Except</Radio>
                            </Radio.Group>
                        </FormItemWithoutLabel>
                    </span>

                    <span>Location:</span>
                    <span className="col-span-3 flex">
                        <FormItemWithoutLabel name="location">
                            <Radio.Group className="flex flex-nowrap">
                                <Radio value="HOME">Home</Radio>
                                <Radio value="AWAY">Away</Radio>
                            </Radio.Group>
                        </FormItemWithoutLabel>
                    </span>

                    <span>Ice Pattern:</span>
                    <span className="col-span-3">
                        <FormItemWithoutLabel name="schedulerConfigIcePatternId">
                            <Select
                                className="!w-[350px]"
                                options={icePatterns?.map(p => ({
                                    value: p.schedulerConfigIcePatternId,
                                    label:
                                        <span className="flex items-center gap-2 ml-[2px]">
                                            <EntityLogo entity={p.Venue} size={20} />
                                            {p.Venue?.name + ', ' + p.weekday + ', ' + formatTimeShort(p.startTime) + ' – ' + formatTimeShort(p.endTime)}
                                        </span>
                                }))}
                            />
                        </FormItemWithoutLabel>
                    </span>
                </div>
            </Form>

            <div className="flex justify-end gap-2 mt-6">
                <Button size="small" onClick={addCancelled}>Cancel</Button>
                <Button size="small" type="primary" onClick={okClicked} disabled={busy}>
                    {
                        mode == 'CREATE'
                            ?
                                'Create'
                            :
                                'Update'
                    }
                </Button>
            </div>
        </Modal>
    );
}

export default SchedulerConfigIceSpecialRequestModal
