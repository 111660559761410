import { Tooltip } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

function TooltipIfTruncated({ children, title }) {
    const elementRef = useRef();
    const [overflows, setOverflows] = useState(false);

    useEffect(() => {
        const element = elementRef.current;

        const handleResize = () => {
            setOverflows(element.offsetWidth < element.scrollWidth);
          };
      
          const resizeObserver = new ResizeObserver(handleResize);

          resizeObserver.observe(element);
      
          return () => {
            resizeObserver.unobserve(element);
          };
    }, []);

    return (
        <div ref={elementRef} className="truncate">
            <Tooltip title={overflows ? title : null}>
                {children}
            </Tooltip>
        </div>
    );
}

export default TooltipIfTruncated;
