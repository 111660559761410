import { Button } from 'antd';
import Confirm from 'components/Confirm';
import { useState } from 'react';
import useLoginStore from 'store/loginStore';
import icons from 'utils/icons';
import { getDefaultCardWidth } from 'utils/uiPreferences';

const ApprovalBar = ({ status, entity, id, reload, missingItems }) => {
  const userRoles = useLoginStore(s => s.roles);
  const isAdmin = userRoles?.indexOf('ADMIN') != -1;
  const [confirmOpen, setConfirmOpen] = useState(null);

  return (
    <>
      <Confirm
        message={confirmOpen?.message}
        confirmButtonText={confirmOpen?.confirmButtonText}
        confirmButtonLoading={confirmOpen?.confirmButtonLoading}
        confirmButtonType={confirmOpen?.confirmButtonType}
        confirmButtonProps={confirmOpen?.confirmButtonProps}
        cancelButtonProps={confirmOpen?.cancelButtonProps}
        visible={confirmOpen}
        handleConfirm={confirmOpen?.onOk}
        handleCancel={confirmOpen?.onCancel}
        title={confirmOpen?.title}
      />

      {!isAdmin && status == 'DRAFT' &&
        <>
            <div className={`bg-[#e5f5ff] text-[#2280ff] border-[#cee7ff] rounded-md mb-3 p-3 ${getDefaultCardWidth(true)} flex items-center justify-between border-[1px]`}>
              <div className="w-full flex items-center gap-3">
                {missingItems?.length > 0 
                  ?
                    <div>
                      <div className="flex items-center gap-3">
                        <span>{icons.ErrorTriangleOutlined}</span>
                        <span>The following steps are required for approval:</span>
                      </div>
                      <ul className="mt-1 mb-0 ml-2 flex flex-col gap-1">
                        {missingItems.map((item, idx) => (
                          <li key={idx}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  :
                    <div>
                      <div className='flex items-center gap-3 border-red-500'>
                        <span className="text-[#2280ff]">{icons.Correct}</span>
                        <span>
                          This {entity.displayName} is ready to be sent for approval.</span>
                      </div>
                  </div>
                }
                <Button
                  className="ml-auto"
                  size="small"
                  type="primary"
                  disabled={missingItems?.length > 0}
                  onClick={() => {
                    setConfirmOpen({
                      title: 'Confirm',
                      message:
                        <div className="mt-4 mb-6">
                          By sending this record for approval:
                          <ul>
                            <li>All fields will be locked for editing</li>
                            <li>Organization administrator will be notified about approval request</li>
                          </ul>
                          Please confirm that you want to send this {entity.displayName} for approval?
                        </div>,
                      confirmButtonText: 'Yes - Confirm',
                      confirmButtonProps: { size: 'small' },
                      cancelButtonProps: { size: 'small' },
                      onOk: async () => {
                        await entity.patch(id, { status: 'PREAPPROVED' });
                        reload();
                        setConfirmOpen(null);
                      },
                      onCancel: () => {
                        setConfirmOpen(null);
                      }
                    })
                  }}
                >
                  Send for approval
                </Button>
              </div>

            </div>
        </>
      }
      {!isAdmin && status == 'PREAPPROVED' &&
        <div className={`bg-[#ddeeff] text-[#2280ff] rounded-md mb-3 p-3 ${getDefaultCardWidth(true)} flex gap-2 items-center border-[1px] border-[#cee7ff]`}>
          <span className="text-[#2280ff]">{icons.Pending}</span>
          <span>This {entity.displayName} has been sent to the organization administrator and it's pending approval.</span>
        </div>
      }
    </>
  )
}

export default ApprovalBar