import React from 'react'

const EmbedCard = ({ children, className }) => {
  return (
    <div className={"bg-white box-border px-5 desktop:px-10 pt-4 desktop:pt-8 pb-8 desktop:pb-8 font-roboto-condensed w-full max-w-[800px] " + (className ?? '')}>
      {children}
    </div>
  )
}

export default EmbedCard
