import useEntities from 'hooks/useEntities';
import RelationsEditable from 'components/RelationsEditable';

const SchedulerSimulationGeneratedGames = ({ sourceEntityId, data, onChange }) => {
    const entities = useEntities();
    const sourceEntity = entities.SchedulerSimulation;
    const targetEntity = entities.Game;

    return (
        <RelationsEditable 
            data={data}
            sourceEntity={sourceEntity} 
            targetEntity={targetEntity}
            sourceEntityId={sourceEntityId}
            emptyMessage={`This ${sourceEntity.displayName} does not have any ${targetEntity.displayNamePlural}`}
            onChange={onChange}
            canOpen={true}
            canRemove={false}
            columns={[
                { column: 'StartDateTime-nowrap', defaultSortOrder: 'ascend' },
                'Venue', 
                'TeamsVs-nick',
                'status', 
                'name',
            ]}
        />
    )    

}

export default SchedulerSimulationGeneratedGames
