import { Button, Checkbox, Modal, Radio, Space, Spin, Tooltip } from 'antd'
import useFetchEntities from 'hooks/useFetchEntities'
import React, { useState } from 'react'

const TeamRegistrationDialog_SelectSeason = ({ registration, setRegistration, alreadyRegisteredSeasonIds }) => {
  const { data: seasons } = useFetchEntities('Season', {
    filters: {
      status: 'OPEN_FOR_REGISTRATION',
    },
    relations: [
      'Competition',
      'Groups',
    ].join(','),
  });

  const seasonOptions = seasons
    ?.filter(s => !alreadyRegisteredSeasonIds.includes(s.seasonId))

  return (
    <div>
      <h3 className="">Team ➛ Season Registration</h3>

      <h4 className="mt-3">1. Select a season</h4>

        {seasonOptions == null && 
          <div className="flex items-center justify-center h-[100px] w-full">
            <Spin />
          </div>
        }

        <div>
          {seasonOptions && seasonOptions.length > 0 &&
            <Radio.Group
            onChange={(e) => {
                setRegistration({
                  ...registration,
                  seasonId: e.target.value,
                  groupIds: null,
                });
              }
            }
            value={registration?.seasonId}
          >
            <Space direction="vertical">
              {seasonOptions.map(c => (
                <Radio key={c.seasonId} value={c.seasonId}>{c.Competition?.name} - {c.name}</Radio>
              ))}
            </Space>
          </Radio.Group>
          }
        </div>

        <div>
          {seasonOptions && seasonOptions.length == 0 &&
            <span>
              There are no more available competitions.
            </span>
          }
        </div>
    </div>
  )
}

export default TeamRegistrationDialog_SelectSeason
