import { Button, Checkbox, Input, Modal, Spin, Switch } from 'antd'
import React, { useState } from 'react'

const TeamRegistrationDialog_Specials = ({ registration, setRegistration }) => {
  return (
    <div>
      <h3 className="mt-0">Registration – Secial requests (optional)</h3>

      <div className="mt-4 mb-2">
        <div className="flex items-center gap-3">
          <Switch
            checked={registration.SeasonRegistrationSpecials?.find(a => a.type == 'PREMIUM_TIMESLOT')?.enabled}
            onChange={(checked) => {
              setRegistration({
                ...registration,
                SeasonRegistrationSpecials: (
                  registration.SeasonRegistrationSpecials?.find(a => a.type == 'PREMIUM_TIMESLOT')
                    ? (registration.SeasonRegistrationSpecials ?? [])
                    : [ ...(registration.SeasonRegistrationSpecials ?? []), { type: 'PREMIUM_TIMESLOT', name: 'Premium timeslots', } ]
                ).map(a => a.type != 'PREMIUM_TIMESLOT' ? a : { ...a, enabled: checked }),
              });
            }}
          />
          Request premium timeslots (fees will apply)
        </div>
        <div className={`${registration.SeasonRegistrationSpecials?.find(a => a.type == 'PREMIUM_TIMESLOT')?.enabled ? 'block' : 'hidden'} mt-4`}>
          Please describe your request below in English or Czech language:
          <br/>
          We will contact you later with an offer.
          <Input.TextArea
            value={registration.SeasonRegistrationSpecials?.find(a => a.type == 'PREMIUM_TIMESLOT')?.text ?? ''}
            onChange={(e) => {
              setRegistration({
                ...registration,
                SeasonRegistrationSpecials: registration.SeasonRegistrationSpecials?.map(a => a.type != 'PREMIUM_TIMESLOT' ? a : { ...a, text: e.target.value }) ?? [],
              });
            }}
            autoSize={{ minRows: 5, maxRows: 100 }}
            className="mt-2 mb-2 w-full"
            placeholder={'Examples:\n- Our team would like to play only Tuesday and Thursday evenings;\n- We do not want to play on weekends'}
          />          
        </div>
      </div>
    </div>
  );
}

export default TeamRegistrationDialog_Specials
