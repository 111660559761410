import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);


export function formatTimeStringToHHMM(str) {
  if (str == null) return '-';

  const [hours, minutes] = str.split(":").map(Number);

  // Pad hours and minutes with leading zeros if necessary
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
}

export function formatDateToLongFormat(dateString) {
  return dayjs(dateString).format('ddd DD.MM.YYYY');
}

export function formatDateToShortFormat(dateString) {
  return dayjs(dateString).format('DD.MM.YYYY');
}
