import useFetch from 'hooks/useFetch'
import useFetchEntity from 'hooks/useFetchEntity';
import RefereeIcon from 'icons/RefereeIcon';
import TimekeeperIcon from 'icons/TimekeeperIcon';
import React, { useEffect, useState } from 'react'
import { removeAccents, sorted, unique } from 'utils/oneLiners';
import moment from 'moment';
import { Input, Select, Spin } from 'antd';
import useLang from 'hooks/useLang';
import { ClockLoader } from 'react-spinners';
import useIsMobile from 'hooks/useIsMobile';
import { compareString } from 'utils/comparators';
import extractTextFromJSX from 'utils/extractTextFromJsx';

const FilterWrapper = ({ label, type, value, onChange, options, allowAll, lang }) => {
  const LANG = useLang(lang);

  return (
    <div className="flex">
      <div className="font-roboto-flex rounded-l-xl bg-[#aaa] px-2 py-1 w-[130px] desktop:w-[150px] text-white flex justify-center items-center text-[0.9rem] desktop:text-[1.0rem]">{label}:</div>
      <div className="border-b-[1px] border-[#aaa] w-[170px] desktop:w-[150px]">
        {type == 'select' &&
          <Select
            bordered={false}
            className="w-full"
            value={value ?? '[ALL]'}
            options={[...(allowAll ? [{ value: '[ALL]', label: LANG.ALL }] : []), ...(options ?? [])].map(o =>
              ({ value: o.value, label: <span className="font-roboto-flex cursor-pointer text-center w-full inline-block text-[0.8rem] desktop:text-[0.9rem]">{o.label}</span> })
            )}
            filterOption={(inputValue, option) => {
              const text = extractTextFromJSX(option.label);
              return removeAccents(text).toLowerCase().includes(removeAccents(inputValue).toLowerCase());
            }}
            onChange={(value) => onChange ? onChange(value != '[ALL]' ? value : null) : {}}
            allowClear={allowAll && value != null}
            showSearch={true}
          />
        }
        {type == 'input' &&
          <Input
            className="border-none rounded-none"
            value={value}
            allowClear
            onChange={(e) => onChange(e.target.value)}
          />
        }
      </div>
    </div>
  )
}

export default FilterWrapper
