import React from 'react'
import moment from 'moment';
import { Tooltip } from 'antd';
import SchedulerSimulationLegend from 'components/SchedulerSimulation/SchedulerSimulationLegend';
import SchedulerSimulationSummaryTable from 'components/SchedulerSimulation/SchedulerSimulationSummaryTable';
import { getSchedulerUserFriendlyName } from 'utils/schedulerHelper';
  
const SchedulerSimulationVenues = ({ data }) => {
    const generateDaysBetween = (startDate, endDate) => {
        return Array.from({ length: moment(endDate).diff(startDate, 'days') + 1 }, (_, i) => moment(startDate).add(i, 'days').format('YYYY-MM-DD'));
    }

    if (!data || !data.venues) {
        return (<></>)
    }

    const venueTimes = [];
    data.venues.forEach(v => venueTimes.push(...v.availableTimes.map(at => at.date + 'T' + at.startTime)));
    venueTimes.sort();

    const minDate = venueTimes[0];
    const maxDate = venueTimes[venueTimes.length - 1];

    const allDays = generateDaysBetween(minDate, maxDate);

    const gamesPerDay = Object.fromEntries(
        allDays.map(d => [d, data.games.filter(g => (g.time ?? '').substr(0, 10) == d)])
    );

    const venues = data.venues.map(v => {
        const timesPerDay = allDays.map(d => {
            return {
                date: d,
                times: v.availableTimes.filter(at => at.date == d),
                games: (gamesPerDay[d] ?? []).filter(g => g.venue == v.name),
            };
        });
        return {
            ...v,
            timesPerDay,
        };
    });

    const sumCat = {};
    data.venues.forEach(v => {
        v.availableTimes.forEach(at => {
            sumCat[at.category] = (sumCat[at.category] ?? 0) + 1;
        });
    });

    return (
        <div>
            <SchedulerSimulationLegend
                className="mx-auto mb-4"
                items={[
                    { color: 'bg-green-300', text: 'Fully used' },
                    { color: 'bg-orange-300', text: 'Partially used' },
                    { color: 'bg-orange-500', text: 'Unused' },
                    { color: 'bg-gray-100', text: 'Not playing' },
                ]} />
            <SchedulerSimulationSummaryTable
                className="mb-6"
                data={[
                    { label: 'Total slots', value: venueTimes.length },
                    { label: 'Used', value: data.games.length },
                    { label: 'Unused', value: venueTimes.length - data.games.length },
                    { label: 'Cat A', value: sumCat.A },
                    { label: 'Cat B', value: sumCat.B },
            ]} />
            <div className="max-w-screen overflow-scroll">
                {venues.map(v => (
                    <div key={v.name} className="flex flex-nowrap items-center mb-2">
                        <div className="flex-shrink-0 mb-1 pr-3 w-[280px] whitespace-nowrap overflow-hidden overflow-ellipsis flex items-center gap-2 justify-end">
                            {getSchedulerUserFriendlyName(v.name)}
                            <Tooltip title="Number of games / Number of slots ~ Utilization %">
                                <span className="text-gray-400 text-xs">
                                    (
                                        {data?.games?.filter(g => g.venue == v.name).length}
                                        /
                                        {v.availableTimes.length}
                                        &nbsp;~&nbsp;
                                        {(data?.games?.filter(g => g.venue == v.name).length / (v.availableTimes.length || 1) * 100).toFixed(0)}%)
                                    </span>
                            </Tooltip>
                        </div>
                        <div>
                            <div className={`flex items-center gap-[1px]`}>
                                {v.timesPerDay.map(d => {
                                    const times = d.times.length;
                                    const games = d.games.length;

                                    let bgColor = '';
                                    if (times > 0 && times == games) {
                                        bgColor = 'bg-green-300';
                                    } else if (times > 0 && games > 0 && games != times) {
                                        bgColor = 'bg-orange-300';
                                    } else if (times > 0 && games == 0) {
                                        bgColor = 'bg-orange-500';
                                    } else if (times == 0) {
                                        bgColor = 'bg-gray-100';
                                    }

                                    return (
                                        <Tooltip key={d.date} title={
                                            <>
                                                <div className="mb-3">{d.date}</div>
                                                {d.times.map((t, idx) => {
                                                    const game = d.games.find(g => g.time == t.date + 'T' + t.startTime);

                                                    return (
                                                        <div key={idx} className="mb-5">
                                                            <div>Time: {t.startTime}</div>
                                                            <div>{game ? (getSchedulerUserFriendlyName(game.homeTeam) + ' vs ' + getSchedulerUserFriendlyName(game.awayTeam)) : '<EMPTY>'}</div>
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        }>
                                            <span className={`w-1 h-6 ${bgColor} hover:bg-slate-500 cursor-pointer`} />
                                        </Tooltip>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SchedulerSimulationVenues