import React from 'react'

const HmsEntityFormGridBasic = ({ children }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-5">
        {children}
    </div>
  )
}

export default HmsEntityFormGridBasic