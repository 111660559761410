import useEntities from 'hooks/useEntities';
import RelationsEditable from 'components/RelationsEditable';
import RelationsTable from 'components/RelationsTable';
import useLoginStore from 'store/loginStore';

const PlayerPositions = ({ data, sourceEntityId, sourceEntityData, onChange }) => {
    const entities = useEntities();
    const hasRole = useLoginStore(s => s.hasRole);
    const isAdmin = hasRole('ADMIN');

    return (
        isAdmin || sourceEntityData?.status == 'DRAFT'
            ?
                <RelationsEditable
                    data={data}
                    sourceEntity={entities.Player} 
                    targetEntity={entities.ListPosition}
                    emptyMessage="This player does not have any positions"
                    sourceEntityId={sourceEntityId}
                    addButtonText="Add Positions"
                    onChange={onChange}
                />
            :
                <RelationsTable
                    data={data}
                    sourceEntity={entities.Player} 
                    targetEntity={entities.ListPosition}
                    emptyMessage="This player does not have any positions"
                    sourceEntityId={sourceEntityId}
                />
    )
}

export default PlayerPositions
