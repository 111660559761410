import useEntities from 'hooks/useEntities';
import RelationsTable from 'components/RelationsTable';
import { Button, Checkbox, Radio, DatePicker, Form, Input, Modal, Select, Table, TimePicker } from 'antd';
import { useMemo, useState } from 'react';
import { add } from 'lodash';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import moment from 'moment';
import useFetchEntity from 'hooks/useFetchEntity';
import useConfirmStore from 'store/confirmStore';
import exception from '@openreplay/tracker/lib/modules/exception';
import { formatTimeShort } from 'utils/generateEntityColumns';
import FormItemWithoutLabel from 'components/FormItemWithoutLabel';
import dayjs from 'dayjs';
import ShortTimePicker from 'components/Form/ShortTimePicker';

const dateFormatList = [
    // 'DD/MM/YYYY',
    // 'D/MM/YYYY',
    // 'DD/M/YYYY',
    // 'D/M/YYYY',
    // 'DD/MM/YY',
    // 'D/MM/YY',
    // 'DD/M/YY',
    // 'D/M/YY',
    'DD.MM.YYYY',
    // 'D.MM.YYYY',
    // 'DD.M.YYYY',
    // 'D.M.YYYY',
    // 'DD.MM.YY',
    // 'D.MM.YY',
    // 'DD.M.YY',
    // 'D.M.YY',
];

const SchedulerConfigIcePatternModal = ({ sourceEntityId, addModalOpen, onClose, phases, values }) => {
    const entities = useEntities();

    const { data: relationsData } = useFetchEntity('SchedulerConfig', sourceEntityId, 'Venues');
    const venues = relationsData?.Venues ?? [];

    const phasesMinStartDate = phases?.reduce((min, p) => { return p.startDate < min ? p.startDate : min; }, phases[0]?.startDate);
    const phasesMaxEndDate = phases?.reduce((max, p) => { return p.endDate > max ? p.endDate : max; }, phases[0]?.endDate);

    const today = dayjs();

    const initialValues =
        values
            ?   {
                    ...values,
                    startDate: dayjs(values.startDate),
                    endDate: dayjs(values.endDate),
                    except: (values.except && values.except != '') ? values.except.split(',') : [],
                    startTime: formatTimeShort(values.startTime),
                    endTime: formatTimeShort(values.endTime),
                }
            :
                {
                    startDate: dayjs(phasesMinStartDate ?? today),
                    endDate: dayjs(phasesMaxEndDate ?? today),
                };

    const mode = values ? 'EDIT' : 'CREATE';

    const required = {
        required: true,
        message: 'This field is required.',
    };

    const [busy, setBusy] = useState(false);

    const [form] = Form.useForm();
    const watched = Form.useWatch([], form);

    const watchedObj = watched ?? {};

    const exceptOptions = useMemo(() => {

        const formValues = watchedObj;

        const startDate = formValues?.startDate?.toDate();
        const endDate = formValues?.endDate?.toDate();
        const weekday = formValues?.weekday;

        if (!startDate || !endDate || !weekday) {
            return [];
        }

        const startMoment = moment(startDate);
        const endMoment = moment(endDate);

        const items = [];

        while (startMoment.isSameOrBefore(endMoment)) {
            if (formValues.weekday == moment(startMoment).format('ddd')) {
                items.push({
                    value: startMoment.format('YYYY-MM-DD'),
                    label: startMoment.format('YYYY-MM-DD'),
                });
            }
            startMoment.add(1, 'days');
        }

        return items;

    }, [watchedObj]);

    const okClicked = async () => {

        try {
            await form.validateFields();
        } catch (e) {
            return;
        }

        const formValues = form.getFieldsValue();

        const record = {
            schedulerConfigId: sourceEntityId,
            startDate: moment(formValues.startDate.toDate()).format('YYYY-MM-DD'),
            endDate: moment(formValues.endDate.toDate()).format('YYYY-MM-DD'),
            weekday: formValues.weekday,
            startTime: formValues.startTime + ':00',
            endTime: formValues.endTime + ':00',
            except: (formValues.except ?? []).join(','),
            venueId: formValues.venueId,
            category: formValues.category,
        }

        if (mode == 'EDIT') {
            record.schedulerConfigIcePatternId = values.schedulerConfigIcePatternId;
        }

        setBusy(true);

        const result = 
            mode == 'CREATE'
                ?
                    await entities.SchedulerConfigIcePattern.create(record)
                :
                    await entities.SchedulerConfigIcePattern.put(record);

        setBusy(false);

        if (result.status == 200) {
            onClose();
        }
    }

    const addCancelled = () => {
        onClose();
    }

    const requiredSymbol = <span className='text-red-500 text-sm'>*</span>;

    return (
        <Modal
            title="Add Ices"
            open={addModalOpen}
            onCancel={addCancelled}
            width={800}
            footer={null}
        >
            <Form
                form={form}
                initialValues={initialValues}
                className="mt-8"
                labelAlign='left'
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
            >
                <div className="grid grid-cols-[1fr_2fr_1fr_2fr] gap-y-4 items-center">

                    <span>Start date: {requiredSymbol}</span>
                    <FormItemWithoutLabel name="startDate" rules={[required]}>
                        <DatePicker className="w-[200px]" format={dateFormatList} />
                    </FormItemWithoutLabel>
                    <span>End date: {requiredSymbol}</span>
                    <FormItemWithoutLabel name="endDate" rules={[required]}>
                        <DatePicker className="w-[200px]" format={dateFormatList} />
                    </FormItemWithoutLabel>

                    <span>Weekday: {requiredSymbol}</span>
                    <span className="col-span-3">
                        <FormItemWithoutLabel name="weekday" rules={[required]}>
                            <Radio.Group className="flex flex-nowrap">
                                <Radio value="Mon">Mon</Radio>
                                <Radio value="Tue">Tue</Radio>
                                <Radio value="Wed">Wed</Radio>
                                <Radio value="Thu">Thu</Radio>
                                <Radio value="Fri">Fri</Radio>
                                <Radio value="Sat">Sat</Radio>
                                <Radio value="Sun">Sun</Radio>
                            </Radio.Group>
                        </FormItemWithoutLabel>
                    </span>

                    <span>Start time: {requiredSymbol}</span>
                    <FormItemWithoutLabel name="startTime" rules={[required]}>
                        <ShortTimePicker placeholder="hh:mm" className="w-[200px]" />
                    </FormItemWithoutLabel>
                    <span>End time: {requiredSymbol}</span>
                    <FormItemWithoutLabel name="endTime" rules={[required]}>
                        <ShortTimePicker placeholder="hh:mm" className="w-[200px]" />
                    </FormItemWithoutLabel>

                    <span>Venue: {requiredSymbol}</span>
                    <FormItemWithoutLabel name="venueId" rules={[required]}>
                        <Select
                            className="!w-[200px]"
                            options={venues.map(v => ({
                                value: v.venueId,
                                label: v.name,
                            }))}
                        />
                    </FormItemWithoutLabel>
                    <span>Category:</span>
                    <FormItemWithoutLabel name="category">
                        <Radio.Group className="flex flex-nowrap">
                            <Radio value="A">A</Radio>
                            <Radio value="B">B</Radio>
                            <Radio value="C">C</Radio>
                        </Radio.Group>
                    </FormItemWithoutLabel>

                    <span>Exceptions:</span>
                    <span className="col-span-3">
                        <FormItemWithoutLabel name="except">
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                options={exceptOptions}
                            />
                        </FormItemWithoutLabel>
                    </span>

                </div>

                {/* https://ant.design/components/form#shouldupdate */}
                {/* <FormItemWithoutLabel shouldUpdate>
                            {() => {
                                return <pre>{JSON.stringify(addIcesForm.getFieldsValue(), null, 2)}</pre>;
                            }}
                        </FormItemWithoutLabel> */}
            </Form>

            <div className="flex justify-end gap-2 mt-6">
                <Button size="small" onClick={addCancelled}>Cancel</Button>
                <Button size="small" type="primary" onClick={okClicked} disabled={busy}>
                    {
                        mode == 'CREATE'
                            ?
                                'Create'
                            :
                                'Update'
                    }
                </Button>
            </div>
        </Modal>
    );
}

export default SchedulerConfigIcePatternModal
