import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useEntities from 'hooks/useEntities';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import useLoadEntity from 'hooks/useLoadEntity';
import HmsField from 'components/Form/HmsField';
import HmsTabbedTables from 'components/HmsTabbedTables';
import PhaseTeams from 'components/Phase/PhaseTeams';
import EntityAttachments from 'components/EntityAttachments';
import TabsWithUrlMemory from 'components/TabsWithUrlMemory';
import HmsEntityFormGridFullWidth from 'components/Form/HmsEntityFormGridFullWidth';
import EntityLogoEditor from 'components/EntityLogoEditor';

const Phase = () => {
  const entities = useEntities();
  const entity = entities.Phase;
  const parentEntity = entities.Group;
  const childEntity = null;
  const { id, parentId, isLoading, isError, isNotFound, data, parentData, childrenData, relationsData, reloadRelations } = useLoadEntity({
    entity, 
    parentEntity, 
    childEntity,
    relations: [
      'Teams',
    ].join(','),
  });
  const { relationsData: attachmentsData, reloadRelations: reloadAttachments } = useLoadEntity({ entity,
    relations: [
      'Attachments',
    ].join(',')
  });

  return (
    <>
      <HmsEntityFormController
        {... { entity, parentEntity, id, parentId, isLoading, isError, isNotFound, data, parentData, childrenData }}

        form={(
          <TabsWithUrlMemory
            className="no-tab-padding w-[calc(100vw-75px)] desktop:w-[calc(100vw-280px)]"
            items={[
              {
                label: `Basic`,
                children: <>
                  <div className="flex flex-col desktop:flex-row w-full">
                    <div className="flex items-center justify-center ml-4 mr-6 mb-5 desktop:mb-0 ">
                      <EntityLogoEditor entity={entities.Phase} id={id} data={data} size={112} />
                    </div>
                    <div className="flex-auto">
                      <HmsEntityFormGridBasic>
                        <HmsField name="name" autoFocus />
                        <HmsField name="nick" />
                        <HmsField name="short" />
                        <HmsField name="startDate" />
                        <HmsField name="endDate" />
                        <HmsField name="tournamentType" />
                        <HmsField name="status" />
                      </HmsEntityFormGridBasic>
                    </div>
                  </div>
                </>
              },
            ].map(tab => ({ ...tab, key: tab.label }))}
          />
        )}
        footer={id != '_new_' && (
          <div className="mt-4">
            <HmsTabbedTables
              tables={[
                {
                  title: "Teams" + (relationsData?.Teams ? (' (' + relationsData?.Teams?.length + ')') : ''),
                  children:
                    <PhaseTeams data={relationsData?.Teams} sourceEntityId={data?.phaseId} onChange={reloadRelations} />
                },
                {
                  title: "Attachments" + (attachmentsData?.Attachments ? (' (' + attachmentsData?.Attachments?.length + ')') : ''),
                  children: <EntityAttachments entity={entity.name} id={data?.[entity.primaryKey]} data={attachmentsData} reload={reloadAttachments} />,
                },
              ]}
            />
          </div>
        )}
      />
    </>
  );
}

export default Phase
