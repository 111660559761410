import EmbedDisciplinariesInner from 'components/Embeds/EmbedDisciplinariesInner';
import useFetchData from 'hooks/useFetchData'
import useLang from 'hooks/useLang';
import usePageTitle from 'hooks/usePageTitle';
import React from 'react'
import { useSearchParams } from 'react-router-dom';
import EmbedWrapper from 'components/Embeds/EmbedWrapper';

const EmbedDisciplinaries = () => {
  const [searchParams] = useSearchParams();
  const competitionId = searchParams.get('competitionId');

  const { data, isLoading, isError } = useFetchData(`/public/disciplinaries?competitionId=${competitionId}`);

  usePageTitle('Seznam disciplinárních řízení')

  return (
    data && <EmbedDisciplinariesInner data={data} />
  )
}

export default EmbedDisciplinaries