import { Input, Radio, Table, Tooltip } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import SegmentedTabs from 'components/SegmentedTabs'
import { removeAccents, sorted, unique } from 'utils/oneLiners'
import SchedulerSimulationSummaryTable from 'components/SchedulerSimulation/SchedulerSimulationSummaryTable'
import FixedHeaderTable from 'components/SchedulerSimulation/FixedHeaderTable'
import _, { has } from 'lodash'
import icons from 'utils/icons'
import { getSchedulerUserFriendlyName } from 'utils/schedulerHelper';

const SchedulerSimulationGames = ({ data }) => {
    const [search, setSearch] = useState('');
    // const [searchedGames, setSearchedGames] = useState(data?.games);
    const [filter, setFilter] = useState('All');

    const games = useMemo(() => 
        data?.games?.map(game => ({
            ...game,
            hasError: game.error != null,
            hasWarning: (game.warnings != null && game.warnings.length > 0) || game.subsequentWarning || game.prefersHt === false || game.prefersAt === false,
    })), [data]);

    const searchedGames = useMemo(() => {
        return games.filter(g => {
            const json = removeAccents(JSON.stringify(g).toUpperCase());
            const words = removeAccents(search.toUpperCase()).split(' ').map(s => s.trim());
            for (const word of words) {
                if (json.indexOf(word) == -1) {
                    return false;
                }
            }
            return true;
        })
    }, [games, search]);

    if (!searchedGames) {
        return (<></>)
    }

    const groups = data?.groups?.map(g => g.name);

    const TeamColumn = ({ text, record, homeOrAway }) => {
        const prefersField = homeOrAway == 'HOME' ? 'prefersHt' : homeOrAway == 'AWAY' ? 'prefersAt' : '';

        const unpreferredWarning = record.time && !record[prefersField];
        if (!unpreferredWarning && !record.subsequentWarning) {
            return text;
        } else {
            return (
                <span className="flex">
                    <span className="cursor-pointer flex items-center gap-1 text-orange-500 ">
                        <span>{text}</span>
                        <span className="flex items-center">{icons.WarningTriangle}</span>
                        {unpreferredWarning &&
                            <Tooltip title="Team plays at an unpreferred time">
                                <span>(unpreferred)</span>
                            </Tooltip>
                        }
                        {record.subsequentWarning &&
                            <Tooltip title="Team plays subsequently against the same opponent">
                                <span>(subsequent)</span>
                            </Tooltip>
                        }
                    </span>
                </span>
            );
        }
    }


    return (
        <SegmentedTabs
            items={
                sorted([
                    'all__All',
                    ...groups].map((g, idx) => ({
                        label: getSchedulerUserFriendlyName(g),
                        key: idx,
                        children:
                            <div>
                                <SchedulerSimulationSummaryTable
                                    data={[
                                        { label: 'Games', value: games.filter(game => g == 'all__All' || (game.group ?? '').toUpperCase() == g.toUpperCase()).length },
                                        search.trim() != '' ? {
                                            label: 'Filtered games',
                                            value: searchedGames
                                                .filter(game => g == 'all__All' || (game.group ?? '').toUpperCase() == g.toUpperCase())
                                                .filter(game => filter == 'All' || (filter == 'OK' && !game.hasError && !game.hasWarning) || (filter == 'Warnings' && game.hasWarning) || (filter == 'Errors' && game.hasError))
                                                .length
                                        } : null
                                    ].filter(o => o != null)} />
                                <div className="flex justify-between">
                                    <div>
                                        <Radio.Group 
                                            options={[
                                                { value: 'All',      label: 'All' },
                                                { value: 'OK',       label: <span className="text-green-500">OK ({
                                                    searchedGames
                                                        .filter(game => (g == 'all__All' || (game.group ?? '').toUpperCase() == g.toUpperCase()) && (!game.hasError && !game.hasWarning))
                                                        .length
                                                })</span> },
                                                { value: 'Warnings', label: <span className="text-orange-500">Warnings ({
                                                    searchedGames
                                                        .filter(game => (g == 'all__All' || (game.group ?? '').toUpperCase() == g.toUpperCase()) && (game.hasWarning))
                                                        .length
                                                })</span> },
                                                { value: 'Errors',   label: <span className="text-red-500">Errors ({
                                                    searchedGames
                                                        .filter(game => (g == 'all__All' || (game.group ?? '').toUpperCase() == g.toUpperCase()) && (game.hasError))
                                                        .length
                                                })</span> },
                                            ]} 
                                            onChange={(e) => setFilter(e.target.value)} 
                                            value={filter}
                                            optionType="button"
                                        />
                                    </div>
                                    <Input className="w-64 mb-2" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search..." allowClear />
                                </div>
                                <FixedHeaderTable
                                    size="small"
                                    columns={[
                                        {
                                            title: 'Idx', width: '5%',
                                            // sortDirections: ['ascend'], defaultSortOrder: 'ascend',
                                            render: (record) => (
                                                <Tooltip
                                                    title={
                                                        <pre className="text-sm">
                                                            {JSON.stringify(record, null, 2)}
                                                        </pre>}
                                                    placement="right"
                                                    overlayStyle={{ minWidth: 500 }}
                                                >
                                                    {record.idx}
                                                </Tooltip>
                                            ),
                                            // sorter: (a, b) => a.idx - b.idx,
                                        },
                                        {
                                            title: 'Time', width: '20%', dataIndex: 'time', sortDirections: ['ascend', 'descend'], // defaultSortOrder: 'descend',
                                            render: (t) => t ? moment(t).format('DD.MM.YYYY, ddd - HH:mm') : '-',
                                        },
                                        { title: 'Group', width: '20%', dataIndex: 'group', sortDirections: ['ascend', 'descend'] },
                                        {
                                            title: 'Home', width: '20%', dataIndex: 'homeTeam', sortDirections: ['ascend', 'descend'],
                                            render: (text, record) => (
                                                <TeamColumn text={text} record={record} homeOrAway='HOME' />
                                            ),
                                        },
                                        {
                                            title: 'Away', width: '20%', dataIndex: 'awayTeam', sortDirections: ['ascend', 'descend'],
                                            render: (text, record) => (
                                                <TeamColumn text={text} record={record} homeOrAway='AWAY' />
                                            ),
                                        },
                                        {
                                            title: 'Venue', width: '20%', dataIndex: 'venue', sortDirections: ['ascend', 'descend'],
                                            render: (t) => t ? t : '-',
                                        },
                                        {
                                            title: 'Status', width: '20%', sortDirections: ['ascend', 'descend'],
                                            render: (record) => {
                                                if (record.error) {
                                                    return (
                                                        <Tooltip title={record.error?.message ?? JSON.stringify(record.error)}>
                                                            <span className="text-red-500 flex items-center gap-2 cursor-pointer">
                                                                {icons.ErrorTriangle}
                                                                ERROR
                                                            </span>
                                                        </Tooltip>
                                                    );
                                                }
                                                if (record.warnings && record.warnings.length > 0) {
                                                    return (
                                                        <Tooltip title={
                                                            <div className="flex flex-col gap-3">
                                                                {record.warnings.map((w, idx) => (
                                                                    <div key={idx}>{w.message}</div>
                                                                ))}
                                                            </div>
                                                        }>
                                                            <span className="text-orange-500 flex items-center gap-2 cursor-pointer">
                                                                {icons.ErrorTriangle}
                                                                WARNING
                                                            </span>
                                                        </Tooltip>
                                                    );
                                                }
                                            },
                                        },
                                    ].map(c => ({
                                        ...c,
                                        sorter: c.sortDirections ? (a, b) => (a[c.dataIndex] ?? '').localeCompare(b[c.dataIndex]) : null,
                                    }))}
                                    rowKey={(r) => (r.homeTeam + r.awayTeam + r.time)}
                                    dataSource={searchedGames
                                        .filter(game => g == 'all__All' || (game.group ?? '').toUpperCase() == g.toUpperCase())
                                        .filter(game => filter == 'All' || (filter == 'OK' && !game.hasError && !game.hasWarning) || (filter == 'Warnings' && game.hasWarning) || (filter == 'Errors' && game.hasError))
                                        .map(game => ({
                                            ...game,
                                            group: getSchedulerUserFriendlyName(game.group),
                                            homeTeam: getSchedulerUserFriendlyName(game.homeTeam),
                                            awayTeam: getSchedulerUserFriendlyName(game.awayTeam),
                                            venue: getSchedulerUserFriendlyName(game.venue),
                                        }))
                                    }
                                    pagination={false}
                                    showSorterTooltip={false}
                                />
                            </div>
                    })), (a, b) => a.label.localeCompare(b.label))
            }
        />
    )
}

export default SchedulerSimulationGames