import { Button, Checkbox, Modal, Spin } from 'antd'
import useFetchEntity from 'hooks/useFetchEntity';
import React, { useEffect, useState } from 'react'
import icons from 'utils/icons';

const TeamRegistrationDialog_AgreeToTerms = ({ registration, setRegistration }) => {
  const { data, isLoading } = useFetchEntity('Season', registration?.seasonId);

  return (
    <div>
      <h4 className="mt-0">2. Read season proposition</h4>

      {isLoading &&
        <div>
          <Spin />
        </div>
      }

      {!isLoading &&
        <div className="mt-3">
          <Button 
            type="primary"
            size="small" 
            className="flex items-center gap-1"
            onClick={() => {
              window.open(`/embed/season_terms?seasonId=${registration.seasonId}&lang=en`);
              setTimeout(() => {
                setRegistration({
                  ...registration,
                  termsOpen: true,
                });
              }, 1000);
            }}
          >
            <span>Open proposition</span>
            {icons.ExternalLink}
          </Button>

          {registration.termsOpen &&
            <>
              <h4 className="mt-6">3. Agree to terms</h4>

              <div className="mt-3">
                <Checkbox
                  checked={registration.termsAgreed}
                  onChange={(e) => {
                    setRegistration({
                      ...registration,
                      termsAgreed: e.target.checked
                    });
                  }}
                >
                  I agree to the terms and conditions
                </Checkbox>
              </div>

              <div className="mt-1 mb-4">
                <Checkbox
                  checked={registration.feeAgreed}
                  onChange={(e) => {
                    setRegistration({
                      ...registration,
                      feeAgreed: e.target.checked
                    });
                  }}
                >
                  I agree to pay the registration fee
                  {data?.registrationFee != null && typeof data.registrationFee == 'number' &&
                    <span>: <b>{data.registrationFee.toLocaleString()} Kč</b></span>
                  }
                </Checkbox>
              </div>
            </>
          }
        </div>
      }
    </div>
  );
}

export default TeamRegistrationDialog_AgreeToTerms
