import React, { useRef } from 'react'
import { Checkbox, Form, Input, Row, Col, DatePicker, Select, Space } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import useFetchEntities from 'hooks/useFetchEntities';
const { Option } = Select;

const PlayerSelect = ({ defaultValue, onChange }) => {
    const { data: players } = useFetchEntities('Player');

    const PlayerOption = ({ player: p }) => (
        <Space>
            <span role="img" aria-label="Photo">
                🥇
            </span>
            {p.firstName + ' ' + p.lastName}
        </Space>
    );

    return (
        <Select
            className="w-full"
            showAction='focus'
            showSearch
            optionLabelProp="label"
            optionFilterProp="label"
            defaultValue={defaultValue}
            onChange={onChange}
        >
            {players?.map((p, idx) => (
                <Option key={idx} value={p.playerId} label={<PlayerOption player={p} />}>
                    <PlayerOption player={p} />
                </Option>
            ))}
        </Select>
    )
}

export default PlayerSelect