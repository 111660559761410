import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const usePersistentStore = create(
    persist(
        (set, get) => ({
            set: (key) => {
                return (arg) => {
                    if (typeof arg == 'function') {
                        const setter = arg;
                        const result = setter(get()[key]);
                        set({
                            [key]: result,
                        });
                    } else {
                        const value = arg;
                        set({
                            [key]: value,
                        });
                    }
                };
            },

            get: (key) => {
                return get()[key];
            },
        }),
        {
            name: 'persistentStore',
            storage: createJSONStorage(() => localStorage),
        }
    ),
);

export default usePersistentStore;
