import React, { createContext, useContext, useState, useEffect, useRef, useMemo } from 'react';
import useEntities from 'hooks/useEntities';
import RelationsEditable from 'components/RelationsEditable';
import RelationsTable from 'components/RelationsTable';
import { entityColumnsToAntColumns } from 'utils/entityColumnsToAntColumns';
import useIsMobile from 'hooks/useIsMobile';
import icons from 'utils/icons';
import useLoginStore from 'store/loginStore';
import { Badge, Dropdown, Radio } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import useSearchParamsMulti from 'hooks/useSearchParamsMulti';

const EntityChangeRequests = ({ sourceEntityName, sourceEntityId, data, onChange }) => {
  const entities = useEntities();
  const sourceEntity = entities[sourceEntityName];
  const targetEntity = entities.ChangeRequest;
  const isMobile = useIsMobile();
  const hasRole = useLoginStore(s => s.hasRole);
  const isAdmin = hasRole('ADMIN');
  const views =
    isAdmin ?
      [
          { value: 'NEW', label: 'New' },
          { value: 'PROCESSED', label: 'Processed' },
      ]
    :
      [
        { value: 'ALL', label: 'All' },
      ];
  const { useSearchParamSingle } = useSearchParamsMulti();
  const [view, setView] = useSearchParamSingle('entityChangeRequestView', views[0].value);
  const tableData = useMemo(() => {
    switch (view) {
      case 'ALL':
        return data;
      case 'NEW':
        return data?.filter(r => r.status == 'NEW');
      case 'PROCESSED':
        return data?.filter(r => r.status != 'NEW');
      default:
        throw new Error('Invalid view');
    }
  }, [data, view]);

  const ViewSelector = () => (
      <Radio.Group
          size="small"
          value={view}
          onChange={(e) => setView(e.target.value)}
      >
          {views.map((v, idx) => (
              <Radio.Button
                  key={idx}
                  value={v.value}
              >
                {v.label}
              </Radio.Button>
          ))}
      </Radio.Group>
  )

  const changeStatus = async (id, status) => {
    await entities.ChangeRequest.patch(id, { status: status });
    onChange();
  }

  return <RelationsTable
    data={tableData}
    extraButtonBarItems={isAdmin ? <ViewSelector /> : null}
    sourceEntity={sourceEntity}
    targetEntity={targetEntity}
    emptyMessage={`This ${sourceEntity.displayName} does not have any ${targetEntity.displayNamePlural}`}
    sourceEntityId={sourceEntityId}
    onChange={onChange}
    columns={[
      ...entityColumnsToAntColumns(targetEntity, [
        'CreatedAt',
        'CreatedBy',
        'FieldDisplayName',
        'OldDisplayValue',
        'NewDisplayValue',
        'UpdatedAt',
        'UpdatedBy',
        'Status',
      ]),
      ...(isAdmin ? [
        {
          title: '',
          sortDirections: [],
          width: 50,
          // className: 'pointer-events-none',
          fixed: !isMobile ? 'right' : undefined,
          exportable: false,
          render: (record) => {
            return (<Dropdown menu={{
              items: [
                {
                  key: 'NEW',
                  label: 'NEW',
                  onClick: () => changeStatus(record.changeRequestId, 'NEW')
                },
                {
                  key: 'PROCESSED',
                  label: 'PROCESSED',

                  onClick: () => changeStatus(record.changeRequestId, 'PROCESSED')
                },
                {
                  key: 'REJECTED',
                  label: 'REJECTED',
                  onClick: () => changeStatus(record.changeRequestId, 'REJECTED')
                },
              ]
            }} placement="top">
              <span>
                <a
                  className="inline-block cursor-pointer select-none whitespace-nowrap"
                  onClick={(e) => e.preventDefault()}
                >
                  <span className="cursor-pointer">
                    Update
                  </span>
                  <DownOutlined className="ml-1" />
                </a>
              </span>
            </Dropdown>)
          },
        }
      ] : [])
    ]}
  />
}

export default EntityChangeRequests


