import React, { useEffect, useState } from 'react'
import { Button, Form, Input } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import useLoginStore from 'store/loginStore';
import { Link, useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import usePageTitle from 'hooks/usePageTitle';
import LoginLayout from 'components/Layouts/LoginLayout';

const LoginPage = () => {
  usePageTitle('HMS - Log In');

  const login = useLoginStore(s => s.login);
  const [status, setStatus] = useState('INIT');

  const [searchParams] = useSearchParams()
  const redirectTo = searchParams.get('redirectTo');

  useEffect(() => {
    console.log('login initialized')
  }, [])

  const onFinish = async (values) => {
    setStatus('PROGRESS');

    const result = await login({
      ...values,
      rememberMe: true,
    });
    console.log(result);

    switch (result.status) {
      case 200:
        setStatus('SUCCESS');
        if (redirectTo) {
          window.location.href = redirectTo;
        }
        break;
      case 403:
        setStatus('INVALID_CREDENTIALS');
        break;
      default:
        setStatus('UNKNOWN_ERROR');
        break;
    }
  };

  const disableSubmit = (status == 'PROGRESS' || status == 'SUCCESS');

  return (
    <LoginLayout>
        <Form
          onFinish={onFinish}
        >
          <div className="w-[300px] grid grid-cols-1 mx-auto">
            <div>
              <Form.Item
                name="email"
                rules={[{ required: true, message: 'Please enter your email!' }]}
              >
                <Input
                  className="w-full"
                  placeholder="E-mail address"
                  prefix={<UserOutlined className="text-gray-400 mr-1" />}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input className="w-full" placeholder="Password" type="password" prefix={<LockOutlined className="text-gray-400 mr-1" />} />
              </Form.Item>
            </div>
            <span>
              {!disableSubmit && <Button className="w-full" type="primary" htmlType="submit" >Log in</Button>}
              {disableSubmit && <Button className="w-full" type="primary" htmlType="submit" loading={true}> </Button>}
            </span>
            <div className="mt-4 text-center">
              <Link to="/auth/forgotPassword" className="text-[#d7ecff]">Forgot Password</Link>
            </div>
            <div className="h-10 mt-5 text-center">
              {status == 'INVALID_CREDENTIALS' &&
                <span className="mb-4 text-red-500 text-center">
                  Invalid email or password
                </span>
              }
              {status == 'UNKNOWN_ERROR' &&
                <span className="mb-4 text-red-500 text-center">
                  Error during log in - please try again
                </span>
              }
            </div>
          </div>
        </Form>
    </LoginLayout>
  )
}

export default LoginPage
