import React from 'react'

const IGImg = (props) => {
  const src = props.src ?? '/1x1-transparent.png';

  return (
    src == null
      ?
        <span className={`inline-block ${props.className ?? ''}`}></span>
      :
        <img
          {...props}
          src={src.startsWith('/') ? src : `${process.env.REACT_APP_IMAGE_PROXY_URL}?url=${src}`} 
          crossOrigin="anonymous"
        />
  )
}

export default IGImg