const EmbedCardVideo = ({ youtubeVideoUrl }) => {
  const getVideoId = (url) => {
    try {
      //https://www.youtube.com/watch?v=MisSWdxqyBo
      var params = new URLSearchParams(new URL(url).search);
      const v1 = params.get('v');
      if (v1) {
        return v1;
      }

      //https://www.youtube.com/live/MisSWdxqyBo?si=deOH78Tcaipl9vdq
      const videoPart = url.split('/').slice(-1)[0];
      if (videoPart) {
        const v2 = videoPart.split('?')[0];
        if (v2) {
          return v2;
        }
      }

      console.log('Cannot decode youtube URL:', url);
    } catch (error) {
      console.log('Error parsing youtube URL:', error);
      return null;
    }
  }
  return (
    youtubeVideoUrl && getVideoId(youtubeVideoUrl) && (
      <div className="bg-white box-border pt-8 font-roboto-condensed w-full max-w-[800px]">
        <div className="px-10">
          <div className="font-roboto-condensed text-xl pb-5">
            Video
          </div>
        </div>
        <div className="youtubeVideoWrapper">
          <iframe src={`https://www.youtube.com/embed/${getVideoId(youtubeVideoUrl)}?controls=1`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
    )
  );
};

export default EmbedCardVideo;
