import React, { createContext, useContext, useState, useEffect } from 'react';
import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useEntities from 'hooks/useEntities';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import useLoadEntity from 'hooks/useLoadEntity';
import HmsEntityFormGridFullWidth from 'components/Form/HmsEntityFormGridFullWidth';
import HmsField from 'components/Form/HmsField';
import FormSelect from 'components/Form/FormSelect';
import useFetchEntities from 'hooks/useFetchEntities';
import { Button, Card, Cascader, Form, Space, Spin, Tabs } from 'antd';
import PlayerSelect from 'components/Form/PlayerSelect';
import PositionSelect from 'components/Form/PositionSelect';
import JerseySelect from 'components/Form/JerseySelect';
import RemoveButton from 'components/RemoveButton';
import FormCascader from 'components/Form/FormCascader';
import { PlusOutlined } from '@ant-design/icons';
import FormCheckbox from 'components/Form/FormCheckbox';
import useInterval from 'use-interval';
import { Checkbox, Input, Row, Col, DatePicker, Select } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import FormDatePicker from 'components/Form/FormDatePicker';
import FormRangePicker from 'components/Form/FormRangePicker';
import TeamSelect from 'components/Form/TeamSelect';
import EntityLogo from 'components/EntityLogo';

const GameResults = ({ data, id, gameResult, isLoading }) => {
    return (
        <>
            {isLoading &&
                <div className="min-h-[350px] w-full flex items-center justify-center">
                    <Spin />
                </div>
            }
            {!isLoading && gameResult &&
                <div className="grid grid-cols-10 gap-y-3">
                    <div className="col-span-2"></div>
                    <div className="col-span-4 text-center font-bold text-md">
                        {data?.HomeTeam?.name}
                    </div>
                    <div className="col-span-4 text-center font-bold text-md">
                        {data?.AwayTeam?.name}
                    </div>
                    <div className="col-span-2"></div>
                    <div className="col-span-4 font-bold text-md flex items-center justify-center">
                        <EntityLogo entity={data?.HomeTeam} size={128} />
                    </div>
                    <div className="col-span-4 font-bold text-md flex items-center justify-center">
                        <EntityLogo entity={data?.AwayTeam} size={128} />
                    </div>
                    {[
                        ['Goals', 'GameEventGoal'],
                        ['Penalties', 'GameEventPenalty'],
                        ['Penalty Shots', 'GameEventPenaltyshot'],
                        ['Saves', 'GameEventSave'],
                        ['Face Offs', 'GameEventFaceOff'],
                        ['Injuries', 'GameEventInjury'],
                    ].map(([name, type, overwriteHomeField, overwriteAwayField]) => (
                        <>
                            <div className="col-span-2 text-right text-md">
                                {name}:
                            </div>
                            <div className="col-span-4 text-center text-md">
                                <span className="flex gap-2 justify-center text-lg">
                                    <span className="font-bold w-4">
                                        {gameResult[`HomeTeam_${type}_Count`]}
                                    </span>
                                    <span className="font-normal w-4">
                                        {data?.overwriteEnabled && (gameResult[`HomeTeam_${type}_Count_Original`] !== undefined) &&
                                            <>({gameResult[`HomeTeam_${type}_Count_Original`]})</>
                                        }
                                    </span>
                                </span>
                            </div>
                            <div className="col-span-4 text-center text-md">
                                <span className="flex gap-2 justify-center text-lg">
                                    <span className="font-bold w-4">
                                        {gameResult[`AwayTeam_${type}_Count`] ?? gameResult[`AwayTeam_${type}_Count`]}
                                    </span>
                                    <span className="font-normal w-4">
                                        {data?.overwriteEnabled && (gameResult[`AwayTeam_${type}_Count_Original`] !== undefined) &&
                                            <>({gameResult[`AwayTeam_${type}_Count_Original`]})</>
                                        }
                                    </span>
                                </span>
                            </div>
                        </>
                    ))}
                </div>
            }
        </>
    )
}

export default GameResults;
