import useEntities from 'hooks/useEntities';
import HmsEntityTableController from 'components/HmsEntityTableController';
import useFetchEntities from 'hooks/useFetchEntities';
import Dot from 'components/Dot';

const Teams = () => {
  const { data: players } = useFetchEntities('Team', { onlyAttributes: 'teamId', extraAttributes: 'NewChangeRequestCount', filters: { 'HasNewChangeRequest': 1 } });
  const hasWithChangeRequests = players?.length > 0;

  const entities = useEntities();
  const filterGroups = null;
  const showColumnsDefault = [
    'NameWithLogo',
    'nick',
    'short',
    'externalId',
    'foundDate',
    'Status',
    'ActivePlayerCount',
    'TotalPlayerCount',
    'TeamColor1',
    'TeamColor2',
    'TeamColor3',
    'JerseyColor1',
    'JerseyColor2',
    'JerseyColor3',
    'NewChangeRequestCount',
  ];

  return (
    <HmsEntityTableController
      entity={entities.Team}
      filterGroups={filterGroups}
      columnSets={[
        {
          name: 'All',
          columns: showColumnsDefault,
        },
        {
          name: 'Newly registered',
          filters: { status: ['DRAFT', 'PREAPPROVED'] },
          columns: showColumnsDefault,
        },
        {
          name: <Dot show={hasWithChangeRequests} dotClassName="top-[-6px] right-[-10px]">Change Requests</Dot>,
          key: 'ChangeRequests',
          shitFilter: (r) => r.NewChangeRequestCount > 0,        
          columns: showColumnsDefault,
        },
      ]}
    />
  )
}

export default Teams