import useEntities from 'hooks/useEntities';
import RelationsEditable from 'components/RelationsEditable';
import { Button } from 'antd';
import icons from 'utils/icons';
import { useState } from 'react';
import LinkRelationModal from 'components/Modals/LinkRelationModal';
import Confirm from 'components/Confirm';
import RelationsTable from 'components/RelationsTable';
import PlayerJerseyModal from 'components/Player/PlayerJerseyModal';
import useLoginStore from 'store/loginStore';

const PlayerJerseys = ({ sourceEntityId, sourceEntityData, teams, data, onChange }) => {
    const entities = useEntities();
    const [isJerseyModalOpen, setJerseyModalOpen] = useState(false);
    const hasRole = useLoginStore(s => s.hasRole);
    const isAdmin = hasRole('ADMIN');

    const removeClicked = async (id) => {
        await entities.Jersey.delete(id);
        onChange();
    }

    const createClicked = async () => {
        setJerseyModalOpen(true);
    }

    const jerseModalOk = async (data) => {
        await entities.Jersey.create({
            ...data,
            name: data.teamName + ' - ' + data.number,
            playerId: sourceEntityId,
        });
        onChange();
        setJerseyModalOpen(false);
    }

    const jerseModalCancel = async () => {
        setJerseyModalOpen(false);
    }

    return (
        <div>
            <PlayerJerseyModal
                teams={teams}
                open={isJerseyModalOpen}
                onOk={jerseModalOk} 
                onCancel={jerseModalCancel}
            />

            <RelationsTable
                data={data}
                sourceEntity={entities.Player}
                targetEntity={entities.Jersey}
                emptyMessage="This player does not have any Jerseys"
                addButton={(isAdmin || sourceEntityData?.status == 'DRAFT') && {
                    label: 'Add Jersey',
                    icon: icons.Link,
                    onClick: createClicked,
                }}
                columns={[
                    { ...entities.Jersey.columns['TeamWithLogo'], defaultSortOrder: 'ascend' },
                    { ...entities.Jersey.columns['number'] },
                ]}
                sourceEntityId={sourceEntityId}
                removeClicked={(isAdmin || sourceEntityData?.status == 'DRAFT') && removeClicked}
                rowClickable={false}
            />
        </div>
    );
}

export default PlayerJerseys
