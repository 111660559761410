import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useEntities from 'hooks/useEntities';
import useLoadEntity from 'hooks/useLoadEntity';
import useFetchData from 'hooks/useFetchData';
import useFetchEntities from 'hooks/useFetchEntities';
import useFetchEntity from 'hooks/useFetchEntity';
import { Button, Card, Divider, Input, Modal, Spin, Tabs, Tooltip } from 'antd';
import GameInfo from 'components/Game/GameInfo';
import GameLineup from 'components/Game/GameLineup';
import GamePayments from 'components/Game/GamePayments';
import GameNews from 'components/Game/GameNews';
import GameResults from 'components/Game/GameResults';
import { BeatLoader } from 'react-spinners';
import icons from 'utils/icons';
import GameAwards from 'components/Game/GameAwards';
import HmsTabbedTables from 'components/HmsTabbedTables';
import GameEvents from 'components/Game/GameEvents';
import openInNewTab from 'utils/openInNewTab';
import { useState } from 'react';
import { formatDate, formatTime } from 'utils/generateEntityColumns';
import moment from 'moment';
import { BsFillPersonFill } from 'react-icons/bs';
import TextArea from 'antd/es/input/TextArea';
import copy from 'copy-to-clipboard';
import GameInviteModal from 'components/Modals/GameInviteModal';
import { formatTimeString } from 'utils/timeString';
import HmsStatusTag from 'components/HmsStatusTag';

const GameTeamManager = () => {
  const entities = useEntities();
  const entity = entities.Game;
  const { id, isLoading, isNotFound, isError, data, relationsData, reload, reloadRelations } = useLoadEntity({
    entity,
    relations: [
      'Awards',
      'Lineups',
      'Lineups>Player',
      'Lineups>ListPosition',
      'Venue'
    ].join(','),
  });

  return (
    <>
      <HmsEntityFormController
        {...{ entity, id, isLoading, isError, isNotFound, data }}

        canSave={false}
        canDelete={false}

        breadcrumbs={[
          { title: 'Home', icon: icons.Home, link: '/' },
          { title: 'Games', icon: icons.Game, link: '/games_team_manager' },
          { title: data && data.gameId ? (entities.Game.getTitle ? entities.Game.getTitle(data) : data.name) : 'New', icon: icons.Game },
        ]}

        form={
          data && relationsData && <>
            <div className="grid grid-cols-[auto,1fr] gap-x-4 gap-y-2">
              <div className="">Venue:</div>
              <div className="font-bold">{relationsData.Venue?.name}</div>
              <div className="">Start time:</div>
              <div className="font-bold">{formatDate(data.startDate)} – {formatTime(data.startTime)}</div>
              <div className="">Status:</div>
              <div className="font-bold"><HmsStatusTag status={data.status} entity="Game" /></div>
            </div>

          </>
        }

      footer={id != '_new_' && (
        <div className="mt-4 min-h-[calc(100vh-120px)]">
          <div className="mt-4">
            <HmsTabbedTables
              className="no-tab-padding w-[calc(100vw-75px)] desktop:w-[calc(100vw-280px)]"
              tables={[
                {
                  label: `Line up`,
                  children: <GameLineup data={data} readonly={data?.status == 'RUNNING' || data?.status == 'FINISHED'} />,
                },
              ].map(tab => ({ title: tab.label, children: tab.children }))}
            />
          </div>
        </div>
      )}
      />
    </>
  );
}

export default GameTeamManager
