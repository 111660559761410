import React from 'react'

// Preload fonts - otherwise fonts will be loaded after data is loaded and it will cause flickering

const PreloadEmbedFonts = () => {
  return (
    <div className="absolute top-0 left-0 w-0 h-0">
      <span className="text-transparent font-roboto-condensed"></span>
      <span className="text-transparent font-roboto-condensed font-bold"></span>
      <span className="text-transparent font-roboto-condensed font-light"></span>
      <span className="text-transparent font-roboto-flex"></span>
      <span className="text-transparent font-score"></span>
    </div>
  )
}

export default PreloadEmbedFonts