import { Button, Input, Modal, Spin, Table } from 'antd'
import useFetchEntities from 'hooks/useFetchEntities'
import React, { useEffect, useRef, useState } from 'react'
import icons from 'utils/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { getColumnFtindex, matchSearchString } from 'utils/getColumnFtindex';
import useHotKey from 'hooks/useHotKey';
import { autoAttributesFromColumns, autoExtraAttributesFromColumns, autoRelationsFromColumns } from 'utils/autoColumns';

const LinkRelationModal = ({ data: paramData, entity, columns: columnsParam, open, onOk, onCancel, relations: relationsParam, filters, okButtonText = 'Link with $number $entities', size, width = 600 }) => {
  const columns =
    columnsParam
    ?? entity.defaultColumnsForAddRelationsModal?.map(c => entity.columns[c])
    ?? [{ ...entity.columns[entity.defaultColumnName] }];

  if (columns[0] && typeof columns[0] == 'object') {
    columns[0].defaultSortOrder = 'ascend';
  }

  const relations =
    relationsParam
    ?? autoRelationsFromColumns(entity, columns);

  // TODO: Add 'onlyAttributes' based on columns...

  const { data: loadedData } = useFetchEntities(paramData == null ? entity?.name : null, { relations, filters, listOnly: true });
  const [dataFiltered, setDataFiltered] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [okButtonBusy, setOkButtonBusy] = useState(false);
  const [search, setSearch] = useState('');
  const autoFocusInputRef = useRef(null);

  const data = paramData ?? loadedData;
  const okDisabled = selectedRowKeys.length == 0;
    
  useHotKey('Enter', () => {
    // console.log('Enter:', 'LinkRelationModal', open ? '' : 'IGNORED');
    if (open) {
      handleOk();
    }
  });

  // clear selection on open
  useEffect(() => {
    if (open) {
      setSelectedRowKeys([]);
      setOkButtonBusy(false);
      setSearch('');
    }
  }, [open]);

  // auto-focus on open
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        autoFocusInputRef.current?.focus();
      })
    }
  }, [open])

  useEffect(() => {
    setDataFiltered(data
      ?.filter(d => {
        if (search.trim() == '') {
          return true;
        }

        const ftIndex = getColumnFtindex(columns, d);

        return matchSearchString(ftIndex, search);
        return false;
        // return search.trim() == '' || (entity.columns[entity.defaultColumnName].searchValue(d) ?? '').toLowerCase().indexOf(search.toLowerCase()) != -1;
      })
    );
  }, [data, search]);

  const onSelectChange = (visibleSelectedRowKeys) => {
    // Note: the selectedRowKeys are returned by Ant.D Table only within the context of the currently fitlered data
    // (it doesn't know about other selected rows, that are currently invisible due to filters)
    // We need to add those to the selection
    const invisibleSelectedRowKeys = selectedRowKeys
      .filter(key => dataFiltered.map(d => d[entity.primaryKey]).indexOf(key) == -1);

    const allSelectedRowKeys = invisibleSelectedRowKeys.concat(visibleSelectedRowKeys);
    setSelectedRowKeys(allSelectedRowKeys);
  };

  const handleOk = () => {
    if (onOk && !okDisabled && !okButtonBusy) {
      setOkButtonBusy(true);
      onOk(selectedRowKeys, data.filter(d => selectedRowKeys.indexOf(d[entity.primaryKey]) != -1));
    }
  }

  const handleCancel = () => {
    if (onCancel) onCancel();
  }

  const divRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      if (divRef.current) {
        setDivHeight(divRef.current.offsetHeight);
      }
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const paginationNeeded = dataFiltered?.length > 150;

  return (
    <>
      <Modal
        title={null}
        okButtonProps={{ size: "small" }}
        cancelButtonProps={{ size: "small" }}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={false}
        transitionName=""
        footer={null}
        centered
        width={width}
      >
        {entity &&
          <div ref={divRef}>
            <div>
              <div className="flex items-center mb-2">
                <span className="font-bold flex items-center gap-2">
                  {icons[entity.name]}{entity.displayNamePlural}
                </span>
                <span className="ml-auto flex items-center gap-2">
                  <span className="whitespace-nowrap">
                    Count: <span className="font-bold">{dataFiltered?.length?.toLocaleString() ?? 0}</span>
                  </span>
                  <Input
                    ref={autoFocusInputRef}
                    placeholder="Search"
                    size="small"
                    className="p-[1px] px-[8px]"
                    allowClear
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </span>
              </div>
              <Table
                // className="no-sort-highlight w-full mb-6 max-h-[calc(80vh-200px)] desktop:max-h-[calc(85vh-135px)] overflow-auto"
                // className="no-sort-highlight w-full h-[calc(80vh-200px)] desktop:h-[calc(85vh-135px)] overflow-auto"
                className={`no-sort-highlight w-full overflow-auto ${size == 'large' ? 'h-[calc(100svh-150px)] desktop:h-[calc(100vh-135px)]' : 'h-[calc(80vh-200px)] desktop:h-[calc(85vh-135px)]'}`}
                showSorterTooltip={false}
                columns={columns}
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys,
                  onChange: onSelectChange,
                  hideSelectAll: true
                }}
                dataSource={
                  dataFiltered?.map(d => ({ ...d, key: d[entity.primaryKey] }))
                }
                locale={{
                  emptyText: dataFiltered
                    ? <span className='h-6 flex items-center justify-center max-w-[calc(100vw-25px)] desktop:max-w-[calc(100vw-233px)]'>No data</span>
                    : <span className='h-6 flex items-center justify-center max-w-[calc(100vw-25px)] desktop:max-w-[calc(100vw-233px)]'><Spin delay={0}></Spin></span>
                }}
                size={paginationNeeded || size == "large" ? "small" : "medium"}
                onRow={(record) => ({
                  onClick: () => {
                    const index = selectedRowKeys.indexOf(record.key);
                    const newSelectedRowKeys = [...selectedRowKeys];
                    if (index === -1) {
                      newSelectedRowKeys.push(record.key);
                    } else {
                      newSelectedRowKeys.splice(index, 1);
                    }
                    setSelectedRowKeys(newSelectedRowKeys);
                  },
                })}
                rowClassName='hover:cursor-pointer smaller-row-spacing select-none'
                pagination={
                  paginationNeeded ?
                    {
                      position: ['bottomCenter'],
                      defaultPageSize: 50,
                      showSizeChanger: false,
                    } : false
                }
                scroll={{
                  y: divHeight - (paginationNeeded ? 155 : 120),
                }}
              />
            </div>
            <div className="flex gap-3">
              <Button
                className="ml-auto"
                size="small"
                onClick={handleCancel}
              >Cancel</Button>
              <Button
                size="small"
                type="primary"
                disabled={okDisabled}
                onClick={handleOk}
              >{okButtonBusy ? <LoadingOutlined spin /> : null} {okButtonText.replace('$number', selectedRowKeys.length).replace('$entities', selectedRowKeys.length != 1 ? entity.displayNamePlural : entity.displayName)}</Button>
            </div>
          </div>
        }
      </Modal>
    </>
  )
}

export default LinkRelationModal