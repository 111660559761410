import React, { createContext, useContext, useState, useEffect } from 'react';
import HmsEntityFormGridFullWidth from 'components/Form/HmsEntityFormGridFullWidth';
import HmsField from 'components/Form/HmsField';
import FormSelect from 'components/Form/FormSelect';
import { HmsFormContext } from 'components/Form/HmsFormContext';
import icons from 'utils/icons';

const GameOverwrite = () => {
  return (
    <>
      <HmsFormContext.Consumer>
        {({ data, setFieldValue }) =>
          <GameOverwriteInner data={data} setFieldValue={setFieldValue} />
        }
        </HmsFormContext.Consumer>
    </>
  )
}

const GameOverwriteInner = ({ data, setFieldValue }) => {
  useEffect(() => {
    if (data?.overwriteKeepLineup == false) {
      setFieldValue('overwriteKeepStats', false);
    }
  }, [data?.overwriteEnabled, data?.overwriteKeepLineup]);

  return (
    <>
      <div>
        <HmsEntityFormGridFullWidth>
          <div className="flex items-center gap-3">
            <HmsField
              type="BOOLEAN_SWITCH"
              name="overwriteEnabled"
              label={null}
            />
            <span
              className="flex items-center cursor-pointer mb-2"
              onClick={() => {setFieldValue('overwriteEnabled', !data?.overwriteEnabled)}}
            >
              Overwrite Active
            </span>
          </div>
        </HmsEntityFormGridFullWidth>
      </div>

      {data?.overwriteEnabled == true &&
        <>
          <div className="bg-[#E6F4FF] p-4 pb-2 mb-4 mt-2 rounded-lg">
            <div className="mb-5 font-bold">Statistics preferences:</div>
            <div className="inline-grid gap-x-8 grid-cols-[auto_auto] items-center">
              <span>
                <span className="inline-block mb-2">Do you want to keep lineups and count the Game played to player statistics?</span>
              </span>
              <span>
                <HmsField
                  type="BOOLEAN_YESNO"
                  name="overwriteKeepLineup"
                  label={null}
                />
              </span>
              <span>
                <span
                  className={"inline-block mb-2" + (data?.overwriteKeepLineup ? "" : " text-gray-400")}
                >
                  Do you want to keep players statistics (goals, penalties, points)?
                </span>
              </span>
              <span>
                <HmsField
                  type="BOOLEAN_YESNO"
                  name="overwriteKeepStats"
                  fieldItemProps={{
                    disabled: !data?.overwriteKeepLineup
                  }}
                  label={null}
                />
              </span>
            </div>
          </div>

          <div className="bg-[#E6F4FF] p-4 pb-2 mb-4 rounded-lg">
            <div className="mb-5 font-bold">Set overwrites:</div>
            <div className="inline-grid grid-cols-4 gap-x-4 gap-y-1 items-center">
              <span></span>
              <span className="mb-2">Home Team</span>
              <span className="mb-2">Away Team</span>
              <span></span>
              {[
                ['Score', 'overwriteHomeScore', 'overwriteAwayScore', true, false],
                ['Points', 'overwriteHomePoints', 'overwriteAwayPoints', true, false],
                ['Saves', 'overwriteHomeSaves', 'overwriteAwaySaves', false, true],
                ['Face Offs', 'overwriteHomeFaceOffs', 'overwriteAwayFaceOffs', false, true],
                ['Penalties', 'overwriteHomePenalties', 'overwriteAwayPenalties', false, true],
              ].map(([label, field1Name, field2Name, required, canClear], index) => (
                <React.Fragment key={index}>
                  <span className="text-right mb-2">{label}</span>
                  <span><span className="block w-[100px]"><HmsField name={field1Name} label={null} required={required} /></span></span>
                  <span><span className="block w-[100px]"><HmsField name={field2Name} label={null} required={required} /></span></span>
                  <span>
                    {canClear && <a href="#" onClick={(e) => {
                      setFieldValue(field1Name, null);
                      setFieldValue(field2Name, null);
                      e.preventDefault();
                    }} className="flex gap-1 mb-2">{icons.Trash}Clear</a>}
                  </span>
                </React.Fragment>
              ))}
            </div>
          </div>
        </>
      }
    </>
  )
}

export default GameOverwrite;
