export function entityColumnsToAntColumns(entity, showColumns) {
  const allColumns = entity.columns;
  const showColumnsList = showColumns ?? entity.defaultColumns;

  let columns = showColumnsList
        .map((colDef, idx) => {
          switch (typeof colDef) {
            case 'string':
              const col = allColumns[colDef];
              if (col) {
                return {
                  sortDirections: [],
                  ...col,
                }
              }
            case 'object':
              if (colDef.column) {
                const col = {
                  ...allColumns[colDef.column],
                  ...colDef
                };
                return col;
              } else {
                return {
                  title: colDef.title,
                  titleTooltip: colDef.titleTooltip,
                  defaultSortOrder: colDef.defaultSortOrder,
                  sorter: colDef.sorter,
                  sortDirections: colDef.sortDirections,
                  align: colDef.align,
                  width: colDef.width,
                  className: colDef.className,
                  dataIndex: colDef.dataIndex,
                  render: colDef.render,
                };
              }
          }
        })
        .filter(c => c != null);

  // columns = columns.map(c => ({
  //   ...c,
  //   width: c.width ? c.width : ('' + Math.round(100 / (columns.length ?? 1)) + '%'),
  // }));

  let hasSort = false;
  for (const col of columns) {
    if (col.defaultSortOrder) {
      hasSort = true;
    }
  }
  if (!hasSort && columns.length > 0) {
    columns[0].defaultSortOrder = 'ascend';
  }

  return columns;
}
