import React from 'react'
import Breadcrumbs from 'components/Breadcrumbs';
import { Spin, Table, Card, Button, Layout } from 'antd';
import { Link } from 'react-router-dom';
import icons from 'utils/icons';
import DataNotFound from 'components/DataNotFound';
import ErrorIcon from 'components/ErrorIcon';
const { Header, Content, Sider } = Layout;

const HmsFormLayout = ({ isLoading, isError, isNotFound, breadcrumbs, children }) => {
    if (isNotFound) {
        return <DataNotFound />;
    }

    return (
        <>
            {isLoading && !isError &&
                <Content className="flex items-center justify-center">
                    <Spin />
                </Content>
            }

            {isError &&
                <Content className="flex items-center justify-center">
                    <ErrorIcon />
                </Content>
            }

            {!isLoading && !isError &&
                <Content className="m-3 desktop:m-4 desktop:mr-0">  { /* desktop:mr-0 is to prevent unwanted horizontal scrollbar */ }
                <Breadcrumbs items={breadcrumbs} />

                    {children}
                </Content>
            }
        </>
    )
}

export default HmsFormLayout