import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/* Source:
   https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition?page=1&tab=scoredesc#tab-top
*/

export default function RouterScrollToTop() {
    const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}