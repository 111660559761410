import React, { useRef } from 'react'
import { Checkbox, Form, Input, Row, Col, DatePicker, Select, Space } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import useFetchEntities from 'hooks/useFetchEntities';
import useLists from 'hooks/useLists';
import NullableSelect from 'components/Form/NullableSelect';
const { Option } = Select;

const JerseySelect = ({ defaultValue, onChange }) => {
    let jerseys = [];
    for (var i = 1; i < 100; i++) {
        jerseys.push({
            value: i,
            label: '' + i,
        })
    }

    return (
        <NullableSelect
            className="w-full"
            showAction='focus'
            showSearch
            optionLabelProp="label"
            optionFilterProp="label"
            options={jerseys}
            defaultValue={defaultValue}
            onChange={onChange}
        >
            { }
        </NullableSelect>
    )
}

export default JerseySelect