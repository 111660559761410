import useFetch from 'hooks/useFetch.js';
import useNotificationStore from 'store/notificationStore';
import { useEffect } from 'react';
import useGlobalStore from 'store/globalStore';
import useLoginStore from 'store/loginStore';
import useFetchData from 'hooks/useFetchData';

export default function useFetchEntities (entity, { filters, relations, extraAttributes, onlyAttributes, search, listOnly } = {}) {
  const ORGANIZATION_ID = useGlobalStore(s => s.ORGANIZATION_ID);
  const entitiesDontNeedOrganizationId = useGlobalStore(s => s.entitiesDontNeedOrganizationId);

  filters = filters ?? {};
  if (entitiesDontNeedOrganizationId.indexOf(entity) == -1) {
      filters = { ...filters, 'organizationId': ORGANIZATION_ID };
  }

  const params = new URLSearchParams();
  params.append('entity', entity);
  params.append('relations', relations ?? '');
  params.append('extraAttributes', extraAttributes ?? '');
  params.append('onlyAttributes', onlyAttributes ?? '');
  params.append('search', search ?? '');
  if (listOnly) {
    params.append('listOnly', 'true');
  }
  for (const [key, value] of Object.entries(filters)) {
    if (value !== undefined) {
      params.append(key, JSON.stringify(value));
    }
  }

  const { data, status, isLoading, isNotFound, isError, reload } = useFetchData(
      entity
        ? `/admin/entities?${params.toString()}`
        : null
  );

  return { data, status, isLoading, isNotFound, isError, reload };
}