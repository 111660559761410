import React, { useEffect, useState } from 'react'
import { Content } from 'antd/es/layout/layout';

const LoginLayout = ({ children }) => {
  return (
    <Content className="bg-cover bg-center bg-no-repeat" style={{backgroundImage: "url('/login-bg-70.jpg')"}}>
      <div className="w-full h-full min-h-screen flex flex-col items-center justify-center">
        <div>
          <img src="/login-logo.svg" className="w-100 mb-5" alt="HMS Logo" />
        </div>
        {children}
      </div>
    </Content>
  )
}

export default LoginLayout
