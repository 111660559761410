import { Form, message } from 'antd';
import ChangeRequestDialog from 'components/ChangeRequestDialog';
import { HmsFormContext } from 'components/Form/HmsFormContext';
import HmsFormInner from 'components/Form/HmsFormInner';
import HmsFormLayout from 'components/HmsFormLayout';
import copy from 'copy-to-clipboard';
import usePageTitle from 'hooks/usePageTitle';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useLocationStore from 'store/locationStore';
import useLoginStore from 'store/loginStore';
import useNotificationStore from 'store/notificationStore';
import openInNewTab from 'utils/openInNewTab';

const HmsEntityFormController = forwardRef(({ isLoading, isReloading, isNotFound, isError,
  data: loadedData, reload, parentData, entity, id, form, buttons, footer, header,
  afterSave, systemButtons, onDataChanged, breadcrumbs,
  canSave = true, canDelete = true, canClone = false, beforeSave, initialValues,
  canCreateChangeRequest, changeRequestFields, reloadChangeRequests, onValuesChange },
  ref) => {
  const formRef = useRef();
  const navigate = useNavigate();
  const [isDirty, setIsDirty] = useState(false);
  const [data, setData] = useState(null);
  const [formDisabled, setFormDisabled] = useState(false);
  const [messageApi, messageContextHolder] = message.useMessage();
  const getPreviousLocationFromHistory = useLocationStore(state => state.getPreviousLocationFromHistory);
  const location = useLocation();
  const replaceNextLocation = useLocationStore(state => state.replaceNextLocation);
  const roles = useLoginStore(state => state.roles);
  const addMessage = useNotificationStore(state => state.addMessage);
  const [formInitiallySet, setFormInitiallySet] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [lastValidationErrors, setLastValidationErrors] = useState([]);
  usePageTitle(entity.displayName + (loadedData && entity.getTitle ? ' - ' + entity.getTitle(loadedData) : ''));

  useImperativeHandle(ref, () => ({
    setFieldValue: (name, value) => {
      if (formRef.current) {
        formRef.current.setFieldValue(name, value);
      } else {
        console.log('?????? 1');
        setData((state) => ({ ...state, [name]: value }));
      }
    },
    getFieldValue: (name, value) => {
      if (formRef.current) {
        formRef.current.getFieldValue(name, value);
      } else {
        console.log('?????? 2');
        return data[name];
      }
    },
    getFieldsValue: () => {
      if (formRef.current) {
        return formRef.current.getFieldsValue();
      } else {
        console.log('?????? 3');
        return data;
      }
    }
  }));

  useEffect(() => {
    if (isLoading == false) {
      if (id == '_new_') {
        let newData = {};
        if (entity.defaultValues) {
          newData = entity.defaultValues;
        }
        if (entity.initNew) {
          newData = entity.initNew(parentData, loadedData);
        }
        if (initialValues) {
          newData = { ...newData, ...initialValues };
        }
        setData(newData || {});
      } else {
        setData(loadedData || {});
      }
      setFormInitiallySet(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isReloading == false) {
      if (formInitiallySet) {
        console.log('isReloading finished - setting form state:', loadedData)
        setData(loadedData || {});
      }
    }
  }, [isReloading]);

  function storeValidationErrors(invalidFields) {
    if (invalidFields && invalidFields.errorFields) {
      setLastValidationErrors(invalidFields.errorFields ?? []);
    } else {
      setLastValidationErrors([]);
    }
  }

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setFieldsValue(data);

      if (id != '_new_') {
        formRef.current?.validateFields()
          .then(() => {
            storeValidationErrors(false);
          })
          .catch((err) => {
            console.log('Validation error', err)
            storeValidationErrors(err);
          });
      }

    }
    if (onDataChanged) {
      onDataChanged(data);
    }
  }, [data]);

  const saveButtonClick = async () => {
    try {
      await formRef.current.validateFields()
      storeValidationErrors(false);
    } catch (err) {
      console.log('Validation failed:', err);
      storeValidationErrors(err);
      addMessage({ text: <>Validation failed.<br />Please fix field errors before save.</>, type: 'error' });
      return;
    }

    setFormDisabled(true);

    let dataToSave = { ...data };
    if (beforeSave) {
      dataToSave = beforeSave(dataToSave);
    }

    delete dataToSave.logoMetadata; /* need to prevent logoMetadata from being overwritten when saving the form */
    delete dataToSave.logoUrl;      /* need to prevent logoUrl from being overwritten when saving the form */

    const result = await entity.save(dataToSave);

    if (result.success) {
      const targetUrl = entity.entityUrl(result.data);
      if (location.pathname != targetUrl) {
        replaceNextLocation(true);
        navigate(entity.entityUrl(result.data), { replace: true });
      }

      setData(result.data);
      formRef.current.setFieldsValue(result.data);
      setIsDirty(false);
    }

    setFormDisabled(false);

    if (reload) {
      reload();
    }

    if (afterSave) {
      afterSave();
    }
  }

  const saveAndCloseButtonClick = async () => {
    try {
      await formRef.current.validateFields()
      storeValidationErrors(false);
    } catch (err) {
      storeValidationErrors(err);
      return;
    }

    setFormDisabled(true);

    const result = await entity.save(data);

    console.log(result);

    if (result.success) {
      setIsDirty(false);
      setFormDisabled(false);

      const backUrl = getPreviousLocationFromHistory();
      if (backUrl) {
        navigate(backUrl);
        return;
      }
      if (window.opener) {
        window.close();
        return;
      }
      navigate(entity.entitiesUrl());
    }
  };

  // const closeButtonClick = async () => {
  //   const backUrl = getPreviousLocationFromHistory();
  //   if (backUrl) {  // this is the case when the user opened this entity through a link
  //     console.log('navigate -1')
  //     navigate(-1);
  //   } else {
  //     navigate(entity.entitiesUrl());
  //   }
  // }

  const deleteButtonClick = async () => {
    setFormDisabled(true);

    const result = await entity.delete(data);

    console.log(result);

    if (result.success) {
      const backUrl = getPreviousLocationFromHistory();
      navigate(backUrl ?? entity.entitiesUrl());
    }

    setFormDisabled(false);
  }

  const cloneButtonClick = async () => {

    const clone = await entity.clone(id);

    if (clone.status != 200) {
      alert('Error: ' + clone?.body?.message);
      return;
    }

    window.open(clone.url, '_blank');
  }

  const handleFormValuesChange = (changedValues) => {
    setIsDirty(true);
    if (onValuesChange) {
      onValuesChange(changedValues);
    }

    for (let [key, value] of Object.entries(changedValues)) {
      // console.log(`HmsEntityFormController - Key: ${key}, Value: ${JSON.stringify(value)}`, typeof value);
      setData((oldData) => ({ ...oldData, [key]: value }));
    }
  }

  const setFieldValue = (name, value) => {
    formRef.current.setFieldValue(name, value);
    handleFormValuesChange({ [name]: value });
  }

  const systemButtonsFinal = systemButtons ?? [];
  if (roles.includes('ADMIN')) {
    if (id != '_new_') {
      if (systemButtonsFinal.length > 0) {
        systemButtonsFinal.push({ type: 'divider' });
      }
      systemButtonsFinal.push({
        label: 'Copy ID',
        onClick: () => {
          copy(id);
          messageApi.open({
            type: 'success',
            content: `ID "${id}" copied to clipboard.`,
          });
        }, disabled: id == '_new_'
      });
    }
    if (entity.originalLink && data?.createdBy == 'IMPORTED') {
      if (systemButtonsFinal.length > 0) {
        systemButtonsFinal.push({ type: 'divider' });
      }
      systemButtonsFinal.push({
        label: 'Open original item',
        onClick: () => { openInNewTab(entity.originalLink(data)); }
      });
    }
  }

  const [changeRequestOpen, setChangeRequestOpen] = useState(false);

  return (
    <HmsFormContext.Provider value={{ entity, data, setFieldValue }}>
      {messageContextHolder}

      <HmsFormLayout
        isLoading={isLoading || data == null}
        isNotFound={isNotFound}
        isError={isError}
        breadcrumbs={breadcrumbs ?? entity.entityBreadcrumb(data, parentData)}
      >
        {header && (typeof header === 'function') ? header({ isDirty, lastValidationErrors, data }) : header}

        <ChangeRequestDialog
          id={id}
          entity={entity}
          data={data}
          open={changeRequestOpen}
          changeRequestFields={changeRequestFields}
          onOk={() => {
            setChangeRequestOpen(false);
            reloadChangeRequests();
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set('relationsTab', 'ChangeRequests');
            setSearchParams(newSearchParams);
          }}
          onCancel={() => {
            setChangeRequestOpen(false)
          }}
        />

        <HmsFormInner
          title={entity.displayName ?? entity.name}
          isDirty={isDirty || data?.isCloneDraft}
          isSaving={formDisabled}
          saveButtonClick={canSave && saveButtonClick}
          saveAndCloseButtonClick={canSave && saveAndCloseButtonClick}
          canDelete={canDelete && entity.canDelete(data) && !formDisabled && !data?.isCloneDraft}
          deleteButtonClick={canDelete && deleteButtonClick}
          canClone={canClone && entity.clone && entity.canClone(data)}
          cloneButtonClick={canClone && entity.clone && cloneButtonClick}
          buttons={[
            ...(canCreateChangeRequest ? [
              {
                label: 'Request Change',
                onClick: () => setChangeRequestOpen(true),
              }] : []
            ),
            ...((buttons && typeof buttons == 'function' ? buttons({ isDirty, lastValidationErrors }) : buttons) ?? [])
          ]}
          systemButtons={systemButtonsFinal}
        >
          <Form
            layout="vertical"
            initialValues={data}
            onValuesChange={handleFormValuesChange}
            disabled={formDisabled}
            ref={formRef}
          >
            {form}
          </Form>
        </HmsFormInner>
        {footer}
      </HmsFormLayout>
    </HmsFormContext.Provider>
  )
})

HmsEntityFormController.displayName = 'HmsEntityFormController';

export default HmsEntityFormController