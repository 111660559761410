
function WarnMessage({ icon, msg }) {
    return (
        <div>
            <div className="flex items-start gap-3">
                <span>{icon}</span>
                <span>
                    {msg}
                </span>
            </div>
        </div>
    )
}

export default WarnMessage