import React from 'react'
import { CloseCircleFilled } from '@ant-design/icons';

const ErrorIcon = () => {
  return (
    <div className="flex items-center gap-4 text-[#d00]">
      <CloseCircleFilled className="text-2xl" />
      <span>Error loading data</span>
    </div>
  )
}

export default ErrorIcon