import HmsTable from 'components/HmsTable'
import React, { useEffect, useState } from 'react'
import { AppstoreAddOutlined, FileExcelOutlined, DownOutlined, CheckCircleOutlined, FilterOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { Badge, Button, Checkbox, Input, Radio, Select, Switch } from 'antd';
import useSearchParamsMulti from 'hooks/useSearchParamsMulti';
import { useRef } from 'react';
import icons from 'utils/icons';
import { removeAccents } from 'utils/oneLiners';
import extractTextFromJSX from 'utils/extractTextFromJsx';
import moment from 'moment';
import useIsMobile from 'hooks/useIsMobile';
const safeParseFloat = (str) => isNaN(parseFloat(str)) ? 0 : parseFloat(str);

const HmsTableWithFilters = ({ filterEnabled = true, filterStore = 'url', data, columns, ...props }) => {
  const [filteredData, setFilteredData] = useState(null);
  const [uniqueValues, setUniqueValues] = useState(null);
  const { useSearchParamSingle } = useSearchParamsMulti();
  const [filtersStrUrl, setFiltersStrUrl] = useSearchParamSingle('filters', '[]');
  const [isFilterActiveUrl, setIsFilterActiveUrl] = useSearchParamSingle('filtersActive', false, { type: 'boolean' });
  const [filtersStrState, setFiltersStrState] = useState('[]');
  const [isFilterActiveState, setIsFilterActiveState] = useState(false);
  const [filteredCounts, setFilteredCounts] = useState([]);
  const lastFilterColumnRef = useRef(null);
  const isMobile = useIsMobile();

  const filtersStr = filterStore == 'url' ? filtersStrUrl : filtersStrState;
  const setFiltersStr = filterStore == 'url' ? setFiltersStrUrl : setFiltersStrState;
  const isFilterActive = filterStore == 'url' ? isFilterActiveUrl : isFilterActiveState;
  const setIsFilterActive = filterStore == 'url' ? setIsFilterActiveUrl : setIsFilterActiveState;

  const filterableColumns = columns
    .filter(c => c.filterDisabled !== true)
    .map(c => {
      if (c.filterColumnTitle == null && typeof c.title != 'string') {
        return {
          ...c,
          filterColumnTitle: extractTextFromJSX(c.title),
        };
      }
      return c;
    });

  useEffect(() => {
    const filteredCounts = filters.map(f => 0);
    if (data) {
      const fiteredData =
        data
          .filter(d => {
            let allMatches = true;
            for (const filter of filters) {
              let matches = true;

              if (isFilterColumnValid(filter) && isFilterOpValid(filter) &&
                (filter.op == 'isEmpty' || (filter.value != null && (Array.isArray(filter.value) ? filter.value.length > 0 : filter.value.trim() != '' && isFilterValueValid(filter))
                ))) {
                const val = getColumnValue(d, filterableColumns[filter.column]);

                switch (filter.op) {
                  case 'in':
                    const anyMatches = (arr1, arr2) => {
                      for (const a1 of arr1) {
                        for (const a2 of arr2) {
                          if (a1 == a2) {
                            return true;
                          }
                        }
                      }
                      return false;
                    }
                    if (Array.isArray(val)) {
                      if (!anyMatches(val, filter.value)) {
                        matches = false;
                      }
                    } else {
                      if (filter.value.indexOf(val) == -1) {
                        matches = false;
                      }
                    }
                    break;
                  case 'isEmpty':
                    if (!(val == '' || val == null || (Array.isArray(val) && val.length == 0))) {
                      matches = false;
                    }
                    break;
                  default:
                    if (typeof filter.value != 'string') {
                      console.warn('inconsistent filter type ' + typeof filter.value + ' with op ' + filter.op);
                      matches = false;
                    } else {
                      const v = removeAccents(('' + val).toLowerCase());
                      const f = removeAccents(filter.value.toLowerCase());
                      if (f.trim() != '') {
                        switch (filter.op) {
                          case 'contains':
                            if (v.indexOf(f) == -1) {
                              matches = false;
                            }
                            break;
                          case 'equals':
                            if (v != f) {
                              matches = false;
                            }
                            break;
                          case '=':
                          case '<':
                          case '<=':
                          case '>':
                          case '>=':
                            let vdt;
                            let fdt;
                            switch (filter.type) {
                              case 'date':
                              case 'datetime':
                                vdt = moment(v, "YYYY-MM-DD").format('YYYY-MM-DD');
                                fdt = parseFilterDate(f);
                                break;
                              case 'number':
                                vdt = safeParseFloat(v);
                                fdt = safeParseFloat(f);
                                break;
                              default:
                                vdt = v;
                                fdt = f;
                                break;
                            }
                            let result;
                            switch (filter.op) {
                              case '=': result = vdt == fdt; break;
                              case '<': result = vdt < fdt; break;
                              case '<=': result = vdt <= fdt; break;
                              case '>': result = vdt > fdt; break;
                              case '>=': result = vdt >= fdt; break;
                            }
                            if (!result) {
                              matches = false;
                            }
                            break;
                          default:
                            console.warn('Unknown filter op:', filter.op);
                            matches = false;
                        }
                      }
                    }
                }
                if (filter.not) {
                  matches = !matches;
                }
              }
              filteredCounts[filters.indexOf(filter)] += matches ? 1 : 0;
              allMatches = allMatches && matches;
            }
            return allMatches;
          })
      setFilteredData(fiteredData[0]?.key ? fiteredData : fiteredData.map((r, idx) => ({ key: idx, ...r })));
      setFilteredCounts(filteredCounts);
    } else {
      setFilteredData(null);
    }
  }, [data, filtersStr]);

  useEffect(() => {
    const uniqueValues = filterableColumns.map(() => []);
    for (const idx in filterableColumns) {
      const column = filterableColumns[idx];
      if (column.filterType == 'list') {
        const values = new Set();
        for (const record of data ?? []) {
          if (column.filterValues) {
            for (const fv of column.filterValues(record) ?? []) {
              if (fv) {
                values.add(fv);
              }
            }
          } else {
            const value = getColumnValue(record, column);
            if (value) {
              if (Array.isArray(value)) {
                values.add(...value);
              } else {
                values.add(value);
              }
            }
          }
        }
        uniqueValues[idx] = [...values].sort((a, b) => {
          const aLower = a?.toLowerCase();
          const bLower = b?.toLowerCase();
          return (aLower.startsWith('#') - bLower.startsWith('#')) || aLower.localeCompare(bLower);
        });
      }
    }
    setUniqueValues(uniqueValues);
  }, [filteredData]);

  const getColumnValue = (record, column) => {
    if (!record || !column) {
      return null;
    }
    return column.filterValue?.(record) ??
      column.filterValues?.(record) ??
      record[column.dataIndex] ??
      column.searchValue?.(record) ??
      (column.render && extractTextFromJSX(column.render?.(record)));
  }

  const parseFilterDate = (f) => {
    const parts = f?.split('.');
    if (parts?.length == 3) {
      if (parts[0].length >= 1 && parts[0].length <= 2 &&
        parts[1].length >= 1 && parts[1].length <= 2 &&
        parts[2].length == 4) {
        if (moment(f, "DD.MM.YYYY").isValid()) {
          return moment(f, "DD.MM.YYYY").format('YYYY-MM-DD');
        }
      }
    }
    return null;
  }

  const setFilters = (newFilters) => {
    setFiltersStr(JSON.stringify(newFilters));
  }

  const filters = JSON.parse(filtersStr ?? '[]');

  const getValidFilterCount = () => {
    let count = 0;
    for (const filter of filters) {
      if (
        filter.column !== undefined &&
        filter.op != undefined && filter.op != '' &&
        filter.value != undefined && (Array.isArray(filter.value) ? filter.value.length > 0 : filter.value.trim() != '')
      ) {
        count++;
      }
    }
    return count;
  };

  const getColumnFilterOption = (idx) => {
    const column = filterableColumns[idx];
    switch (column?.filterType ?? 'string') {
      case 'list':
        const options = uniqueValues?.[idx]?.map(v => ({ value: v, label: column.filterListKeywords?.[v] ?? v })) ?? [];
        if (column.filterListKeywords) {
          for (const [value, label] of Object.entries(column.filterListKeywords)) {
            if (options.findIndex(o => o.value == value) == -1) {
              options.push({ value, label });
            }
          }
        }
        return {
          type: 'number',
          ops: [
            { label: 'in', value: 'in' },
            { label: 'is empty', value: 'isEmpty' },
          ],
          options,
        };
      case 'date':
      case 'datetime':
        return {
          type: column.filterType,
          ops: [
            { label: '=', value: '=' },
            { label: '<', value: '<' },
            { label: '≤', value: '<=' },
            { label: '>', value: '>' },
            { label: '≥', value: '>=' },
          ]
        };
      case 'number':
        return {
          type: 'number',
          ops: [
            { label: '=', value: '=' },
            { label: '<', value: '<' },
            { label: '≤', value: '<=' },
            { label: '>', value: '>' },
            { label: '≥', value: '>=' },
          ]
        };
      default:
        return {
          type: 'string',
          ops: [
            { label: 'contains', value: 'contains' },
            { label: 'is empty', value: 'isEmpty' },
          ]
        };
    }
  }

  const isFilterColumnEmpty = (filter) => {
    return (filter.column == null);
  }

  const isFilterColumnValid = (filter) => {
    return (filter.column != null && (filterableColumns[filter.column]?.filterColumnTitle ?? filterableColumns[filter.column]?.title) == filter.columnTitle);
  }

  const isFilterOpValid = (filter) => {
    return (filter.op != null); // && getColumnFilterOption(filter.column).ops.some(o => o.value == filter.op));
  }

  const isFilterValueValid = (filter) => {
    switch (filter.type) {
      case 'date':
      case 'datetime':
        return filter.value == '' || parseFilterDate(filter.value) !== null;
      default:
        return true;
    }
  }

  return (
    <HmsTable
      buttonBarFooter={isFilterActive &&
        <div className="bg-gray-100 my-2 py-2 px-2 font-normal w-full">
          <span className={(isMobile ? `` : `mb-3`) + ` flex gap-2`}>
            <Button
              type="default"
              size={isMobile ? 'small' : 'small'}
              icon={<PlusOutlined />}
              onClick={() => {
                setFilters([...filters, { op: 'contains' }]);
                setTimeout(() => {
                  lastFilterColumnRef.current.focus();
                });
              }}
            >
              Add
            </Button>
            <Button
              type="default"
              size={isMobile ? 'small' : 'small'}
              icon={<PlusOutlined />}
              onClick={(e) => {
                const newFilters = [];
                filterableColumns.forEach((c, idx) => {
                  const fopt = getColumnFilterOption(idx);
                  newFilters.push({ 
                    column: idx,
                    columnTitle: c.filterColumnTitle ?? c.title, 
                    type: fopt.type,
                    op: fopt.ops[0].value
                  });
                });
                setFilters([...filters, ...newFilters]);
              }}
            >
              Add All
            </Button>
            <Button
              type="default"
              size={isMobile ? 'small' : 'small'}
              icon={icons.Trash}
              onClick={(e) => {
                setFilters([]);
              }}
            >
              Remove All
            </Button>
          </span>
          <div className={isMobile ? `` : `table`}>
            <div className={isMobile ? `hidden` : `table-row`}>
              <div className={isMobile ? `` : `table-cell pb-2 pr-2 align-middle font-bold min-w-[200px]`}>Column</div>
              <div className={isMobile ? `` : `table-cell pb-2 pr-2 align-middle font-bold min-w-[130px]`}>Operator</div>
              <div className={isMobile ? `` : `table-cell pb-2 pr-2 align-middle font-bold min-w-[200px]`}>Value</div>
              <div className={isMobile ? `` : `table-cell pb-2 pr-2 align-middle font-bold`}></div>
              <div className={isMobile ? `` : `table-cell pb-2 pr-2 align-middle font-bold`}></div>
            </div>
            {filters.map((f, idx) => {
              const fopt = getColumnFilterOption(f.column);
              const isThisFilterColumnValid = isFilterColumnEmpty(filters[idx]) || isFilterColumnValid(filters[idx]);
              const isThisFilterValueValid = isFilterValueValid(filters[idx]);
              const isThisFilterValueEmpty = filters[idx].value == null || filters[idx].value == '' || (Array.isArray(filters[idx].value) && filters[idx].value.length == 0);

              return isThisFilterColumnValid && (
                <div key={idx} className={isMobile ? `pt-2 mt-2 border-t-[1px] border-gray-300 grid gap-2 grid-cols-2 grid-rows-[auto_auto_auto]` : `table-row`}>
                  <div className={isMobile ? `` : `table-cell pb-2 pr-2 align-middle`}>
                    <Select
                      ref={idx == filters.length - 1 ? lastFilterColumnRef : null}
                      size={isMobile ? 'small' : 'small'}
                      style={{
                        width: isMobile ? 150 : 190,
                      }}
                      virtual={!isMobile}
                      value={isThisFilterColumnValid ? filters[idx].column : '(invalid)'}
                      disabled={isThisFilterColumnValid ? false : true}
                      showSearch={!isMobile}
                      filterOption={(inputValue, option) => {
                        const opt = removeAccents(option.label).toLowerCase();
                        const inp = removeAccents(inputValue).toLowerCase();
                        return opt.includes(inp);
                      }}
                      onChange={(e) => {
                        filters[idx].column = e;
                        filters[idx].columnTitle = filterableColumns[e].filterColumnTitle ?? filterableColumns[e].title;
                        const fopt = getColumnFilterOption(e);
                        // set type
                        filters[idx].type = fopt.type;
                        // change op if needed
                        const currentOp = filters[idx].op;
                        if (!fopt.ops.some(o => o.value == currentOp)) {
                          filters[idx].op = fopt.ops[0].value;
                        }
                        setFilters([...filters]);
                      }}
                      options={filterableColumns.map((c, idx) => ({ value: idx, label: c.filterColumnTitle ?? c.title }))}
                    />
                  </div>
                  <div className={isMobile ? `` : `table-cell pb-2 pr-2 align-middle`}>
                    <div className={(isMobile ? `justify-end` : ``) + ` flex gap-2`}>
                      <Button
                        size={isMobile ? 'small' : 'small'}
                        type={filters[idx].not ? 'primary' : 'default'}
                        disabled={isThisFilterColumnValid && filters[idx].column != null ? false : true}
                        onClick={() => {
                          filters[idx].not = !(filters[idx].not);
                          setFilters([...filters]);
                        }}
                      >
                        not
                      </Button>
                      <Radio.Group
                        value={filters[idx].op}
                        size={isMobile ? 'small' : 'small'}
                        className="font-normal"
                        options={fopt.ops}
                        disabled={isThisFilterColumnValid && filters[idx].column != null ? false : true}
                        onChange={(e) => {
                          filters[idx].op = e.target.value;
                          setFilters([...filters]);
                        }}
                        optionType="button"
                        buttonStyle="solid"
                      />
                    </div>
                  </div>
                  <div className={isMobile ? `col-span-2` : `table-cell pb-2 pr-2 align-middle`}>
                    {!fopt.options && filters[idx].op != 'isEmpty' &&
                      <Input
                        className="w-full"
                        size={isMobile ? 'small' : 'small'}
                        value={filters[idx].value}
                        disabled={!isThisFilterColumnValid || filters[idx].column == undefined}
                        allowClear
                        onChange={(e) => {
                          filters[idx].value = e.target.value;
                          setFilters([...filters]);
                        }}
                        placeholder={fopt.type == 'date' || fopt.type == 'datetime' ? 'dd.mm.yyyy' : ''}
                        status={!isThisFilterValueEmpty && !isThisFilterValueValid ? 'error' : undefined}
                      />
                    }
                    {fopt.options && filters[idx].op != 'isEmpty' &&
                      <Select
                        className="w-full"
                        size={isMobile ? 'small' : 'small'}
                        mode="multiple"
                        showSearch={!isMobile}
                        allowClear
                        value={Array.isArray(filters[idx].value) ? filters[idx].value : []}
                        disabled={!isThisFilterColumnValid || filters[idx].column == undefined}
                        options={fopt.options}
                        virtual={!isMobile}
                        onChange={(e) => {
                          filters[idx].value = e;
                          setFilters([...filters]);
                        }}
                      />
                    }
                    {filters[idx].op == 'isEmpty' &&
                      <Input size="small" disabled />
                    }
                  </div>
                  <div className={isMobile ? `order-last text-right` : `table-cell pb-2 pr-2 align-middle`}>
                    <Button
                      type="default"
                      size={isMobile ? 'small' : 'small'}
                      icon={icons.Trash}
                      onClick={() => {
                        filters.splice(idx, 1);
                        setFilters([...filters]);
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                  <div className={(isMobile ? `order-4 flex items-center` : `table-cell pb-2 pr-2 pl-4 align-left align-middle`) + ` text-xs text-gray-500`}>
                    {
                      data == null || (filters[idx].op != 'isEmpty' && isThisFilterValueEmpty) ? <></>
                        : isThisFilterColumnValid && isThisFilterValueValid ? <span>matches {filteredCounts[idx]} record{filteredCounts[idx] != 1 ? 's' : ''}</span>
                          : isThisFilterColumnValid && !isThisFilterValueValid ? <span className="text-red-500">invalid value</span>
                            : !isThisFilterColumnValid && isThisFilterValueValid ? <span className="text-red-500">invalid filter</span>
                              : <></>
                    }
                  </div>
                </div>
              );
            })
            }
          </div>
        </div>
      }
      extraButtons={filterEnabled &&
        <div>
          <Badge count={getValidFilterCount() > 0 ? '' + getValidFilterCount() : null}>
            <Button size="small" type={isFilterActive ? 'primary' : 'default'} icon={<FilterOutlined />} onClick={() => setIsFilterActive(!isFilterActive)}>Filters</Button>
          </Badge>
        </div>
      }
      columns={columns}
      data={filteredData}
      {...props}
    />
  )
}

export default HmsTableWithFilters
