import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useEntities from 'hooks/useEntities';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import useLoadEntity from 'hooks/useLoadEntity';
import HmsField from 'components/Form/HmsField';
import SeasonGroups from 'components/Season/SeasonGroups';
import EntityAttachments from 'components/EntityAttachments';
import HmsTabbedTables from 'components/HmsTabbedTables';
import TabsWithUrlMemory from 'components/TabsWithUrlMemory';
import HmsEntityFormGridFullWidth from 'components/Form/HmsEntityFormGridFullWidth';
import openInNewTab from 'utils/openInNewTab';
import EntityLogoEditor from 'components/EntityLogoEditor';

const Season = () => {
  const entities = useEntities();
  const entity = entities.Season;
  const parentEntity = entities.Competition;
  const childEntity = entities.Group;
  const { id, parentId, isLoading, isError, isNotFound, data, reload, parentData, childrenData } = useLoadEntity({ entity, parentEntity, childEntity });
  const { relationsData: attachmentsData, reloadRelations: reloadAttachments } = useLoadEntity({ entity,
    relations: [
      'Attachments',
    ].join(',')
  });

  return (
    <>
      <HmsEntityFormController
        {... {entity, parentEntity, id, parentId, isError, isLoading, isNotFound, data, reload, parentData, childrenData} }
        systemButtons={[
          {
            label: 'Open embed - Terms',
            onClick: () => { openInNewTab(`/embed/season_terms?seasonId=${id}&lang=cs`) },
            disabled: id == '_new_',
          },
        ]}
        form={(
          <TabsWithUrlMemory
            className="no-tab-padding w-[calc(100vw-75px)] desktop:w-[calc(100vw-280px)]"
            items={[
              {
                label: `Basic`,
                children: <>  
                  <div className="flex flex-col desktop:flex-row w-full">
                    <div className="flex items-center justify-center ml-4 mr-6 mb-5 desktop:mb-0 ">
                      <EntityLogoEditor entity={entities.Season} id={id} data={data} size={112} />
                    </div>
                    <div className="flex-auto">
                    <HmsEntityFormGridBasic>
                      <HmsField name="name" autoFocus />
                      <HmsField name="nick" />
                      <HmsField name="short" />
                      <HmsField name="startDate" />
                      <HmsField name="endDate" />
                      <HmsField name="status" />
                    </HmsEntityFormGridBasic>
                    </div>
                  </div>
                </>
              },
              {
                label: `Terms`,
                children: <>
                  <HmsEntityFormGridBasic>
                    <HmsField name="registrationFee" addonAfter="Kč" />
                  </HmsEntityFormGridBasic>
                  <HmsEntityFormGridFullWidth>
                    {/* <TipTapEditor /> */}
                    <HmsField
                      name="terms"
                      type="HTML"
                    />
                  </HmsEntityFormGridFullWidth>
                </>
              },
            ].map(tab => ({ ...tab, key: tab.label }))}
          />
        )}
        footer={id != '_new_' && (
          <div className="mt-4">
            <HmsTabbedTables
              tables={[
                {
                  title: "Groups" + (childrenData ? (' (' + childrenData?.length + ')') : ''),
                  children: <SeasonGroups data={childrenData} sourceEntityId={data?.seasonId} /> 
                },
                {
                  title: "Attachments" + (attachmentsData?.Attachments ? (' (' + attachmentsData?.Attachments?.length + ')') : ''),
                  children: <EntityAttachments entity={entity.name} id={data?.[entity.primaryKey]} data={attachmentsData} reload={reloadAttachments} />,
                },
              ]}
            />
          </div>
        )}
      />
    </>
  );
}

export default Season
