import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useEntities from 'hooks/useEntities';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import useLoadEntity from 'hooks/useLoadEntity';
import HmsField from 'components/Form/HmsField';
import HmsTabbedTables from 'components/HmsTabbedTables';
import SponsorTeams from 'components/Sponsor/SponsorTeams';
import SponsorPlayers from 'components/Sponsor/SponsorPlayers';
import SponsorVenues from 'components/Sponsor/SponsorVenues';
import SponsorCompetitions from 'components/Sponsor/SponsorCompetitions';
import SponsorSeasons from 'components/Sponsor/SponsorSeasons';
import SponsorGroups from 'components/Sponsor/SponsorGroups';
import SponsorPhases from 'components/Sponsor/SponsorPhases';
import SponsorAwards from 'components/Sponsor/SponsorAwards';
import EntityLogoEditor from 'components/EntityLogoEditor';
import EntityAttachments from 'components/EntityAttachments';

const Sponsor = () => {
  const entities = useEntities();
  const entity = entities.Sponsor;
  const { id, isLoading, isError, isNotFound, data, reload, relationsData, reloadRelations } = useLoadEntity({
    entity,
    relations: [
      'Teams',
      'Venues',
      'Competitions',
      'Awards',
    ].join(','),
  });

  /* Note: need to load the following relations separately, otherwise JOINs put a huge load and overload on the server */
  const { isLoading: playersLoading, relationsData: playersData, reloadRelations: reloadPlayers } = useLoadEntity({
    entity,
    relations: [
      'Players',
      'Players>Teams',
      'Players>ListPlayerLevel',
      'Players>ListPositions',
    ].join(','),
  });
  const { isLoading: seasonsLoading, relationsData: seasonsData, reloadRelations: reloadSeasons } = useLoadEntity({
    entity,
    relations: [
      'Seasons,Seasons>Competition',
    ].join(','),
  });
  const { isLoading: groupsLoading, relationsData: groupsData, reloadRelations: reloadGroups } = useLoadEntity({
    entity,
    relations: [
      'Groups,Groups>Season,Groups>Season>Competition',
    ].join(','),
  });
  const { isLoading: phasesLoading, relationsData: phasesData, reloadRelations: reloadPhases } = useLoadEntity({
    entity,
    relations: [
      'Phases,Phases>Group,Phases>Group>Season,Phases>Group>Season>Competition',
    ].join(','),
  });

  const { relationsData: attachmentsData, reloadRelations: reloadAttachments } = useLoadEntity({ entity,
    relations: [
      'Attachments',
    ].join(',')
  });

  return (
    <HmsEntityFormController
      {... { entity, id, isLoading, isError, isNotFound, data, reload }}
      form={(
        <div className="flex flex-col desktop:flex-row w-full">
          <div className="flex items-center justify-center ml-4 mr-6 mb-5 desktop:mb-0 ">
            <EntityLogoEditor entity={entities.Sponsor} id={id} data={data} size={112} />
          </div>
          <div className="flex-auto">
            <HmsEntityFormGridBasic>
              <HmsField name="name" />
              <HmsField name="nick" />
              <HmsField name="short" />
              <HmsField name="claim" />
              <HmsField name="web" placeholder="www.example.com" />
              <HmsField name="description" />
              <HmsField name="legalName" />
            </HmsEntityFormGridBasic>
          </div>
        </div>
      )}
      footer={id != '_new_' && (
        <div className="mt-4">
          <div className="mt-4">
            <HmsTabbedTables
              tables={[
                {
                  title: "Teams" + (relationsData?.Teams ? (' (' + relationsData?.Teams?.length + ')') : ''),
                  children: <SponsorTeams data={relationsData?.Teams} sourceEntityId={data?.sponsorId} onChange={reloadRelations} />
                },
                {
                  title: "Players" + (playersData?.Players ? (' (' + playersData?.Players?.length + ')') : ''),
                  children: <SponsorPlayers data={playersData?.Players} sourceEntityId={data?.sponsorId} onChange={reloadPlayers} />
                },
                {
                  title: "Venues" + (relationsData?.Venues ? (' (' + relationsData?.Venues?.length + ')') : ''),
                  children: <SponsorVenues data={relationsData?.Venues} sourceEntityId={data?.sponsorId} onChange={reloadRelations} />
                },
                {
                  title: "Competitions" + (relationsData?.Competitions ? (' (' + relationsData?.Competitions?.length + ')') : ''),
                  children: <SponsorCompetitions data={relationsData?.Competitions} sourceEntityId={data?.sponsorId} onChange={reloadRelations} />
                },
                {
                  title: "Seasons" + (seasonsData?.Seasons ? (' (' + seasonsData?.Seasons?.length + ')') : ''),
                  children: <SponsorSeasons data={seasonsData?.Seasons} sourceEntityId={data?.sponsorId} onChange={reloadSeasons} />
                },
                {
                  title: "Groups" + (groupsData?.Groups ? (' (' + groupsData?.Groups?.length + ')') : ''),
                  children: <SponsorGroups data={groupsData?.Groups} sourceEntityId={data?.sponsorId} onChange={reloadGroups} />
                },
                {
                  title: "Phases" + (phasesData?.Phases ? (' (' + phasesData?.Phases?.length + ')') : ''),
                  children: <SponsorPhases data={phasesData?.Phases} sourceEntityId={data?.sponsorId} onChange={reloadPhases} />
                },
                {
                  title: "Awards" + (relationsData?.Awards ? (' (' + relationsData?.Awards?.length + ')') : ''),
                  children: <SponsorAwards data={relationsData?.Awards} sourceEntityId={data?.sponsorId} onChange={reloadRelations} />
                },
                {
                  title: "Attachments" + (attachmentsData?.Attachments ? (' (' + attachmentsData?.Attachments?.length + ')') : ''),
                  children: <EntityAttachments entity={entity.name} id={data?.[entity.primaryKey]} data={attachmentsData} reload={reloadAttachments} />,
                },
              ]}
            />
          </div>
        </div>
      )}
    />
  );
}

export default Sponsor
