import { useContext, useEffect, useMemo } from 'react';
import { ListContext } from 'components/ListsProvider';
const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const useFields = () => {
    const { fields } = useContext(ListContext);
    return fields;
}

export default useFields;
