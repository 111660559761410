import React from 'react'
import { Checkbox, Form, Input, Row, Col, DatePicker, Select } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const dateFormatList = [
    'DD/MM/YYYY',
    'D/MM/YYYY',
    'DD/M/YYYY',
    'D/M/YYYY',
    'DD/MM/YY',
    'D/MM/YY',
    'DD/M/YY',
    'D/M/YY',
    'DD.MM.YYYY',
    'D.MM.YYYY',
    'DD.M.YYYY',
    'D.M.YYYY',
    'DD.MM.YY',
    'D.MM.YY',
    'DD.M.YY',
    'D.M.YY',
];

const StringDatePicker = ({ value, onChange, ...props }) => {
    const dateStringToDayjs = (dateStr) => {
        return dateStr ? dayjs(dateStr) : null;
    }

    const dayjsToDateStr = (date) => {
        return date ? date.format('YYYY-MM-DD') : '';
    }

    return (
        <DatePicker 
            {...props}
            value={dateStringToDayjs(value)}
            onChange={onChange ? (value) => onChange(dayjsToDateStr(value)) : null}
        />
    );
}

const FormDatePicker = ({ label, name, required, autoFocus, formItemProps, fieldItemProps }) => {
    return (
        <HmsFormItem
            label={label}
            name={name}
            required={required}
            {...formItemProps}
        >
            <StringDatePicker
                autoFocus={autoFocus}
                className="w-full"
                format={dateFormatList}
                allowClear={true}
                {...fieldItemProps}
            />
        </HmsFormItem>
    )
}

export default FormDatePicker
