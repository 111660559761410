import React, { useEffect, useState } from 'react'
import { Divider, Input, Select, Tooltip } from 'antd';
import { AiFillStar } from 'react-icons/ai';
import { formatTime, formatDate } from 'utils/generateEntityColumns';
import { compareString } from 'utils/comparators';
import useIsMobile from 'hooks/useIsMobile';
import PlayerLevelTag from 'components/PlayerLevelTag';
import { getImage } from 'utils/getImage';
import sortGameEvents from 'utils/sortGameEvents';
import { InfoCircleOutlined } from '@ant-design/icons';
import EmbedCard from 'components/Embeds/EmbedCard';
import EmbedCardVideo from 'components/Embeds/EmbedCardVideo';
import EmbedCardTitleDivier from 'components/Embeds/EmbedCardTitleDivier';
import RefereeIcon from 'icons/RefereeIcon';
import TimekeeperIcon from 'icons/TimekeeperIcon';
import useLang from 'hooks/useLang';

const EmbedGameInner = ({ data, imagesData, lang }) => {
  const isMobile = useIsMobile();
  const LANG = useLang(lang);

  // TODO: Remove this when we have 'periodId' (and Period object via relations) in GameEvent
  const periods = data.Periods
    ?.map(lp => ({
      name: lp.name,
      short: lp.code,
      duration: lp.duration,
      index: lp.priority,
    }));
  periods?.sort((a, b) => a.index - b.index);

  // magic happens inside sortGameEvents...
  const events = sortGameEvents(data.GameEvents, data.HomeTeam?.teamId, data.AwayTeam?.teamId, periods);

  const translateGameEventType = (type) => {
    switch (type) {
      case 'GameEventGoal': return LANG['EMBED_GAME_GOAL'];
      case 'GameEventPenalty': return LANG['EMBED_GAME_PENALTY'];
      case 'GameEventPenaltyshot': return LANG['EMBED_GAME_PENALTYSHOT_SHORT'];
      default: return type.replace('GameEvent', '');
    }
  }

  const translatePeriod = (period) => {
    if (lang == 'en') {
      return period?.replace('Třetina', 'Period');
    } else {
      return period;
    }
  }

  const getTypeIcon = (type) => {
    switch (type) {
      case 'GameEventGoal': return '/EmbedGoalIcon.svg';
      case 'GameEventPenalty': return '/EmbedPenaltyIcon.svg';
      case 'GameEventPenaltyshot': return '/EmbedPenaltyIcon.svg';
      default: return null;
    }
  }

  const getTypeColor = (type) => {
    switch (type) {
      case 'GameEventGoal': return 'bg-[#1E9800]';
      case 'GameEventPenalty': return 'bg-[#980900]';
      case 'GameEventPenaltyshot': return 'bg-[#99371f]';
      default: return 'bg-gray-400';
    }
  }

  const getEventTeamId = (event) => {
    switch (event.entity) {
      case 'GameEventGoal': return event.scoredByTeamId;
      case 'GameEventPenalty': return event.penalizedTeamId;
      case 'GameEventPenaltyshot': return event.executedByTeamId;
      default: console.log('Cannot infer team id for event:', event); return null;
    }
  }

  const playerInfo = (player) => {
    if (player) {
      const name = player.firstName + ' ' + player.lastName;
      const number = getPlayerJersey(player.playerId);
      if (number) {
        return name + ' (' + number + ')';
      } else {
        return name;
      }
    } else {
      return '';
    }
  }

  // Source: ChatGPT-3.5
  //
  // Formats: `Text with *bold* asterisks`
  // As:      `Text with <span className="font-semibold">bold</span> asterisks`
  //
  function textWithBoldAsterisks(text) {
    const parts = text.split('*');
    const formattedText = parts.map((part, index) => {
      if (index % 2 === 0) {
        return <span key={index}>{part}</span>;
      } else {
        return <span className="font-semibold" key={index}>{part}</span>;
      }
    });
    return (
      <span>
        {formattedText}
      </span>
    );
  }

  const getEventDescriptionShort = (r) => {
    switch (r.entity) {
      case 'GameEventSave':
        return <span></span>
      case 'GameEventGoal':
        return (<span>
          {r.ScoredByPlayer && <>{playerInfo(r.ScoredByPlayer)}</>}
        </span>)
      case 'GameEventPenalty':
        return (<span>
          {r.PenalizedPlayer && <>{playerInfo(r.PenalizedPlayer)}</>}
        </span>)
      case 'GameEventInjury':
        return (<span>
          {r.InjuredPlayer && <>{playerInfo(r.InjuredPlayer)}</>}
        </span>)
      case 'GameEventFaceOff':
        return (<span>
        </span>)
      case 'GameEventPenaltyshot':
        return (<span>
          {r.ExecutedByPlayer && <>{playerInfo(r.ExecutedByPlayer)}</>}
        </span>)
      default:
        console.warn('Unknown entity', r.entity);
        break;
    }
  }

  const getEventDescription = (r) => {
    switch (r.entity) {
      case 'GameEventSave':
        return <span></span>
      case 'GameEventGoal':
        const effectiveAssist1 = r.AssistedBy1Player ?? r.AssistedBy2Player;
        const effectiveAssist2 = r.AssistedBy1Player && r.AssistedBy2Player;
        return (<span>
          {r.ScoredByPlayer && <>{textWithBoldAsterisks('*' + playerInfo(r.ScoredByPlayer) + '*')}{' '}</>}
          {effectiveAssist1 && <>{LANG['EMBED_GAME_ASSISTED_SHORT']} {playerInfo(effectiveAssist1)}{' '}</>}
          {effectiveAssist2 && <>{LANG['EMBED_GAME_ASSISTED_AND']} {playerInfo(effectiveAssist2)} </>}
        </span>)
      case 'GameEventPenalty':
        return (<span>
          {r.PenalizedPlayer && <>{playerInfo(r.PenalizedPlayer)}</>}
          {r.duration && <>{' '}{r.duration}m</>}
          {r.ListPenaltyType && <>{', '}{r.ListPenaltyType?.name}</>}
          {r.ListPenaltySubtype && <>{' - '}{r.ListPenaltySubtype?.name}</>}
        </span>)
        break;
      case 'GameEventInjury':
        return (<span>
          {r.InjuredPlayer && <>{playerInfo(r.InjuredPlayer)}{' '}</>}
          {r.ListInjuryType && <>{r.ListInjuryType?.name}{' '}</>}
        </span>)
      case 'GameEventFaceOff':
        return (<span>
        </span>)
      case 'GameEventPenaltyshot':
        return (<span>
          {r.ExecutedByPlayer && <>{playerInfo(r.ExecutedByPlayer)}{' '}</>}
        </span>)
      default:
        console.warn('Unknown entity', r.entity);
        break;
    }
  }

  const getGoalsByPlayer = (playerId) => {
    let count = 0;
    if (playerId != undefined) {
      for (const event of events) {
        if (event.ScoredByPlayer?.playerId == playerId) {
          count++;
        }
      }
    }
    return count;
  }

  const getAssistsByPlayer = (playerId) => {
    let count = 0;
    if (playerId != undefined) {
      for (const event of events) {
        if (event.AssistedBy1Player?.playerId == playerId || event.AssistedBy2Player?.playerId == playerId) {
          count++;
        }
      }
    }
    return count;
  }

  const getPointsByPlayer = (playerId) => {
    return getGoalsByPlayer(playerId) + getAssistsByPlayer(playerId);
  }

  const getPenaltyMinutesByPlayer = (playerId) => {
    let seconds = 0;
    if (playerId != undefined) {
      for (const event of events) {
        if (event.PenalizedPlayer?.playerId == playerId) {
          if (event.duration) {
            if (event.duration.indexOf(':') != -1) {
              const duration1 = event.duration.split(':');
              seconds += parseInt(duration1[0]) * 60 + parseInt(duration1[1]);
            } else {
              if (!Number.isNaN(parseFloat(event.duration))) {
                seconds += parseFloat(event.duration) * 60;
              }
            }
          }
        }
      }
    }
    return (seconds / 60).toFixed(2).replace('.00', '');
  }

  const getEventTeam = (event) => {
    const teamId = getEventTeamId(event);
    if (data.HomeTeam?.teamId == teamId) {
      return data.HomeTeam;
    }
    if (data.AwayTeam?.teamId == teamId) {
      return data.AwayTeam;
    }
    console.log('Unknown event team:', event);
  }

  const getEventPlayer = (event) => {
    return event.ScoredByPlayer ?? event.PenalizedPlayer;
  }

  const getPlayerJersey = (playerId) => {
    return data.Lineups?.find(l => l.Player?.playerId == playerId)?.number;
  }

  const getHomeTeamResult = (game) => game.HomeTeamGoals > game.AwayTeamGoals ? 'win' : game.HomeTeamGoals < game.AwayTeamGoals ? 'loss' : (game.status != 'NOT PLAYED' && game.status != null) ? 'tie' : null;
  const getAwayTeamResult = (game) => game.HomeTeamGoals < game.AwayTeamGoals ? 'win' : game.HomeTeamGoals > game.AwayTeamGoals ? 'loss' : (game.status != 'NOT PLAYED' && game.status != null) ? 'tie' : null;

  const TeamLogo = ({ team, result, position = 'top' }) => {
    const bgClass = result == 'win' ? 'bg-[#53C020]' : result == 'loss' ? 'bg-[#C02020]' : result == 'tie' ? 'bg-[#9B9B9B]' : '';
    const letter = result == 'win' ? 'W' : result == 'loss' ? 'L' : result == 'tie' ? 'T' : null;
    const marginClass = (letter == null) ? 'mt-[10px] mb-[10px]' : '';
    return (
      <div className={`${bgClass} rounded-lg w-[45px] desktop:w-[50px]`}>
        {position == 'top' && letter && <div className="font-roboto-condensed text-center text-white text-[0.7rem] desktop:text-xs py-[1px]">{letter}</div>}
        <div
          className={`${marginClass} w-[45px] desktop:w-[50px] h-[45px] desktop:h-[50px] bg-no-repeat bg-center rounded-lg`}
          style={{ backgroundImage: `url('${getImage(team, 'md')}')`, backgroundSize: '150%' }}>
        </div>
        {position == 'bottom' && letter && <div className="font-roboto-condensed text-center text-white text-[0.7rem] desktop:text-xs py-[1px]">{letter}</div>}
      </div>
    )
  }

  const TeamName = ({ team, align }) => {
    const size = isMobile ? 'small' : 'regular';
    const alignClass = align == 'right' ? 'text-right' : 'text-left';
    const marginClass = align == 'right' ? (size == 'small' ? 'mr-2' : 'mr-4') : (size == 'small' ? 'ml-2' : 'ml-4');
    return (
      <span className={`${marginClass} flex flex-col gap-0 desktop:gap-1`}>
        <span className={`${alignClass} font-roboto-condensed text-[0.9rem] desktop:text-[1.3rem] text-black break-all line-clamp-1 desktop:line-clamp-2`}>{team?.name}</span>
      </span>
    )
  }

  const TeamStats = ({ shots, shots_Original, faceOffs, faceOffs_Original, penaltyMinutes, align }) => {
    const size = isMobile ? 'small' : 'regular';
    const alignClass = align == 'right' ? 'text-right' : 'text-left';
    const marginClass = align == 'right' ? (size == 'small' ? 'mr-2' : 'mr-4') : (size == 'small' ? 'ml-2' : 'ml-4');
    const justifyClass = align == 'right' ? 'justify-end' : 'justify-start';
    if (isMobile) {
      return (
        <div className={`${marginClass} ${alignClass} mt-1 font-light flex flex-col text-[#A1A1A1] text-sm`}>
          <div className={`flex gap-1 ${justifyClass}`}>
            <span className="break-all line-clamp-1">{LANG['EMBED_GAME_SHOTS']}:</span>
            <span>{shots_Original !== null ? `${shots} (${shots_Original})` : `${shots}`}</span>
          </div>
          <div className={`flex gap-1 ${justifyClass}`}>
            <span className="break-all line-clamp-1">{LANG['EMBED_GAME_FACEOFFS_SHORT']}:</span>
            <span>{faceOffs_Original !== null ? `${faceOffs} (${faceOffs_Original})` : `${faceOffs}`}</span>
          </div>
          <div className={`flex gap-1 ${justifyClass}`}>
            <span className="break-all line-clamp-1">{LANG['EMBED_GAME_PENALTYMINUTES_SHORT']}:</span>
            <span>{penaltyMinutes}</span>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`${marginClass} ${alignClass} font-light mt-1 gap-1 text-[#A1A1A1] text-xs`}>
          {LANG['EMBED_GAME_SHOTS']}: {shots_Original !== null ? `${shots} (${shots_Original})` : `${shots}`}
          <span className="inline mx-[3px]">·</span>
          {LANG['EMBED_GAME_FACEOFFS']}: {faceOffs_Original !== null ? `${faceOffs} (${faceOffs_Original})` : `${faceOffs}`}
          <span className="inline mx-[3px]">·</span>
          {LANG['EMBED_GAME_PENALTYMINUTES']}: {penaltyMinutes}
        </div>
      )
    }
  }

  const Star = ({ gameStar, align, size }) => {
    const sizePx = size == 'small' ? 50 : 90;
    const overflow = size == 'small' ? 10 : 60;
    const fontSize = 'text-[0.65rem] desktop:text-sm';
    const player = gameStar?.Player;
    const number = gameStar?.LineupNumber;
    const justifyClass = align == 'right' ? 'justify-center' : 'justify-center';
    return (
      <div className={`w-full flex items-center ${justifyClass}`}>
        {player &&
          <div className='flex justify-center relative' style={{ width: `${sizePx + overflow}px`, height: `${sizePx}px` }}>
            <span className={`absolute top-0 bg-gray-200 bg-cover bg-center rounded-xl`} style={{ left: `${Math.round(overflow / 2)}px`, width: `${sizePx}px`, height: `${sizePx}px`, backgroundImage: `url('${getImage(player, 'md')}')`, backgroundSize: '150%' }} />
            <span className={`absolute overflow-hidden font-roboto-flex ${fontSize} bg-black rounded-xl text-white px-2 py-[2px] text-center flex items-center gap-1`} style={{ bottom: `-${Math.round(overflow / 4)}px` }}>
              <span className="whitespace-nowrap">{isMobile ? (player.firstName?.substr(0, 1) + '.') : player.firstName} {player.lastName} ({number})</span>
              <AiFillStar />
            </span>
          </div>
        }
      </div>
    )
  }

  const StarStats = ({ label, valueLeft, valueRight, size = 'regular' }) => {
    const w1 = size == 'small' ? 'w-[50px]' : 'w-[90px]';
    const w2 = size == 'small' ? 'w-[70px]' : 'w-[125px]';
    const fontSize1 = size == 'small' ? 'text-[0.75rem]' : 'text-lg';
    const fontSize2 = size == 'small' ? 'text-[0.75rem]' : 'text-xl';

    return (
      <div className="flex justify-between items-center">
        <span className={`truncate text-center ${w1} text-[#A1A1A1] font-light ${fontSize1}`}>{!Number.isNaN(valueLeft) && valueLeft}</span>
        <span className={`truncate text-center ${w2} text-[#2E2E30] font-normal ${fontSize2} uppercase`}>{label}</span>
        <span className={`truncate text-center ${w1} text-[#A1A1A1] font-light ${fontSize1}`}>{!Number.isNaN(valueRight) && valueRight}</span>
      </div>
    )
  }

  const homeTeamStar = data.GameStars.find(gs => gs.teamId == data.HomeTeam?.teamId);
  const awayTeamStar = data.GameStars.find(gs => gs.teamId == data.AwayTeam?.teamId);

  // Urbis:
  //   "Střely by v případě Kontumace a vynulování Saves měly být také Nulové, jen pokud se rozhodneš nechat hodnoty na jiné nez nula, pak by se měly počítat střely jako součet Goals + Saves"
  //   "IF Saves = 0 THAN Shots = 0 (no matter what is Score)"
  //   "to je správná podmínka :)"
  //   "Ale jen v pripade kontumace, cili jen kdyz je overwrite active"
  const homeTeamShots =          data.overwriteEnabled && data.AwayTeamSaves == 0 ? 0 : data.HomeTeamGoals + data.AwayTeamSaves /* AWAY = opposite!!! */;
  const awayTeamShots =          data.overwriteEnabled && data.HomeTeamSaves == 0 ? 0 : data.AwayTeamGoals + data.HomeTeamSaves /* HOME = opposite!!! */;
  const homeTeamShots_Original = data.overwriteEnabled ? data.HomeTeamGoals_Original + data.AwayTeamSaves_Original : null; /* AWAY = opposite!!! */;
  const awayTeamShots_Original = data.overwriteEnabled ? data.AwayTeamGoals_Original + data.HomeTeamSaves_Original : null; /* HOME = opposite!!! */;

  const homeTeamFaceOffs =          data.HomeTeamFaceOffs;
  const awayTeamFaceOffs =          data.AwayTeamFaceOffs;
  const homeTeamFaceOffs_Original = data.overwriteEnabled ? data.HomeTeamFaceOffs_Original : null;
  const awayTeamFaceOffs_Original = data.overwriteEnabled ? data.AwayTeamFaceOffs_Original : null;

  const TeamLineup = ({ team, lineup, gameStars }) => {
    const isStar = (player) => {
      return (player?.playerId != null && (gameStars ?? []).find(gs => gs.Player?.playerId == player?.playerId))
    }

    const lineupSorted = lineup;
    lineupSorted.sort((a, b) => compareString(a.Player?.lastName ?? '' + a.Player?.firstName ?? '', b.Player?.lastName ?? '' + b.Player?.firstName ?? ''));

    return (
      <div>
        <div className="mb-4 font-roboto-condensed text-xl">{team?.name}</div>
        <div className="table w-full">
          <div className="table-row text-[#5F5F5F] font-thin">
            <div className="table-cell pb-3 w-[8%] text-center"><Tooltip title={LANG['EMBED_GAME_LINEUP_STATUS']}>{LANG['EMBED_GAME_LINEUP_STATUS_SHORT']}</Tooltip></div>
            <div className="table-cell pb-3 w-[%44]">{LANG['EMBED_GAME_LINEUP_NAME']}</div>
            <div className="table-cell pb-3 w-[8%] text-center"><Tooltip title={LANG['EMBED_GAME_LINEUP_LEVEL']}>{LANG['EMBED_GAME_LINEUP_LEVEL_SHORT']}</Tooltip></div>
            <div className="table-cell pb-3 w-[8%] text-center"><Tooltip title={LANG['EMBED_GAME_LINEUP_JERSEY']}>{LANG['EMBED_GAME_LINEUP_JERSEY_SHORT']}</Tooltip></div>
            <div className="table-cell pb-3 w-[8%] text-center"><Tooltip title={LANG['EMBED_GAME_LINEUP_POSITION']}>{LANG['EMBED_GAME_LINEUP_POSITION_SHORT']}</Tooltip></div>
            <div className="table-cell pb-3 w-[8%] text-center"><Tooltip title={LANG['EMBED_GAME_LINEUP_GOALS']}>{LANG['EMBED_GAME_LINEUP_GOALS_SHORT']}</Tooltip></div>
            <div className="table-cell pb-3 w-[8%] text-center"><Tooltip title={LANG['EMBED_GAME_LINEUP_ASSISTS']}>{LANG['EMBED_GAME_LINEUP_ASSISTS_SHORT']}</Tooltip></div>
            <div className="table-cell pb-3 w-[8%] text-center"><Tooltip title={LANG['EMBED_GAME_LINEUP_POINTS']}>{LANG['EMBED_GAME_LINEUP_POINTS_SHORT']}</Tooltip></div>
            <div className="table-cell pb-3 w-[8%] text-center"><Tooltip title={LANG['EMBED_GAME_LINEUP_PENALTY_MINUTES']}>{LANG['EMBED_GAME_LINEUP_PENALTY_MINUTES_SHORT']}</Tooltip></div>
          </div>
          {lineupSorted.map((l, idx) => (
            <div key={idx} className="table-row text-[#939393] font-thin">
              <div className="table-cell pb-2 text-center">
                {l.isCaptain && <Tooltip title="Kapitán"><img src="/Captain.svg" className="w-4 h-4" /></Tooltip>}
                {!l.isCaptain && l.ListPosition?.isGoalie && <Tooltip title="Gólman"><img src="/Goalie.svg" className="w-4 h-4" /></Tooltip>}
                {!l.isCaptain && !l.ListPosition?.isGoalie && <img src="/Player.svg" className="w-4 h-4" />}
              </div>
              <div className="table-cell pb-2">
                <div className=" flex items-center gap-[5px]">
                  <span>{l.Player?.firstName} {l.Player?.lastName}</span>
                  {isStar(l.Player) &&
                    <Tooltip title="Hvězda zápasu">
                      <img src="/Star.svg" className="w-4 h-4 opacity-60" />
                    </Tooltip>
                  }
                </div>
              </div>
              <div className="table-cell pb-2 text-center">
                <PlayerLevelTag className="font-light text-[0.8rem]" listPlayerLevel={l.Player?.ListPlayerLevel} />
              </div>
              <div className="table-cell pb-2 text-center">{l.number}</div>
              <div className="table-cell pb-2 text-center"><Tooltip title={l.ListPosition?.name}>{l.ListPosition?.short}</Tooltip></div>
              <div className="table-cell pb-2 text-center">{getGoalsByPlayer(l.Player?.playerId)}</div>
              <div className="table-cell pb-2 text-center">{getAssistsByPlayer(l.Player?.playerId)}</div>
              <div className="table-cell pb-2 text-center">{getPointsByPlayer(l.Player?.playerId)}</div>
              <div className="table-cell pb-2 text-center">{getPenaltyMinutesByPlayer(l.Player?.playerId)}</div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  const GameEmbedNews = () => (
    ((data.headline != null && data.headline.trim() != '') ||
      (data.perex != null && data.perex.trim() != '') ||
      (data.body != null && data.body.trim() != '')) && (

      <EmbedCard>
        <div className="text-xl">
          {data.headline}
        </div>
        <div className="mt-4 text-[#696969] font-light whitespace-pre-line">
          {data.perex}
        </div>
        <div className="mt-5 text-[#939393] font-light whitespace-pre-line">
          {data.body}
        </div>
      </EmbedCard>
    )
  );

  const GameEmbedInfo = () => (
    <EmbedCard className="desktop:pb-6">
      <div className="flex justify-between gap-2 text-[#A1A1A1] font-light text-xs dekstop:text-sm">
        <span className="truncate">{formatTime(data.startTime)} / {formatDate(data.startDate)}</span>
        <span className="truncate">·</span>
        <span className="truncate">{data.Venue?.name}</span>
        <span className="truncate">·</span>
        <span className="truncate">{data.Phase?.Group?.Season?.Competition?.name}</span>
        <span className="truncate">·</span>
        <span className="truncate">{data.Phase?.Group?.name}</span>
        <span className="truncate">·</span>
        <span className="truncate">{data.Phase?.name}</span>
      </div>

      <EmbedCardTitleDivier />

      <div className="mb-2 flex justify-between items-end">
        <div className="flex-none w-[45px] desktop:w-[50px]">
          <TeamLogo team={data.HomeTeam} result={getHomeTeamResult(data)} position={isMobile ? "bottom" : "top"} />
        </div>
        <div className="flex-1">
          <TeamName team={data.HomeTeam} align="left" />
          <TeamStats 
            shots={homeTeamShots} 
            shots_Original={homeTeamShots_Original} 
            faceOffs={homeTeamFaceOffs} 
            faceOffs_Original={homeTeamFaceOffs_Original} 
            penaltyMinutes={data.HomeTeamPenaltyMinutes}
            align="left"
          />
        </div>
        <div className="flex-none w-[65px] desktop:w-[100px] self-center">
          <span className="flex no-wrap justify-center items-center gap-2 desktop:font-bold font-score text-[2.5rem] desktop:text-[3rem] text-[#2E2E30]">
            <span>{(data.HomeTeamGoals != 0 || data.AwayTeamGoals != 0 || data.status == 'RUNNING' || data.status == 'FINISHED') ? data.HomeTeamGoals : '–'}</span>
            <span>:</span>
            <span>{(data.HomeTeamGoals != 0 || data.AwayTeamGoals != 0 || data.status == 'RUNNING' || data.status == 'FINISHED') ? data.AwayTeamGoals : '–'}</span>
          </span>
        </div>
        <div className="flex-1">
          <TeamName team={data.AwayTeam} align="right" />
          <TeamStats 
            shots={awayTeamShots} 
            shots_Original={awayTeamShots_Original} 
            faceOffs={awayTeamFaceOffs} 
            faceOffs_Original={awayTeamFaceOffs_Original} 
            penaltyMinutes={data.AwayTeamPenaltyMinutes} 
            align="right"
          />
        </div>
        <div className="flex-none w-[45px] desktop:w-[50px]">
          <TeamLogo team={data.AwayTeam} result={getAwayTeamResult(data)} position={isMobile ? "bottom" : "top"} />
        </div>
      </div>

      {data.overwriteEnabled &&
        <div className="mt-7 flex gap-2 justify-center items-center font-light text-sm text-orange-500">
          <InfoCircleOutlined />
          <span className="font-light text-sm">
            {
              LANG['KONTUMACE_ADJUSTED_MESSAGE']
                .replace('{homeTeamGoals}', data.HomeTeamGoals_Original)
                .replace('{awayTeamGoals}', data.AwayTeamGoals_Original)
            }
          </span>
        </div>
      }
    </EmbedCard>
  );

  const GameEmbedStars = () => (
    (homeTeamStar || awayTeamStar) && (
      <EmbedCard>
        <div className="font-roboto-condensed text-xl">
          Hvězdy zápasu
        </div>
        <EmbedCardTitleDivier />
        <div className="flex justify-between items-center">
          <Star gameStar={homeTeamStar} align="left" size={isMobile ? 'small' : 'regular'} />
          <div className={`flex flex-col gap-1 desktop:gap-2`}>
            <StarStats size={isMobile ? 'small' : 'regular'} label="Číslo dresu" valueLeft={homeTeamStar?.LineupNumber} valueRight={awayTeamStar?.LineupNumber} />
            <StarStats size={isMobile ? 'small' : 'regular'} label="Pozice" valueLeft={homeTeamStar?.LineupPositionName} valueRight={awayTeamStar?.LineupPositionName} />
            <StarStats size={isMobile ? 'small' : 'regular'} label="Góly" valueLeft={homeTeamStar?.Goals} valueRight={awayTeamStar?.Goals} />
            <StarStats size={isMobile ? 'small' : 'regular'} label="Asistence" valueLeft={homeTeamStar?.Assists} valueRight={awayTeamStar?.Assists} />
            <StarStats size={isMobile ? 'small' : 'regular'} label="Body" valueLeft={homeTeamStar?.Goals + homeTeamStar?.Assists} valueRight={awayTeamStar?.Goals + awayTeamStar?.Assists} />
          </div>
          <Star gameStar={awayTeamStar} align="right" size={isMobile ? 'small' : 'regular'} />
        </div>
      </EmbedCard>
    )
  );

  const GameEmbedEvents = () => {
    const periods = events.map(e => e.period).filter((v, i, a) => a.indexOf(v) === i);
    periods.sort();
    return (
      (events.length > 0) && (
        <EmbedCard>
          <div className="font-roboto-condensed text-xl">
            {LANG['EMBED_GAME_EVENTS_TITLE']}
          </div>
          <EmbedCardTitleDivier />

          <div className={`grid items-center grid-cols-[auto_auto_auto_1fr_auto] ${isMobile ? 'gap-x-2' : 'gap-x-4'} gap-y-2 font-roboto-condensed text-[#939393] font-light text-sm`}>
            {periods.map((period, idx) => (
              <React.Fragment key={idx}>
                <div className="col-span-5">
                  <Divider orientation="left" orientationMargin="0" className={`!border-gray-800 ${idx != 0 ? '!mt-2' : '!mt-0'} !mb-0`}>
                    <span className="font-roboto-condensed font-light text-gray-800">{translatePeriod(period)}</span>
                  </Divider>
                </div>
                {events.filter(e => e.period == period).map((event, idx) => (
                  <React.Fragment key={idx}>
                    <div className={`${isMobile ? 'ml-1' : 'ml-4'}`}>
                      {event.gameTime}
                    </div>
                    <div>
                      <span className={
                        `${isMobile ? 'w-[30px] h-[20px] px-[2px]' : 'w-[65px] h-[20px]'} ` +
                        `text-center rounded-[4px] text-white flex gap-1 items-center justify-center ` +
                        `${getTypeColor(event.entity)}`
                        }>
                          {!isMobile &&
                          <img src={getTypeIcon(event.entity)} className="w-4 h-4" />
                        }
                        {translateGameEventType(event.entity)}
                      </span>
                    </div>
                    <div>
                      <Tooltip title={isMobile ? getEventTeam(event)?.nick : null}>
                        <div className="flex gap-1 items-center">
                          <div
                            className={`flex-none ${isMobile ? 'w-[40px]' : 'w-[20px]'} h-[20px] bg-no-repeat bg-center rounded-[4px]`}
                            style={{ backgroundImage: `url('${getImage(getEventTeam(event), 'md')}')`, backgroundSize: '200%' }}>
                          </div>
                          {!isMobile &&
                            <span className="line-clamp-1">{getEventTeam(event)?.nick}</span>
                          }
                        </div>
                      </Tooltip>
                    </div>
                    <div>
                      <div className="flex gap-1 items-center justify-between desktop:justify-normal"> 
                        <span className="flex gap-1">
                          <span className="min-w-[22px] max-w-[22px]">
                            {getEventPlayer(event) &&
                              <Tooltip title={(getEventPlayer(event)?.firstName ?? '') + ' ' + (getEventPlayer(event)?.lastName ?? '')}>
                                <div
                                  className="flex-none w-[20px] h-[20px] bg-no-repeat bg-center rounded-[4px]"
                                  style={{ backgroundImage: `url('${getImage(getEventPlayer(event), 'md')}')`, backgroundSize: '200%' }}>
                                </div>
                              </Tooltip>
                            }
                          </span>
                          {isMobile &&
                            <span className="line-clamp-1">
                              {getEventDescriptionShort(event)}
                            </span>
                          }
                        </span>
                        {isMobile &&
                          <Tooltip title={getEventDescription(event)}>
                            <InfoCircleOutlined />
                          </Tooltip>
                        }
                        {!isMobile &&
                            <span className="line-clamp-3">
                              {getEventDescription(event)}
                            </span>
                        }
                      </div>
                    </div>
                    <span className="font-score text-[1.25em] text-center">
                      {event.scoredByTeamId &&
                        <span className="flex no-wrap justify-center">
                          <span className="min-w-[10px] desktop:min-w-[15px]">{event.scoreHome}</span>
                          <span className="min-w-[5px]  desktop:min-w-[5px] ">:</span>
                          <span className="min-w-[10px] desktop:min-w-[15px]">{event.scoreAway}</span>
                        </span>
                      }
                    </span>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </div>

        </EmbedCard>
      )
    );
  };

  const GameEmbedLineups = () => (
    data.Lineups.length > 0 && (
      <EmbedCard className="desktop:pb-10">
        <div className="font-roboto-condensed text-xl">
          {LANG['EMBED_GAME_TEAM_LINEUPS']}
        </div>

        <EmbedCardTitleDivier />

        <div className="mb-2 grid gap-5 desktop:grid-cols-2 desktop:gap-10">
          <TeamLineup team={data.HomeTeam} lineup={data.Lineups?.filter(l => l.teamId == data.HomeTeam?.teamId)} gameStars={data.GameStars} />
          <TeamLineup team={data.AwayTeam} lineup={data.Lineups?.filter(l => l.teamId == data.AwayTeam?.teamId)} gameStars={data.GameStars} />
        </div>

        <div className="mt-6 font-light text-[#a1a1a1] flex items-center justify-center gap-8">
          <div className="flex items-center justify-center gap-3">
            <TimekeeperIcon />
            {(data.timekeeper && data.timekeeper.trim() != '') ? data.timekeeper : LANG.UNKNOWN}
          </div>
          <div className="flex items-center justify-center gap-3">
            <RefereeIcon />
            {(data.referee && data.referee.trim() != '') ? data.referee : LANG.UNKNOWN}
          </div>
        </div>

        {data.overwriteEnabled == true && data.overwriteKeepStats == false &&
          <div className="mt-6 flex items-center justify-center gap-2 text-orange-500">
            <InfoCircleOutlined />
            <span className="font-light text-sm">
              {LANG['EMBED_GAME_DATA_NOT_AFFECTED_MESSAGE']}
            </span>
          </div>
        }

      </EmbedCard>
    )
  );

  const GameEmbedImages = () => (
    imagesData && imagesData.images && imagesData.images.length > 0 && (
      <EmbedCard>
        <div className="font-roboto-condensed text-xl">
          Obrázky
        </div>

        <EmbedCardTitleDivier />

        <div className="flex gap-2 flex-wrap">
          {imagesData.images.map((image, idx) => (
            <a key={idx} href={image.full} target="_blank" className="text-[0px] leading-none">
              <img className="w-[65px] desktop:w-[107px]" src={image.thumbnail} alt={`Flickr image ${idx}`} />
            </a>
          ))}
        </div>
      </EmbedCard>
    )
  );

  return (
    <div className="min-h-screen bg-[#eeeeee]">
      <div className="flex flex-col gap-5 items-center dekstop:mx-10 p-5 dekstop:p-10">

        <GameEmbedNews />

        <GameEmbedInfo />

        <EmbedCardVideo youtubeVideoUrl={imagesData?.youtubeVideoUrl} /> 

        <GameEmbedStars />

        <GameEmbedEvents />

        <GameEmbedLineups />

        <GameEmbedImages />

      </div>
    </div>
  );
}

export default EmbedGameInner