import { Tag } from 'antd';
import RelationsEditable from 'components/RelationsEditable';
import useEntities from 'hooks/useEntities';
import useIsMobile from 'hooks/useIsMobile';
import { entityColumnsToAntColumns } from 'utils/entityColumnsToAntColumns';
import { fullNameCamel } from 'utils/oneLiners';

const TeamPersons = ({ sourceEntityId, canEdit, data, onChange }) => {
    const entities = useEntities();
    const sourceEntity = entities.Team;
    const targetEntity = entities.Player;

    const isMobile = useIsMobile();

    const columns = isMobile ? [
        {
            column: 'Player', 
            width: 200, 
            searchValue: (p) => 
                (p.firstName ?? '') + (p.lastName ?? '') + (p.birthday ?? '') 
                                        + (p.status ?? '') + (p.cityBirth ?? ''),
            render:
                (_, p) => <>
                    <span>{fullNameCamel(p ?? {})} </span>
                    {p?.birthday && <Tag color={'blue'} className='ml-2'>
                        {p?.birthday}
                    </Tag>}
                    <Tag color={'success'} className='ml-1'>
                        {p?.status}
                    </Tag>
                    <span>{p?.cityBirth}</span>
                </>,
        },

    ] : [
        { column: 'NameWithLogo', width: 200, },
        { column: 'status', width: 50 },
        { column: 'birthday', width: 50 },
        { column: 'cityBirth', width: 80 },
    ];

    return (
        <RelationsEditable
            data={data}
            sourceEntity={sourceEntity}
            targetEntity={targetEntity}
            targetEntityColumns={entityColumnsToAntColumns(targetEntity, columns)}
            targetEntityModalWidth={800}
            sourceEntityId={sourceEntityId}
            emptyMessage={`This ${sourceEntity.displayName} does not have any ${targetEntity.displayNamePlural}`}
            onChange={onChange}
            canRemove={canEdit}
            canAdd={canEdit}
        />
    )
}

export default TeamPersons
