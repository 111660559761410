import useEntities from 'hooks/useEntities';
import HmsEntityTableController from 'components/HmsEntityTableController';
import { Tag, Tooltip } from 'antd';
import moment from 'moment';
import useLoginStore from 'store/loginStore';
import { formatDate, formatTime } from 'utils/generateEntityColumns';
import HmsStatusTag from 'components/HmsStatusTag';
import EntityLogo from 'components/EntityLogo';
import useIsMobile from 'hooks/useIsMobile';
import { sorted, unique } from 'utils/oneLiners';
import PlayerLevelTag from 'components/PlayerLevelTag';
import icons from 'utils/icons';

const PlayersTeamManager = () => {
  const entities = useEntities();
  const isMobile = useIsMobile();
  const getColumns = (showCreatedAt) => ([
    showCreatedAt && 'createdAt',
    { column: 'NameWithLogo', width: 200, fixed: !isMobile ? 'left' : undefined, },
    { column: 'LevelTag', },
    { column: 'Teams', width: 250, },
    { column: 'Positions', width: 125, },
    { column: 'Jerseys', width: 125, },
    'Status',
    'birthday',
    { column: 'phone', width: 175, },
    { column: 'email', width: 250, },
    'gender',
    'stick',
    'weight',
    'height',
    'externalId',
    'countryBirth',
    'cityBirth',
    'country',
    'city',
  ].map(c => c))

  return (
    <HmsEntityTableController
      entity={entities.Player}
      extraButtonBarItems={
        <Tooltip className="cursor-pointer" overlayStyle={{maxWidth: '450px'}} title={
          <div>
            <div>
              In this view you can find players that are connected with the teams you are managing.
              <br/>
              <br/>
              <span className="underline">Status:</span>
            </div>
            <div className="mt-4 flex flex-col gap-2">
              <div>
                <b>Active</b> - All your active players ready to play in current season
              </div>
              <div>
                <b>Preapproved</b> - Players that being sent for approval to become 'Active' (Will be processed by League manager)
              </div>
              <div>
                <b>Draft</b> - Players that registered themself using website form or you created them, but still did not sent them for approval
              </div>
              <div>
                <b>Retired</b> - Players that has status INACTIVE/RETIRED (you can ask for re-activate them using 'change request')
              </div>
            </div>
          </div>}
        >
          {icons.Information}
        </Tooltip>
      }
      columnSets={[
        // {
        //   name: 'All',
        //   columns: getColumns(false),
        // },
        {
          name: 'Active + New',
          filters: { status: ['ACTIVE', 'PREAPPROVED', 'DRAFT'] },
          columns: getColumns(false),
        },
        {
          name: 'Retired',
          filters: { status: ['INACTIVE', 'RETIRED', 'UNKNOWN'] },
          columns: getColumns(false),
        },
        // {
        //   name: 'Requested',
        //   filters: { status: ['PREAPPROVED'] },
        //   columns: getColumns(false),
        // },
        // {
        //   name: 'New',
        //   filters: { status: ['DRAFT'] },
        //   columns: getColumns(false),
        // },
      ]}
      onRowClickLink={(record) => `/players_team_manager/${record.playerId}`}
      createUrl={'/players_team_manager/_new_'}
      renderMobileRow={(record) => (
        <div>
          <div className="border-b-[1px] border-gray-100 px-4 py-4 flex rounded-t-md" style={{ background: 'linear-gradient(90deg, #f2f2f2 0%, #fafafa 2%, #fafafa 98%, #f2f2f2 100%)' }}>
            <div className="flex flex-col gap-[1px]">
              <span className="flex items-center gap-3">
                  <EntityLogo entity={record} size={24} />
                  <span className="font-bold text-[0.9rem]">{record?.firstName + ' ' + record?.lastName}</span>
              </span>
            </div>
            <div className="ml-auto my-auto">
              <HmsStatusTag className="m-0" status={record.status} entity="Player" />
            </div>
          </div>
          <div className="px-6 py-4 flex items-center rounded-b-md" style={{ background: 'linear-gradient(90deg, #f8f8f8 0%, #fff 2%, #fff 98%, #f8f8f8 100%)' }}>
            <div className="w-full grid grid-cols-4 gap-y-2">
              <span className="text-gray-500">Level:</span>
              <span className="col-span-3">
                {record.ListPlayerLevel == null && <span>-</span>}
                <span className="flex items-center gap-2">
                  <PlayerLevelTag listPlayerLevel={record.ListPlayerLevel} />
                  <span>{record.ListPlayerLevel?.name}</span>
                </span>
              </span>

              <span className="text-gray-500">Position(s):</span>
                <span className="col-span-3">
                  {record.ListPositions?.length == 0 && <span>-</span>}
                  <span className="flex flex-wrap gap-2">{sorted(unique(record.ListPositions?.map(p => p.name)))?.map((p, idx) => (
                      <Tag key={idx} className="text-[0.85rem]">{p}</Tag>
                    ))}
                  </span>
              </span>

              <span className="text-gray-500">Team(s):</span>
                <span className="col-span-3">
                  {record.Team?.length == 0 && <span>-</span>}
                  <span className="flex flex-col gap-3">
                      {record.Teams?.map((t, idx) => (
                        <span key={idx} className="flex items-center gap-2">
                            <EntityLogo entity={t} size={24} />
                            <span>{t.name}</span>
                        </span>
                      ))}
                  </span>
              </span>
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default PlayersTeamManager