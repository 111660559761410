import React, { createContext, useContext, useState, useEffect } from 'react';
import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useEntities from 'hooks/useEntities';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import useLoadEntity from 'hooks/useLoadEntity';
import HmsEntityFormGridFullWidth from 'components/Form/HmsEntityFormGridFullWidth';
import HmsField from 'components/Form/HmsField';
import FormSelect from 'components/Form/FormSelect';
import useFetchEntity from 'hooks/useFetchEntity';
import useFetchEntities from 'hooks/useFetchEntities';
import { Button, Card, Cascader, Divider, Form, Space, Tabs, Tooltip } from 'antd';
import PlayerSelect from 'components/Form/PlayerSelect';
import PositionSelect from 'components/Form/PositionSelect';
import JerseySelect from 'components/Form/JerseySelect';
import RemoveButton from 'components/RemoveButton';
import FormCascader from 'components/Form/FormCascader';
import { PlusOutlined } from '@ant-design/icons';
import FormCheckbox from 'components/Form/FormCheckbox';
import useInterval from 'use-interval';
import { Checkbox, Input, Row, Col, DatePicker, Select } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import FormDatePicker from 'components/Form/FormDatePicker';
import FormRangePicker from 'components/Form/FormRangePicker';
import TeamSelect from 'components/Form/TeamSelect';
import VenueSelect from 'components/Form/VenueSelect';
import FormColorList from 'components/Form/FormColorList';
import FormColorPicker from 'components/Form/FormColorPicker';
import { InfoCircleOutlined } from '@ant-design/icons';
import { set } from 'lodash';
import validateFlickrAlbumUrl from 'utils/validateFlickrUrl';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const GameInfo = ({ data, formValues }) => {
    const { isLoading: isMembershipLoading, data: membership } = useFetchEntities('Competition', { relations: 'Seasons>Groups>Phases' });
    const { data: homeTeam } = useFetchEntity('Team', formValues?.homeTeamId);
    const { data: awayTeam } = useFetchEntity('Team', formValues?.awayTeamId);
    const [flickrAlbumValidation, setFlickAlbumValidation] = useState(null);

    useEffect(() => {
        validateFlickrAlbum();
    }, [data]);

    const getTeamColors = (team) => {
        return [
            {
                name: 'Primary',
                hex: team?.jerseyColor1
            },
            {
                name: 'Secondary',
                hex: team?.jerseyColor2
            },
            {
                name: 'Tertiary',
                hex: team?.jerseyColor3
            },
        ]
            .filter(c => c.hex);
    }

    const membershipOptions =
        membership?.map(c => ({
            value: c.competitionId,
            label: c.Seasons.length > 0 ? c.name : <Tooltip title="There are no Seasons in this Competition">{c.name}</Tooltip>,
            disabled: c.Seasons.length == 0,
            children: c.Seasons.map(s => ({
                value: s.seasonId,
                label: s.Groups.length > 0 ? s.name : <Tooltip title="There are no Groups in this Season">{s.name}</Tooltip>,
                disabled: s.Groups.length == 0,
                children: s.Groups.map(g => ({
                    value: g.groupId,
                    label: g.Phases.length > 0 ? g.name : <Tooltip title="There are no Phases in this Group">{g.name}</Tooltip>,
                    disabled: g.Phases.length == 0,
                    children: g.Phases.map(p => ({
                        value: p.phaseId,
                        label: p.name,
                    }))
                }))
            }))
        }));

    const validateFlickrAlbum = () => {
        const flickrAlbum = formValues?.flickrAlbum ?? data?.flickrAlbum;
        if (flickrAlbum == null || flickrAlbum == '') {
            setFlickAlbumValidation(null);
        } else {
            const isValid = validateFlickrAlbumUrl(flickrAlbum);
            setFlickAlbumValidation({
                fieldClassName: isValid ? '!border-[#6bc63e]' : '!border-[#ff4d4f]',
                help: <span className={`flex w-full gap-2 ${isValid ? 'text-[#6bc63e]' : 'text-[#ff4d4f]'}`}>
                    <span>
                        {isValid ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                    </span>
                    <span className="break-all line-clamp-2 flex-wrap flex">
                        {isValid ? 'Flickr Album format OK' : 'Flickr Album format Invalid'}
                    </span>
                </span>
            });
        }
    }

    // const teamValidationRules = [
    //     ({ getFieldValue }) => ({
    //         validator(_, value) {
    //             const homeTeamId = getFieldValue('homeTeamId');
    //             const awayTeamId = getFieldValue('awayTeamId');
    //             if (homeTeamId && awayTeamId && homeTeamId === awayTeamId) {
    //                 return Promise.reject(new Error('Home Team and Away Team cannot be the same!'));
    //             } else {
    //                 return Promise.resolve();
    //             }
    //         },
    //     }),
    // ];

    return (
        <>
            <HmsEntityFormGridFullWidth>
                <FormCascader
                    name="phaseId"
                    label="Membership"
                    options={membershipOptions}
                />
            </HmsEntityFormGridFullWidth>

            <HmsEntityFormGridBasic>
                <HmsField name="startDate" />
                <HmsField name="startTime" />
                <HmsField name="endDate" />
                <HmsField name="endTime" />
            </HmsEntityFormGridBasic>

            <HmsEntityFormGridBasic>
                <TeamSelect 
                    name="homeTeamId" 
                    label="Home Team" 
                    phaseId={null}          // TODO: Implement PhaseId
                    dependencies={['awayTeamId']}
                    // rules={teamValidationRules}
                />
                <TeamSelect 
                    name="awayTeamId" 
                    label="Away Team" 
                    phaseId={null}          // TODO: Implement PhaseId
                    dependencies={['homeTeamId']}
                    // rules={teamValidationRules}
                />
                <VenueSelect name="venueId"/>
            </HmsEntityFormGridBasic>

            <HmsEntityFormGridBasic>
                <FormColorList name="homeJerseyColor" label="Home Jersey Color" colors={getTeamColors(homeTeam)} />
                <FormColorList name="awayJerseyColor" label="Away Jersey Color" colors={getTeamColors(awayTeam)} />
            </HmsEntityFormGridBasic>

            <Divider />

            <HmsEntityFormGridBasic>
                <HmsField name="name" />
                <HmsField name="price" addonAfter="Kč" />
                {/* <HmsField name="type" /> */}
                <HmsField name="foreignId" />
                <HmsField name="info" />
                <HmsField name="description" />
                <HmsField name="timekeeper" />
                <HmsField name="referee" />
                <HmsField name="flickrAlbum"
                    label={
                        <span className="flex gap-1 items-center">
                            Flickr Album:
                            <Tooltip overlayClassName="custom-wide-tooltip" title={
                                <div>
                                    <div>Allowed formats:</div>
                                    <ul>
                                        <li>72177720311061111</li>
                                        <li>https://www.flickr.com/photos/153512269@N03/albums/72177720311621111</li>
                                        <li>https://www.flickr.com/photos/flickr/albums/72157716075071111</li>
                                        <li>https://www.flickr.com/gp/153512269@N03/M84TeP1111</li>
                                        <li>https://flic.kr/s/aHBqjA1111</li>
                                        <li>... or leave empty</li>
                                    </ul>
                                </div>
                            }>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                    formItemProps={{
                        validateStatus: flickrAlbumValidation?.status,
                        help: flickrAlbumValidation?.help,
                    }}
                    fieldItemProps={{
                        onBlur: () => validateFlickrAlbum(),
                        className: flickrAlbumValidation?.fieldClassName,
                    }}
                />
                <HmsField name="youtubeVideoUrl" />
                <HmsField name="report" />
            </HmsEntityFormGridBasic>

            {/* <FormRangePicker
                        label="Timing"
                        names={["startDate", "startTime", "endDate", "endTime"]}
                      /> */}


            {/* <h4>Teams:</h4>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-5">
                {['Home', 'Away'].map(n => (
                    <div key={n} className="flex gap-2">
                        <div className="flex-1">
                            <TeamSelect
                                phaseId={entityData?.phaseId}
                                teamName={`${n} Team`}
                                teamIdField={`${n.toLowerCase()}TeamId`}
                            />
                        </div>
                        <div className="flex-none w-36">
                            <HmsField
                                label="Jersey Color"
                                name={`${n.toLowerCase()}JerseyColor`}
                            />
                        </div>
                    </div>
                ))}
            </div> */}
        </>
    )
}

export default GameInfo;
