import EmbedGameInner from 'components/Embeds/EmbedGameInner';
import EmbedSeasonTermsInner from 'components/Embeds/EmbedSeasonTermsInner';
import useFetchData from 'hooks/useFetchData'
import useLang from 'hooks/useLang';
import usePageTitle from 'hooks/usePageTitle';
import React from 'react'
import { useSearchParams } from 'react-router-dom';

const EmbedSeasonTerms = () => {
  const [ searchParams ] = useSearchParams();
  const seasonId = searchParams.get('seasonId');
  const lang = searchParams.get('lang');
  const LANG = useLang(lang);
  const { data } = useFetchData(`/public/seasonTerms` +
    (seasonId ? `?seasonId=${seasonId}` : '')
  );
  const title = LANG.EMBED_SEASON_TERMS_TITLE + (data ? `: ${data?.Competition?.name} - ${data?.name}` : '');
  usePageTitle(title);

  if (!data) {
    return (<></>);
  }

  return (
    <>
      <EmbedSeasonTermsInner data={data} lang={lang} title={title} />
    </>
  )
}

export default EmbedSeasonTerms