import { Link } from 'react-router-dom';
import HmsTable from 'components/HmsTable';
import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useEntities from 'hooks/useEntities';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import useLoadEntity from 'hooks/useLoadEntity';
import HmsField from 'components/Form/HmsField';
import GroupPhases from 'components/Group/GroupPhases';
import EntityAttachments from 'components/EntityAttachments';
import HmsTabbedTables from 'components/HmsTabbedTables';
import GroupTeams from 'components/Group/GroupTeams';
import EntityLogoEditor from 'components/EntityLogoEditor';

const Group = () => {
  const entities = useEntities();
  const entity = entities.Group;
  const parentEntity = entities.Season;
  const childEntity = entities.Phase;
  const { id, parentId, isLoading, isError, isNotFound, data, reload, parentData, childrenData } = useLoadEntity({ entity, parentEntity, childEntity });
  const { relationsData: attachmentsData, reloadRelations: reloadAttachments } = useLoadEntity({ entity,
    relations: [
      'Attachments',
    ].join(',')
  });
  const { relationsData: teamsData, reloadRelations: reloadTeams } = useLoadEntity({ entity,
    relations: [
      'Teams',
    ].join(',')
  });
  
  return (
    <>
      <HmsEntityFormController
        {... {entity, parentEntity, id, parentId, isLoading, isError, isNotFound, data, reload, parentData, childrenData} }
        form={(
          <div className="flex flex-col desktop:flex-row w-full">
            <div className="flex items-center justify-center ml-4 mr-6 mb-5 desktop:mb-0 ">
              <EntityLogoEditor entity={entities.Group} id={id} data={data} size={112} />
            </div>
            <div className="flex-auto">
              <HmsEntityFormGridBasic>
                <HmsField name="name" autoFocus />
                <HmsField name="nick" />
                <HmsField name="short" />
                <HmsField name="teamsLimit" />
                <HmsField name="status" />
                <HmsField name="color" />
              </HmsEntityFormGridBasic>
            </div>
          </div>
        )}
        footer={id != '_new_' && (
          <div className="mt-4">
            <HmsTabbedTables
              tables={[
                {
                  title: "Phases" + (childrenData ? (' (' + childrenData?.length + ')') : ''),
                  children: <GroupPhases sourceEntityId={id} data={childrenData} />
                },
                {
                  title: "Teams" + (teamsData?.Teams ? (' (' + teamsData?.Teams?.length + ')') : ''),
                  children:
                    <GroupTeams data={teamsData?.Teams} sourceEntityId={data?.groupId} onChange={reloadTeams} />
                },
                {
                  title: "Attachments" + (attachmentsData?.Attachments ? (' (' + attachmentsData?.Attachments?.length + ')') : ''),
                  children: <EntityAttachments entity={entity.name} id={data?.[entity.primaryKey]} data={attachmentsData} reload={reloadAttachments} />,
                },
              ]}
            />
          </div>
        )}
      />
    </>
  );
}

export default Group
