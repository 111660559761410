import { Layout } from 'antd';
import SchedulerSimulation from 'pages/SchedulerSimulation';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

export default function SchedulerLayout() {
  return (
    <Layout className="w-screen h-screen bg-white">
      <Outlet />
    </Layout>
  )
}
