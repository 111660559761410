import React from "react";
import LogoOrTitle from "./LogoOrTitle";
import GraphWinLossDraw from "./GraphWinLossDraw";
import IGImg from "./IGImg";

const GameUpcomingCard = ({ game }) => {
  return (
    <div className="grid w-full grid-cols-3 place-items-center border-b-2 border-white bg-hockey-card-bg p-2 pt-0 text-center font-header text-lg font-bold text-white">
      <span className="z-[2] w-full bg-hockey-card-bg py-3 h-20 flex items-center justify-center">
        {game.HomeTeamName ? game.HomeTeamName : "Domácí"}
      </span>
      <LogoOrTitle
        logo={game.LeagueLogo}
        title="VS"
        logoClassName="object-contain h-[50px] max-w-[150px]"
      />
      <span className="z-[2] w-full bg-hockey-card-bg py-3">
        {game.AwayTeamName ? game.AwayTeamName : "Hosté"}
      </span>

      <IGImg
        src={game.HomeTeamLogo}
        alt="Home Team Logo"
        className="z-[1] max-h-[90px] max-w-[90px] scale-150 rounded-full border-4 border-gray-800"
      />
      <p className="text-5xl font-normal">{game.Time}</p>
      <IGImg
        src={game.AwayTeamLogo}
        alt="Away Team Logo"
        className="z-[1] max-h-[90px] max-w-[90px] scale-150 rounded-full border-4 border-gray-800"
      />


      <GraphWinLossDraw wins={game.HomeTeamWins ?? 0} losses={game.HomeTeamLosses ?? 0} draws={game.HomeTeamDraws ?? 0} />
      {/* TODO: Possibly add a color for each league and make an enum with the league colors */}
      <p 
        className="font-league text-base" 
        style={{ color: game.GroupColor ?? '#777777' }}
      >
        {game.GroupName}
      </p>
      <GraphWinLossDraw wins={game.AwayTeamWins ?? 0} losses={game.AwayTeamLosses ?? 0} draws={game.AwayTeamDraws ?? 0} />
    </div>
  );
};

export default GameUpcomingCard;
