import React, { useEffect, useState } from 'react';
import { Spin, Layout } from 'antd'
import { CloseCircleFilled } from '@ant-design/icons';

const AppLoadingLayout = ({ delay }) => {
  const [loaderVisible, setLoaderVisible] = useState(!delay);

  useEffect(() => {
    if (delay) {
      const timer = setTimeout(() => {
        setLoaderVisible(true);
      }, delay);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <Layout className="absolute top-0 left-0 right-0 bottom-0 flex bg-white justify-center items-center">
      <div className={`opacity-0 transition-opacity duration-500 ${loaderVisible ? 'opacity-100' : ''}`}>
        <div className="flex flex-col gap-6">
          <div className="text-blue-400 text-lg">HMS is loading...</div>
          <Spin />
        </div>
      </div>
    </Layout>
  )
}

export default AppLoadingLayout