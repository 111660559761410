import { create } from 'zustand';

const useLoginStore = create((set, get) => ({
    BASE_URL: process.env.REACT_APP_BACKEND_BASE_URL,

    isLoggedIn: null,
    user: null,
    roles: null,
    managedTeams: null,

    updateIsLoggedIn: async () => {
        const BASE_URL = get().BASE_URL;
    
        try {
            // console.log('UpdateIsLoggedIn...')

            const response = await fetch(`${BASE_URL}/auth/isLoggedIn`, {
                method: 'GET',
                credentials: 'include',
            });

            const respBody = await response.json();
            set({
                isLoggedIn: (respBody?.user) ? true : false,
                user: respBody?.user,
                roles: respBody?.user?.roles?.split(','),
                managedTeams: respBody?.user?.ManagedTeams,
            });

            // console.log('UpdateIsLoggedIn = ' + ((respBody?.user) ? true : false))

            return {
                status: response.status,
                body: respBody,
            }
        } catch (e) {
            console.error(e);
        }
    },

    login: async (data) => {
        const BASE_URL = get().BASE_URL;
    
        try {
            const response = await fetch(`${BASE_URL}/auth/login`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            get().updateIsLoggedIn();

            const respBody = await response.json();
            return {
                status: response.status,
                body: respBody,
            }
        } catch (e) {
            console.error(e);
            return {
                status: null,
                body: null,
            };
        }
    },

    logout: async () => {
        const BASE_URL = get().BASE_URL;
    
        try {
            const response = await fetch(`${BASE_URL}/auth/logout`, {
                method: 'POST',
                credentials: 'include',
            });

            get().updateIsLoggedIn();

            const respBody = await response.json();
            return {
                status: response.status,
                body: respBody,
            }
        } catch (e) {
            console.error(e);
            return {
                status: null,
                body: null,
            };
        }
    },

    verifyPasswordResetRequest: async ({ passwordResetRequestId }) => {
        const BASE_URL = get().BASE_URL;
    
        try {
            const response = await fetch(`${BASE_URL}/auth/verifyPasswordResetRequest?passwordResetRequestId=${passwordResetRequestId}`, {
                method: 'GET',
                credentials: 'include',
            });

            const respBody = await response.json();
            return {
                status: response.status,
                body: respBody,
            }
        } catch (e) {
            console.error(e);
            return {
                status: null,
                body: null,
            };
        }
    },

    forgotPassword: async ({ email }) => {
        const BASE_URL = get().BASE_URL;
    
        try {
            const response = await fetch(`${BASE_URL}/auth/forgotPassword`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                }),
            });

            const respBody = await response.json();
            return {
                status: response.status,
                body: respBody,
            }
        } catch (e) {
            console.error(e);
            return {
                status: null,
                body: null,
            };
        }
    },

    resetPassword: async ({ passwordResetRequestId, newPassword }) => {
        const BASE_URL = get().BASE_URL;
    
        try {
            const response = await fetch(`${BASE_URL}/auth/resetPassword`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    passwordResetRequestId,
                    newPassword,
                }),
            });

            const respBody = await response.json();
            return {
                status: response.status,
                body: respBody,
            }
        } catch (e) {
            console.error(e);
            return {
                status: null,
                body: null,
            };
        }
    },

    hasRole: (role) => {
        const roles = get().roles;
        return roles?.indexOf(role) != -1;
    },
}));

export default useLoginStore;
