import React, { useEffect, useState } from 'react'
import { simulateCtrlClick } from 'utils/simulateCtrlClick';
import { Link, useNavigate } from 'react-router-dom';
import EmbedCard from 'components/Embeds/EmbedCard';
import { formatDate, formatTime } from 'utils/generateEntityColumns';
import EmbedCardTitleDivier from 'components/Embeds/EmbedCardTitleDivier';

const EmbedDisciplinariesInner = ({ data, lang }) => {
  const navigate = useNavigate();

  const rowClicked = (row, event) => {
    const isCtrlClick = event.metaKey || event.ctrlKey;
    const link = `/embed/disciplinary?disciplinaryId=${row.disciplinaryId}`;
    if (isCtrlClick) {
        simulateCtrlClick(link);
    } else {
        navigate(link)
    }
  }

  return (
    <div className="min-h-screen bg-[#eeeeee]">
      <div className="flex flex-col gap-5 items-center dekstop:mx-10 p-5 dekstop:p-10">

        <EmbedCard>
          <div className="font-roboto-condensed text-xl">
            Seznam disciplinárních řízení
          </div>
          <EmbedCardTitleDivier />

          <table className="mt-6 w-full border-collapse font-roboto-condensed">
            <thead>
              <tr>
                <th className="font-medium text-left border-b-[0px] border-gray-400 py-3">Datum</th>
                <th className="font-medium text-left border-b-[0px] border-gray-400 py-3">Zápas</th>
                <th className="font-medium text-left border-b-[0px] border-gray-400 py-3">Hráč</th>
                <th className="font-medium text-left border-b-[0px] border-gray-400 py-3">Předmět</th>
                <th className="font-medium text-left border-b-[0px] border-gray-400 py-3">Stav</th>
              </tr>
            </thead>
            <tbody>
              {data.map((d, idx) => (
                <tr key={idx}>
                  <td className="align-top font-light pr-4 py-2">{formatDate(d.date)}</td>
                  <td className="align-top font-light pr-4 py-2">
                    {d.Game ? (
                      <span className="flex gap-1 flex-wrap">
                        <span>{d.Game?.startDate ? formatDate(d.Game?.startDate) : 'bez data'} {formatTime(d.Game?.startTime)}</span>
                        •
                        <span>{d.Game?.HomeTeam?.short} vs {d.Game?.AwayTeam?.short}</span>
                      </span>
                    ) : (
                      'bez zápasu'
                    )}
                  </td>
                  <td className="align-top font-light py-2">
                    <span className="flex gap-2 flex-col">
                      {d.Players?.map((p, idx) => (
                        <span key={idx}>
                          {p.firstName} {p.lastName}
                        </span>
                      ))}
                    </span>
                  </td>
                  <td className="align-top font-light pr-4 py-2 max-w-[200px]">
                    <Link to={`/embed/disciplinary?disciplinaryId=${d.disciplinaryId}`} className="text-black hover:no-underline">
                      {(d.subject && d.subject != '') ? d.subject : 'Bez předmětu'}
                    </Link>
                  </td>
                  <td className="align-top font-light pr-4 py-2">{d.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </EmbedCard>

      </div>
    </div>
  );

}

export default EmbedDisciplinariesInner