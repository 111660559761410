import { useState, useEffect } from 'react';
import HmsTable from 'components/HmsTable';
import useFetchEntities from 'hooks/useFetchEntities';
import HmsTableLayout from 'components/HmsTableLayout';
import useEntities from 'hooks/useEntities';
import useFields from 'hooks/useFields';
import { entityColumnsToAntColumns } from 'utils/entityColumnsToAntColumns';

const RulePackList = ({ entity: entityName }) => {
  const entities = useEntities();
  const entity = entities[entityName];
  const { data: fetchedData } = useFetchEntities(entityName, { relations: entity.fetchRelations });
  const [data, setData] = useState(null);
  const allFields = useFields();

  useEffect(() => {
    setData(fetchedData ? fetchedData.map(r => ({...r, fullName: r.firstName + ' ' + r.lastName})) : null);
  }, [fetchedData]);

  return (
    <HmsTableLayout breadcrumbs={entity.entitiesBreadcrumb}>
      <HmsTable
        columns={entityColumnsToAntColumns(entity, entity.defaultColumns)}
        data={data}
        title={entity.namePlural}
        createButtonLink={entity.newUrl}
        createButtonType="primary"
        onRowClickedLink={(record) => entity.entityUrl(record)}
        fixedX={true}
        fixedY={true}
    />
    </HmsTableLayout>
  )
}

export default RulePackList
