import React from 'react';

const SchedulerSimulationLog = ({ data }) => {
    const log = data?.log;
    const algorithm = data?.algorithm;

    return (
        <pre className="whitespace-pre-wrap bg-gray-100 p-2 text-xs">
            <div className="mb-5">
                Algorithm: <b>{algorithm}</b>
            </div>

            {log && Array.isArray(log) && log.length > 0 &&
                <div className="flex flex-col gap-1">
                    {log.map((e) => (
                        <div>{e}</div>
                    ))}
                </div>
            }
        </pre>
    )
}

export default SchedulerSimulationLog


