import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Spin } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Content } from 'antd/es/layout/layout';
import useLoginStore from 'store/loginStore';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { set } from 'lodash';
import usePageTitle from 'hooks/usePageTitle';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import LoginLayout from 'components/Layouts/LoginLayout';
import { validatePassword } from 'utils/passwordUtils';

const ResetPassword = () => {
  usePageTitle('HMS - Reset Password');

  const [status, setStatus] = useState('LOADING');

  const [searchParams] = useSearchParams()
  const passwordResetRequestId = searchParams.get('passwordResetRequestId');

  const resetPassword = useLoginStore(s => s.resetPassword);
  const verifyPasswordResetRequest = useLoginStore(s => s.verifyPasswordResetRequest);

  const [errorMessage, setErrorMessage] = useState(null);

  const [submitInProgress, setSubmitInProgress] = useState(false);

  const [form] = Form.useForm();

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues['password'] || changedValues['password_confirm']) {
      form.validateFields(['password_confirm']);
    }
  }

  useEffect(() => {
    async function runVerifyPasswordResetRequest() {
      const res = await verifyPasswordResetRequest({
        passwordResetRequestId,
      });
      if (res.status == 200) {
        if (res.body.status == 'OK') {
          setStatus('ENTER_PASSWORD');
        } else {
          setStatus('INVALID_INVITE');
        }
      }
    }
    runVerifyPasswordResetRequest();
  }, [])

  const onFinish = async (values) => {
    setSubmitInProgress(true);
    setErrorMessage(null);

    const res = await resetPassword({
      passwordResetRequestId,
      newPassword: values.password,
    });

    if (res.status == 200) {
      setStatus('SUCCESS');
    } else {
      setErrorMessage('Sorry, there was an error processing your request.');
    }

    setSubmitInProgress(false);
  }

  const validatePasswordConfirmField = (_, value) => {
    const { password } = form.getFieldsValue();
    if (value && value !== password) {
      return Promise.reject('The two passwords do not match!');
    }
    return Promise.resolve();
  };

  const validatePasswordField = (_, value) => {
    const result = validatePassword(value);
    if (result.isValid) {
      return Promise.resolve();
    } else {
      return Promise.reject(result.message);
    }
  };

  return (
    <LoginLayout>

      {status == 'LOADING' &&
        <div className="flex items-center justify-center h-[100px]">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} />
        </div>
      }

      {status == 'INVALID_INVITE' &&
        <div className="max-w-[80vw] flex items-center justify-center">
          <div className="text-center text-white">
            <div className="mt-4 mb-4 text-red-500 text-lg flex gap-2 items-center justify-center">
              <CloseCircleFilled />
              Looks like there is a problem
            </div>
            <div className="mt-8">
              <p>
                We are sorry, but this link is not valid anymore.
              </p>
            </div>
          </div>
        </div>
      }

      {status == 'ENTER_PASSWORD' &&
        <Form
          form={form}
          onValuesChange={onValuesChange}
          onFinish={onFinish}
        >
          <div className="text-white mt-2 mb-8 text-center">
            Please create a new password:
          </div>

          <div className="w-[300px] grid grid-cols-1 mx-auto">


            {/* <div>
                <Form.Item
                  name="email"
                >
                  <Input className="w-full" prefix={<UserOutlined className="text-gray-400 mr-1" />} />
                </Form.Item>
              </div> */}

            <div>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'Missing field' },
                  { validator: validatePasswordField },
                ]}
              >
                <Input className="w-full" placeholder="Enter a new password" type="password" prefix={<LockOutlined className="text-gray-400 mr-1" />} />
              </Form.Item>
            </div>

            <div>
              <Form.Item
                name="password_confirm"
                rules={[
                  { required: true, message: 'Missing field' },
                  { validator: validatePasswordConfirmField },
                ]}
              >
                <Input className="w-full" placeholder="Confirm password" type="password" prefix={<LockOutlined className="text-gray-400 mr-1" />} />
              </Form.Item>
            </div>

            <div>
              {!submitInProgress && <Button className="w-full" type="primary" htmlType="submit" >Set Password</Button>}
              {submitInProgress && <Button className="w-full" type="primary" htmlType="submit" loading={true}> </Button>}
            </div>
          </div>

          <div className="h-10 mt-5 text-center">
            {errorMessage &&
              <span className="mb-4 text-red-500 text-center">
                {errorMessage}
              </span>
            }
          </div>
        </Form>
      }

      {status == 'SUCCESS' &&
        <div className="max-w-[80vw] flex items-center justify-center">
        <div className="text-center text-white">
          <div className="mt-4 mb-4 text-green-400 text-lg flex gap-2 items-center justify-center">
            <CheckCircleFilled />
            Success!
          </div>
          <div className="mt-8">
            <p>
              We have just changed your password. You can now log in.
            </p>
            <p className="mt-10 flex items-center justify-center">
              <div className="w-[300px] grid grid-cols-1 mx-auto">
                <Button className="w-full" type="primary" onClick={() => { window.location.href = '/auth/login?redirectTo=/'; }} >Log in</Button>
              </div>
            </p>
          </div>
        </div>
      </div>
    }


    </LoginLayout>
  )
}

export default ResetPassword
