import React from 'react'
import { Cascader, Form, Input, Row, Col, DatePicker, Select } from 'antd';

const LastValueCascader = ({ value, onChange, ...props }) => {
  function findValuePath(jsonData, targetValue, path = []) {
      if (!jsonData)
          return null;

      for (let i = 0; i < jsonData.length; i++) {
          const node = jsonData[i];
  
          if (node.value === targetValue) {
              return [...path, node.value];
          }
  
          if (node.children) {
              path.push(node.value);
              const result = findValuePath(node.children, targetValue, path);
              if (result !== null) {
                  return result;
              }
              path.pop();
          }
      }
      return null;
  }

  const toValueArray = (val) => {
      return findValuePath(props.options, val);
  }

  const getLastValue = (valArray) => {
      return valArray && valArray[valArray.length - 1];
  }

  return (
      <Cascader
          {...props}
          value={toValueArray(value)}
          onChange={onChange ? (value) => onChange(getLastValue(value)) : null}
      />
  );
}

export default LastValueCascader;
