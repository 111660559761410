import React, { useEffect, useState } from 'react'
import AppLoadingLayout from 'components/Layouts/AppLoadingLayout';
import { ListProvider, ListContext } from 'components/ListsProvider';
import { LoginContext, LoginProvider } from 'components/LoginProvider';
import LoginPage from 'pages/LoginPage';
import AppErrorLayout from 'components/Layouts/AppErrorLayout';
import useLoginStore from 'store/loginStore';
import { useNavigate } from 'react-router-dom';
import useGlobalStore from 'store/globalStore';
import AdminLayout from 'components/Layouts/AdminLayout';

const LoginWrapper = ({ roles, children }) => {
    const [wasLoggedIn, setWasLoggedIn] = useState(false);
    const isLoggedIn = useLoginStore(s => s.isLoggedIn);
    const userRoles = useLoginStore(s => s.roles);
    const ORGANIZATION_ID = useGlobalStore(s => s.ORGANIZATION_ID);
    const hasRequiredRole = roles ? roles.some(r => userRoles?.indexOf(r) != -1) : true;
    const navigate = useNavigate();

    // redirect to / after logout - to prevent 403 forbidden after login as another user
    useEffect(() => {
        if (isLoggedIn) {
            setWasLoggedIn(true);
        } else {
            if (wasLoggedIn) {
                navigate('/');
            }
        }
    }, [isLoggedIn]);

    return (
        <LoginProvider>
            {(isLoggedIn === null) && <AppLoadingLayout delay={250} />}
            {(isLoggedIn === false) && <LoginPage />}
            {(isLoggedIn === true && ORGANIZATION_ID == 'NO_ORGANIZATIONS') &&
                <AppErrorLayout type="NO_ORGANIZATIONS" />
            }
            {(isLoggedIn === true && ORGANIZATION_ID != 'NO_ORGANIZATIONS' && hasRequiredRole) &&
                <ListProvider>
                    <ListContext.Consumer>
                        {({ isLoading, isError }) => <>
                            {isLoading && <AppLoadingLayout />}
                            {isError && <AppErrorLayout />}
                            {(!isLoading && !isError) && <>{children}</>}
                        </>}
                    </ListContext.Consumer>
                </ListProvider>
            }
            {(isLoggedIn === true && ORGANIZATION_ID != 'NO_ORGANIZATIONS' && !hasRequiredRole) &&
                <AppErrorLayout type="403" />
            }
        </LoginProvider>
    )
}

export default LoginWrapper