import { CalendarOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import EmbedStatsWrapper from 'components/Embeds/EmbedStatsWrapper';
import FilterWrapper from 'components/Embeds/FilterWrapper';
import useIsMobile from 'hooks/useIsMobile';
import useLang from 'hooks/useLang';
import RefereeIcon from 'icons/RefereeIcon';
import TimekeeperIcon from 'icons/TimekeeperIcon';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { compareString } from 'utils/comparators';
import { getImage } from 'utils/getImage';
import icons from 'utils/icons';
import { removeAccents, sorted, unique } from 'utils/oneLiners';
import { simulateCtrlClick } from 'utils/simulateCtrlClick';

const EmbedGamesInner = ({ data, lang }) => {
  const [search, setSearch] = useState('');
  const LANG = useLang(lang);
  const [phaseFilter, setPhaseFilter] = useState(null);
  const [groupFilter, setGroupFilter] = useState(null);

  const [teamFilter, setTeamFilter] = useState(null);
  const todayRef = useRef(null);
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  // Helper functions

  const isToday = (day) => {
    return day == moment().format('YYYY-MM-DD');
  }

  const getYearMonthInCzech = (d) => {
    const months = LANG.MONTHS;
    const date = moment(d, 'YYYY/MM');
    const formattedDate = `${months[new Date(date).getMonth()]}, ${date.format('YYYY')}`;
    return formattedDate;
  }

  const getDateInCzech = (d) => {
    const weekdays = LANG.DAYS;
    const formattedDate = moment(d).format(`[${weekdays[moment(d).day()]}], DD.MM.YYYY`);
    return formattedDate;
  }

  const monthCache = {};
  const getMonth = (day) => {
    if (monthCache[day] == null) {
      monthCache[day] = moment(day).format('YYYY/MM');
    }
    return monthCache[day];
  }

  /* SCROLL TO TODAY */

  const updateHeaderHeight = () => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.getBoundingClientRect().height);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateHeaderHeight);
    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);

  // Data preprocessing

  const indexedData = useMemo(() => {
    console.log('useMemo - indexedData');
    return data.map(g => ({
      ...g,
      ftIndex: removeAccents([
        g.HomeTeam?.name,
        g.AwayTeam?.name,
        g.referee,
        g.timekeeper,
        g.Venue?.name,
      ].map(v => v ?? '').join(' ')).toUpperCase()
    }));
  }, [data]);


  const groupOptions = useMemo(() => {
    if (indexedData) {
      const groups = {};
      for (const game of data) {
        const group = game.Phase?.Group;
        if (group) {
          groups[group.groupId] = group;
        }
      }
      const options = Object.values(groups).map(g => ({ value: g.groupId, label: g.name }));
      options.sort((a, b) => compareString(a.label, b.label));
      return options;

    } else {
      return [];
    }
  }, [data]);

  const phaseOptions = useMemo(() => {
    if (indexedData && groupFilter) {
      const phases = {};
      for (const game of data) {
        const phase = game.Phase;
        if (groupFilter && phase?.Group?.groupId != groupFilter) continue;
        if (phase) {
          phases[phase.phaseId] = phase;
        }
      }
      const options = Object.values(phases).map(p => ({ value: p.phaseId, label: p.name }));
      options.sort((a, b) => compareString(a.label, b.label));
      return options;

    } else {
      return [];
    }
  }, [data, groupFilter]);

  const IsInCompGroupPhase = (g, groupFilter, phaseFilter) => {
    if (groupFilter && g.Phase?.Group?.groupId != groupFilter) return false;
    if (phaseFilter && g.Phase?.phaseId != phaseFilter) return false;
    return true;
  }

  const teamOptions = useMemo(() => {
    if (indexedData && groupFilter && phaseFilter) {
      return sorted(
        unique(
          [
            ...indexedData.map(g => IsInCompGroupPhase(g, groupFilter, phaseFilter) ? g?.HomeTeam?.name : null),
            ...indexedData.map(g => IsInCompGroupPhase(g, groupFilter, phaseFilter) ? g?.AwayTeam?.name : null)
          ]
        )
      )
        .filter(t => t && t != '')
        .map(t => ({ value: t, label: t }));

    } else {
      return [];
    }
  }, [data, groupFilter, phaseFilter]);

  useEffect(() => {
    updateHeaderHeight();
  }, [ teamOptions]);

  const scrollToToday = (e) => {
    e?.preventDefault();

    if (todayRef.current) {
      const rect = todayRef.current.getBoundingClientRect();
      const absoluteElementTop = rect.top + window.pageYOffset;
      const offset = !isMobile ? headerHeight : 15;
      const top = absoluteElementTop - offset;
      window.scrollTo({
        top: top,
        behavior: 'auto' // replace 'instant' with 'auto' for non-smooth scrolling in modern browsers
      });
    }
  }

  const allDays = useMemo(() => {
    if (indexedData) {
      const allDays = sorted(unique(indexedData.map(g => g.startDate).filter(d => d)));

      const minDate = allDays[0];
      const maxDate = allDays[allDays.length - 1];
      const today = moment().format('YYYY-MM-DD');
      if (minDate <= today && today <= maxDate) {
        if (allDays.indexOf(today) == -1) {
          allDays.push(today);
          allDays.sort();
        }
      }

      return allDays;
    } else {
      return [];
    }
  }, [indexedData]);

  const gamesByMonth = useMemo(() => {
    if (indexedData) {
      const searchWords = search.trim() != '' ? removeAccents(search.trim().toUpperCase()).split(' ') : [];

      const allMonths = sorted(unique(allDays.map(d => getMonth(d))));

      const result = allMonths
        .map(m => {
          return {
            month: m,
            days: allDays
              .filter(d => getMonth(d) == m)
              .map(d => {
                const games = indexedData.filter(g => {
                  // filter on date
                  if (g.startDate != d) return false;
                  // filter on phase
                  if (!((!phaseFilter || phaseFilter == g.Phase?.phaseId))) { return false; }
                  // filter on group
                  if (!((!groupFilter || groupFilter == g.Phase?.Group?.groupId))) { return false; }
                  // filter on search
                  for (const searchWord of searchWords) {
                    if (g.ftIndex.indexOf(searchWord) == -1) {
                      return false;
                    }
                  }
                  // filter on team
                  if (!((!teamFilter || teamFilter.toUpperCase() == g.HomeTeam?.name.toUpperCase() || teamFilter.toUpperCase() == g.AwayTeam?.name.toUpperCase()))) {
                    return false;
                  }

                  return true;
                });
                games.sort((a, b) => (a.startTime ?? '').localeCompare(b.startTime ?? ''));
                return {
                  day: d,
                  games: games,
                };
              })
              .filter(d => d.games.length > 0 || isToday(d.day))
          };
        })
        .filter(m => m.days.length > 0)

      return result;
    } else {
      return [];
    }
  }, [indexedData, groupFilter, phaseFilter, teamFilter, search]);

  const hasToday = useMemo(() => {
    const allDays = sorted(unique(indexedData.map(g => g.startDate).filter(d => d)));

    const minDate = allDays[0];
    const maxDate = allDays[allDays.length - 1];
    const today = moment().format('YYYY-MM-DD');
    if (minDate <= today && today <= maxDate) {
      if (allDays.indexOf(today) == -1) {
        allDays.push(today);
        allDays.sort();
      }
      return true;
    } else {
      return false;
    }

  }, [indexedData, teamFilter, search]);

  // Helper functions

  const getScoreBgImage = (game, HomeTeamGoals, AwayTeamGoals) => {
    if ((game.status == 'FINISHED' || game.status == 'RUNNING')) {
      if (HomeTeamGoals > AwayTeamGoals) return '/Export-HomeWin.png';
      if (HomeTeamGoals < AwayTeamGoals) return '/Export-AwayWin.png';
    }
    return '/Export-Tie.png';
  }

  const getHomeTeamResult = (game) => {
    if ((game.status == 'FINISHED' || game.status == 'RUNNING')) {
      return game.HomeTeamGoals > game.AwayTeamGoals ? 'win' : game.HomeTeamGoals < game.AwayTeamGoals ? 'loss' : (game.status != 'NOT PLAYED' && game.status != null) ? 'tie' : null;
    } else {
      return null;
    }
  }
  const getAwayTeamResult = (game) => {
    if ((game.status == 'FINISHED' || game.status == 'RUNNING')) {
      return game.HomeTeamGoals < game.AwayTeamGoals ? 'win' : game.HomeTeamGoals > game.AwayTeamGoals ? 'loss' : (game.status != 'NOT PLAYED' && game.status != null) ? 'tie' : null;
    } else {
      return null;
    }
  }

  const formatScore = (game) => {
    let homeScore = '-';
    let awayScore = '-';

    if (game.HomeTeamGoals != 0 || game.AwayTeamGoals != 0 || (game.status == 'FINISHED' || game.status == 'RUNNING')) {
      homeScore = game.HomeTeamGoals;
      awayScore = game.AwayTeamGoals;
    }

    return (
      <span className="flex flex-nowrap">
        <span>{homeScore}</span>
        <span className="min-w-[0.5em] text-center">:</span>
        <span>{awayScore}</span>
      </span>
    );
  }

  const gameClicked = (game, event) => {
    const url = `/embed/game?gameId=${game.gameId}&lang=${lang}`;
    // const isCtrlClick = event.metaKey || event.ctrlKey;
    // if (isCtrlClick) {
    simulateCtrlClick(url);
    // } else {
    //     navigate(url)
    // }
  }

  // DESKTOP

  const TeamLogo = ({ team, result }) => {
    const bgClass = result == 'win' ? 'bg-[#53C020]' : result == 'loss' ? 'bg-[#C02020]' : result == 'tie' ? 'bg-[#9B9B9B]' : '';
    const letter = result == 'win' ? 'W' : result == 'loss' ? 'L' : result == 'tie' ? 'T' : null;
    const marginClass = (letter == null) ? 'mt-[10px] mb-[10px]' : '';
    return (
      <div className={`${bgClass} rounded-xl`}>
        {letter && <div className="font-roboto-condensed text-center text-white text-xs py-[1px]">{letter}</div>}
        <div
          className={`${marginClass} hidden md:block w-[50px] h-[50px] bg-no-repeat bg-center rounded-xl`}
          style={{ backgroundImage: `url('${getImage(team, 'md')}')`, backgroundSize: '150%' }}>
        </div>
      </div>
    )
  }

  const TeamName = ({ team, align }) => {
    const alignClass = align == 'right' ? 'text-right' : 'text-left';
    const marginClass = align == 'right' ? 'mr-4' : 'ml-4';
    return (
      <span className={`${marginClass} flex flex-col gap-0 md:gap-1`}>
        <span className={`${alignClass} font-roboto-condensed text-[1.3rem] text-black line-clamp-2`}>{team?.name}</span>
      </span>
    )
  }

  const DesktopScore = ({ game }) => (
    <div className="hidden md:flex justify-center items-center">
      <div className="absolute w-[150px] top-[12px] bottom-[12px] xl:top-0 xl:bottom-0 bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url('${getScoreBgImage(game, game?.HomeTeamGoals, game?.AwayTeamGoals)}')` }}></div>
      <div className="absolute z-2 flex items-center justify-center font-score text-[2.25rem] text-white top-0 bottom-0">
        <div>
          {formatScore(game)}
          {game.overwriteEnabled &&
            <div className="text-white font-roboto-condensed text-sm">
              ({LANG.KONT_SHORT})
            </div>
          }
        </div>
      </div>
    </div>
  )

  const Star = ({ gameStar, align }) => {
    const player = gameStar?.Player;
    if (!player) {
      return <></>
    }
    const number = gameStar?.LineupNumber;
    const justifyClass = align == 'right' ? 'justify-end' : 'justify-start';
    return (
      <div className={`w-full flex items-center ${justifyClass}`}>
        <div className="w-[150px] h-[70px] flex justify-center relative">
          <span className="absolute top-0 left-[40px] w-[70px] h-[70px] bg-gray-200 bg-cover bg-center rounded-xl" style={{ backgroundImage: `url('${getImage(player, 'md')}')`, backgroundSize: '150%' }} />
          <span className="absolute bottom-[0px] overflow-hidden font-roboto-flex text-xs bg-black rounded-xl text-white px-2 py-[2px] text-center flex items-center gap-1">
            <span className="whitespace-nowrap">{player.firstName} {player.lastName} {number && '(' + number + ')'}</span>
            <AiFillStar />
          </span>
        </div>
      </div>
    )
  }

  // MOBILE

  const TeamNameMobile = ({ team, align }) => {
    const alignClass = align == 'right' ? 'text-right' : 'text-left';
    const marginClass = align == 'right' ? 'mr-5' : 'ml-5';
    const justifyClass = align == 'right' ? 'justify-end' : 'justify-start';
    return (
      <span className={`${marginClass} ${justifyClass} flex`}>
        <span className={`${alignClass} font-roboto-condensed text-[1.3rem] text-black line-clamp-2`}>{team?.name}</span>
      </span>
    )
  }

  const StarMobile = ({ gameStar, align }) => {
    const player = gameStar?.Player;
    if (!player) {
      return <></>
    }
    const number = gameStar?.LineupNumber;
    const marginClass = align == 'right' ? 'mr-5' : 'ml-5';
    const justifyClass = align == 'right' ? 'justify-end' : 'justify-start';
    return (
      <span className={`${marginClass} ${justifyClass} mt-2 flex items-center`}>
        <span className="overflow-hidden font-roboto-flex text-xs bg-black rounded-xl text-white px-2 py-[2px] flex items-center gap-1">
          <span className="whitespace-nowrap">{player.firstName ? player.firstName.substr(0, 1) + '.' : ''} {player.lastName} ({number})</span>
          <AiFillStar />
        </span>
      </span>
    )
  }

  const ScoreMobile = ({ game, homeTeamGoals, awayTeamGoals, homeTeam, awayTeam }) => {
    const homeTeamResult = getHomeTeamResult(game);
    const awayTeamResult = getAwayTeamResult(game);
    const winBgClass = 'bg-[#53C020]';
    const lossBgClass = 'bg-[#C02020]';
    return (
      <div className="flex relative mt-[7px] mb-[17px]">
        <div className="block w-[50px] h-[50px] bg-no-repeat bg-center rounded-l-xl" style={{ backgroundImage: `url('${getImage(homeTeam, 'md')}')`, backgroundSize: '150%' }}></div>
        <div className="block w-[50px] h-[50px] bg-no-repeat bg-center rounded-r-xl" style={{ backgroundImage: `url('${getImage(awayTeam, 'md')}')`, backgroundSize: '150%' }}></div>
        {(homeTeamResult == 'win' || homeTeamResult == 'loss') && <div className={`absolute z-[-1] bottom-[-20px] left-0 w-[32px] h-[50px] ${homeTeamResult == 'win' ? winBgClass : lossBgClass} rounded-xl font-roboto-condensed text-white text-xs flex items-end justify-center pb-[2px]`}>{homeTeamResult == 'win' ? 'W' : 'L'}</div>}
        {(awayTeamResult == 'win' || awayTeamResult == 'loss') && <div className={`absolute z-[-1] bottom-[-20px] right-0 w-[32px] h-[50px] ${awayTeamResult == 'win' ? winBgClass : lossBgClass} rounded-xl font-roboto-condensed text-white text-xs flex items-end justify-center pb-[2px]`}>{awayTeamResult == 'win' ? 'W' : 'L'}</div>}
        <img className={`absolute z-1 ${homeTeamResult == 'loss' ? 'left-[10%] right-[20%]' : homeTeamResult == 'win' ? 'left-[20%] right-[10%]' : 'left-[15%] right-[15%]'} top-0 bottom-0 h-[50px]`} src={`${getScoreBgImage(game, homeTeamGoals, awayTeamGoals)}`} />
        <div className={`absolute z-2 ${homeTeamResult == 'loss' ? 'left-[10%] right-[20%]' : homeTeamResult == 'win' ? 'left-[20%] right-[10%]' : 'left-[15%] right-[15%]'} top-0 bottom-0 flex items-center justify-center font-score text-[1.2rem] text-white pl-[4px]`}>
          <div className="flex flex-col items-center">
            {formatScore(game)}
            {game.overwriteEnabled &&
              <div className="text-white font-roboto-condensed text-[0.6rem]">
                (kont.)
              </div>
            }
          </div>
        </div>
      </div>
    )
  }

  const isMobile = useIsMobile('768px');

  const GameTitleDesktop = ({ children }) => (
    <div className="hidden md:flex absolute z-10 top-[-14px] h-[28px] px-0 lg:px-4 gap-2 lg:gap-4 left-0 right-0 items-center font-roboto-condensed font-light text-gray-400">
      {children}
    </div>
  )

  const GameTitleItem = ({ isToday, tooltip, icon, label, emptyLabel, truncate, wrap = false, className }) => (
    <span className={`${isToday ? 'bg-gray-100' : 'bg-white'} rounded px-2 flex items-center gap-2 ${truncate ? 'overflow-hidden' : ''} ${className}`}>
      <Tooltip title={tooltip}>
        <span className={truncate ? "truncate" : !wrap ? 'whitespace-nowrap' : ''}>
          {icon} {label && label.trim() != '' ? label : emptyLabel}
        </span>
      </Tooltip>
    </span>
  )

  const GameTitleSeparator = () => (
    <span className="flex-grow"></span>
  )

  const GameTitleMobile = ({ children }) => (
    <div className="flex md:hidden absolute z-10 top-[-14px] h-[28px] px-3 gap-3 left-0 right-0 items-center font-roboto-condensed font-light text-gray-400">
      {children}
    </div>
  )

  const GameTitleItemMobile = ({ isToday, label, emptyLabel }) => (
    <span className={`${isToday ? 'bg-gray-100' : 'bg-white'} rounded px-2 text-xl`}>
      {label ?? emptyLabel}
    </span>
  )

  return (
    <EmbedStatsWrapper>
      <div className="md:mx-2">
        <div className={`flex mb-8 gap-x-8 gap-y-4 flex-wrap justify-center bg-white z-20 p-4 ${!isMobile ? 'sticky top-0' : ''}`} ref={headerRef}>

          <FilterWrapper
            label={LANG.GROUP}
            type="select"
            value={groupFilter}
            options={groupOptions}
            onChange={(value) => { setTeamFilter(null); setPhaseFilter(null); setGroupFilter(value); }}
            allowAll={true}
            lang={lang}
          />

          <FilterWrapper
            label={LANG.PHASE}
            type="select"
            value={phaseFilter}
            options={phaseOptions}
            onChange={(value) => { setTeamFilter(null); setPhaseFilter(value); }}
            allowAll={true}
            lang={lang}
          />

          <FilterWrapper
            label={LANG.TEAM}
            type="select"
            value={teamFilter}
            options={teamOptions}
            onChange={(value) => setTeamFilter(value)}
            allowAll={true}
            lang={lang}
          />

          <FilterWrapper
            label={LANG.SEARCH}
            type="input"
            value={search}
            onChange={(value) => setSearch(value)}
            allowAll={true}
            lang={lang}
          />

          {indexedData && hasToday &&
            <div className="flex">
              <div className="flex justify-center items-center gap-2 cursor-pointer h-6 md:h-auto font-roboto-flex rounded-xl bg-[#aaa] px-2 py-1 w-[130px] md:w-[150px] text-white text-[0.9rem] md:text-[1.0rem]" onClick={scrollToToday}>
                <CalendarOutlined /> <span className="uppercase">{LANG.TODAY}</span>
              </div>
            </div>
          }
        </div>

        {gamesByMonth?.length === 0 &&
          <div className="font-roboto-condensed text-gray-500 flex items-center justify-center min-h-[75vh]">
            {LANG.NO_GAMES_TO_DISPLAY}
          </div>
        }

        {gamesByMonth.map((month, idx) => (
          <div key={idx} className="mb-16 max-w-[1300px] mx-auto">
            <div className="mb-2 font-roboto-condensed font-normal text-[1.4rem] text-black text-center">{getYearMonthInCzech(month.month)}</div>
            <div className="mb-4 h-px bg-gray-400"></div>
            {month.days.map((day, idx) => (
              <div ref={isToday(day.day) ? todayRef : null} key={idx} className={`md:mx-4 mt-4 mb-4 px-4 pt-[1px] ${isToday(day.day) ? 'bg-gray-100' : ''}`}>
                {!isToday(day.day) &&
                  <div className={`mt-4 mb-4 font-roboto-condensed font-bold text-[1.1rem]`}>{getDateInCzech(day.day)}</div>
                }
                {isToday(day.day) &&
                  <div className={`mt-4 mb-4 font-roboto-condensed font-bold text-[1.1rem] text-blue-500`}>
                    <span className="bg-blue-500 text-white p-1 rounded-md px-2 mr-3 uppercase">{isToday(day.day) ? LANG.TODAY : ''}</span>
                    {getDateInCzech(day.day)}
                  </div>
                }
                <div className="mt-8">
                  {day.games.length == 0 &&
                    <div className="font-roboto-condensed font-light text-gray-500 pb-8 pl-8">{LANG.NO_GAMES_TODAY}</div>
                  }
                  {day.games.map((game, idx) => (
                    <div key={idx} className="cursor-pointer first:border-t-[1px] border-b-[1px] border-gray-200 relative h-[160px] md:h-[105px]" onClick={(e) => gameClicked(game, e)}>

                      { /* Desktop only: Horizontal line */}
                      <GameTitleDesktop>
                        <GameTitleItem
                          className="lg:text-xl"
                          isToday={isToday(day.day)}
                          tooltip={LANG.TIME}
                          icon={icons.Time}
                          label={game.startTime?.substr(0, 5)}
                          emptyLabel={LANG.UNKNOWN_TIME}
                        />
                        <GameTitleItem
                          className="lg:text-xl"
                          isToday={isToday(day.day)}
                          tooltip={LANG.VENUE}
                          label={game.Venue?.name}
                          emptyLabel={LANG.UNKNOWN_VENUE}
                        />
                        <GameTitleItem
                          isToday={isToday(day.day)}
                          tooltip={LANG.GROUP}
                          // icon={icons.Group}
                          label={game.Phase?.Group?.name}
                          emptyLabel={LANG.UNKNOWN_GROUP}
                          truncate
                        />
                        <GameTitleItem
                          isToday={isToday(day.day)}
                          tooltip={LANG.PHASE}
                          // icon={icons.Phase}
                          label={game.Phase?.name}
                          emptyLabel={LANG.UNKNOWN_PHASE}
                          truncate
                        />
                        <GameTitleSeparator />
                        <GameTitleItem
                          isToday={isToday(day.day)}
                          tooltip={LANG.TIMEKEEPER + ': ' + (game.timekeeper ?? LANG.UNKNOWN)}
                          icon={<TimekeeperIcon />}
                          label={game.timekeeper}
                          emptyLabel={LANG.UNKNOWN}
                          truncate
                        />
                        <GameTitleItem
                          isToday={isToday(day.day)}
                          tooltip={LANG.REFEREE + ': ' + (game.timekeeper ?? LANG.UNKNOWN)}
                          icon={<RefereeIcon />}
                          label={game.referee}
                          emptyLabel={LANG.UNKNOWN}
                          truncate
                        />
                      </GameTitleDesktop>

                      { /* Mobile only: Horizontal line */}
                      <GameTitleMobile>
                        <GameTitleItemMobile
                          isToday={isToday(day.day)}
                          label={game.startTime?.substr(0, 5)}
                          emptyLabel={LANG.UNKNOWN_TIME}
                        />
                        <GameTitleItemMobile
                          isToday={isToday(day.day)}
                          label={game.Venue?.name}
                          emptyLabel={LANG.UNKNOWN_VENUE}
                        />
                      </GameTitleMobile>

                      <div className="flex flex-col h-full">

                        { /* Mobile only: Additional fields */}
                        <div className="pt-5 md:hidden flex justify-center gap-3">
                          <span className="font-roboto-condensed font-light text-gray-400 text-md flex items-center justify-center gap-2">{icons.Group}{game.Phase?.Group?.name ?? LANG.UNKNOWN_GROUP}</span>
                          <span className="font-roboto-condensed font-light text-gray-400 text-md flex items-center justify-center gap-2">{icons.Phase}{game.Phase?.name ?? LANG.UNKNOWN_PHASE}</span>
                        </div>
                        <div className="pt-2 md:hidden flex justify-center gap-3">
                          <span className="font-roboto-condensed font-light text-gray-400 text-md flex items-center justify-center gap-2 overflow-hidden">
                            <Tooltip title={`${LANG.TIMEKEEPER}: ${(game.timekeeper && game.timekeeper.trim() != '') ? game.timekeeper : LANG.UNKNOWN}`}>
                              <span className="truncate">
                                <TimekeeperIcon />&nbsp;{(game.timekeeper && game.timekeeper.trim() != '') ? game.timekeeper : LANG.UNKNOWN}
                              </span>
                            </Tooltip>
                          </span>
                          <span className="font-roboto-condensed font-light text-gray-400 text-md flex items-center justify-center gap-2 overflow-hidden">
                            <Tooltip title={`${LANG.REFEREE}: ${(game.referee && game.referee.trim() != '') ? game.referee : LANG.UNKNOWN}`}>
                              <span className="truncate">
                                <RefereeIcon />&nbsp;{(game.referee && game.referee.trim() != '') ? game.referee : LANG.UNKNOWN}
                              </span>
                            </Tooltip>
                          </span>
                        </div>

                        <div className="flex-grow">

                          {!isMobile &&
                            <div className="flex items-center p-4">
                              <div className="flex-none w-[50px]">
                                <TeamLogo team={game.HomeTeam} result={getHomeTeamResult(game)} />
                              </div>
                              <div className="flex-1">
                                <TeamName team={game.HomeTeam} />
                              </div>
                              <div className="flex-1 hidden lg:flex min-w-[150px]">
                                <Star gameStar={game.GameStars.find(gs => gs.teamId == game.HomeTeam?.teamId)} />
                              </div>
                              <div className="flex-none w-[140px] text-center">
                                <DesktopScore game={game} />
                              </div>
                              <div className="flex-1 hidden lg:flex min-w-[150px]">
                                <Star gameStar={game.GameStars.find(gs => gs.teamId == game.AwayTeam?.teamId)} align="right" />
                              </div>
                              <div className="flex-1 text-right">
                                <TeamName team={game.AwayTeam} align="right" />
                              </div>
                              <div className="flex-none w-[50px]">
                                <TeamLogo team={game.AwayTeam} result={getAwayTeamResult(game)} />
                              </div>
                            </div>
                          }

                          {isMobile &&
                            <div className="mt-1 flex items-center">
                              <div className="flex-1">
                                <TeamNameMobile team={game.HomeTeam} align="right" />
                                <StarMobile gameStar={game.GameStars.find(gs => gs.teamId == game.HomeTeam?.teamId)} align="right" />
                              </div>
                              <div className="flex-none w-[100px] flex justify-center">
                                <ScoreMobile game={game} homeTeamGoals={game.HomeTeamGoals} awayTeamGoals={game.AwayTeamGoals} homeTeam={game.HomeTeam} awayTeam={game.AwayTeam} />
                              </div>
                              <div className="flex-1">
                                <TeamNameMobile team={game.AwayTeam} align="left" />
                                <StarMobile gameStar={game.GameStars.find(gs => gs.teamId == game.AwayTeam?.teamId)} align="left" />
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </EmbedStatsWrapper>
  )
}

export default EmbedGamesInner