import React, { useRef } from 'react'
import { Checkbox, Form, Input, Row, Col, DatePicker, Select, Space } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import useFetchEntities from 'hooks/useFetchEntities';
import useLists from 'hooks/useLists';
const { Option } = Select;

const PositionSelect = ({ defaultValue, onChange }) => {
    const { ListPosition } = useLists();

    return (
        <Select
            className="w-full"
            showAction='focus'
            showSearch
            optionLabelProp="label"
            optionFilterProp="label"
            options={ListPosition
                ?.map(pt => (
                    { value: pt.listPositionId, label: pt.name }
            ))}
            defaultValue={defaultValue}
            onChange={onChange}
        >
            { }
        </Select>
    )
}

export default PositionSelect