
import _ from 'lodash';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import useUrlParamStore from 'store/urlParamStore';
import { useLocation } from "react-router-dom"

const DETHROTTLE_TIMEOUT = 1500;

export const useSearchParamsMaster = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const state = useUrlParamStore((state) => state.state);
  const replaceState = useUrlParamStore((state) => state.replaceState);
  const defaultValue = useUrlParamStore((state) => state.defaultValue);
  const dethrottle = useUrlParamStore((state) => state.dethrottle);
  const setValidForLocation = useUrlParamStore((state) => state.setValidForLocation);

  useEffect(() => {
    if (Date.now() > dethrottle) {
      const newState = Object.fromEntries(searchParams.entries());
      replaceState(newState);
    }
  }, [searchParams]);

  useEffect(() => {
    // console.log('Replacing state - location changed:', location.pathname)
    const newState = Object.fromEntries(searchParams.entries());
    replaceState(newState);
    setValidForLocation(location.pathname)
  }, [location.pathname]);

  useEffect(() => {
    let hasChanged = false;
    let updatedSearchParams = new URLSearchParams(searchParams.toString());
    for (const k in state) {
      if (searchParams.get(k) !== state[k]) {
        if (state[k] != defaultValue[k]) {
          updatedSearchParams.set(k, state[k]);
        } else {
          updatedSearchParams.delete(k);
        }
        hasChanged = true;
      }
    }
    if (hasChanged) {
      setSearchParams(updatedSearchParams.toString(), { replace: true });
    }
  }, [state]);

}

const useSearchParamsMulti = () => {
  const storeState = useUrlParamStore((state) => state.state);
  const setStoreParam = useUrlParamStore((state) => state.setParam);
  const setStoreParamDefaultValue = useUrlParamStore((state) => state.setParamDefaultValue);
  const setStoreDethrottle = useUrlParamStore((state) => state.setDethrottle);

  // fallback to original location params
  //   this special hack is necessary to prevent state histeresis when a page is being first loaded
  //   and during certain transitions
  const storeValidForLocation = useUrlParamStore((state) => state.validForLocation);
  const location = useLocation();
  const [locationSearchParams] = useSearchParams();
  const locationState = Object.fromEntries(locationSearchParams.entries());

  const state = (location.pathname != storeValidForLocation) ? locationState : storeState;

  const updateState = (key, value) => {
    setStoreParam(key, value);
    setStoreDethrottle(Date.now() + DETHROTTLE_TIMEOUT);
  };

  const useSearchParamSingle = (key, defaultValue, options = {}) => {
    useEffect(() => {
      setStoreParamDefaultValue(key, defaultValue);
    }, []);

    switch (options.type ?? 'string') {
      case 'string':
        return [
          state[key] ?? defaultValue,
          (value) => updateState(key, value)
        ];
      case 'int':
        return [
          state[key] ? parseInt(state[key]) : defaultValue,
          (value) => updateState(key, '' + value)
        ];
      case 'boolean':
        return [
          state[key] == 'true' ?? defaultValue,
          (value) => updateState(key, value == defaultValue ? '' : value ? 'true' : 'false')
        ];
    }
  }

  return { state, updateState, useSearchParamSingle };
}

export default useSearchParamsMulti;
