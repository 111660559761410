import React, { useRef, useState } from 'react'
import { Checkbox, Form, Input, Row, Col, DatePicker, Select, Space, Button, Tooltip } from 'antd';
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons';
import HmsFormItem from 'components/Form/HmsFormItem';
import { BsKey } from 'react-icons/bs';
import copy from 'copy-to-clipboard';
import useNotificationStore from 'store/notificationStore';

function generateSafePassword() {
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';

    // Generate at least one character from each set
    const requiredChars = [
        lowercase[Math.floor(Math.random() * lowercase.length)],
        uppercase[Math.floor(Math.random() * uppercase.length)],
        numbers[Math.floor(Math.random() * numbers.length)],
    ];

    // Combine character sets
    const allChars = lowercase + uppercase + numbers;

    // Fill the rest of the password length
    const remainingChars = 12 - requiredChars.length; // Change length as needed

    let newPassword = requiredChars.join('');

    for (let i = 0; i < remainingChars; i++) {
        const randomIndex = Math.floor(Math.random() * allChars.length);
        newPassword += allChars[randomIndex];
    }

    // Shuffle the password characters
    newPassword = newPassword.split('').sort(() => 0.5 - Math.random()).join('');

    return newPassword;
}

const GeneratablePasswordInput = ({ value, onChange, editable = true, ...props }) => {
    const addMessage = useNotificationStore((state) => state.addMessage);
    const [isEditable, setIsEditable] = useState(editable);
    const [generatedSuccessfully, setGeneratedSuccessfully] = useState(false);
    const [copiedSuccessfully, setCopiedSuccessfully] = useState(false);

    const generatePassword = () => {
        const newPassword = generateSafePassword();
        onChange(newPassword);

        addMessage({ text: 'New password generated', type: 'success' });

        setGeneratedSuccessfully(true);
        setCopiedSuccessfully(false);
        setTimeout(() => setGeneratedSuccessfully(false), 2000);
    }

    const copyToClipboard = () => {
        copy(value);

        addMessage({ text: 'Copied to clipboard', type: 'success' });

        setCopiedSuccessfully(true);
        setGeneratedSuccessfully(false);
        setTimeout(() => setCopiedSuccessfully(false), 2000);
    }

    if (isEditable) {
        return (
            <span className="flex gap-2">
                <Input.Password
                    {...props}
                    className="flex-1"
                    value={value}
                    onChange={onChange}
                />
                <Tooltip title="Generate a safe password for me">
                    <Button onClick={() => generatePassword()} className={`flex items-center ${generatedSuccessfully ? 'border-green-500 hover:!border-green-500' : ''}`}>
                        {generatedSuccessfully ? <CheckCircleOutlined className="text-green-500" /> : <BsKey />}</Button>
                </Tooltip>
                <Tooltip title="Copy to clipboard">
                    <Button onClick={() => copyToClipboard()} disabled={value == null || value == ''} className={`flex items-center ${copiedSuccessfully ? 'border-green-500 hover:!border-green-500' : ''}`}>
                        {copiedSuccessfully ? <CheckCircleOutlined className="text-green-500" /> : <CopyOutlined />}
                    </Button>
                </Tooltip>
            </span >
        );
    } else {
        return (
            <Button onClick={() => setIsEditable(true)} className="flex items-center">Change password</Button>
        );
    }
}

const FormInput = ({ label, name, autoFocus, placeholder, required, validators, password, formItemProps, fieldItemProps }) => {
    return (
        <HmsFormItem
            label={label}
            name={name}
            required={required}
            validators={validators}
            {...formItemProps}
        >
            {password &&
                <GeneratablePasswordInput
                    autoFocus={autoFocus}
                    className={`w-full ${fieldItemProps.className ? fieldItemProps.className : ''}`}
                    placeholder={placeholder}
                    autoComplete="one-time-code"
                    {...fieldItemProps}
                />
            }
            {!password &&
                <Input
                    autoFocus={autoFocus}
                    className={`w-full ${fieldItemProps.className ? fieldItemProps.className : ''}`}
                    placeholder={placeholder}
                    {...fieldItemProps}
                />
            }
        </HmsFormItem>
    )
}

export default FormInput