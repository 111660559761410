import useEntities from 'hooks/useEntities';
import HmsEntityTableController from 'components/HmsEntityTableController';
import { Tooltip } from 'antd';
import moment from 'moment';
import useLoginStore from 'store/loginStore';
import { formatDate, formatTime } from 'utils/generateEntityColumns';
import HmsStatusTag from 'components/HmsStatusTag';
import EntityLogo from 'components/EntityLogo';

const PersonsTeamManager = () => {
  const entities = useEntities();
  const filterGroups = null;
  const showColumns = [
    { column: 'Name', width: 200, },
    'birthday',
    'height',
    'weight',
    'phone',
    'email',
    'Role',
    'countryBirth',
    'cityBirth',
    'country',
    'city',
    'status',
    'externalId',
  ];

  return (
    <HmsEntityTableController
      entity={entities.Person}
      filterGroups={filterGroups}
      showColumns={showColumns}
      onRowClickLink={(record) => `/persons_team_manager/${record.personId}`}
      createUrl={'/persons_team_manager/_new_'}
      renderMobileRow={(record) => (
        <div>
          <div className="border-b-[1px] border-gray-100 px-4 py-4 flex rounded-t-md" style={{ background: 'linear-gradient(90deg, #f2f2f2 0%, #fafafa 2%, #fafafa 98%, #f2f2f2 100%)' }}>
            <div className="flex flex-col gap-[1px]">
              <span className="flex items-center gap-3">
                  {/* <EntityLogo entity={record} size={24} /> */}
                  <span className="font-bold text-[0.9rem]">{record?.firstName + ' ' + record?.lastName}</span>
              </span>
            </div>
            <div className="ml-auto my-auto">
              {/* <HmsStatusTag className="m-0" status={'NOT REGISTERED'} entity="Team" /> */}
            </div>
          </div>
          <div className="px-6 py-4 flex items-center rounded-b-md" style={{ background: 'linear-gradient(90deg, #f8f8f8 0%, #fff 2%, #fff 98%, #f8f8f8 100%)' }}>
            <div className="w-full grid grid-cols-4 gap-y-2">
              <span className="text-gray-500">Phone:</span>
              <span className="col-span-3">{record.phone && record.phone != '' ? record.phone : '-'}</span>

              <span className="text-gray-500">E-mail:</span>
              <span className="col-span-3">{record.email && record.email != '' ? record.email : '-'}</span>
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default PersonsTeamManager