import { Modal } from 'antd'
import useFetchEntity from 'hooks/useFetchEntity'
import React, { useEffect, useRef, useState } from 'react'
import { Select, Space } from 'antd';
import { Checkbox, Divider, Form } from 'antd';
import { removeAccents } from 'utils/oneLiners';
import { compareString } from 'utils/comparators';
import EntityLogo from 'components/EntityLogo';
const { Option } = Select;

const PlayerJerseyModal = ({ teams, open, onOk, onCancel }) => {
    const [data, setData] = useState({});
    const formRef = useRef(null);
    teams.sort((a, b) => compareString(a.name, b.name));

    useEffect(() => {
        if (open) {
            const newData = {
                teamId: teams?.[0]?.teamId,
            }
            formRef.current.resetFields();
            formRef.current.setFieldsValue(newData);
            setData(newData);
        }
    }, [open])

    const handleFormValuesChange = (changedValues, allValues) => {
        setData(allValues);
    }

    let jerseys = [];
    for (var i = 1; i < 100; i++) {
        jerseys.push({
            value: i,
            label: '' + i,
        })
    }

    const handleOk = () => {
        onOk({
            ...data,
            teamName: teams.find(t => t.teamId == data.teamId)?.name,
        });
    }

    const TeamOption = ({ team: t }) => (
        <div className="flex gap-2 items-center">
            <EntityLogo entity={t} size={16} />
            {t.name}
        </div>
    );

    return (
        <>
            <Modal
                title="Jersey"
                open={open}
                onOk={handleOk}
                onCancel={onCancel}
                transitionName=""
                centered
            >
                <Form
                    layout="vertical"
                    onValuesChange={handleFormValuesChange}
                    ref={formRef}
                >
                    <h4>Team</h4>
                    <Form.Item
                        name="teamId"
                    >
                        <Select
                            className="w-full"
                            showAction='focus'
                            showSearch
                            optionLabelProp="label"
                            optionFilterProp="label"
                            placeholder="Please select"
                            filterOption={(input, option) => option.customSearchText.indexOf(removeAccents(input).toUpperCase()) != -1}
                            options={teams?.map(t => ({
                                value: t.teamId,
                                customSearchText: removeAccents(t.name.toUpperCase()),
                                label: <TeamOption team={t} />
                            }))}
                        >
                        </Select>
                    </Form.Item>

                    <h4>Jersey</h4>
                    <Form.Item
                        name="number"
                    >
                        <Select
                            className="w-full"
                            showAction='focus'
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            options={jerseys}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default PlayerJerseyModal