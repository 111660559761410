import React from 'react'
import { Tag } from 'antd';
import { getStatusColor, getStatusText } from 'utils/status';

const HmsStatusTag = ({ status, entity, className }) => {
    return (
        <Tag className={className} color={getStatusColor(status, entity)}>
            {getStatusText(status)}
        </Tag>
    )
}

export default HmsStatusTag