import React from 'react'
import useFetchEntities from 'hooks/useFetchEntities';

const usePhaseIdTranslator = ({ competitionId, seasonId, groupId, phaseId } = {}) => {
  const { data: competitionData } = useFetchEntities('Competition', { relations: 'Seasons>Groups>Phases' });

  const phaseIdsAll = competitionId != null && seasonId != null ? (
    phaseId != null ? [phaseId] :
      groupId != null ? competitionData
        ?.find(c => c.competitionId == competitionId)
        ?.Seasons
        ?.find(s => s.seasonId == seasonId)
        ?.Groups
        ?.find(g => g.groupId == groupId)
        ?.Phases
        ?.map(p => p.phaseId) :
        seasonId != null ? competitionData
          ?.find(c => c.competitionId == competitionId)
          ?.Seasons
          ?.find(s => s.seasonId == seasonId)
          ?.Groups
          ?.map(g => g.Phases.map(p => p.phaseId))
          .flat() :
          []
  ) : null;

  return { phaseIdsAll };

}

export default usePhaseIdTranslator