import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const useGlobalStore = create(
    persist(
        (set, get) => ({
            BASE_URL: process.env.REACT_APP_BACKEND_BASE_URL,
            ORGANIZATION_ID: null,
            entitiesDontNeedOrganizationId: ['Organization', 'User', 'Lineup', 'GameStar', 'Attachment', 'ChangeRequest'],
            hmsTablePageSize: 50,

            setOrganizationId: (organizationId) => {
                set({
                    ORGANIZATION_ID: organizationId,
                });
            },

            setHmsTablePageSize: (pageSize) => {
                set({
                    hmsTablePageSize: pageSize,
                });
            },

            postEntity: async (entity, data) => {
                const ORGANIZATION_ID = get().ORGANIZATION_ID;
                const entitiesDontNeedOrganizationId = get().entitiesDontNeedOrganizationId;
                const BASE_URL = get().BASE_URL;

                if (entitiesDontNeedOrganizationId.indexOf(entity.name) == -1) {
                    data = { ...data, 'organizationId': ORGANIZATION_ID };
                }
                const response = await fetch(`${BASE_URL}/admin/entity?entity=${entity.name}`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });
                return {
                    status: response.status,
                    body: await response.json(),
                }
            },

            postEntities: async (entity, data) => {
                const ORGANIZATION_ID = get().ORGANIZATION_ID;
                const entitiesDontNeedOrganizationId = get().entitiesDontNeedOrganizationId;
                const BASE_URL = get().BASE_URL;

                if (entitiesDontNeedOrganizationId.indexOf(entity.name) == -1) {
                    data = data.map(r => ({ ...r, 'organizationId': ORGANIZATION_ID }));
                }
                const response = await fetch(`${BASE_URL}/admin/entities?entity=${entity.name}`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });
                return {
                    status: response.status,
                    body: await response.json(),
                }
            },

            patchEntity: async (entity, id, data) => {
                const ORGANIZATION_ID = get().ORGANIZATION_ID;
                const entitiesDontNeedOrganizationId = get().entitiesDontNeedOrganizationId;
                const BASE_URL = get().BASE_URL;

                if (entitiesDontNeedOrganizationId.indexOf(entity.name) == -1) {
                    data = { ...data, 'organizationId': ORGANIZATION_ID };
                }
                const response = await fetch(`${BASE_URL}/admin/entity?entity=${entity.name}&id=${id}`, {
                    method: 'PATCH',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });
                return {
                    status: response.status,
                    body: await response.json(),
                }
            },

            putEntity: async (entity, data) => {
                const ORGANIZATION_ID = get().ORGANIZATION_ID;
                const entitiesDontNeedOrganizationId = get().entitiesDontNeedOrganizationId;
                const BASE_URL = get().BASE_URL;

                if (entitiesDontNeedOrganizationId.indexOf(entity.name) == -1) {
                    data = { ...data, 'organizationId': ORGANIZATION_ID };
                }
                const response = await fetch(`${BASE_URL}/admin/entity?entity=${entity.name}&id=${data[entity.primaryKey]}`, {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });

                return {
                    status: response.status,
                    body: await response.json(),
                }
            },

            deleteEntity: async (entity, id) => {
                const BASE_URL = get().BASE_URL;

                const response = await fetch(`${BASE_URL}/admin/entity?entity=${entity.name}&id=${id}`, {
                    method: 'DELETE',
                    credentials: 'include',
                });
                return {
                    status: response.status,
                }
            },

            cloneEntity: async (entity, id, data) => {
                const BASE_URL = get().BASE_URL;

                const response = await fetch(`${BASE_URL}/admin/cloneEntity?entity=${entity.name}&id=${id}`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });
                return {
                    status: response.status,
                    body: await response.json(),
                }
            },

            deleteEntities: async (entity, ids) => {
                const BASE_URL = get().BASE_URL;

                const response = await fetch(`${BASE_URL}/admin/entities?entity=${entity.name}`, {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ids: ids }),
                });
                return {
                    status: response.status,
                }
            },

            postRelation: async (sourceEntity, sourceEntityId, targetEntity, targetEntityIds, targetEntityAs) => {
                const BASE_URL = get().BASE_URL;

                const url = `${BASE_URL}/admin/entityRelation?` +
                    `sourceEntity=${sourceEntity.name}&` +
                    `sourceEntityId=${sourceEntityId}&` +
                    `targetEntity=${targetEntity.name}&` +
                    `targetEntityAs=${targetEntityAs ?? targetEntity.name}&` +
                    `targetEntityId=${Array.isArray(targetEntityIds) ? targetEntityIds.join(',') : targetEntityIds}`;

                const response = await fetch(url, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                return {
                    status: response.status,
                    body: await response.json(),
                }
            },

            deleteRelation: async (sourceEntity, sourceEntityId, targetEntity, targetEntityIds, targetEntityAs) => {
                const BASE_URL = get().BASE_URL;

                const url = `${BASE_URL}/admin/entityRelation?` +
                    `sourceEntity=${sourceEntity.name}&` +
                    `sourceEntityId=${sourceEntityId}&` +
                    `targetEntity=${targetEntity.name}&` +
                    `targetEntityAs=${targetEntityAs ?? targetEntity.name}&` +
                    `targetEntityId=${Array.isArray(targetEntityIds) ? targetEntityIds.join(',') : targetEntityIds}`;

                const response = await fetch(url, {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                return {
                    status: response.status,
                    body: await response.json(),
                }
            },

            postSchedulerGenerateGames: async (id) => {
                const BASE_URL = get().BASE_URL;

                const response = await fetch(`${BASE_URL}/scheduler/generateGames?id=${id}`, {
                    method: 'POST',
                    credentials: 'include',
                });
                return {
                    status: response.status,
                    body: await response.json(),
                }
            },

            postLogo: async (blob, parameters) => {
                const BASE_URL = get().BASE_URL;

                const urlParams = new URLSearchParams(parameters);
                const formData = new FormData();
                formData.append('file', blob);
                
                return await fetch(`${BASE_URL}/admin/logo?${urlParams.toString()}`, {
                  method: 'POST',
                  body: formData,
                  credentials: 'include',
                });
            },

            patchLogo: async (parameters) => {
                const BASE_URL = get().BASE_URL;

                const urlParams = new URLSearchParams(parameters);
                
                return await fetch(`${BASE_URL}/admin/logo?${urlParams.toString()}`, {
                  method: 'PATCH',
                  credentials: 'include',
                });
            },

            deleteLogo: async (parameters) => {
                const BASE_URL = get().BASE_URL;

                const urlParams = new URLSearchParams(parameters);
                
                return await fetch(`${BASE_URL}/admin/logo?${urlParams.toString()}`, {
                  method: 'DELETE',
                  credentials: 'include',
                });
            },

            postAttachment: async (blob, parameters) => {
                const BASE_URL = get().BASE_URL;

                const urlParams = new URLSearchParams(parameters);
                const formData = new FormData();
                formData.append('file', blob);
                
                return await fetch(`${BASE_URL}/admin/attachment?${urlParams.toString()}`, {
                  method: 'POST',
                  body: formData,
                  credentials: 'include',
                });
            },

            deleteAttachment: async (parameters) => {
                const BASE_URL = get().BASE_URL;

                const urlParams = new URLSearchParams(parameters);
                
                return await fetch(`${BASE_URL}/admin/attachment?${urlParams.toString()}`, {
                  method: 'DELETE',
                  credentials: 'include',
                });
            },

            sendInvite: async (data) => {
                const BASE_URL = get().BASE_URL;

                return await fetch(`${BASE_URL}/admin/sendInvite`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });
            },

        }),
        {
            name: 'globalStore-persistentItems',
            storage: createJSONStorage(() => localStorage),
            partialize: (state) => {
                const include = [
                    'ORGANIZATION_ID',
                    'hmsTablePageSize',
                ]
                return Object.fromEntries(Object.entries(state).filter(([key]) => include.includes(key)));
            },
        }
    ),
);

export default useGlobalStore;