import React from 'react'

const SchedulerSimulationSummaryTable = ({ data, className }) => (
  <div className={"flex justify-center gap-4 " + className}>
    {data?.map((d, idx) => (
      <div className="flex border-[1px] border-solid border-gray-300 rounded-lg px-3 py-[1px] whitespace-nowrap text-[0.9rem] gap-x-1" key={idx}>
        {d.label}: <span className="font-bold">{d.value}</span>
      </div>
    ))}
  </div>
);

export default SchedulerSimulationSummaryTable