import { Button, Input, Modal, Radio } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import useLists from 'hooks/useLists';
import { formatTimeString, parseTimeString } from 'utils/timeString';
import { set } from 'lodash';

const GameEventTimeSelect = ({ autoFocusInputRef, data, onValueChange, setValidity: setParentValidity }) => {
    const { ListPeriod } = useLists();
    const [fieldValues, setFieldValues] = useState({
        remainingTime: data?.event?.remainingTime ?? '',
        gameTime: data?.event?.gameTime ?? '',
    });
    const [fieldValidities, setFieldValidities] = useState({
        remainingTime: true,
        gameTime: true,
    });
    const [remainingTimeIsEditing, setRemainingTimeIsEditing] = useState(false);
    const [gameTimeIsEditing, setGameTimeIsEditing] = useState(false);

    const periods = ListPeriod
        .map(lp => ({
            name: lp.name,
            short: lp.code,
            duration: lp.duration,
            index: lp.priority,
    }));
    periods.sort((a, b) => a.index - b.index);
    const periodIndex = periods.map(p => p.name).indexOf(data?.event?.period);
    const periodOffset = periods.slice(0, periodIndex).reduce((acc, p) => acc + p.duration, 0) * 60;
    const periodLength = periods[periodIndex]?.duration * 60 ?? 0;

    const remainingTimeValid = { from: 0, thru: periodLength };
    const gameTimeValid = { from: periodOffset, thru: periodOffset + periodLength };

    const setFieldValue = (name, value) => {
        setFieldValues(fieldValues => ({
            ...(fieldValues ?? {}),
            [name]: value,
        }));
    }

    const setFieldValidity = (name, value) => {
        setFieldValidities(fieldValidities => ({
            ...(fieldValidities ?? {}),
            [name]: value,
        }));
        setParentValidity(name, value);
    }

    const format = (value) => {
        if (value) {
            const parsed = parseTimeString(value);
            if (parsed !== null) {
                return formatTimeString(parsed);
            } else {
                return value;
            }
        } else {
            return '';
        }
    }

    const validate = (value, validFrom, validThru) => {
        const parsed = parseTimeString(value)
        if (value != '') {
            if (parsed !== null) {
                if (parsed >= validFrom && parsed <= validThru) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const getFieldValue = (name) => {
        return fieldValues?.[name];
    }

    const isFieldValid = (name) => {
        return fieldValidities?.[name] !== false;
    }

    // Remaining Time

    const remainingTimeFocus = (e) => {
        setRemainingTimeIsEditing(false);
    }

    const remainingTimeBlur = (e) => {
        setRemainingTimeIsEditing(false);

        const formatted = format(e.target.value);
        if (formatted != null) {
            setFieldValue('remainingTime', formatted)
        }
    }

    const onRemainingTimeChange = (e) => {
        setRemainingTimeIsEditing(true);

        const value = e.target.value;
        setFieldValue('remainingTime', value)

        if (value != '') {
            const formatted = format(value);
    
            const isValid = validate(value, remainingTimeValid.from, remainingTimeValid.thru);
            setFieldValidity('remainingTime', isValid);
    
            if (isValid) {
                const parsed = parseTimeString(value);
                const gameTime = periodOffset + (periodLength - parsed);
                const formattedGameTime = formatTimeString(gameTime);
                setFieldValue('gameTime', formattedGameTime);
                setFieldValidity('gameTime', true);
    
                onValueChange('remainingTime', formatted);
                onValueChange('gameTime', formattedGameTime);
            } else {
                setFieldValue('gameTime', '?');
                setFieldValidity('gameTime', false);
            }
        } else {
            setFieldValue('remainingTime', '')
            setFieldValue('gameTime', '');

            setFieldValidity('remainingTime', true);
            setFieldValidity('gameTime', true);

            onValueChange('remainingTime', '');
            onValueChange('gameTime', '');
        }
    }

    // Game Time

    const gameTimeFocus = (e) => {
        setGameTimeIsEditing(false);
    }

    const gameTimeBlur = (e) => {
        setGameTimeIsEditing(false);

        const formatted = format(e.target.value);
        if (formatted != null) {
            setFieldValue('gameTime', formatted)
        }
    }

    const gameTimeChange = (e) => {
        setGameTimeIsEditing(true);

        const value = e.target.value;
        setFieldValue('gameTime', value)

        if (value != '') {
            const formatted = format(value);
    
            const isValid = validate(value, gameTimeValid.from, gameTimeValid.thru);
            setFieldValidity('gameTime', isValid);
    
            if (isValid) {
                const parsed = parseTimeString(value);
                const remainingTime = periodLength - (parsed - periodOffset);
                const formattedRemainingTime = formatTimeString(remainingTime);
                setFieldValue('remainingTime', formattedRemainingTime);
                setFieldValidity('remainingTime', true);
    
                onValueChange('gameTime', formatted);
                onValueChange('remainingTime', formattedRemainingTime);
            } else {
                setFieldValue('remainingTime', '?');
                setFieldValidity('remainingTime', false);
            }
        } else {
            setFieldValue('remainingTime', '')
            setFieldValue('gameTime', '');

            setFieldValidity('remainingTime', true);
            setFieldValidity('gameTime', true);

            onValueChange('remainingTime', '');
            onValueChange('gameTime', '');
        }
    }

    // Period selector

    useEffect(() => {
        if (fieldValues) {
            if (fieldValues.remainingTime && fieldValues.remainingTime != '' && isFieldValid('remainingTime')) {
                console.log('periodIndex changed:', periodIndex);

                const isRemainingTimeValid = validate(fieldValues?.remainingTime, remainingTimeValid.from, remainingTimeValid.thru);
                setFieldValidity('remainingTime', isRemainingTimeValid);
        
                if (isRemainingTimeValid) {
                    const parsed = parseTimeString(fieldValues?.remainingTime);
                    const gameTime = periodOffset + (periodLength - parsed);
                    const formattedGameTime = formatTimeString(gameTime);
                    setFieldValue('gameTime', formattedGameTime);
                    setFieldValidity('gameTime', true);
        
                    onValueChange('gameTime', formattedGameTime);
                }    
            }
        }

    }, [periodIndex])

    return (
        <div className="grid gap-x-4 grid-cols-10 items-start">
            <div className="col-span-2 h-8 flex items-center">
                Remainig Time:
            </div>
            <div className="col-span-8 h-8 flex items-center">

                {/* <div className="flex flex-col">
                    <div className="flex gap-2">
                        <span>{JSON.stringify(fieldValues)}</span>
                        <span>{JSON.stringify(fieldValidities)}</span>
                        <span>{JSON.stringify(remainingTimeIsEditing)}</span>
                        <span>{JSON.stringify(gameTimeIsEditing)}</span>
                    </div>

                <div> */}
                <span>
                    <Input
                        ref={autoFocusInputRef}
                        className={`w-20 text-center ${!isFieldValid('remainingTime') ? (remainingTimeIsEditing || gameTimeIsEditing ? 'text-orange-400' : 'text-red-500') : ''}`}
                        placeholder="00:00"
                        value={getFieldValue('remainingTime') ?? ''}
                        status={isFieldValid('remainingTime') ? 'success' : (remainingTimeIsEditing || gameTimeIsEditing ? 'warning' : 'error')}
                        onChange={onRemainingTimeChange}
                        onFocus={remainingTimeFocus}
                        onBlur={remainingTimeBlur}
                    />
                    <span className="ml-2 text-gray-400">
                        ({formatTimeString(remainingTimeValid.from)} - {formatTimeString(remainingTimeValid.thru)})
                    </span>
                </span>

                <span>
                    <span className="ml-6 mr-2">Game Time:</span>
                    <Input
                        className={`w-20 text-center ${!isFieldValid('gameTime') ? (remainingTimeIsEditing || gameTimeIsEditing ? 'text-orange-400' : 'text-red-500') : ''}`}
                        placeholder="00:00"
                        value={getFieldValue('gameTime') ?? ''}
                        status={isFieldValid('gameTime') ? 'success' : (remainingTimeIsEditing || gameTimeIsEditing ? 'warning' : 'error')}
                        onChange={gameTimeChange}
                        onFocus={gameTimeFocus}
                        onBlur={gameTimeBlur}
                    />
                    <span className="ml-2 text-gray-400">
                        ({formatTimeString(gameTimeValid.from)} - {formatTimeString(gameTimeValid.thru)})
                    </span>
                </span>
                {/* </div> */}
                {/* </div> */}
            </div>
        </div>
    )
}

export default GameEventTimeSelect;
