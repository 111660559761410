import GoogleAnalyticsTracker from 'components/GoogleAnalyticsTracker';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

export default function EmbedLayout() {
  return (
    <div className="bg-white">
      <GoogleAnalyticsTracker id={process.env.REACT_APP_EMBED_GA4_ID} />

      <Outlet />
    </div>
  )
}
