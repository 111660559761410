import React from 'react'
import { DownOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd'
import useNotificationStore from 'store/notificationStore';
import icons from 'utils/icons';

const ExportButton = ({ exportClicked, disabled }) => {
  const addMessage = useNotificationStore((state) => state.addMessage);

  return (
    <Dropdown.Button
    className="w-fit"
    size="small"
    placement="bottomRight"
    disabled={disabled}
    menu={{
      items: [
        {
          key: 'copy',
          label: 'Copy as table',
          onClick: () => {
            exportClicked('clipboard');
            addMessage({ text: 'Copied to clipboard', type: 'success' });
          },
        },
        {
          key: 'download',
          label: 'Download as CSV',
          onClick: () => {
            exportClicked('csv');
          },
        },
      ]
    }}
    onClick={() => {
      exportClicked('clipboard');
      addMessage({ text: 'Copied to clipboard', type: 'success' });
    }}
    icon={<DownOutlined />}
  >
    {icons.Export}
    Export
  </Dropdown.Button>  )
}

export default ExportButton