import { Button, Input, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Select, Space } from 'antd';
import useLists from 'hooks/useLists';
import { formatTimeString, parseTimeString } from 'utils/timeString';

const GameEventGridSelect = ({ name, data, toggleValueChange, options, width, image, height }) => (
    <div className="" style={{ width: `${width}px`, height: `${height}px`, backgroundImage: `url('${image}')`, backgroundSize: 'contain' }}>
        <div className={`w-full h-full grid grid-cols-3`}>
            {options.map((o, i) => {
                const id = o.value;
                const isActive = id != null && data.event[name] == id;
                return (
                    <span
                        key={i}
                        className="flex justify-center items-center"
                    >
                        <Button
                            className={`${isActive ? '' : 'opacity-80'} w-[80px] min-h-[50px]`}
                            size="small"
                            type={isActive ? 'primary' : 'default'}
                            onClick={() => toggleValueChange(name, id)}
                        >
                            <span
                                className={`text-[0.5rem] uppercase whitespace-break-spaces font-bold ${isActive ? 'text-white' : 'text-gray-500'} ${id == null ? 'line-through' : ''}`}
                            >
                                {o.label}
                            </span>
                            {id == null && <span className="text-red-500">!!!</span>}
                        </Button>
                    </span>
                )
            })}
        </div>
    </div>
)

export default GameEventGridSelect;