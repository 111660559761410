import React, { useEffect, useState } from 'react';
import AdminLayout from 'components/Layouts/AdminLayout';
import { ConfigProvider } from 'antd';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
    Navigate,
    Outlet,
    useParams,
} from "react-router-dom";

import Competitions from 'pages/Competitions';
import Sponsors from 'pages/Sponsors';
import Awards from 'pages/Awards';
import Venues from 'pages/Venues';
import Teams from 'pages/Teams';
import Team from 'pages/Team';
import Players from 'pages/Players';
import Persons from 'pages/Persons';
import Events from 'pages/Events';
import Games from 'pages/Games';
import Competition from 'pages/Competition';
import Season from 'pages/Season';
import Group from 'pages/Group';
import Phase from 'pages/Phase';
import Sponsor from 'pages/Sponsor';
import Venue from 'pages/Venue';
import Award from 'pages/Award';
import Player from 'pages/Player';
import Person from 'pages/Person';
import Game from 'pages/Game';
import Organizations from 'pages/Organizations';
import Organization from 'pages/Organization';
import Users from 'pages/Users';
import User from 'pages/User';
import RulePackList from 'pages/RulePackList';
import RulePackRecord from 'pages/RulePackRecord';
import GamePlay from 'pages/GamePlay';
import SchedulerLayout from 'components/Layouts/SchedulerLayout';
import SchedulerSimulations from 'pages/SchedulerSimulations';
import SchedulerSimulation from 'pages/SchedulerSimulation';
import EmbedLayout from 'components/Layouts/EmbedLayout';
import EmbedGames from 'pages/EmbedGames';
import LoginWrapper from 'components/Layouts/LoginWrapper';
import EmbedStandings from 'pages/EmbedStandings';
import EmbedGame from 'pages/EmbedGame';
import Dashboard from 'pages/Dashboard';
import EmbedNewPlayer from 'pages/EmbedNewPlayer';
import EmbedPlayersStats from 'pages/EmbedPlayersStats';
import EmbedGoaliesStats from 'pages/EmbedGoaliesStats';
import AppErrorLayout from 'components/Layouts/AppErrorLayout';
import useLoginStore from 'store/loginStore';
import GamesTeamManager from 'pages/GamesTeamManager';
import NavigateHome from 'components/NavigateHome';
import GameTeamManager from 'pages/GameTeamManager';
import Statistics from 'pages/Statistics';
import Disciplinary from 'pages/Disciplinary';
import Disciplinaries from 'pages/Disciplinaries';
import EmbedDisciplinaries from 'pages/EmbedDisciplinaries';
import EmbedDisciplinary from 'pages/EmbedDisciplinary';
import TeamsTeamManager from 'pages/TeamsTeamManager';
import PlayersTeamManager from 'pages/PlayersTeamManager';
import PersonsTeamManager from 'pages/PersonsTeamManager';
import EmbedSeasonTerms from 'pages/EmbedSeasonTerms';
import SeasonRegistrations from 'pages/SeasonRegistrations';
import SeasonRegistration from 'pages/SeasonRegistration';
import ResetPassword from 'pages/ResetPassword';
import LoginPage from 'pages/LoginPage';
import ForgotPassword from 'pages/ForgotPassword';
import MyProfile from 'pages/MyProfile';
import PaymentStatus from 'pages/PaymentStatus';
import SchedulerConfigs from 'pages/SchedulerConfigs';
import SchedulerConfig from 'pages/SchedulerConfig';
import Infographics from 'pages/Infographics';

export default function Router() {
    const { id } = useParams();

    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path="/auth">
                    <Route path="login" element={<LoginPage />} />
                    <Route path="forgotPassword" element={<ForgotPassword />} />
                    <Route path="resetPassword" element={<ResetPassword />} />
                </Route>
                <Route path="/" element={<LoginWrapper><AdminLayout mode="general" /></LoginWrapper>}>
                    <Route path="" element={<NavigateHome homesByRole={{'ADMIN': '/dashboard', 'TEAM_MANAGER': '/dashboard_team_manager'}} replace={true} />} />
                </Route>
                <Route path="/" element={<LoginWrapper roles={['ADMIN', 'TEAM_MANAGER']}><AdminLayout mode="general" /></LoginWrapper>}>
                    <Route path="profile" element={<MyProfile />} />
                </Route>
                <Route path="/" element={<LoginWrapper roles={['ADMIN']}><AdminLayout mode="general" /></LoginWrapper>}>
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="competitions" element={<Competitions />} />
                    <Route path="competitions" element={<Competitions />} />
                    <Route path="competitions/:id" element={<Competition />} />
                    <Route path="seasons/:id" element={<Season />} />
                    <Route path="groups/:id" element={<Group />} />
                    <Route path="phases/:id" element={<Phase />} />
                    <Route path="sponsors" element={<Sponsors />} />
                    <Route path="sponsors/:id" element={<Sponsor />} />
                    <Route path="venues" element={<Venues />} />
                    <Route path="venues/:id" element={<Venue />} />
                    <Route path="awards" element={<Awards />} />
                    <Route path="awards/:id" element={<Award />} />
                    <Route path="teams" element={<Teams />} />
                    <Route path="teams/:id" element={<Team />} />
                    <Route path="players" element={<Players />} />
                    <Route path="players/:id" element={<Player />} />
                    <Route path="persons" element={<Persons />} />
                    <Route path="persons/:id" element={<Person />} />
                    <Route path="games" element={<Games />} />
                    <Route path="games/:id" element={<Game />} />
                    <Route path="statistics" element={<Statistics />} />
                    <Route path="schedulerconfigs" element={<SchedulerConfigs />} />
                    <Route path="schedulerconfigs/:id" element={<SchedulerConfig />} />
                    <Route path="infographics" element={<Infographics />} />
                    <Route path="disciplinaries" element={<Disciplinaries />} />
                    <Route path="disciplinaries/:id" element={<Disciplinary />} />
                    <Route path="seasonregistrations" element={<SeasonRegistrations />} />
                    <Route path="seasonregistrations/:id" element={<SeasonRegistration />} />
                </Route>
                <Route path="/" element={<LoginWrapper><AdminLayout mode="general" navbar={false} /></LoginWrapper>}>
                    <Route path="gameplay/:id" element={<GamePlay />} />
                    <Route path="payment-status" element={<PaymentStatus />} />
                </Route>                
                <Route path="/" element={<LoginWrapper><SchedulerLayout /></LoginWrapper>}>
                    <Route path="schedulerruns/:id" element={<SchedulerSimulation />} />       { /* legacy */ }
                    <Route path="schedulersimulations/:id" element={<SchedulerSimulation />} />
                </Route>
                <Route path="/" element={<LoginWrapper roles={['TEAM_MANAGER']}><AdminLayout mode="general" /></LoginWrapper>}>
                    <Route path="dashboard_team_manager" element={<Dashboard />} />
                    <Route path="games_team_manager" element={<GamesTeamManager />} />
                    <Route path="games_team_manager/:id" element={<GameTeamManager />} />
                    <Route path="teams_team_manager" element={<TeamsTeamManager /> } />
                    <Route path="teams_team_manager/:id" element={<Team /> } />
                    <Route path="players_team_manager" element={<PlayersTeamManager />} />
                    <Route path="players_team_manager/:id" element={<Player />} />
                    <Route path="persons_team_manager" element={<PersonsTeamManager />} />
                    <Route path="persons_team_manager/:id" element={<Person />} />
                </Route>
                <Route path="/settings" element={<LoginWrapper roles={['ADMIN']}><AdminLayout mode="settings" /></LoginWrapper>}>
                    <Route path="organizations" element={<Organizations />} />
                    <Route path="organizations/:id" element={<Organization />} />
                    <Route path="system_settings" element={<div>settings/system_settings</div>} />
                    <Route path="system_settings/:id" element={<></>} />
                    <Route path="users" element={<Users />} />
                    <Route path="users/:id" element={<User />} />
                    <Route path="" element={<Navigate to="/settings/organizations" replace />} />
                </Route>
                <Route path="/rulepacks" element={<LoginWrapper roles={['ADMIN']}><AdminLayout mode="rulepacks" /></LoginWrapper>}>
                    <Route path="ListEventLocations" element={<RulePackList entity="ListEventLocation" />} />
                    <Route path="ListEventLocations/:id" element={<RulePackRecord entity="ListEventLocation" />} />
                    <Route path="ListGoalLocations" element={<RulePackList entity="ListGoalLocation" />} />
                    <Route path="ListGoalLocations/:id" element={<RulePackRecord entity="ListGoalLocation" />} />
                    <Route path="ListGoalSubtypes" element={<RulePackList entity="ListGoalSubtype" />} />
                    <Route path="ListGoalSubtypes/:id" element={<RulePackRecord entity="ListGoalSubtype" />} />
                    <Route path="ListGoalTypes" element={<RulePackList entity="ListGoalType" />} />
                    <Route path="ListGoalTypes/:id" element={<RulePackRecord entity="ListGoalType" />} />
                    <Route path="ListInjuryTypes" element={<RulePackList entity="ListInjuryType" />} />
                    <Route path="ListInjuryTypes/:id" element={<RulePackRecord entity="ListInjuryType" />} />
                    <Route path="ListPenaltySubtypes" element={<RulePackList entity="ListPenaltySubtype" />} />
                    <Route path="ListPenaltySubtypes/:id" element={<RulePackRecord entity="ListPenaltySubtype" />} />
                    <Route path="ListPenaltyTypes" element={<RulePackList entity="ListPenaltyType" />} />
                    <Route path="ListPenaltyTypes/:id" element={<RulePackRecord entity="ListPenaltyType" />} />
                    <Route path="ListPeriods" element={<RulePackList entity="ListPeriod" />} />
                    <Route path="ListPeriods/:id" element={<RulePackRecord entity="ListPeriod" />} />
                    <Route path="ListPlayerLevels" element={<RulePackList entity="ListPlayerLevel" />} />
                    <Route path="ListPlayerLevels/:id" element={<RulePackRecord entity="ListPlayerLevel" />} />
                    <Route path="ListPersonRoles" element={<RulePackList entity="ListPersonRole" />} />
                    <Route path="ListPersonRoles/:id" element={<RulePackRecord entity="ListPersonRole" />} />
                    <Route path="ListPositions" element={<RulePackList entity="ListPosition" />} />
                    <Route path="ListPositions/:id" element={<RulePackRecord entity="ListPosition" />} />
                    <Route path="ListResultPoints" element={<RulePackList entity="ListResultPoint" />} />
                    <Route path="ListResultPoints/:id" element={<RulePackRecord entity="ListResultPoint" />} />
                    <Route path="ListResultTypes" element={<RulePackList entity="ListResultType" />} />
                    <Route path="ListResultTypes/:id" element={<RulePackRecord entity="ListResultType" />} />
                    <Route path="ListShottypes" element={<RulePackList entity="ListShotType" />} />
                    <Route path="ListShottypes/:id" element={<RulePackRecord entity="ListShotType" />} />
                    <Route path="ListColors" element={<RulePackList entity="ListColor" />} />
                    <Route path="ListColors/:id" element={<RulePackRecord entity="ListColor" />} />
                    <Route path="" element={<Navigate to={"/rulepacks/ListEventLocations"} replace />} />
                </Route>
                <Route path="/embed" element={<EmbedLayout />}>
                    <Route path="games" element={<EmbedGames />} />
                    <Route path="standings" element={<EmbedStandings />} />
                    <Route path="players_stats" element={<EmbedPlayersStats />} />
                    <Route path="goalies_stats" element={<EmbedGoaliesStats />} />
                    <Route path="game" element={<EmbedGame />} />
                    <Route path="new_player" element={<EmbedNewPlayer />} />
                    <Route path="disciplinaries" element={<EmbedDisciplinaries />} />
                    <Route path="disciplinary" element={<EmbedDisciplinary />} />
                    <Route path="season_terms" element={<EmbedSeasonTerms />} />
                </Route>
                <Route path="*" element={<AppErrorLayout type="404" />} />
            </>
        )
    );

    return (
        <RouterProvider router={router} />
    );
};
