import useEntities from 'hooks/useEntities';
import HmsEntityTableController from 'components/HmsEntityTableController';

const Venues = () => {
  const entities = useEntities();
  const filterGroups = null;
  const showColumns = [
    'NameWithLogo', 
    'nick', 
    'short', 
    'web', 
    'description', 
    'foundDate', 
    'location', 
    'capacity'
  ];

  return (
    <HmsEntityTableController
      entity={entities.Venue}
      filterGroups={filterGroups}
      showColumns={showColumns}
    />
  )
}

export default Venues