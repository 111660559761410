import useEntities from 'hooks/useEntities';
import RelationsTable from 'components/RelationsTable';
import { useNavigate } from 'react-router-dom';
import { entityColumnsToAntColumns } from 'utils/entityColumnsToAntColumns';

const GameDisciplinary = ({ sourceEntityId, data, competitionId, onChange }) => {
    const entities = useEntities();
    const sourceEntity = entities.Game;
    const targetEntity = entities.Disciplinary;
    const navigate = useNavigate();

    return (
        <div>
            <RelationsTable
                addButton={{
                    label: 'Create disciplinary',
                    onClick: () => navigate(`/disciplinaries/_new_?gameId=${sourceEntityId}&competitionId=${competitionId}`),
                }}
                data={data}
                columns={entityColumnsToAntColumns(targetEntity, [
                    'Game',
                    'subject',
                    'Players',
                    'status',
                ])}
                sourceEntity={sourceEntity}
                targetEntity={targetEntity}
                emptyMessage={`This ${sourceEntity.displayName} does not have any ${targetEntity.displayNamePlural}`}
            />
        </div>
    )
}

export default GameDisciplinary
