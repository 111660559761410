import useEntities from 'hooks/useEntities';
import HmsEntityTableController from 'components/HmsEntityTableController';

const Sponsors = () => {
  const entities = useEntities();
  const filterGroups = null;
  const showColumns = [
    'NameWithLogo', 
    'nick', 
    'short',
    'claim',
    'web',
    'description',
    'legalName',
  ];

  return (
    <HmsEntityTableController
      entity={entities.Sponsor}
      filterGroups={filterGroups}
      showColumns={showColumns}
    />
  )
}

export default Sponsors