import useEntities from 'hooks/useEntities';
import HmsEntityTableController from 'components/HmsEntityTableController';

const Users = () => {
  const entities = useEntities();
  const filterGroups = null;

  return (
    <HmsEntityTableController
      entity={entities.User}
      filterGroups={filterGroups}
      columnSets={[
        {
          name: 'Admins',
          filters: { roles: 'ADMIN' },
          columns: [
          'Name',
          'email',
          'phone',
          'Roles',
          'Organizations',
          ],
        },
        {
          name: 'Team Managers',
          filters: { roles: 'TEAM_MANAGER' },
          columns: [
          'Name',
          'email',
          'phone',
          'Roles',
          'Organizations',
          'ManagedTeams',
          ],
        },
      ]}
    />
  )
}

export default Users