import React, { useState, useEffect } from 'react';
import { Form, Input, Button } from 'antd';

const ShortTimePicker = ({ value = '', onChange }) => {
  const initialTime = value ? value.slice(0, 5) : '';
  const [inputValue, setInputValue] = useState(initialTime);
  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    setInputValue(initialTime);
  }, [initialTime]);

  const handleChange = (e) => {
    const timeValue = e.target.value;
    setInputValue(timeValue);

    if (!/^(2[0-3]|[01][0-9]):([0-5][0-9])$/.test(timeValue)) {
      setValidationError('Correct format HH:MM');
    } else {
      setValidationError('');
      onChange(`${timeValue}:00`); // Append ":00" to "HH:mm"
    }
  };

  return (
    <div>
      <Input
        value={inputValue}
        onChange={handleChange}
        placeholder="HH:MM"
      />
      {validationError && (
        <div style={{ color: 'red', marginTop: '5px' }}>{validationError}</div>
      )}
    </div>
  );
};

export default ShortTimePicker;
