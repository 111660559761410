import React, { useEffect, useRef, useState } from 'react'

function useHotKey(hotkey, action) {
    const actionRef = useRef(action);

    // Update the action ref whenever the action changes
    useEffect(() => {
        actionRef.current = action;
    }, [action]);

    useEffect(() => {
        function handleKeyPress(event) {
            if (event.key === hotkey || event.keyCode === hotkey) {
                actionRef.current();
            }            
        }

        console.log('registering keydown listener......');
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            console.log('REMOVING keydown listener......');
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [hotkey]);
}

export default useHotKey;