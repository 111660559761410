import { Content } from 'antd/es/layout/layout'
import Breadcrumbs from 'components/Breadcrumbs'
import React from 'react'
import icons from 'utils/icons'
import QuickLinks from 'components/Dashboard/QuickLinks'

const Dashboard = () => {
  return (
    <Content className="m-3 desktop:m-4 desktop:mr-0">  { /* desktop:mr-0 is to prevent unwanted horizontal scrollbar */ }
      <Breadcrumbs items={[{ title: 'Dashboard', icon: icons.Dashboard }]} />
      <div className="flex gap-5 items-start">
        <QuickLinks />
      </div>
    </Content>
  )
}

export default Dashboard