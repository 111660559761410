import extractTextFromJSX from "utils/extractTextFromJsx";
import { removeAccents } from "utils/oneLiners";

export function getColumnFtindex(columns, d) {
  let ftIndex = '';
  for (const col of columns) {
    if (col.searchValue) {
      ftIndex += col.searchValue(d) + ' ';
    } else if (col.render) {
      const r = col.render(d);
      if (typeof r == 'string') {
        ftIndex += r + ' ';
      } else {
        ftIndex += extractTextFromJSX(r) + ' ';
      }
    }
  }
  ftIndex = removeAccents(ftIndex.toLowerCase());
  return ftIndex;
}

export function matchSearchString(ftIndex, search) {
  const searchWords = removeAccents(search.trim().toLowerCase()).split(' ').map(w => w.trim());
  for (const searchWord of searchWords) {
      if (ftIndex.indexOf(searchWord) == -1) {
          return false;
      }
  }
  return true;
}
