import React from 'react'
import EmbedLoadingIndicator from 'components/Embeds/EmbedLoadingIndicator';
import PreloadEmbedFonts from 'components/Embeds/PreloadEmbedFonts';
import Notifications from 'components/Notifications';
import './EmbedWrapper.css';

const EmbedWrapper = ({ isLoading, isError, lang, children }) => {
  if (isLoading || isError) {
    return (
      <>
        <PreloadEmbedFonts />
        <Notifications />
        <EmbedLoadingIndicator isLoading={isLoading} isError={isError} lang={lang} />
      </>
    );
  }

  return (
    <>
      <div className="font-roboto-flex">
        <Notifications />
        {children}
      </div>
    </>
  )
}

export default EmbedWrapper