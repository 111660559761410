import Confirm from 'components/Confirm';
import React, { useState } from 'react'
import useConfirmStore from 'store/confirmStore';

const ConfirmGlobal = () => {
  const confirmOpen = useConfirmStore(state => state.confirmOpen);
  const setConfirmOpen = useConfirmStore(state => state.setConfirmOpen);

  return (
    <Confirm
      {...confirmOpen}
      visible={confirmOpen}
      confirmButtonProps={{ size: 'small', ...(confirmOpen?.confirmButtonProps ?? {}) }}
      cancelButtonProps={{ size: 'small', ...(confirmOpen?.cancelButtonProps ?? {}) }}
      handleConfirm={async () => {
        if (confirmOpen.onOk) {
          await confirmOpen.onOk();
        }
        setConfirmOpen(null);
      }}
      handleCancel={async () => {
        if (confirmOpen.onCancel) {
          await confirmOpen.onCancel();
        }
        setConfirmOpen(null);
      }}
    />
  )
}

export default ConfirmGlobal