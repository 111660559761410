import React, { useEffect, useState } from 'react'
import EntityLogo from 'components/EntityLogo'
import useFetchEntity from 'hooks/useFetchEntity';
import { JSON_parseSafe } from 'utils/oneLiners';
import EntityLogoEditorModal from 'components/EntityLogoEditorModal';
import { getOriginalImage } from 'utils/getImage';
import { Tooltip } from 'antd';

const EntityLogoEditor = ({ entity, id, size, disabled = false }) => {
  const [isCropDialogVisible, setIsCropDialogVisible] = useState(false)
  const additionalFields = entity.name == 'Player' ? ['firstName', 'lastName'] : ['name'];
  const { data, reload } = useFetchEntity(entity.name, id, '', { onlyAttributes: [entity.primaryKey, 'logoUrl', 'logoMetadata', ...additionalFields] });
  const cropParameters = JSON_parseSafe(data?.logoMetadata)?.cropParameters;
  const cropParam = cropParameters && { x: cropParameters.x, y: cropParameters.y };
  const zoomParam = cropParameters && cropParameters.zoom;

  const showCropDialog = () => {
    setIsCropDialogVisible(true)
  }

  const onClose = (success) => {
    setIsCropDialogVisible(false)
    if (success) {
      reload();
    }
  }

  if (!data) {
    return (
        <span className="bg-gray-100 rounded-full flex items-center justify-center text-gray-500" style={{ width: size + 'px', height: size + 'px' }}>
          <Tooltip className="cursor-pointer" title="You will be able to select an image after you first save this record.">
            No image
          </Tooltip>
        </span>
    );
  }

  return (
    <>
      <EntityLogoEditorModal
        srcImage={data.logoMetadata && getOriginalImage(entity.name, id)}
        cropParam={cropParam}
        zoomParam={zoomParam}
        entity={entity}
        id={id}
        isOpen={isCropDialogVisible}
        onClose={onClose}
        disabled={true}
      />

      <span className={!disabled ? 'cursor-pointer' : ''} onClick={() => !disabled && showCropDialog()}>
        <EntityLogo entity={data} id={id} size={size} />
      </span>
    </>
  )
}

export default EntityLogoEditor
