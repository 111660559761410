import React from "react";
import { formatDateToLongFormat } from "../../../utils/IGdateTimeFunctions";
import IGImg from "./IGImg";

const GameMatchRecap = ({ game }, key) => (
  <div key={key} className="w-fit border-b border-dotted border-white">
    <section className="flex w-auto items-center justify-between">
      {<p className="font-righteous text-xs text-yellow-400">
        <span className="mr-2 text-sm">★</span>
        {game.HomeStar}
      </p>}
      <p className="font-economica">
        {formatDateToLongFormat(game.Date)}, {game.Time} {game.VenueName}
      </p>
      {<p className="font-righteous text-xs text-yellow-400">
        {game.AwayStar}
        <span className="ml-2 text-sm">★</span>
      </p>}
    </section>
    <section className="mt-1 flex w-fit items-center justify-center gap-6 text-4xl">
      <p className="w-[200px] text-right">{game.HomeTeamName}</p>
      <IGImg src={game.HomeTeamLogo} alt="" className="h-12 w-24 object-cover" />
      <p className="w-24 text-center font-number">{game.Score}</p>
      <IGImg src={game.AwayTeamLogo} alt="" className="h-12 w-24 object-cover" />
      <p className="w-[200px]">{game.AwayTeamName}</p>
    </section>
  </div>
);

export default GameMatchRecap;
