import { Button, Divider } from 'antd'
import React from 'react'
import { CheckCircleOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';
import useLang from 'hooks/useLang';

const EmbedNewPlayerSUCCESS = ({ setState }) => {
  const [searchParams] = useSearchParams();
  const lang = searchParams.get('lang') ?? 'cs';
  const LANG = useLang(lang);

  const registerNextPlayer = () => {
    setState('START');
  }

  return (
    <div className="m-4 mb-10 font-roboto-flex">
      <div className="text-2xl font-bold text-center mt-8 mb-8">{LANG.CONGRATULATIONS}!</div>

      <div className="max-w-[800px] mx-auto">
        <Divider />
      </div>

      <div className="text-xl">

        <div className="text-center mt-20 text-green-500 text-[5rem]">
          <CheckCircleOutlined />
        </div>

        <div className="text-center mt-20">
          {LANG.WE_HAVE_RECEIVED_YOUR_REGISTRATION}
        </div>

        <div className="text-center mt-10">
          {LANG.WE_WILL_CONTACT_YOU_SOON} :)
        </div>

        <div className="text-center mt-10">
          {LANG.YOUR_PHM}
        </div>

        <div className="text-center mt-12">
          <Button type="primary" onClick={registerNextPlayer}>{LANG.REIGSTER_ANOTHER_PLAYER}</Button>
        </div>
      </div>

    </div>
  )
}

export default EmbedNewPlayerSUCCESS