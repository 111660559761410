import { Button, Input, Modal, Radio } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Select, Space } from 'antd';
import useLists from 'hooks/useLists';
import { formatTimeString, parseTimeString } from 'utils/timeString';

const GameEventPeriodSelect = ({ autoFocusInputRef, data, onValueChange }) => {
  const { ListPeriod } = useLists();
  const disabled = !data?.event?.createdAt;

  const periods = ListPeriod
    .map(lp => ({
      name: lp.name,
      short: lp.code,
      duration: lp.duration,
      index: lp.priority,
    }));
  periods.sort((a, b) => a.index - b.index);

  const periodOptions = periods.map(p => ({
    key: p.name,
    label: p.name,
    value: p.name,
  }));

  return (
    <div className="grid gap-x-4 grid-cols-10 items-start">
      <div className="col-span-2 h-8 flex items-center">
        Period:
      </div>
      <div className="col-span-8 h-8 flex items-center">
        <Radio.Group
          disabled={disabled}
          options={periodOptions}
          onChange={(e) => onValueChange('period', e.target.value)}
          value={data?.event?.period}
          optionType="button"
          buttonStyle="solid"
        />
      </div>
    </div>
  )
}

export default GameEventPeriodSelect;
