import useEntities from 'hooks/useEntities';
import RelationsTable from 'components/RelationsTable';
import { Button } from 'antd';
import icons from 'utils/icons';
import { useRef, useState } from 'react';
import LinkRelationModal from 'components/Modals/LinkRelationModal';
import Confirm from 'components/Confirm';

const RelationsEditable = ({ sourceEntity, targetEntity, targetEntityAs, emptyMessage, addButtonText, columns, targetEntityColumns, targetEntityRelations, targetEntityFilters, sourceEntityId, data, onChange, targetEntityModalWidth, canRemove = true, canOpen = false, canAdd = true, cannotRemoveTooltip, isLocked = false }) => {
    const [linkRelation, setLinkRelation] = useState(null);

    const linkRelationOk = async (values) => {
        const res = await sourceEntity.linkWith(sourceEntityId, targetEntity, values, targetEntityAs);
        setLinkRelation(null);
        onChange();
    }

    const linkRelationCancel = () => {
        setLinkRelation(null);
    }

    const removeClicked = async (id) => {
        await sourceEntity.removeLink(sourceEntityId, targetEntity, id, targetEntityAs);
        onChange();
    }

    return (
        <div>
            <RelationsTable
                addButton={!isLocked && canAdd && {
                    onClick: () => setLinkRelation(targetEntity.name),
                }}
                data={data}
                sourceEntity={sourceEntity}
                targetEntity={targetEntity}
                emptyMessage={emptyMessage}
                columns={columns}
                removeClicked={!isLocked && canRemove && removeClicked}
                canOpen={canOpen}
                cannotRemoveTooltip={!isLocked && cannotRemoveTooltip}
            />

            <LinkRelationModal
                entity={targetEntity}
                open={linkRelation}
                onOk={linkRelationOk}
                onCancel={linkRelationCancel}
                columns={targetEntityColumns}
                relations={targetEntityRelations}
                filters={targetEntityFilters}
                width={targetEntityModalWidth}
            />

        </div>
    )
}

export default RelationsEditable
