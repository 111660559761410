import { create } from 'zustand';

const useUrlParamStore = create(
  (set, get) => ({
    state: {},
    defaultValue: {},
    dethrottle: 0,
    validForLocation: null,

    setValidForLocation: (validForLocation) => {
      set({
        validForLocation,
      });
    },

    replaceState: (newState) => {
      set({
        state: {
          ...newState,
        },
      });
    },

    setParam: (key, value) => {
      set({
        state: {
          ...get().state,
          [key]: value,
        },
      });
    },

    setParamDefaultValue: (key, value) => {
      set({
        defaultValue: {
          ...get().defaultValue,
          [key]: value,
        },
      });
    },

    setDethrottle: (value) => {
      set({
        dethrottle: value,
      });
    },
  })
);

export default useUrlParamStore;