import { Link, useParams, useSearchParams, useNavigate } from 'react-router-dom';

const useUrlParams = () => {
  const { id } = useParams();
  const [ searchParams ] = useSearchParams();
  const parentId = searchParams.get('parentId');
  return { id, parentId };
}

export default useUrlParams;
