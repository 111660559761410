import React, { useEffect, useRef, useState } from 'react';
import useEntities from 'hooks/useEntities';
import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useLoadEntity from 'hooks/useLoadEntity';
import HmsTabbedTables from 'components/HmsTabbedTables';
import SchedulerConfigVenues from 'components/SchedulerConfig/SchedulerConfigVenues';
import SchedulerConfigPhases from 'components/SchedulerConfig/SchedulerConfigPhases';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import HmsField from 'components/Form/HmsField';
import SchedulerConfigIcePatterns from 'components/SchedulerConfig/SchedulerConfigIcePatterns';
import SchedulerConfigSpecialRequests from 'components/SchedulerConfig/SchedulerConfigSpecialRequests';
import icons from 'utils/icons';
import { getDefaultCardWidth } from 'utils/uiPreferences';
import useInterval from 'use-interval';
import useConfirmStore from 'store/confirmStore';
import SchedulerConfigSimulations from 'components/SchedulerConfig/SchedulerConfigSimulations';

export default function SchedulerConfig() {
  const entities = useEntities();
  const entity = entities.SchedulerConfig;
  const showConfirm = useConfirmStore(state => state.showConfirm);

  const { id, isLoading, isReloading, isError, isNotFound, data, reload } = useLoadEntity({
    entity,
  });

  const { relationsData: relationsData, reloadRelations: reloadRelations } = useLoadEntity({
    entity,
    relations: [
      'Venues',
      'Phases,Phases>Group,Phases>Group>Season,Phases>Group>Season>Competition',
      'Phases>Teams',
    ].join(','),
  });

  const { relationsData: schedulerConfigRelationsData, reloadRelations: reloadSchedulerConfigRelations } = useLoadEntity({
    entity,
    relations: [
      'SchedulerConfigIcePatterns,SchedulerConfigIcePatterns>Venue',
      'SchedulerConfigSpecialRequests,SchedulerConfigSpecialRequests>Team,SchedulerConfigSpecialRequests>SchedulerConfigIcePattern,SchedulerConfigSpecialRequests>SchedulerConfigIcePattern>Venue',
    ].join(','),
  });

  const { relationsData: schedulerSimulationRelationsData, reloadRelations: reloadSchedulerSimulations } = useLoadEntity({
    entity,
    relations: [
      'SchedulerSimulations(schedulerSimulationId,algorithm,createdAt,gamesCount,warningCount,errCount,+GeneratedGamesCount,teamPrefRateMin,teamPrefRateMax)',
    ].join(','),
  });

  const ref = useRef(null);
  const tabbedTablesRef = useRef(null);

  const isLocked = (id != '_new_' && data?.status != 'NEW');
  const infoBarClassName =
    data?.status == 'WAITING' ? 'border-blue-200 bg-blue-100 text-blue-500'
      : data?.status == 'RUNNING' ? 'border-orange-200 bg-orange-100 text-orange-500'
        : data?.status == 'FINISHED' ? 'border-green-200 bg-green-100 text-green-500'
          : 'border-red-200 bg-red-100 text-red-500';

  useInterval(() => {
    if (data?.status == 'WAITING' || data?.status == 'RUNNING') {
      reload();
    }
  }, 1000);

  useEffect(() => {
    if (data?.status == 'FINISHED') {
      reloadSchedulerSimulations();
      if (tabbedTablesRef.current) {
        tabbedTablesRef.current.setActiveKey('Simulations');
      }
    }
  }, [data?.status]);

  const runClicked = async () => {
    // Validation: check if relationsData1.Phases all have non-null tournamentType
    if (relationsData?.Phases?.some(p => !p.tournamentType)) {
      showConfirm({
        title: 'Error',
        message:
          <p>Cannot run simulation because some <b>Phases</b> do not have <b>Tournament type</b> filled in!</p>
        ,
        confirmButtonText: 'OK',
        cancelButtonText: null,
      });
      return;
    }

    showConfirm({
      title: 'Confirm',
      message:
        data.status == 'NEW'
          ?
          `Are you sure do you want to run scheduler?`
          :
          data.status == 'FINISHED'
            ?
            <div>
              <p>{`WARNING: Scheduler has already finished and generated ${schedulerSimulationRelationsData?.SchedulerSimulations?.length} results.`}</p>
              <p>{`Are you sure do you want to run scheduler again?`}</p>
            </div>
            :
            <div>
              <p>{`WARNING: Scheduler is currently in ${data?.status} state.`}</p>
              <p>{`Are you sure do you want to run scheduler again?`}</p>
            </div>
      ,
      confirmButtonText: 'Run',
      onOk: async () => {
        await entities.SchedulerConfig.patch(id, { status: 'WAITING' });
        reload();
      },
    });
  }

  return (
    <>
      <HmsEntityFormController
        {... { ref, entity, id, isLoading: isLoading, isReloading, isError, isNotFound, data, reload }}
        canClone={true}
        header={({ isDirty }) =>
          (id != '_new_' && data?.status != undefined && data?.status != 'NEW') &&
          <div className={`bg-[#ddeeff] ${infoBarClassName} rounded-md mb-3 p-3 ${getDefaultCardWidth(true)} flex items-center justify-between border-[1px]`}>
            <div className="w-full flex items-center gap-3">
              <span className="">{icons.Information}</span>
              <span>Scheduler status: {data?.status}</span>
            </div>
          </div>
        }
        buttons={[{
          label: 'Run',
          icon: icons.Game,
          onClick: runClicked,
          disabled: id == '_new_' || data?.isCloneDraft,
          disabledTooltip: 'Need to save first',
        }]}
        form={(
          <>
            <div className="flex-auto">
              <HmsEntityFormGridBasic>
                <HmsField name="name" fieldItemProps={{ disabled: isLocked }} />
                <HmsField name="iterations" fieldItemProps={{ disabled: isLocked }} />
                {/* <HmsField name="status" fieldItemProps={{ disabled: true }} /> */}
              </HmsEntityFormGridBasic>
            </div>
          </>
        )}
        footer={id != '_new_' && (
          <div className="mt-4">
            <div className="mt-4">
              <HmsTabbedTables
                ref={tabbedTablesRef}
                tables={[
                  {
                    key: 'Venues',
                    title: "Venues" + (relationsData?.Venues ? (' (' + relationsData?.Venues?.length + ')') : ''),
                    children: <SchedulerConfigVenues data={relationsData?.Venues} sourceEntityId={data?.schedulerConfigId} sourceEntityData={data} onChange={reloadRelations} isLocked={isLocked} />,
                  },
                  {
                    key: 'Phases',
                    title: "Phases" + (relationsData?.Phases ? (' (' + relationsData?.Phases?.length + ')') : ''),
                    children: <SchedulerConfigPhases data={relationsData?.Phases} sourceEntityId={data?.schedulerConfigId} sourceEntityData={data} onChange={reloadRelations} isLocked={isLocked} />,
                  },
                  {
                    key: 'IcePatterns',
                    title: "Ice Patterns" + (schedulerConfigRelationsData?.SchedulerConfigIcePatterns ? (' (' + schedulerConfigRelationsData?.SchedulerConfigIcePatterns?.length + ')') : ''),
                    children: <SchedulerConfigIcePatterns data={schedulerConfigRelationsData?.SchedulerConfigIcePatterns} sourceEntityId={data?.schedulerConfigId} sourceEntityData={data} phases={relationsData?.Phases} onChange={reloadSchedulerConfigRelations} isLocked={isLocked} />,
                  },
                  {
                    key: 'SpecialRequests',
                    title: "Special Requests" + (schedulerConfigRelationsData?.SchedulerConfigSpecialRequests ? (' (' + schedulerConfigRelationsData?.SchedulerConfigSpecialRequests?.length + ')') : ''),
                    children: <SchedulerConfigSpecialRequests data={schedulerConfigRelationsData?.SchedulerConfigSpecialRequests} sourceEntityId={data?.schedulerConfigId} sourceEntityData={data} phases={relationsData?.Phases} icePatterns={schedulerConfigRelationsData?.SchedulerConfigIcePatterns} onChange={reloadSchedulerConfigRelations} isLocked={isLocked} />,
                  },
                  {
                    key: 'Simulations',
                    title: "Simulations" + (schedulerSimulationRelationsData?.SchedulerSimulations ? (' (' + schedulerSimulationRelationsData?.SchedulerSimulations?.length + ')') : ''),
                    children: <SchedulerConfigSimulations data={schedulerSimulationRelationsData?.SchedulerSimulations} sourceEntityId={data?.schedulerConfigId} sourceEntityData={data} onChange={reloadSchedulerSimulations} />,
                    hidden: (data?.status != 'FINISHED') && ((schedulerSimulationRelationsData?.SchedulerSimulations?.length ?? 0) == 0),
                  },
                ].filter(t => t && !t.hidden)}
              />
            </div>
          </div>
        )}
      />
    </>
  );
}
