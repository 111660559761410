import React, { useEffect } from 'react'
import Tracker from '@openreplay/tracker';

const OpenReplayTracker = ({ projectKey, userId, disableSecureMode, metadata }) => {
  const [tracker, setTracker] = React.useState(null);

  useEffect(() => {
    const startOpenReplay = async () => {
      if (!tracker && projectKey) {
        try {
          const tracker = new Tracker({
            projectKey: projectKey,
            __DISABLE_SECURE_MODE: disableSecureMode,
          });
          if (!tracker.isActive()) {
            setTracker(tracker);
            await tracker.start({
              userID: userId,
              metadata: metadata
            });
          }
        } catch (error) {
          console.error('OpenReplay error:', error);
        }
      }
    }
    startOpenReplay();
  }, []);
}

export default OpenReplayTracker
