import { Button } from 'antd'
import React from 'react'
import TooltipIfTruncated from 'components/TooltipIfTruncated';

const GameEventListSelect = ({ label, name, options, data, toggleValueChange, placeholder }) => {
    return (
        <div className="grid gap-x-4 grid-cols-10 items-start">
            <div className="col-span-2 h-8 flex items-center">
                {label}:
            </div>
            <div className="col-span-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                {placeholder && <span className="text-gray-400">{placeholder}</span>}
                {!placeholder && options.length == 0 && <span className="text-gray-400">No options</span>}
                {!placeholder && options.length > 0 && options.map((b, idx) => (
                    <Button
                        key={idx}
                        type={data.event[name] == b.value ? 'primary' : 'default'}
                        disabled={b.disabled}
                        className="flex items-center gap-2"
                        onClick={() => toggleValueChange(name, b.value)}
                    >
                        <TooltipIfTruncated title={b.label}>
                            <span>{b.label}</span>
                        </TooltipIfTruncated>
                    </Button>
                ))}
            </div>
        </div>
    )
}

export default GameEventListSelect;