import { Button, Input, Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Select, Space } from 'antd';
import useLists from 'hooks/useLists';
import { formatTimeString, parseTimeString } from 'utils/timeString';
import GameEventGridSelect from 'components/Modals/GameEvent/GameEventGridSelect';

const GameEventGoalLocationSelect = ({ data, toggleValueChange }) => {
    const { ListGoalLocation } = useLists();

    return (
        <GameEventGridSelect
            name="listGoalLocationId"
            width={338}
            height={209}
            data={data}
            toggleValueChange={toggleValueChange}
            image="/net.png"
            options={
                [
                    'Left top corner',
                    'Top shelf',
                    'Right top corner',
                    'Left side',
                    'Middle',
                    'Right side',
                    'Left bottom corner',
                    'Five hole',
                    'Right bottom corner',
                ].map(o => {
                    const id = ListGoalLocation.find(l => l.name == o)?.listGoalLocationId;
                    return {
                        value: id,
                        label: o,
                    }
                })

            } />
    )
}

export default GameEventGoalLocationSelect;