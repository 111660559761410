import React, { createContext, useContext, useState, useEffect } from 'react';
import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useEntities from 'hooks/useEntities';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import useLoadEntity from 'hooks/useLoadEntity';
import HmsEntityFormGridFullWidth from 'components/Form/HmsEntityFormGridFullWidth';
import HmsField from 'components/Form/HmsField';
import FormSelect from 'components/Form/FormSelect';
import useFetchEntity from 'hooks/useFetchEntity';
import useFetchEntities from 'hooks/useFetchEntities';
import { Button, Card, Cascader, Form, Space, Tabs } from 'antd';
import PlayerSelect from 'components/Form/PlayerSelect';
import PositionSelect from 'components/Form/PositionSelect';
import JerseySelect from 'components/Form/JerseySelect';
import RemoveButton from 'components/RemoveButton';
import FormCascader from 'components/Form/FormCascader';
import { PlusOutlined } from '@ant-design/icons';
import FormCheckbox from 'components/Form/FormCheckbox';
import useInterval from 'use-interval';
import { Checkbox, Input, Row, Col, DatePicker, Select } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import FormDatePicker from 'components/Form/FormDatePicker';
import FormRangePicker from 'components/Form/FormRangePicker';
import TeamSelect from 'components/Form/TeamSelect';
import { HmsFormContext } from 'components/Form/HmsFormContext';
import EntityLogo from 'components/EntityLogo';

const PassiveTeamDisplay = ({ teamId }) => {
  const { data } = useFetchEntity('Team', teamId);

  return (
    <span className="flex gap-1 items-center whitespace-nowrap">
      <EntityLogo entity={data} size={16} />
      {data?.name}
    </span>
  )
}

const GamePayments = () => {
  return (
    <>
      <HmsFormContext.Consumer>
        {({ data }) =>
          <>
            <div className="w-[300px]">
              <HmsEntityFormGridFullWidth>
                <HmsField
                  name="paymentHost"
                  label={
                    <span className="flex items-center whitespace-nowrap gap-1">
                      Payment home - <span className="flex items-center whitespace-nowrap"><PassiveTeamDisplay teamId={data?.homeTeamId} />:</span>
                    </span>}
                />
                <HmsField
                  name="paymentGuest"
                  label={
                    <span className="flex items-center whitespace-nowrap gap-1">
                      Payment away - <span className="flex items-center whitespace-nowrap"><PassiveTeamDisplay teamId={data?.awayTeamId} />:</span>
                    </span>}
                />
                <HmsField
                  name="paymentTimekeeper"
                  label={`Payment timekeeper - ${data?.timekeeper}`}
                />
                <HmsField
                  name="paymentReferee"
                  label={`Payment referee - ${data?.referee}`}
                />
              </HmsEntityFormGridFullWidth>
            </div>
          </>
        }
      </HmsFormContext.Consumer>
    </>
  )
}

export default GamePayments;
