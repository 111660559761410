import React from 'react'
import { Form } from 'antd';

const HmsFormItem = ({ name, label, required, validators = [], children, suffix, ...props }) => {
    return (
        suffix ?
            <Form.Item
                className="mb-2"
                labelCol={ {className: '!p-0 !pb-1'} }
                required={required}
                label={label &&
                    <span className=" text-gray-500 custom-form-label-text-size">
                        {label}{typeof label == 'string' ? ':' : ''}
                    </span>}
                colon={false}
            >
                <div className="flex gap-3 items-center">
                    <Form.Item
                        className="flex-1 mb-0"
                        name={name}
                        rules={required ? [{ required: true, message: label ? (label + ' is required!') : ('Required!') }, ...validators] : [...validators]}
                        {...props}
                    >
                        {children}
                    </Form.Item>
                    {suffix &&
                        <div className="flex-shrink-0">{suffix}</div>
                    }
                </div>
            </Form.Item>
        :
            <Form.Item
                className="mb-2"
                labelCol={ {className: '!p-0 !pb-1'} }
                label={label &&
                    <span className=" text-gray-500 custom-form-label-text-size">
                        {label}{typeof label == 'string' ? ':' : ''}
                    </span>}
                colon={false}
                name={name}
                rules={required ? [{ required: true, message: label ? (label + ' is required!') : ('Required!') }, ...validators] : [...validators]}
                {...props}
            >
                {children}
            </Form.Item>
    )
}

export default HmsFormItem