import { Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { useRef } from 'react';

const FixedHeaderTable = (props) => {
    const tableRef = useRef(null);
    const [tablePosition, setTablePosition] = useState(0);
    const [headerHeight, setHeaderHeight] = useState(0);
    const bottomMarginHeight = props.bottomMarginHeight ?? 0;

    useEffect(() => {
      const tablePosition = tableRef.current.getBoundingClientRect().top;
      setTablePosition(tablePosition);

      const headerCells = tableRef.current.querySelectorAll('.ant-table-thead th');
      let calculatedHeaderHeight = 0;
      headerCells.forEach(cell => {
        calculatedHeaderHeight = Math.max(calculatedHeaderHeight, cell.offsetHeight);
      });
      setHeaderHeight(calculatedHeaderHeight);      

      console.log('tablePosition', tablePosition, 'headerHeight', headerHeight, 'bottomMarginHeight', bottomMarginHeight);
    }, []);

  return (
    <Table
        ref={tableRef}
        scroll={{ y: `calc(100vh - ${tablePosition + headerHeight + bottomMarginHeight}px)` }}
        {...props}
    ></Table>    
  )
}

export default FixedHeaderTable