import React, { useMemo, useState } from 'react';
import useEntities from 'hooks/useEntities';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import icons from 'utils/icons';
import { Button, Input, Modal, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import extractTextFromJSX from 'utils/extractTextFromJsx';
dayjs.extend(utc);

const ChangeRequestDialog = ({ id, entity, data, open, changeRequestFields, onOk, onCancel }) => {
  const entities = useEntities();
  const [editFields, setEditFields] = useState([]);
  const canSubmitSuggetedEdits = editFields.some(f => f.value != null);

  return (
    <Modal
      open={open}
      title="Request Change"
      className="desktop:min-w-[600px]"
      closeIcon={false}
      footer={
        <div>
          {canSubmitSuggetedEdits &&
            <>
              <div className="text-xs text-gray-400">By pressing the Submit button your request will be sent to the organization administrator.</div>
              <div className="mt-3"></div>
            </>
          }
          <div>
            <Button
              size="small"
              onClick={() => {
                onCancel();
                setEditFields([]);
              }}>
              Cancel
            </Button>
            <Button
              type="primary"
              size="small"
              disabled={!canSubmitSuggetedEdits}
              onClick={async () => {
                await entities.ChangeRequest.bulkCreate(
                  editFields
                    .map(f => ({
                      entityId: id,
                      entity: entity.name,
                      fieldName: f.value,
                      fieldDisplayName: f.label,
                      oldValue: f.oldValue,
                      oldDisplayValue: f.oldDisplayValue,
                      newValue: f.newValue,
                      newDisplayValue: f.newDisplayValue,
                      status: 'NEW',
                    }))
                );

                onOk();
                setEditFields([]);
              }}
            >
              Submit request
            </Button>
          </div>
        </div>
      }
    >
      <div className="mt-6 mb-6 min-h-[150px]">
        <div>
          <Button
            size="small"
            onClick={() => {
              setEditFields([...editFields, {}]);
            }}
            icon={<PlusOutlined />}
          >
            Add a Change Request
          </Button>
        </div>

        <div className="mt-6"></div>

        <div className="grid grid-cols-[3fr_3fr_1fr]">
          <div className="border-b-[1px]">Field / Area</div>
          <div className="border-b-[1px] pl-2">New Value / Comment</div>
          <div className="border-b-[1px] pl-2"></div>

          {editFields.length == 0 &&
            <div className="col-span-full mt-5 h-full flex items-center justify-center text-gray-400">
              Please start by clicking the "Add a Change Request" button to suggest an edit.
            </div>
          }

          {editFields.map((field, index) => (
            <React.Fragment key={index}>
              <div className="mt-2 flex items-center">
                <Select
                  className="w-full"
                  size="small"
                  onChange={(value) => {
                    setEditFields(editFields.map((ef, i) => {
                      if (i == index) {
                        const f = changeRequestFields.find(f => f.value == value);
                        return {
                          ...f,
                          oldValue: data?.[value],
                          oldDisplayValue: f.type == 'select' ? extractTextFromJSX(f.options.find(o => o.value == data?.[value])?.label).trim() : data?.[value],
                          newValue: data?.[value],
                          newDisplayValue: f.type == 'select' ? extractTextFromJSX(f.options.find(o => o.value == data?.[value])?.label).trim() : data?.[value],
                        }
                      } else {
                        return ef;
                      }
                    }));
                  }}
                  options={changeRequestFields.map(f => ({ value: f.value, label: f.label }))}
                  showSearch={true}
                  allowClear={true}
                />
              </div>
              <div className="mt-2 pl-2 flex items-center">
                {field.type == 'text' &&
                  <Input
                    size="small"
                    value={field.newValue}
                    onChange={(e) => {
                      setEditFields(editFields.map((f, i) => i == index ? {
                        ...f,
                        newValue: e.target.value,
                        newDisplayValue: e.target.value,
                      } : f));
                    }}
                  />
                }
                {field.type == 'select' &&
                  <Select
                    size="small"
                    className="w-full"
                    value={field.newValue}
                    onChange={(value) => {
                      setEditFields(editFields.map((f, i) => i == index ? {
                        ...f,
                        newValue: value,
                        newDisplayValue: extractTextFromJSX(field.options.find(o => o.value == value)?.label).trim(),
                      } : f));
                    }}
                    options={field.options}
                  />
                }
                {field.type == null &&
                  <Input
                    size="small"
                    disabled={true}
                    value=""
                  />
                }
              </div>
              <div className="mt-2 pl-2 flex items-center">
                <Button
                  type="default"
                  size={'small'}
                  icon={icons.Trash}
                  onClick={() => {
                    setEditFields(editFields.filter((f, i) => i != index));
                  }}
                >
                  Remove
                </Button>
              </div>
            </React.Fragment>
          ))}

        </div>

      </div>
    </Modal>
  )
}

export default ChangeRequestDialog