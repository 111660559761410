import React, { useEffect, useRef } from 'react'
import useFetchEntities from 'hooks/useFetchEntities';
import FormSelect from 'components/Form/FormSelect';
import { formatDate, formatTime } from 'utils/generateEntityColumns';
import { Link } from 'react-router-dom';
import { HmsFormContext } from 'components/Form/HmsFormContext';
import useFetchEntity from 'hooks/useFetchEntity';

const GameSelect = (props) => {
  return (
    <HmsFormContext.Consumer>
      {({ data, setFieldValue }) =>
        <GameSelectInner {...props} setFieldValue={setFieldValue} formData={data} />
      }
    </HmsFormContext.Consumer>
  )
}

const GameSelectInner = ({ name, label, competitionId, setFieldValue, formData, required }) => {
  const { data: competitionData } = useFetchEntity('Competition', competitionId, 'Seasons>Groups>Phases');
  const phases = competitionData?.Seasons?.flatMap(s => s.Groups?.flatMap(g => g.Phases))?.map(p => p.phaseId);

  const { data, isLoading } = useFetchEntities(phases && 'Game', {
    filters: {
      phaseId: phases,
    },
    relations: [
      'Venue(name)',
      'HomeTeam(short)',
      'AwayTeam(short)'
    ],
    onlyAttributes: [
      'gameId',
      'startDate',
      'startTime',
    ],
  });

  useEffect(() => {
    if (competitionData != null && data != null) {
      console.log(`competitionId changed => clearing gameId`);
      setFieldValue('gameId', null);
    }
  }, [competitionId]);

  return (
    <FormSelect
      isLoading={isLoading}
      allowClear
      name={name}
      label={label}
      formItemProps={{
        required: required,
        suffix: (
          formData?.gameId ?
            <Link to={`/games/${formData.gameId}`} target="_blank">
              <span>Open</span>
            </Link>
          :
            <Link to={null} disabled={true}>
              <span>Open</span>
            </Link>
        )
      }}
      options={
        data
          ?.sort((a, b) => ('' + a.startDate + a.startTime).localeCompare('' + b.startDate + b.startTime))
          ?.map(g => ({
            value: g.gameId,
            searchText: `${formatDate(g.startDate)} ${formatTime(g.startTime)} ${g.HomeTeam?.short && g.HomeTeam?.short != '' && g.AwayTeam?.short && g.AwayTeam?.short != '' && g.HomeTeam?.short + ' vs ' + g.AwayTeam?.short} ${g.Venue?.name}`,
            label: <span className="flex items-center gap-2">
              <span>{g.startDate ? formatDate(g.startDate) : 'no date'} {formatTime(g.startTime)}</span>
              <span className="text-gray-500">•</span>
              <span>{g.HomeTeam?.short && g.HomeTeam?.short != '' && g.AwayTeam?.short && g.AwayTeam?.short != '' && g.HomeTeam?.short + ' vs ' + g.AwayTeam?.short}</span>
              <span className="text-gray-500">•</span>
              <span>{g.Venue?.name ?? 'no venue'}</span>
            </span>
          }))
      }
    />
  )
}

export default GameSelect