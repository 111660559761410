import { getDefaultCardWidth } from 'utils/uiPreferences'

function MessagesBar({ messages }) {
  return (
    <>
      {messages?.length > 0 &&
        <div className={`bg-[#ddeeff] text-[#2280ff] rounded-md mb-3 p-3 ${getDefaultCardWidth(true)} flex items-center justify-between border-[1px] border-[#cee7ff]`}>
          <div className="w-full flex items-center gap-3">
            <div>
              {messages.map((warnComp, i) => (
                <div key={i}>{warnComp}</div>
              ))}
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default MessagesBar