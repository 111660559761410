import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useParams, useSearchParams, useNavigate } from 'react-router-dom';

const PaymentStatus = () => {
  const [ searchParams ] = useSearchParams();
  const id = searchParams.get('id');
  const refId = searchParams.get('refId');

  return (
    <>
      <h1>Payment Status</h1>
      <div>
        id: {id}
      </div>
      <div>
        refId: {refId}
      </div>
    </>
  )
}

export default PaymentStatus
