import React from 'react'
import { Radio } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';

const YesNoControl = ({ value, onChange, ...props }) => {
    const handleChange = (e) => {
        const newValue = e.target.value === 'yes' ? true : false;
        console.log('newValue', newValue);
        onChange(newValue);
    };

    return (
        <Radio.Group onChange={handleChange} value={value ? 'yes' : 'no'} {...props}>
            <Radio value="yes">Yes</Radio>
            <Radio value="no">No</Radio>
        </Radio.Group>
    );
}

const FormYesNo = ({ label, name, required, autoFocus, placeholder, formItemProps, fieldItemProps }) => {
      return (
        <HmsFormItem
            label={label}
            name={name}
            required={required}
            {...formItemProps}
        >
            <YesNoControl
                {...fieldItemProps}
            />
        </HmsFormItem>
    )
}

export default FormYesNo