import React, { useEffect, useRef } from 'react';
import { Popconfirm, Button } from 'antd';

function KeyboardPopconfirm({ onConfirm, onCancel, okText, okButtonProps, okHotkey: okHotkeyParam, cancelHotkey: cancelHotkeyParam, children, ...restProps }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const okHotkey = okHotkeyParam !== undefined ? (okHotkeyParam ?? 'Enter') : undefined;
  const cancelHotkey = cancelHotkeyParam !== undefined ? (cancelHotkeyParam ?? 'Escape') : undefined;
  const [isBusy, setIsBusy] = React.useState(null);

  const handleCancel = async () => {
    setIsBusy('cancel');
    await onCancel?.();
    setIsBusy(null);
    setIsOpen(false);
  }

  const handleConfirm = async () => {
    setIsBusy('ok');
    await onConfirm?.();
    setIsBusy(null);
    setIsOpen(false);
  }

  const handleOpen = () => {
    setIsBusy(null);
    setIsOpen(true);
  }

  useEffect(() => {
    function handleKeyPress(event) {
      if (isOpen && isBusy == null) {  // just to double-check!
        if (event.key === okHotkey || event.keyCode === okHotkey) {
          // console.log('KeyboardPopconfirm - keypress: ' + okHotkey + ' - calling handleConfirm()');
          handleConfirm();
          return;
        }
        if (event.key === cancelHotkey || event.keyCode === cancelHotkey) {
          // console.log('KeyboardPopconfirm - keypress: ' + cancelHotkey + ' - calling handleCancel()');
          handleCancel();
          return;
        }
      }
    }

    if (isOpen) {
      console.log('KeyboardPopconfirm - registering keydown listener... (' + okHotkey + ')');
      document.addEventListener('keydown', handleKeyPress);
    } else {
      console.log('KeyboardPopconfirm - REMOVING keydown listener... (' + okHotkey + ') - due to not open state');
      document.removeEventListener('keydown', handleKeyPress);
    }

    return () => {
      console.log('KeyboardPopconfirm - REMOVING keydown listener... (' + okHotkey + ') - due to unload');
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isOpen]);


  return (
    <Popconfirm
      {...restProps}
      open={isOpen}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      okButtonProps={isBusy == 'ok' ? { ...(okButtonProps ?? {}), loading: true } : { ...(okButtonProps ?? {}) }}
    >
      <span onClick={handleOpen}>{children}</span>
    </Popconfirm>
  );
}

export default KeyboardPopconfirm;
