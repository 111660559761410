import PlayerLevelTag from 'components/PlayerLevelTag';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react'
import { getImage } from 'utils/getImage';

const StandingsPlayerCell = ({ player }) => {
  const isMobile = useIsMobile();

  return (
    <div className={`table-cell align-middle py-1 pl-1 desktop:pl-0 font-roboto-condensed text-[0.9rem] desktop:text-[1.2rem] border-gray-300 border-b-[1px]`}>
      <span className="flex items-center gap-2">
        <span
          className="inline-block w-6 h-3 rounded-[5px] bg-gray-300 bg-cover bg-center"
          style={{ backgroundImage: `url('${getImage(player)}')`, backgroundSize: '150%' }}
        ></span>
        <span className="max-w-[calc(34vw-30px)] truncate text-ellipsis overflow-hidden">{
          isMobile ? (player.firstName?.slice(0, 1) + '.' + player.lastName) : (player.firstName + ' ' + player.lastName)}
          <span className="ml-2 inline-block font-light text-gray-500">{player.jerseyNumber ? '#' + player.jerseyNumber : ''}</span>
        </span>
      </span>
    </div>
  )
}

export default StandingsPlayerCell