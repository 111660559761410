import React, { createContext, useContext, useState, useEffect } from 'react';
import HmsEntityFormController from 'components/Form/HmsEntityFormController';
import useEntities from 'hooks/useEntities';
import HmsEntityFormGridBasic from 'components/Form/HmsEntityFormGridBasic';
import useLoadEntity from 'hooks/useLoadEntity';
import HmsEntityFormGridFullWidth from 'components/Form/HmsEntityFormGridFullWidth';
import HmsField from 'components/Form/HmsField';
import FormSelect from 'components/Form/FormSelect';
import useFetchEntities from 'hooks/useFetchEntities';
import { Button, Card, Cascader, Form, Space, Tabs } from 'antd';
import PlayerSelect from 'components/Form/PlayerSelect';
import PositionSelect from 'components/Form/PositionSelect';
import JerseySelect from 'components/Form/JerseySelect';
import RemoveButton from 'components/RemoveButton';
import FormCascader from 'components/Form/FormCascader';
import { PlusOutlined } from '@ant-design/icons';
import FormCheckbox from 'components/Form/FormCheckbox';
import useInterval from 'use-interval';
import { Checkbox, Input, Row, Col, DatePicker, Select } from 'antd';
import HmsFormItem from 'components/Form/HmsFormItem';
import FormDatePicker from 'components/Form/FormDatePicker';
import FormRangePicker from 'components/Form/FormRangePicker';
import TeamSelect from 'components/Form/TeamSelect';

const GameNews = () => {
  return (
    <>
      <HmsEntityFormGridFullWidth>
        <HmsField name="headline" />
      </HmsEntityFormGridFullWidth>
      <HmsEntityFormGridFullWidth>
        <HmsField name="perex" />
      </HmsEntityFormGridFullWidth>
      <HmsEntityFormGridFullWidth>
        <HmsField name="body" />
      </HmsEntityFormGridFullWidth>
    </>
  )
}

export default GameNews;
