import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import useLoginStore from 'store/loginStore'

const NavigateHome = ({ homesByRole, replace }) => {
  const roles = useLoginStore(s => s.roles);
  const navigate = useNavigate();

  useEffect(() => {
    const home = homesByRole[roles[0]];
    navigate(home, { replace });
  }, [roles]);
}

export default NavigateHome