import { create } from 'zustand';
import moment from 'moment';

const useNotificationStore = create((set, get) => ({
    id: 0,
    messages: [],

    addMessage: (message) => {
        const newMessage = (typeof message == 'object') ? message : { text: message, type: 'error' };
        set((state) => ({
            messages: [...state.messages, {
                    ...newMessage,
                    id: state.id,
                    expires: moment().add(newMessage.expiration ?? 3, 'seconds')
                } ],
            id: state.id + 1,
        }));
    },

    removeExpiredMessages: () => {
        set((state) => ({
            messages: state.messages.filter(m => {
                return moment().isBefore(m.expires);
            }),
        }));
    }    

}));

export default useNotificationStore;
